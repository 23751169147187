// @flow
import deepEqual from "fast-deep-equal";
import { isNumber } from "react-redux-flow-tools";

// todo backport to react-redux-flow-tools
export const valueToString = <T>(value: T): string => {
  if (value === undefined) {
    return "undefined";
  } else {
    const str = JSON.stringify(value);
    if (/^".*"$/.test(str)) {
      return str.slice(1, -1);
    } else {
      return str;
    }
  }
};

export const ValidationError = (value: mixed, type: string) =>
  new Error(
    `ValidationError: value ${JSON.stringify(value)} is not a ${type}!`,
  );
export const toValidator = <T>(fn: (T) => T): ((?T) => T) => (value: ?T): T => {
  if (value == null) {
    throw ValidationError(value, fn.toString());
  } else {
    return fn(value);
  }
};
export const validateBoolean = (
  value: ?mixed,
  strict: boolean = true,
): boolean => {
  if (
    ["true", "1", 1, true].includes(
      typeof value === "string" ? value.toLowerCase() : value,
    )
  ) {
    return true;
  } else if (
    ["false", "0", 0, false].includes(
      typeof value === "string" ? value.toLowerCase() : value,
    )
  ) {
    return false;
  } else if (strict) {
    throw ValidationError(value, "boolean");
  } else {
    return false;
  }
};
export const validateNumber = (number: ?mixed): number => {
  if (isNumber(number)) {
    return Number(number);
  } else {
    throw ValidationError(number, "number");
  }
};
export const validateString = (value: ?mixed): string => {
  if (value != null && typeof value === "string") {
    return value;
  } else {
    throw ValidationError(value, "string");
  }
};

export const validateObject = <T>(value: ?mixed): T => {
  if (value != null && typeof value === "object") {
    // $FlowFixMe
    return value;
  } else {
    throw ValidationError(value, "object");
  }
};
// courtesy of https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
export const validateDate = (value: ?mixed): Date => {
  if (value != null) {
    if (value instanceof Date) {
      return value;
    }
    const maxSecondDate = 2147483647;
    const valNumber = Number(value);
    const coerced = new Date(
      isNumber(value)
        ? valNumber <= maxSecondDate
          ? valNumber * 1000
          : valNumber
        : valueToString(value),
    );
    if (
      Object.prototype.toString.call(coerced) === "[object Date]" &&
      !isNaN(coerced.getTime())
    ) {
      return Object.freeze(coerced);
    }
  }
  throw ValidationError(value, "Date");
};
export const validateListOf = <T>(
  validator: (?T) => T,
  value: ?mixed,
): $ReadOnlyArray<T> => {
  if (typeof value === "object" && value instanceof Array) {
    const coerced = value.map(validator);
    if (coerced.every((value) => value != null)) {
      return coerced;
    }
  }
  throw ValidationError(value, "list");
};

export const notNull = <T>(validator: (T) => T): ((?T) => T) => (
  value: ?T,
): T => {
  if (value != null) {
    return validator(value);
  } else {
    throw ValidationError(value, "not null");
  }
};

export const validateEnum = <T>(valid: $ReadOnlyArray<T>, value: ?mixed): T => {
  const pos = valid.findIndex((validValue) => deepEqual(validValue, value));
  if (pos < 0) {
    throw ValidationError(value, JSON.stringify(valid));
  }
  return valid[pos];
};
