// @flow
import { createActions } from "redux-actions";
import { ident } from "react-redux-flow-tools";
import type { ActionCreatorType, DispatchType, GetStateType } from "./types";
import { CandleList, Currency } from "../api/models";
import { getClient } from "../api";
import { getStore } from "../storage/store";
import type { CurrencySymbolType } from "../api/models";

type CandlesActionTypes = {|
  +candles: {|
    +receiveLoading: ActionCreatorType<boolean>,
    +receiveCandles: ActionCreatorType<CandleList>,
    +receiveCandles24: ActionCreatorType<CandleList>,
  |},
|};

export const candlesActions: CandlesActionTypes = createActions({
  candles: {
    receiveLoading: ident,
    receiveCandles: ident,
    receiveCandles24: ident,
  },
});

const fetchCandles = async (base: Currency) => {
  const client = await getClient();
  const until = new Date();
  const since = new Date();
  since.setDate(until.getDate() - 14);
  const response = await client.apis.candles.get_candles__type___base___quote_({
    base: base.symbol,
    quote: "USD",
    type: base.type,
    count: 500,
    aggregate: 120,
    since: since.toISOString(),
    until: until.toISOString(),
  });
  const candleList = new CandleList(response.body);
  getStore().then((store) =>
    store.dispatch(candlesActions.candles.receiveCandles(candleList)),
  );
};

const fetchCandles24 = async (base: Currency) => {
  const client = await getClient();
  const until = new Date();
  const since = new Date();
  since.setDate(until.getDate() - 1);
  const aggregate = 5; // in minutes
  const count = (24 * 60) / aggregate; // 24hrs worth
  const response = await client.apis.candles.get_candles__type___base___quote_({
    base: base.symbol,
    quote: "USD",
    type: base.type,
    count,
    aggregate,
    since: since.toISOString(),
    until: until.toISOString(),
  });
  const candleList = new CandleList(response.body);
  const store = await getStore();
  store.dispatch(candlesActions.candles.receiveCandles24(candleList));
};

export const refreshCandles = (
  ...coins: $ReadOnlyArray<CurrencySymbolType>
) => async (dispatch: DispatchType, getState: GetStateType) => {
  const fetchCoinSymbols =
    coins.length > 0 ? coins : Object.keys(getState().currencies.used);
  if (fetchCoinSymbols.length === 0) {
    return;
  }
  const fetchCoins = fetchCoinSymbols
    .map((symbol) => getState().currencies.available[symbol])
    .filter((coin) => coin != null);
  if (fetchCoins.length === 0) {
    return;
  }
  dispatch(candlesActions.candles.receiveLoading(true));
  await Promise.all([
    ...fetchCoins.map((base) => fetchCandles(base)),
    ...fetchCoins.map((base) => fetchCandles24(base)),
  ]);
  dispatch(candlesActions.candles.receiveLoading(false));
};
