// @flow
import React from "react";
import moize from "moize";
import { noop } from "react-redux-flow-tools";
import { Platform, ART, StyleSheet, View } from "react-native";
import type { StyleType } from "../theme/theme";
import { composeStyles, ThemeContext } from "../theme/theme";
import { flexCenter, lightnessTextColor } from "../theme/common";
import type { LayoutEventType } from "../../models";
import { currentDeviceType } from "../layout/Device";
import { circlePath } from "../svg/svg";

const { Surface, Shape, Transform, Text } = ART;

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
  }),
});

type IconPropsType = {|
  +fill?: string,
  +path?: string | $ReadOnlyArray<string>,
  +baseSize?: number,
  +style?: StyleType,
  +size?: number,
  +text?: string,
|};

type IconComponentStateType = {|
  measuredSize: number,
|};

export class Icon extends React.PureComponent<
  IconPropsType,
  IconComponentStateType,
> {
  path = "";
  baseSize = 24;

  state = {
    measuredSize: 1,
  };

  _onLayout = ({
    nativeEvent: {
      layout: { width, height },
    },
  }: LayoutEventType) => {
    const measuredSize = Math.round(Math.min(width, height));
    if (this.state.measuredSize !== measuredSize) {
      this.setState({ measuredSize });
    }
  };

  _overrideStyle = moize((style?: StyleType) => [styles.container, style], {
    isDeepEqual: true,
  });
  _sizedStyle = moize((base: $ReadOnlyArray<StyleType>, size?: number) => [
    ...base,
    size != null ? { width: size, height: size } : {},
  ]);

  render() {
    const {
      size,
      path = this.path,
      baseSize = this.baseSize,
      fill,
      style,
      text,
    } = this.props;
    const { measuredSize } = this.state;
    const iconSize = size == null ? measuredSize : size;
    const scalingFactor = iconSize / baseSize;
    const overrideStyle = this._overrideStyle(style);
    const sizedStyle = this._sizedStyle(overrideStyle, size);
    const pathArray = path instanceof Array ? path : [path];
    return (
      <View onLayout={size == null ? this._onLayout : noop} style={sizedStyle}>
        <Surface visible={true} width={iconSize} height={iconSize}>
          <ThemeContext.Consumer>
            {({ colors }) =>
              pathArray.map((path, idx) => (
                <Shape
                  key={idx}
                  transform={new Transform().scale(scalingFactor)}
                  fill={fill != null ? fill : colors.juno}
                  d={path}
                />
              ))
            }
          </ThemeContext.Consumer>
          {text != null && text !== "" && (
            <ThemeContext.Consumer>
              {({ colors }) => (
                <Text
                  x={iconSize / 2}
                  y={
                    iconSize / 2 -
                    (Platform.OS === "web"
                      ? iconSize / 5.5 - 0.03 * iconSize
                      : iconSize / 4.125)
                  }
                  fill={lightnessTextColor(fill != null ? fill : colors.juno)}
                  font={`${iconSize / 2.75}px Arial`}
                  alignment="center"
                >
                  {text}
                </Text>
              )}
            </ThemeContext.Consumer>
          )}
        </Surface>
      </View>
    );
  }
}

export class ValueChangeGraphIcon extends Icon {
  path =
    "M 3.5 18.49 l 6 -6.01 l 4 4 L 22 6.92 l -1.41 -1.41 l -7.09 7.97 l -4 -4 L 2 16.99 Z";
}

export class StreamGraphIcon extends Icon {
  path =
    "M 17 16.99 c -1.35 0 -2.2 0.42 -2.95 0.8 c -0.65 0.33 -1.18 0.6 -2.05 0.6 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.57 -0.8 -2.95 -0.8 s -2.2 0.42 -2.95 0.8 c -0.65 0.33 -1.17 0.6 -2.05 0.6 v 1.95 c 1.35 0 2.2 -0.42 2.95 -0.8 c 0.65 -0.33 1.17 -0.6 2.05 -0.6 s 1.4 0.25 2.05 0.6 c 0.75 0.38 1.57 0.8 2.95 0.8 s 2.2 -0.42 2.95 -0.8 c 0.65 -0.33 1.18 -0.6 2.05 -0.6 c 0.9 0 1.4 0.25 2.05 0.6 c 0.75 0.38 1.58 0.8 2.95 0.8 v -1.95 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.6 -0.8 -2.95 -0.8 Z m 0 -4.45 c -1.35 0 -2.2 0.43 -2.95 0.8 c -0.65 0.32 -1.18 0.6 -2.05 0.6 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.57 -0.8 -2.95 -0.8 s -2.2 0.43 -2.95 0.8 c -0.65 0.32 -1.17 0.6 -2.05 0.6 v 1.95 c 1.35 0 2.2 -0.43 2.95 -0.8 c 0.65 -0.35 1.15 -0.6 2.05 -0.6 s 1.4 0.25 2.05 0.6 c 0.75 0.38 1.57 0.8 2.95 0.8 s 2.2 -0.43 2.95 -0.8 c 0.65 -0.35 1.15 -0.6 2.05 -0.6 s 1.4 0.25 2.05 0.6 c 0.75 0.38 1.58 0.8 2.95 0.8 v -1.95 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.6 -0.8 -2.95 -0.8 Z m 2.95 -8.08 c -0.75 -0.38 -1.58 -0.8 -2.95 -0.8 s -2.2 0.42 -2.95 0.8 c -0.65 0.32 -1.18 0.6 -2.05 0.6 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.37 -1.57 -0.8 -2.95 -0.8 s -2.2 0.42 -2.95 0.8 c -0.65 0.33 -1.17 0.6 -2.05 0.6 v 1.93 c 1.35 0 2.2 -0.43 2.95 -0.8 c 0.65 -0.33 1.17 -0.6 2.05 -0.6 s 1.4 0.25 2.05 0.6 c 0.75 0.38 1.57 0.8 2.95 0.8 s 2.2 -0.43 2.95 -0.8 c 0.65 -0.32 1.18 -0.6 2.05 -0.6 c 0.9 0 1.4 0.25 2.05 0.6 c 0.75 0.38 1.58 0.8 2.95 0.8 V 5.04 c -0.9 0 -1.4 -0.25 -2.05 -0.58 Z M 17 8.09 c -1.35 0 -2.2 0.43 -2.95 0.8 c -0.65 0.35 -1.15 0.6 -2.05 0.6 s -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.57 -0.8 -2.95 -0.8 s -2.2 0.43 -2.95 0.8 c -0.65 0.35 -1.15 0.6 -2.05 0.6 v 1.95 c 1.35 0 2.2 -0.43 2.95 -0.8 c 0.65 -0.32 1.18 -0.6 2.05 -0.6 s 1.4 0.25 2.05 0.6 c 0.75 0.38 1.57 0.8 2.95 0.8 s 2.2 -0.43 2.95 -0.8 c 0.65 -0.32 1.18 -0.6 2.05 -0.6 c 0.9 0 1.4 0.25 2.05 0.6 c 0.75 0.38 1.58 0.8 2.95 0.8 V 9.49 c -0.9 0 -1.4 -0.25 -2.05 -0.6 c -0.75 -0.38 -1.6 -0.8 -2.95 -0.8 Z";
}

export class LayeredGraphIcon extends Icon {
  path =
    "M 9 3 L 5 6.99 h 3 V 14 h 2 V 6.99 h 3 L 9 3 Z m 7 14.01 V 10 h -2 v 7.01 h -3 L 15 21 l 4 -3.99 h -3 Z";
}

export class AutoThemeIcon extends Icon {
  path =
    "M 20 8.69 V 4 h -4.69 L 12 0.69 L 8.69 4 H 4 v 4.69 L 0.69 12 L 4 15.31 V 20 h 4.69 L 12 23.31 L 15.31 20 H 20 v -4.69 L 23.31 12 L 20 8.69 Z M 12 18 c -0.89 0 -1.74 -0.2 -2.5 -0.55 C 11.56 16.5 13 14.42 13 12 s -1.44 -4.5 -3.5 -5.45 C 10.26 6.2 11.11 6 12 6 c 3.31 0 6 2.69 6 6 s -2.69 6 -6 6 Z";
}

export class CloseIcon extends Icon {
  path =
    "M 19 6.41 L 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 Z";
}

export class CompactModeIcon extends Icon {
  path =
    "M 20 13 H 3 c -0.55 0 -1 0.45 -1 1 v 6 c 0 0.55 0.45 1 1 1 h 17 c 0.55 0 1 -0.45 1 -1 v -6 c 0 -0.55 -0.45 -1 -1 -1 Z m 0 -10 H 3 c -0.55 0 -1 0.45 -1 1 v 6 c 0 0.55 0.45 1 1 1 h 17 c 0.55 0 1 -0.45 1 -1 V 4 c 0 -0.55 -0.45 -1 -1 -1 Z";
}

export class DarkThemeIcon extends Icon {
  path =
    "M 10 2 c -1.82 0 -3.53 0.5 -5 1.35 C 7.99 5.08 10 8.3 10 12 s -2.01 6.92 -5 8.65 C 6.47 21.5 8.18 22 10 22 c 5.52 0 10 -4.48 10 -10 S 15.52 2 10 2 Z";
}

export class ExpandIcon extends Icon {
  path = "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z";
}

export class CollapseIcon extends Icon {
  path = "M 7.41 15.41 L 12 10.83 l 4.59 4.58 L 18 14 l -6 -6 l -6 6 Z";
}

export class FaceIcon extends Icon {
  path =
    "M 9 11.75 c -0.69 0 -1.25 0.56 -1.25 1.25 s 0.56 1.25 1.25 1.25 s 1.25 -0.56 1.25 -1.25 s -0.56 -1.25 -1.25 -1.25 Z m 6 0 c -0.69 0 -1.25 0.56 -1.25 1.25 s 0.56 1.25 1.25 1.25 s 1.25 -0.56 1.25 -1.25 s -0.56 -1.25 -1.25 -1.25 Z M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 0 18 c -4.41 0 -8 -3.59 -8 -8 c 0 -0.29 0.02 -0.58 0.05 -0.86 c 2.36 -1.05 4.23 -2.98 5.21 -5.37 C 11.07 8.33 14.05 10 17.42 10 c 0.78 0 1.53 -0.09 2.25 -0.26 c 0.21 0.71 0.33 1.47 0.33 2.26 c 0 4.41 -3.59 8 -8 8 Z";
}

export class FilledChartIcon extends Icon {
  path =
    "M 19 3 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z M 9 17 H 7 v -7 h 2 v 7 Z m 4 0 h -2 V 7 h 2 v 10 Z m 4 0 h -2 v -4 h 2 v 4 Z";
}

export class LaptopIcon extends Icon {
  path =
    "M 20 18 c 1.1 0 1.99 -0.9 1.99 -2 L 22 5 c 0 -1.1 -0.9 -2 -2 -2 H 4 c -1.1 0 -2 0.9 -2 2 v 11 c 0 1.1 0.9 2 2 2 H 0 c 0 1.1 0.9 2 2 2 h 20 c 1.1 0 2 -0.9 2 -2 h -4 Z M 4 5 h 16 v 11 H 4 V 5 Z m 8 14 c -0.55 0 -1 -0.45 -1 -1 s 0.45 -1 1 -1 s 1 0.45 1 1 s -0.45 1 -1 1 Z";
}

export class LightThemeIcon extends Icon {
  path =
    "M 20 8.69 V 4 h -4.69 L 12 0.69 L 8.69 4 H 4 v 4.69 L 0.69 12 L 4 15.31 V 20 h 4.69 L 12 23.31 L 15.31 20 H 20 v -4.69 L 23.31 12 L 20 8.69 Z M 12 18 c -3.31 0 -6 -2.69 -6 -6 s 2.69 -6 6 -6 s 6 2.69 6 6 s -2.69 6 -6 6 Z m 0 -10 c -2.21 0 -4 1.79 -4 4 s 1.79 4 4 4 s 4 -1.79 4 -4 s -1.79 -4 -4 -4 Z";
}

export class LockClosedIcon extends Icon {
  path =
    "M 18 8 h -1 V 6 c 0 -2.76 -2.24 -5 -5 -5 S 7 3.24 7 6 v 2 H 6 c -1.1 0 -2 0.9 -2 2 v 10 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 10 c 0 -1.1 -0.9 -2 -2 -2 Z m -6 9 c -1.1 0 -2 -0.9 -2 -2 s 0.9 -2 2 -2 s 2 0.9 2 2 s -0.9 2 -2 2 Z m 3.1 -9 H 8.9 V 6 c 0 -1.71 1.39 -3.1 3.1 -3.1 c 1.71 0 3.1 1.39 3.1 3.1 v 2 Z";
}

export class LockIcon extends Icon {
  path =
    "M 12 17 c 1.1 0 2 -0.9 2 -2 s -0.9 -2 -2 -2 s -2 0.9 -2 2 s 0.9 2 2 2 Z m 6 -9 h -1 V 6 c 0 -2.76 -2.24 -5 -5 -5 S 7 3.24 7 6 h 1.9 c 0 -1.71 1.39 -3.1 3.1 -3.1 c 1.71 0 3.1 1.39 3.1 3.1 v 2 H 6 c -1.1 0 -2 0.9 -2 2 v 10 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 10 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 12 H 6 V 10 h 12 v 10 Z";
}

export class MouseIcon extends Icon {
  path =
    "M 13 1.07 V 9 h 7 c 0 -4.08 -3.05 -7.44 -7 -7.93 Z M 4 15 c 0 4.42 3.58 8 8 8 s 8 -3.58 8 -8 v -4 H 4 v 4 Z m 7 -13.93 C 7.05 1.56 4 4.92 4 9 h 7 V 1.07 Z";
}

export class PhoneIcon extends Icon {
  path =
    "M 15.5 1 h -8 C 6.12 1 5 2.12 5 3.5 v 17 C 5 21.88 6.12 23 7.5 23 h 8 c 1.38 0 2.5 -1.12 2.5 -2.5 v -17 C 18 2.12 16.88 1 15.5 1 Z m -4 21 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z m 4.5 -4 H 7 V 4 h 9 v 14 Z";
}

export class RefreshIcon extends Icon {
  path =
    "M 17.65 6.35 C 16.2 4.9 14.21 4 12 4 c -4.42 0 -7.99 3.58 -7.99 8 s 3.57 8 7.99 8 c 3.73 0 6.84 -2.55 7.73 -6 h -2.08 c -0.82 2.33 -3.04 4 -5.65 4 c -3.31 0 -6 -2.69 -6 -6 s 2.69 -6 6 -6 c 1.66 0 3.14 0.69 4.22 1.78 L 13 11 h 7 V 4 l -2.35 2.35 Z";
}

export class TabletIcon extends Icon {
  path =
    "M 18.5 0 h -14 C 3.12 0 2 1.12 2 2.5 v 19 C 2 22.88 3.12 24 4.5 24 h 14 c 1.38 0 2.5 -1.12 2.5 -2.5 v -19 C 21 1.12 19.88 0 18.5 0 Z m -7 23 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z m 7.5 -4 H 4 V 3 h 15 v 16 Z";
}

export class UnfilledChartIcon extends Icon {
  path =
    "M 9 17 H 7 v -7 h 2 v 7 Z m 4 0 h -2 V 7 h 2 v 10 Z m 4 0 h -2 v -4 h 2 v 4 Z m 2.5 2.1 h -15 V 5 h 15 v 14.1 Z m 0 -16.1 h -15 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 15 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 Z";
}

export class WalletIcon extends Icon {
  path =
    "M 21 18 v 1 c 0 1.1 -0.9 2 -2 2 H 5 c -1.11 0 -2 -0.9 -2 -2 V 5 c 0 -1.1 0.89 -2 2 -2 h 14 c 1.1 0 2 0.9 2 2 v 1 h -9 c -1.11 0 -2 0.9 -2 2 v 8 c 0 1.1 0.89 2 2 2 h 9 Z m -9 -2 h 10 V 8 H 12 v 8 Z m 4 -2.5 c -0.83 0 -1.5 -0.67 -1.5 -1.5 s 0.67 -1.5 1.5 -1.5 s 1.5 0.67 1.5 1.5 s -0.67 1.5 -1.5 1.5 Z";
}

export class NotificationIcon extends Icon {
  path =
    "M 12 22 c 1.1 0 2 -0.9 2 -2 h -4 c 0 1.1 0.89 2 2 2 Z m 6 -6 v -5 c 0 -3.07 -1.64 -5.64 -4.5 -6.32 V 4 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 s -1.5 0.67 -1.5 1.5 v 0.68 C 7.63 5.36 6 7.92 6 11 v 5 l -2 2 v 1 h 16 v -1 l -2 -2 Z";
}

export class NotificationActiveIcon extends Icon {
  path =
    "M 7.58 4.08 L 6.15 2.65 C 3.75 4.48 2.17 7.3 2.03 10.5 h 2 c 0.15 -2.65 1.51 -4.97 3.55 -6.42 Z m 12.39 6.42 h 2 c -0.15 -3.2 -1.73 -6.02 -4.12 -7.85 l -1.42 1.43 c 2.02 1.45 3.39 3.77 3.54 6.42 Z M 18 11 c 0 -3.07 -1.64 -5.64 -4.5 -6.32 V 4 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 s -1.5 0.67 -1.5 1.5 v 0.68 C 7.63 5.36 6 7.92 6 11 v 5 l -2 2 v 1 h 16 v -1 l -2 -2 v -5 Z m -6 11 c 0.14 0 0.27 -0.01 0.4 -0.04 c 0.65 -0.14 1.18 -0.58 1.44 -1.18 c 0.1 -0.24 0.15 -0.5 0.15 -0.78 h -4 c 0.01 1.1 0.9 2 2.01 2 Z";
}

export class NotificationEmptyIcon extends Icon {
  path =
    "M 12 22 c 1.1 0 2 -0.9 2 -2 h -4 C 10 21.1 10.9 22 12 22 Z M 18 16 v -5 c 0 -3.07 -1.63 -5.64 -4.5 -6.32 V 4 c 0 -0.83 -0.67 -1.5 -1.5 -1.5 S 10.5 3.17 10.5 4 v 0.68 C 7.64 5.36 6 7.92 6 11 v 5 l -2 2 v 1 h 16 v -1 L 18 16 Z M 16 17 H 8 v -6 c 0 -2.48 1.51 -4.5 4 -4.5 s 4 2.02 4 4.5 V 17 Z";
}

export class FilledDotIcon extends Icon {
  path =
    "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z";
}

export class UnfilledDotIcon extends Icon {
  path =
    "M 12 2 C 6.47 2 2 6.47 2 12 s 4.47 10 10 10 s 10 -4.47 10 -10 S 17.53 2 12 2 Z m 0 18 c -4.41 0 -8 -3.59 -8 -8 s 3.59 -8 8 -8 s 8 3.59 8 8 s -3.59 8 -8 8 Z";
}

export class UploadIcon extends Icon {
  path =
    "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 14 13 v 4 h -4 v -4 H 7 l 5 -5 l 5 5 h -3 Z";
}

export class DownloadIcon extends Icon {
  path =
    "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 17 13 l -5 5 l -5 -5 h 3 V 9 h 4 v 4 h 3 Z";
}

export class NotDownloadedIcon extends Icon {
  path =
    "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 19 18 H 6 c -2.21 0 -4 -1.79 -4 -4 s 1.79 -4 4 -4 h 0.71 C 7.37 7.69 9.48 6 12 6 c 3.04 0 5.5 2.46 5.5 5.5 v 0.5 H 19 c 1.66 0 3 1.34 3 3 s -1.34 3 -3 3 Z";
}
export class DownloadedIcon extends Icon {
  path =
    "M 19.35 10.04 C 18.67 6.59 15.64 4 12 4 C 9.11 4 6.6 5.64 5.35 8.04 C 2.34 8.36 0 10.91 0 14 c 0 3.31 2.69 6 6 6 h 13 c 2.76 0 5 -2.24 5 -5 c 0 -2.64 -2.05 -4.78 -4.65 -4.96 Z M 10 17 l -3.5 -3.5 l 1.41 -1.41 L 10 14.17 L 15.18 9 l 1.41 1.41 L 10 17 Z";
}

export class UndoIcon extends Icon {
  path =
    "M 12.5 8 c -2.65 0 -5.05 0.99 -6.9 2.6 L 2 7 v 9 h 9 l -3.62 -3.62 c 1.39 -1.16 3.16 -1.88 5.12 -1.88 c 3.54 0 6.55 2.31 7.6 5.5 l 2.37 -0.78 C 21.08 11.03 17.15 8 12.5 8 Z";
}

export class ShareIcon extends Icon {
  path =
    "M 18 16.08 c -0.76 0 -1.44 0.3 -1.96 0.77 L 8.91 12.7 c 0.05 -0.23 0.09 -0.46 0.09 -0.7 s -0.04 -0.47 -0.09 -0.7 l 7.05 -4.11 c 0.54 0.5 1.25 0.81 2.04 0.81 c 1.66 0 3 -1.34 3 -3 s -1.34 -3 -3 -3 s -3 1.34 -3 3 c 0 0.24 0.04 0.47 0.09 0.7 L 8.04 9.81 C 7.5 9.31 6.79 9 6 9 c -1.66 0 -3 1.34 -3 3 s 1.34 3 3 3 c 0.79 0 1.5 -0.31 2.04 -0.81 l 7.12 4.16 c -0.05 0.21 -0.08 0.43 -0.08 0.65 c 0 1.61 1.31 2.92 2.92 2.92 c 1.61 0 2.92 -1.31 2.92 -2.92 s -1.31 -2.92 -2.92 -2.92 Z";
}

export class BackIcon extends Icon {
  path =
    "M 15.41 16.59 L 10.83 12 l 4.58 -4.59 L 14 6 l -6 6 l 6 6 l 1.41 -1.41 Z";
}

export class HiddenIcon extends Icon {
  path =
    "M 12 7 c 2.76 0 5 2.24 5 5 c 0 0.65 -0.13 1.26 -0.36 1.83 l 2.92 2.92 c 1.51 -1.26 2.7 -2.89 3.43 -4.75 c -1.73 -4.39 -6 -7.5 -11 -7.5 c -1.4 0 -2.74 0.25 -3.98 0.7 l 2.16 2.16 C 10.74 7.13 11.35 7 12 7 Z M 2 4.27 l 2.28 2.28 l 0.46 0.46 C 3.08 8.3 1.78 10.02 1 12 c 1.73 4.39 6 7.5 11 7.5 c 1.55 0 3.03 -0.3 4.38 -0.84 l 0.42 0.42 L 19.73 22 L 21 20.73 L 3.27 3 L 2 4.27 Z M 7.53 9.8 l 1.55 1.55 c -0.05 0.21 -0.08 0.43 -0.08 0.65 c 0 1.66 1.34 3 3 3 c 0.22 0 0.44 -0.03 0.65 -0.08 l 1.55 1.55 c -0.67 0.33 -1.41 0.53 -2.2 0.53 c -2.76 0 -5 -2.24 -5 -5 c 0 -0.79 0.2 -1.53 0.53 -2.2 Z m 4.31 -0.78 l 3.15 3.15 l 0.02 -0.16 c 0 -1.66 -1.34 -3 -3 -3 l -0.17 0.01 Z";
}

export class VisibleIcon extends Icon {
  path =
    "M 12 4.5 C 7 4.5 2.73 7.61 1 12 c 1.73 4.39 6 7.5 11 7.5 s 9.27 -3.11 11 -7.5 c -1.73 -4.39 -6 -7.5 -11 -7.5 Z M 12 17 c -2.76 0 -5 -2.24 -5 -5 s 2.24 -5 5 -5 s 5 2.24 5 5 s -2.24 5 -5 5 Z m 0 -8 c -1.66 0 -3 1.34 -3 3 s 1.34 3 3 3 s 3 -1.34 3 -3 s -1.34 -3 -3 -3 Z";
}

export class NotPinpointedIcon extends Icon {
  path =
    "M 20.94 11 c -0.46 -4.17 -3.77 -7.48 -7.94 -7.94 V 1 h -2 v 2.06 C 6.83 3.52 3.52 6.83 3.06 11 H 1 v 2 h 2.06 c 0.46 4.17 3.77 7.48 7.94 7.94 V 23 h 2 v -2.06 c 4.17 -0.46 7.48 -3.77 7.94 -7.94 H 23 v -2 h -2.06 Z M 12 19 c -3.87 0 -7 -3.13 -7 -7 s 3.13 -7 7 -7 s 7 3.13 7 7 s -3.13 7 -7 7 Z";
}

export class PinpointedIcon extends Icon {
  path =
    "M 12 8 c -2.21 0 -4 1.79 -4 4 s 1.79 4 4 4 s 4 -1.79 4 -4 s -1.79 -4 -4 -4 Z m 8.94 3 c -0.46 -4.17 -3.77 -7.48 -7.94 -7.94 V 1 h -2 v 2.06 C 6.83 3.52 3.52 6.83 3.06 11 H 1 v 2 h 2.06 c 0.46 4.17 3.77 7.48 7.94 7.94 V 23 h 2 v -2.06 c 4.17 -0.46 7.48 -3.77 7.94 -7.94 H 23 v -2 h -2.06 Z M 12 19 c -3.87 0 -7 -3.13 -7 -7 s 3.13 -7 7 -7 s 7 3.13 7 7 s -3.13 7 -7 7 Z";
}

export class EditIcon extends Icon {
  path =
    "M 3 17.25 V 21 h 3.75 L 17.81 9.94 l -3.75 -3.75 L 3 17.25 Z M 20.71 7.04 c 0.39 -0.39 0.39 -1.02 0 -1.41 l -2.34 -2.34 c -0.39 -0.39 -1.02 -0.39 -1.41 0 l -1.83 1.83 l 3.75 3.75 l 1.83 -1.83 Z";
}

export class AddIcon extends Icon {
  path =
    "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 5 11 h -4 v 4 h -2 v -4 H 7 v -2 h 4 V 7 h 2 v 4 h 4 v 2 Z";
}

export class RemoveIcon extends Icon {
  path =
    "M 12 2 C 6.48 2 2 6.48 2 12 s 4.48 10 10 10 s 10 -4.48 10 -10 S 17.52 2 12 2 Z m 5 11 H 7 v -2 h 10 v 2 Z";
}

export class FilterIcon extends Icon {
  path =
    "M 10 18 h 4 v -2 h -4 v 2 Z M 3 6 v 2 h 18 V 6 H 3 Z m 3 7 h 12 v -2 H 6 v 2 Z";
}

export class CameraIcon extends Icon {
  path = [
    circlePath(3.2, 12, 12),
    "M 9 2 L 7.17 4 H 4 c -1.1 0 -2 0.9 -2 2 v 12 c 0 1.1 0.9 2 2 2 h 16 c 1.1 0 2 -0.9 2 -2 V 6 c 0 -1.1 -0.9 -2 -2 -2 h -3.17 L 15 2 H 9 Z m 3 15 c -2.76 0 -5 -2.24 -5 -5 s 2.24 -5 5 -5 s 5 2.24 5 5 s -2.24 5 -5 5 Z",
  ];
}

export class ArrowIcon extends Icon {
  path = "M 16.01 11 H 4 v 2 h 12.01 v 3 L 20 12 l -3.99 -4 Z";
}

export class SetupIcon extends Icon {
  path =
    "M 10.82 12.49 c 0.02 -0.16 0.04 -0.32 0.04 -0.49 c 0 -0.17 -0.02 -0.33 -0.04 -0.49 l 1.08 -0.82 c 0.1 -0.07 0.12 -0.21 0.06 -0.32 l -1.03 -1.73 c -0.06 -0.11 -0.2 -0.15 -0.31 -0.11 l -1.28 0.5 c -0.27 -0.2 -0.56 -0.36 -0.87 -0.49 l -0.2 -1.33 c 0 -0.12 -0.11 -0.21 -0.24 -0.21 H 5.98 c -0.13 0 -0.24 0.09 -0.26 0.21 l -0.2 1.32 c -0.31 0.12 -0.6 0.3 -0.87 0.49 l -1.28 -0.5 c -0.12 -0.05 -0.25 0 -0.31 0.11 l -1.03 1.73 c -0.06 0.12 -0.03 0.25 0.07 0.33 l 1.08 0.82 c -0.02 0.16 -0.03 0.33 -0.03 0.49 c 0 0.17 0.02 0.33 0.04 0.49 l -1.09 0.83 c -0.1 0.07 -0.12 0.21 -0.06 0.32 l 1.03 1.73 c 0.06 0.11 0.2 0.15 0.31 0.11 l 1.28 -0.5 c 0.27 0.2 0.56 0.36 0.87 0.49 l 0.2 1.32 c 0.01 0.12 0.12 0.21 0.25 0.21 h 2.06 c 0.13 0 0.24 -0.09 0.25 -0.21 l 0.2 -1.32 c 0.31 -0.12 0.6 -0.3 0.87 -0.49 l 1.28 0.5 c 0.12 0.05 0.25 0 0.31 -0.11 l 1.03 -1.73 c 0.06 -0.11 0.04 -0.24 -0.06 -0.32 l -1.1 -0.83 Z M 7 13.75 c -0.99 0 -1.8 -0.78 -1.8 -1.75 s 0.81 -1.75 1.8 -1.75 s 1.8 0.78 1.8 1.75 S 8 13.75 7 13.75 Z M 18 1.01 L 8 1 c -1.1 0 -2 0.9 -2 2 v 3 h 2 V 5 h 10 v 14 H 8 v -1 H 6 v 3 c 0 1.1 0.9 2 2 2 h 10 c 1.1 0 2 -0.9 2 -2 V 3 c 0 -1.1 -0.9 -1.99 -2 -1.99 Z";
}

export class FileIcon extends Icon {
  path =
    "M 16.5 6 v 11.5 c 0 2.21 -1.79 4 -4 4 s -4 -1.79 -4 -4 V 5 c 0 -1.38 1.12 -2.5 2.5 -2.5 s 2.5 1.12 2.5 2.5 v 10.5 c 0 0.55 -0.45 1 -1 1 s -1 -0.45 -1 -1 V 6 H 10 v 9.5 c 0 1.38 1.12 2.5 2.5 2.5 s 2.5 -1.12 2.5 -2.5 V 5 c 0 -2.21 -1.79 -4 -4 -4 S 7 2.79 7 5 v 12.5 c 0 3.04 2.46 5.5 5.5 5.5 s 5.5 -2.46 5.5 -5.5 V 6 h -1.5 Z";
}

export class ImageIcon extends Icon {
  path =
    "M 21 19 V 5 c 0 -1.1 -0.9 -2 -2 -2 H 5 c -1.1 0 -2 0.9 -2 2 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 Z M 8.5 13.5 l 2.5 3.01 L 14.5 12 l 4.5 6 H 5 l 3.5 -4.5 Z";
}

export class PdfIcon extends Icon {
  path =
    "M 6 2 c -1.1 0 -1.99 0.9 -1.99 2 L 4 20 c 0 1.1 0.89 2 1.99 2 H 18 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 H 6 Z m 7 7 V 3.5 L 18.5 9 H 13 Z";
}

export const CurrentDeviceIcon =
  currentDeviceType() === "mobile"
    ? PhoneIcon
    : currentDeviceType() === "tablet"
    ? TabletIcon
    : LaptopIcon;
