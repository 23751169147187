// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers/index";

import type { DispatchType } from "../../actions/types";
import { Account as AccountComponent } from "./Account";

const mapStateToProps = (state: StateType) => ({
  account: state.account.account,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountComponent);
