// @flow
import React from "react";
import uuid from "uuid/v1";
import { scaleLinear, scaleLog, scaleTime } from "d3-scale";
import { rgb } from "d3-color";
import { areaRadial, curveCatmullRom, lineRadial } from "d3-shape";
import type { LayoutType } from "../../../models/Layout";
import { TimeSeries } from "../../../models/TimeSeries";
import { StretchedSVG } from "../../svg/StretchedSVG";
import {
  circlePath,
  G,
  Line,
  Path,
  Text,
  TextPath,
  transform,
} from "../../svg/svg";
import type { CurrencySymbolType } from "../../../api/models";
import type { DomainType, RangeType } from "../../svg/d3";
import { hexColor } from "../../svg/d3";
import { ThemeContext } from "../../theme/theme";
import { DeliberateHover } from "../../layout/DeliberateHover";
import { currenciesActions } from "../../../actions/currencies";
import { sensibleNumber } from "../../layout/SensibleNumber";

function scaleRadial() {
  const linear = scaleLinear();

  const scale = (x: number): number => {
    return Math.sqrt(linear(x));
  };

  scale.invert = (d: number): number => {
    return linear.invert(Math.pow(d, 2));
  };

  scale.domain = (domain?: DomainType) => {
    if (domain != null) {
      linear.domain(domain);
      return scale;
    } else {
      return linear.domain();
    }
  };

  scale.nice = (count) => {
    linear.nice(count);
    return scale;
  };

  scale.range = (range?: RangeType) => {
    if (range != null) {
      linear.range(range.map((x) => x * x));
      return scale;
    } else {
      return linear.range().map((x) => Math.sqrt(x));
    }
  };

  scale.ticks = linear.ticks;
  scale.tickFormat = linear.tickFormat;

  return scale;
}

type RadialDiagramGraphPropsType = {|
  +timeSeries: TimeSeries,
  +margin: number,
  +filled: boolean,
  +followMouse: boolean,

  +hoveredCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onHoverCurrency: typeof currenciesActions.currencies.hover,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
|};

type RadialDiagramComponentStateType = {|
  width: number,
  height: number,
|};

const strokeDarkness = 0.5;
const nRadiusTicks = 4;
const radiusTickArray = new Array(nRadiusTicks).fill(0);
const nAngleTicks = 13;
const angleTickArray = new Array(nAngleTicks).fill(0);

export class RadialDiagram extends React.PureComponent<
  RadialDiagramGraphPropsType,
  RadialDiagramComponentStateType,
> {
  gapAngle = 0.3;
  gapDegree = (this.gapAngle * 360) / Math.PI;
  gapSin = Math.sin(this.gapAngle);
  gapCos = Math.cos(this.gapAngle);
  angle: $Call<scaleTime>;
  radius: $Call<$PropertyType<typeof scaleRadial, "scale">>;
  valueArea: $Call<areaRadial>;
  valueLine: $Call<lineRadial>;
  deliberateHover = new DeliberateHover();

  static defaultProps = {
    margin: 60,
    filled: true,
    followMouse: true,
  };

  state = {
    width: 0,
    height: 0,
  };

  constructor(props: RadialDiagramGraphPropsType) {
    super(props);
    this.angle = scaleTime().range([
      this.gapAngle,
      2 * Math.PI - this.gapAngle,
    ]);
    this.radius = scaleRadial().domain([1, 2]);
    this.valueLine = areaRadial()
      .curve(curveCatmullRom)
      .angle(({ timestamp }) => this.angle(timestamp));

    this.valueArea = areaRadial()
      .curve(curveCatmullRom)
      .angle(({ timestamp }) => this.angle(timestamp))
      .innerRadius(() => this.radius(this.radius.domain()[0]));
  }

  _onLayout = ({ width, height }: LayoutType) => {
    const { margin } = this.props;
    const finalMargin = margin > 0 && margin < 1 ? margin * height : margin;
    this.radius.range([height / 5, height / 2 - finalMargin / 2]);
    this.setState({ width, height });
  };

  _onMouseEnter = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/radial-.*-/, "");
    if (!this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onEnter(() =>
        this.props.onHoverCurrency({ symbol, flag: true }),
      );
    }
  };
  _onMouseLeave = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/radial-.*-/, "");
    if (this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onLeave(() =>
        this.props.onHoverCurrency({ symbol, flag: false }),
      );
    }
  };
  _onSelect = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/radial-.*-/, "");
    if (this.props.selectedCurrencies.includes(symbol)) {
      this.props.onSelectCurrency({ symbol, flag: false });
    } else {
      this.props.onSelectCurrency({ symbol, flag: true });
    }
  };

  render() {
    const {
      timeSeries,
      filled,
      followMouse,
      hoveredCurrencies,
      selectedCurrencies,
    } = this.props;

    const { width, height } = this.state;
    if (timeSeries.isEmpty() || !width || !height) {
      return <StretchedSVG onLayout={this._onLayout} />;
    }

    this.angle.domain([
      timeSeries.head().timestamp,
      timeSeries.current().timestamp,
    ]);

    const minByKey = timeSeries.minByKey();
    const maxByKey = timeSeries.maxByKey();
    const rangeByKey = timeSeries
      .keys()
      .map((key) => ({ [key]: maxByKey[key] - minByKey[key] }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
    const data = timeSeries.values().map((row) => {
      return timeSeries
        .keys()
        .map((key) => ({
          [key]: 1 + (row[key] - minByKey[key]) / rangeByKey[key],
        }))
        .reduce((acc, obs) => ({ ...acc, ...obs }), {
          timestamp: row.timestamp,
        });
    });

    const unsortedKeys = timeSeries.keys();
    const averages = data
      .reduce((acc, row) => {
        unsortedKeys.forEach((key, idx) => {
          acc[idx] += row[key];
        });
        return acc;
      }, new Array(unsortedKeys.length).fill(0))
      .map((sum, idx) => [unsortedKeys[idx], sum / unsortedKeys.length])
      .sort((a, b) => b[1] - a[1]);
    let keys = averages.map(([key]) => key);
    if (
      selectedCurrencies.length !== 0 &&
      selectedCurrencies.length !== keys.length
    ) {
      keys = [
        ...keys.filter((key) => !selectedCurrencies.includes(key)),
        ...keys.filter((key) => selectedCurrencies.includes(key)),
      ];
    }
    if (hoveredCurrencies != null) {
      keys = [
        ...keys.filter((key) => !hoveredCurrencies.includes(key)),
        ...keys.filter((key) => hoveredCurrencies.includes(key)),
      ];
    }

    const radiusRange = this.radius.range()[1] - this.radius.range()[0];
    const radiusTickSize = radiusRange / (nRadiusTicks - 1);
    const angleRange = 2 * Math.PI - 2 * this.gapAngle;
    const angleTickSize = angleRange / (nAngleTicks - 1);
    const log = scaleLog()
      .domain(this.radius.range())
      .range(this.radius.range());
    const yesterdayTodayOffset = 16;
    return (
      <ThemeContext.Consumer>
        {({ colors, currencyColors }) => (
          <StretchedSVG onLayout={this._onLayout}>
            <G
              transform={transform({
                translate: `${width / 2}, ${height / 2}`,
              })}
            >
              <G className="axis">
                <G className="radius-axis">
                  {radiusTickArray.map((_, idx) => {
                    const radius =
                      this.radius.range()[0] + idx * radiusTickSize;
                    const pathId = uuid();
                    let ticks = ["MIN", "1/3", "2/3", "MAX"];
                    const showCurrencyArr =
                      hoveredCurrencies.length === 1
                        ? hoveredCurrencies
                        : selectedCurrencies.length === 1
                        ? selectedCurrencies
                        : [];
                    const showCurrency = showCurrencyArr[0];
                    if (showCurrency != null) {
                      const min = minByKey[showCurrency];
                      const max = maxByKey[showCurrency];
                      const step = rangeByKey[showCurrency] / 3;
                      if (min != null && max != null && step != null) {
                        ticks = [min, min + step, min + 2 * step, max].map(
                          (tick) => "$" + sensibleNumber(tick),
                        );
                      }
                    }
                    return (
                      <G key={"radius" + pathId}>
                        <Path
                          id={pathId}
                          d={circlePath(log(radius))}
                          fill="none"
                          stroke={colors.separator}
                          strokeOpacity={
                            idx === 0 || idx === nRadiusTicks - 1 ? 1 : 0.5
                          }
                          strokeWidth={1}
                        />
                        <Text
                          textAnchor="middle"
                          fill={colors.textPrimary}
                          fontSize={12}
                          dy={-3}
                        >
                          <TextPath
                            startOffset="25%"
                            href={`#${pathId}`}
                            xlinkHref={`#${pathId}`}
                          >
                            {/*{Math.round(this.radius.invert(log(radius)) * 100 - 100)}%*/}
                            {ticks[idx]}
                          </TextPath>
                        </Text>
                        {idx === nRadiusTicks - 1 && (
                          <G>
                            <Text
                              textAnchor="middle"
                              fill={colors.textPrimary}
                              fontSize={13}
                              dy={-16}
                            >
                              <TextPath
                                startOffset={`${yesterdayTodayOffset}%`}
                                href={`#${pathId}`}
                                xlinkHref={`#${pathId}`}
                              >
                                Today
                              </TextPath>
                            </Text>
                            <Text
                              textAnchor="middle"
                              fill={colors.textPrimary}
                              fontSize={13}
                              dy={-16}
                            >
                              <TextPath
                                startOffset={`${25 +
                                  (25 - yesterdayTodayOffset) -
                                  0.35}%`}
                                href={`#${pathId}`}
                                xlinkHref={`#${pathId}`}
                              >
                                Yesterday
                              </TextPath>
                            </Text>
                          </G>
                        )}

                        {idx === nRadiusTicks - 1 &&
                          angleTickArray.map((_, idx) => {
                            const hours =
                              (this.angle.domain()[0].getHours() + idx * 2) %
                              24;
                            const minutes = this.angle.domain()[0].getMinutes();
                            return (
                              <Text
                                key={`hour-minutes-${idx}-${hours}-${minutes}`}
                                textAnchor="middle"
                                fill={colors.textPrimary}
                                fontSize={12}
                                dy={-3}
                              >
                                <TextPath
                                  startOffset={
                                    ((((this.gapDegree / 2 +
                                      ((360 - this.gapDegree) / 12) * idx) /
                                      360) *
                                      100 +
                                      25) %
                                      100) +
                                    "%"
                                  }
                                  href={`#${pathId}`}
                                  xlinkHref={`#${pathId}`}
                                >
                                  {`${hours < 10 ? "0" : ""}${hours}:${
                                    minutes < 10 ? "0" : ""
                                  }${minutes}`}
                                </TextPath>
                              </Text>
                            );
                          })}
                      </G>
                    );
                  })}
                </G>
                <G className="angle-axis">
                  {angleTickArray.map((_, idx) => {
                    const angle = this.angle.range()[0] + idx * angleTickSize;
                    const radius = this.radius.range()[1];
                    return (
                      <Line
                        key={"angle" + idx}
                        x1={Math.sin(angle) * (height / 5)}
                        y1={-Math.cos(angle) * (height / 5)}
                        x2={Math.sin(angle) * radius}
                        y2={-Math.cos(angle) * radius}
                        stroke={colors.textSecondary}
                        strokeWidth={[0, 3, 6, 9, 12].includes(idx) ? 1 : 0.25}
                      />
                    );
                  })}
                </G>
              </G>
              <G
                className="layers"
                onMouseEnter={this.deliberateHover.onEnableHover}
                onMouseLeave={this.deliberateHover.onDisableHover}
              >
                {keys.map((key) => {
                  const fill = rgb(currencyColors(key));
                  const fillOpacity =
                    hoveredCurrencies.length + selectedCurrencies.length !==
                      0 &&
                    !hoveredCurrencies.includes(key) &&
                    !selectedCurrencies.includes(key)
                      ? 0.2
                      : 0.5;
                  const stroke = fill.darker(strokeDarkness);
                  this.valueLine.radius(({ [key]: value }) =>
                    this.radius(value),
                  );
                  this.valueArea.outerRadius(({ [key]: value }) =>
                    this.radius(value),
                  );
                  return (
                    <G key={key}>
                      {filled && (
                        <Path
                          stroke="none"
                          fill={hexColor(fill)}
                          fillOpacity={fillOpacity}
                          id={`radial-area-${key}`}
                          cursor="pointer"
                          className="radial-area"
                          onMouseEnter={followMouse ? this._onMouseEnter : null}
                          onMouseLeave={followMouse ? this._onMouseLeave : null}
                          onClick={this._onSelect}
                          d={this.valueArea(data)}
                        />
                      )}
                      <Path
                        fill="none"
                        stroke={hexColor(stroke)}
                        strokeWidth={fillOpacity === 0.5 ? 2 : 0.2}
                        strokeLinecap="round"
                        id={`radial-line-${key}`}
                        cursor="pointer"
                        className="radial-line"
                        onMouseEnter={followMouse ? this._onMouseEnter : null}
                        onMouseLeave={followMouse ? this._onMouseLeave : null}
                        onClick={this._onSelect}
                        d={this.valueLine(data)}
                      />
                    </G>
                  );
                })}
              </G>
              <G className="lines">
                {keys.map((key) => {
                  const fill = rgb(currencyColors(key));
                  const stroke = fill.darker(strokeDarkness);
                  const radiusStart = this.radius(data[0][key]);
                  const radiusEnd = this.radius(data[data.length - 1][key]);
                  const strokeWidth =
                    hoveredCurrencies.length + selectedCurrencies.length !==
                      0 &&
                    !hoveredCurrencies.includes(key) &&
                    !selectedCurrencies.includes(key)
                      ? 0.2
                      : 2;
                  return (
                    <Line
                      key={key}
                      x1={this.gapSin * radiusStart}
                      y1={-this.gapCos * radiusStart}
                      x2={-this.gapSin * radiusEnd}
                      y2={-this.gapCos * radiusEnd}
                      id={`radial-trendline-${key}`}
                      className="radial-trendline"
                      cursor="pointer"
                      onMouseEnter={followMouse ? this._onMouseEnter : null}
                      onMouseLeave={followMouse ? this._onMouseLeave : null}
                      onClick={this._onSelect}
                      stroke={hexColor(stroke)}
                      strokeWidth={strokeWidth}
                      strokeLinecap="round"
                    />
                  );
                })}
              </G>
            </G>
          </StretchedSVG>
        )}
      </ThemeContext.Consumer>
    );
  }
}
