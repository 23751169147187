// @flow
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { composeStyles, rem } from "../theme/theme";
import { isDefined, noop } from "react-redux-flow-tools";
import type { DialogActionType } from "../../actions/app";
import { ThemeContext } from "../theme/theme";
import { Modal } from "../modal";
import { flexCenter } from "../theme/common";
import { Button } from "../layout/Button";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    maxWidth: 1000,
  }),
  message: {
    alignSelf: "flex-start",
    fontSize: rem(1.75),
    paddingBottom: rem(4),
    width: "100%",
  },
  buttonArea: {
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexWrap: "wrap",
  },
  buttonContainer: {
    flex: 1,
    minWidth: 300,
    marginLeft: rem(1),
    marginRight: rem(1),
    marginBottom: rem(1),
  },
});

type DialogPropsType = {|
  +title?: string,
  +message?: string,
  +actions?: $ReadOnlyArray<DialogActionType>,
  +onDismiss: () => void,
|};

class JustDialog extends React.PureComponent<DialogPropsType> {
  render() {
    const { title, message, actions = [], onDismiss } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <Modal
            title={title}
            closeable={
              !isDefined(actions) || !actions.some(({ isClosing }) => isClosing)
            }
            onDismiss={onDismiss}
          >
            <View style={styles.container}>
              <Text
                style={themeComposer(styles.message, { color: "textPrimary" })}
              >
                {message}
              </Text>
              {actions.length > 0 && (
                <View style={styles.buttonArea}>
                  {actions.map(
                    (
                      {
                        primary = false,
                        secondary = false,
                        label,
                        onClick = noop,
                        isClosing = false,
                      },
                      idx,
                    ) => (
                      <View key={idx} style={styles.buttonContainer}>
                        <Button
                          label={label}
                          type={
                            primary
                              ? "primary"
                              : secondary
                              ? "secondary"
                              : "default"
                          }
                          onPress={
                            isClosing
                              ? () => {
                                  onClick();
                                  onDismiss();
                                }
                              : onClick
                          }
                        />
                      </View>
                    ),
                  )}
                </View>
              )}
            </View>
          </Modal>
        )}
      </ThemeContext.Consumer>
    );
  }
}

/** @example
 * onDialog({
 *   title: 'Hello World',
 *   message: 'The View is now responding for touch events. This is the time to highlight and show the user what is happening.',
 *   actions: [
 *     {primary: true, label: 'Awesome', isClosing: true},
 *     {secondary: true, label: 'Somewhat', isClosing: true},
 *     {label: 'Nope', isClosing: true},
 *   ],
 * });
 */
export class Dialog extends React.PureComponent<DialogPropsType> {
  render() {
    const { title, message } = this.props;
    if (isDefined(title) || isDefined(message)) {
      return <JustDialog {...this.props} />;
    } else {
      return null;
    }
  }
}
