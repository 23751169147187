// @flow

export class DeliberateHover {
  linger: boolean = false;
  activeHover: ?TimeoutID = null;
  activeHoverEnable: ?TimeoutID = null;

  onEnableHover = () => {
    if (this.linger === false && this.activeHoverEnable == null) {
      this.activeHoverEnable = setTimeout(() => (this.linger = true), 250);
    }
  };

  onDisableHover = () => {
    if (this.activeHover != null) {
      clearTimeout(this.activeHover);
      this.activeHover = null;
    }
    if (this.activeHoverEnable != null) {
      clearTimeout(this.activeHoverEnable);
      this.activeHoverEnable = null;
    }
    this.linger = false;
  };

  onEnter = (action: () => mixed) => {
    if (this.linger === false) {
      if (this.activeHover != null) {
        clearTimeout(this.activeHover);
        this.activeHover = null;
      }
      this.activeHover = setTimeout(() => {
        this.linger === true && action();
        this.activeHover = null;
      }, 250);
    } else {
      action();
    }
  };

  onLeave = (action: () => mixed) => {
    action();
    if (this.activeHover != null) {
      clearTimeout(this.activeHover);
      this.activeHover = null;
    }
  };
}
