// @flow
import moize from "moize";
import React from "react";
import { chord, ribbon } from "d3-chord";
import { arc } from "d3-shape";
import { rgb } from "d3-color";
import type { LayoutType } from "../../../models/Layout";
import { Movements } from "../../../models/Movements";
import { StretchedSVG } from "../../svg/StretchedSVG";
import { withDefault } from "react-redux-flow-tools";
import { G, Line, Path, Text, TextPath, transform } from "../../svg/svg";
import type { CurrencySymbolType } from "../../../api/models";
import { hexColor } from "../../svg/d3";
import { circlePath } from "../../svg/svg";
import type { TimeSeriesElementType } from "../../../models/TimeSeries";
import { ThemeContext } from "../../theme/theme";
import { DeliberateHover } from "../../layout/DeliberateHover";
import { currenciesActions } from "../../../actions/currencies";
import { sensibleNumber } from "../../layout/SensibleNumber";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import { SquareMatrix } from "../../../models";
import { lightnessTextColor } from "../../theme/common";

type ArcGenType = {
  +startAngle: number,
  +endAngle: number,
};

type GroupType = ArcGenType & {
  +value: number,
  +index: number,
  +subindex?: number,
};

type ChordType = {|
  +source: GroupType,
  +target: GroupType,
|};

type ChordsType = $ReadOnlyArray<ChordType> & {
  +groups: $ReadOnlyArray<GroupType>,
};

type ChordDiagramPropsType = {|
  +originValues?: TimeSeriesElementType,
  +currentValues?: TimeSeriesElementType,
  +movements: Movements,
  +ribbonOpacity: number,
  +donutOpacity: number,
  +donutSize: number,
  +padAngle: number,
  +textSize: number,
  +margin: number,
  +filled: boolean,
  +followMouse: boolean,
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +hoveredCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onHoverCurrency: typeof currenciesActions.currencies.hover,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
|};

type ChordDiagramComponentStateType = {|
  width: number,
  height: number,
|};

export class ChordDiagram extends React.PureComponent<
  ChordDiagramPropsType,
  ChordDiagramComponentStateType,
> {
  chord: ($ReadOnlyArray<$ReadOnlyArray<number>>) => ChordsType;
  ribbon: (ChordType) => string;
  arc: (GroupType) => string;
  textArc: (ArcGenType) => string;
  deliberateHover = new DeliberateHover();

  state = {
    width: 0,
    height: 0,
  };
  static defaultProps = {
    margin: 2,
    donutSize: 0.15,
    textSize: 13,
    filled: true,
    ribbonOpacity: 0.5,
    donutOpacity: 1.0,
    padAngle: 0.05,
    followMouse: true,
  };

  constructor(props: ChordDiagramPropsType) {
    super(props);
    this.chord = chord().sortSubgroups((a, b) => b - a);
    this.ribbon = ribbon();
    this.arc = arc();
    this.textArc = arc();
  }

  _onLayout = ({ width, height }: LayoutType) => {
    const { margin, donutSize, textSize } = this.props;
    const finalMargin = margin + textSize;
    const outerRadius = Math.min(width, height) * 0.5 - finalMargin;
    if (donutSize < 0) {
      throw new Error("ValueError: donutSize may not be negative.");
    }
    const innerRadius =
      donutSize <= 1 ? outerRadius * (1 - donutSize) : outerRadius - donutSize;
    this.arc.innerRadius(innerRadius).outerRadius(outerRadius);
    const textRadius = outerRadius + textSize;
    this.textArc.innerRadius(textRadius).outerRadius(textRadius);
    this.ribbon.radius(innerRadius);
    this.setState({ width, height });
  };

  _onMouseEnter = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/chord-.*-/, "");
    if (!this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onEnter(() =>
        this.props.onHoverCurrency({ symbol, flag: true }),
      );
    }
  };
  _onMouseLeave = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/chord-.*-/, "");
    if (this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onLeave(() =>
        this.props.onHoverCurrency({ symbol, flag: false }),
      );
    }
  };
  _onSelect = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/chord-.*-/, "");
    if (this.props.selectedCurrencies.includes(symbol)) {
      this.props.onSelectCurrency({ symbol, flag: false });
    } else {
      this.props.onSelectCurrency({ symbol, flag: true });
    }
  };

  _calculateChords = moize(
    (squareMatrix: $PropertyType<SquareMatrix<number>, "squareMatrix">) => {
      return this.chord(squareMatrix);
    },
    {
      isDeepEqual: true,
      maxSize: 1,
    },
  );
  render() {
    const {
      margin,
      originValues,
      currentValues,
      movements,
      textSize,
      hoveredCurrencies,
      selectedCurrencies,
      filled,
      followMouse,
      ribbonOpacity,
      donutOpacity,
      padAngle,
      availableCurrencies,
    } = this.props;
    const { width, height } = this.state;
    if (movements.isEmpty() || width < 100 || height < 100) {
      return (
        <StretchedSVG onLayout={this._onLayout}>
          <G transform={`translate(${width / 2}, ${height / 2})`}>
            <G transform={transform({ rotate: "-25" })}>
              <Line
                strokeWidth={1}
                stroke="darkgray"
                x1={0}
                y1={0}
                x2={0}
                y2={-height / 2 + margin}
              />
            </G>
            <G transform={transform({ rotate: "205" })}>
              <Line
                strokeWidth={1}
                stroke="darkgray"
                x1={0}
                y1={0}
                x2={0}
                y2={-height / 2 + margin}
              />
            </G>
          </G>
        </StretchedSVG>
      );
    }
    const data = movements.matrix();
    const currencies = movements.currencies();
    const labels = [...currencies, ...currencies.slice(0).reverse()];
    this.chord.padAngle(padAngle);
    const strokeDarkness = 0.7;
    const chords = this._calculateChords(data.squareMatrix);
    const axisOffsetAngle = -((padAngle * 360) / Math.PI) / 4;
    const middleAngle = chords.groups[chords.groups.length / 2 - 1].endAngle;
    return (
      <ThemeContext.Consumer>
        {({ colors, currencyColors }) => (
          <StretchedSVG onLayout={this._onLayout}>
            <G
              transform={`translate(${width / 2}, ${height / 2}) rotate(${(-90 *
                (middleAngle - Math.PI)) /
                Math.PI})`}
              onMouseEnter={this.deliberateHover.onEnableHover}
              onMouseLeave={this.deliberateHover.onDisableHover}
            >
              <G className="arcs">
                {chords.groups.map((group) => {
                  const idx = group.index;
                  const fill = rgb(
                    currencyColors(
                      currencies[
                        idx >= chords.groups.length >> 1
                          ? chords.groups.length - 1 - idx
                          : idx
                      ],
                    ),
                  );
                  const stroke = fill.darker(strokeDarkness);
                  const key = labels[group.index];
                  const opacity =
                    hoveredCurrencies.length + selectedCurrencies.length !==
                      0 &&
                    !hoveredCurrencies.includes(key) &&
                    !selectedCurrencies.includes(key)
                      ? 0.5
                      : 1;
                  return (
                    <Path
                      key={group.index}
                      id={`chord-arc-${key}`}
                      fill={filled ? hexColor(fill) : colors.background} // "none" not clickable
                      fillOpacity={opacity * donutOpacity}
                      stroke={hexColor(stroke)}
                      strokeOpacity={opacity}
                      strokeWidth={filled ? 1 : 3}
                      onMouseEnter={followMouse ? this._onMouseEnter : null}
                      onMouseLeave={followMouse ? this._onMouseLeave : null}
                      onClick={this._onSelect}
                      cursor={"pointer"}
                      d={this.arc(group)}
                    />
                  );
                })}

                <Path
                  id={"textArc-labels"}
                  d={circlePath(height / 2 - 1.5 * margin)}
                  stroke="none"
                  fill="none"
                />
                {chords.groups.map((group) => {
                  const idx = group.index;
                  const fill = rgb(
                    currencyColors(
                      currencies[
                        idx >= chords.groups.length >> 1
                          ? chords.groups.length - 1 - idx
                          : idx
                      ],
                    ),
                  );
                  const stroke = fill.darker(strokeDarkness);
                  // courtesy https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
                  const textFill = lightnessTextColor(fill);
                  const offset =
                    (
                      (25.0 +
                        ((group.startAngle +
                          Math.abs(group.endAngle - group.startAngle) / 2) /
                          (2 * Math.PI)) *
                          100) %
                      100
                    ).toFixed(4) + "%";
                  return (
                    <G key={`text-${group.index}${offset}`}>
                      <Text
                        textAnchor="middle"
                        fill={colors.textPrimary}
                        fontSize={textSize}
                      >
                        <TextPath
                          startOffset={offset}
                          href={"#textArc-labels"}
                          xlinkHref={"#textArc-labels"}
                          side="left"
                        >
                          {
                            withDefault(
                              availableCurrencies[labels[group.index]],
                              { label: labels[group.index] },
                            ).label
                          }
                        </TextPath>
                      </Text>
                      <Text
                        textAnchor="middle"
                        dy={
                          0.8 * textSize +
                          (this.arc.outerRadius()() -
                            this.arc.innerRadius()()) /
                            2
                        }
                        fill={filled ? textFill : hexColor(stroke)}
                        fontSize={textSize}
                      >
                        <TextPath
                          startOffset={offset}
                          id={`chord-textarc-${labels[group.index]}`}
                          onMouseEnter={followMouse ? this._onMouseEnter : null}
                          onMouseLeave={followMouse ? this._onMouseLeave : null}
                          onClick={this._onSelect}
                          cursor={"pointer"}
                          href={"#textArc-labels"}
                          xlinkHref={"#textArc-labels"}
                        >
                          {`$${sensibleNumber(
                            group.index >= labels.length >> 1
                              ? originValues != null
                                ? originValues[labels[group.index]]
                                : movements.origin(labels[group.index])
                              : currentValues != null
                              ? currentValues[labels[group.index]]
                              : movements.current(labels[group.index]),
                          )}`}
                        </TextPath>
                      </Text>
                    </G>
                  );
                })}
              </G>
              <G className="ribbons">
                {chords
                  .filter((chord) => chord.target.index !== chord.source.index)
                  .map((chord, idx) => {
                    const tIdx = chord.target.index;
                    const fill = rgb(
                      currencyColors(
                        currencies[
                          tIdx >= chords.groups.length >> 1
                            ? chords.groups.length - 1 - tIdx
                            : tIdx
                        ],
                      ),
                    );
                    const stroke = fill.darker(strokeDarkness);
                    const key = labels[chord.source.index];
                    const opacity =
                      hoveredCurrencies.length + selectedCurrencies.length !==
                        0 &&
                      !hoveredCurrencies.includes(key) &&
                      !selectedCurrencies.includes(key)
                        ? 0.5
                        : 1;
                    return (
                      <Path
                        key={idx}
                        fill={filled ? hexColor(fill) : colors.background} // "none" not clickable
                        fillOpacity={opacity * ribbonOpacity}
                        stroke={hexColor(stroke)}
                        strokeOpacity={opacity}
                        strokeWidth={filled ? 1 : 3}
                        id={`chord-ribbon-${key}`}
                        onMouseEnter={followMouse ? this._onMouseEnter : null}
                        onMouseLeave={followMouse ? this._onMouseLeave : null}
                        onClick={this._onSelect}
                        cursor={"pointer"}
                        d={this.ribbon(chord)}
                      />
                    );
                  })}
              </G>

              {/* todo the empty one makes more sense? */}
              <G transform={transform({ rotate: axisOffsetAngle.toString() })}>
                <Line
                  strokeWidth={1}
                  stroke="darkgray"
                  x1={0}
                  y1={0}
                  x2={0}
                  y2={-height / 2 + margin}
                />
              </G>
              <G
                transform={transform({ rotate: (-axisOffsetAngle).toString() })}
              >
                <Line
                  strokeWidth={1}
                  stroke="darkgray"
                  x1={0}
                  y1={0}
                  x2={Math.sin(middleAngle) * (height / 2 - margin)}
                  y2={-Math.cos(middleAngle) * (height / 2 - margin)}
                />
              </G>
            </G>
          </StretchedSVG>
        )}
      </ThemeContext.Consumer>
    );
  }
}
