// @flow
import * as React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { Button } from "../layout/Button";
import type { StyleType } from "../theme/theme";
import { composeStyles, ThemeContext } from "../theme/theme";
import {
  border,
  flexCenter,
  flexCenterFlow,
  menuIconSize,
  menuItemStyle,
  minMenuItemHeight,
} from "../theme/common";
import { DividerHorizontal } from "../layout/Dividers";
import { CollapseIcon, ExpandIcon } from "../img/Icon";
import { iconStyle } from "./common";
import type { MenuType } from "../../actions/app";

const styles = StyleSheet.create({
  menu: composeStyles(flexCenterFlow("row"), {
    height: "100%",
    width: "100%",
    alignItems: Platform.OS === "web" ? "center" : "flex-end",
  }),
  collapsedMenu: composeStyles(flexCenterFlow("column"), {
    maxWidth: "80%",
    width: 300,
  }),
  menuItem: menuItemStyle,
  menuLinkButton: composeStyles(flexCenter, {
    height: "100%",
  }),
  collapsedMenuItem: composeStyles(flexCenterFlow("row"), {
    minHeight: minMenuItemHeight,
    width: "100%",
    alignItems: "stretch",
  }),
  menuItemText: {},
  expandIcon: composeStyles(flexCenter, iconStyle(menuIconSize), {
    marginLeft: -6, //offset the svg empty space
  }),
});

type MenuTextPropsType = {|
  +children: string,
  +style?: StyleType,
|};

export class MenuText extends React.PureComponent<MenuTextPropsType> {
  render() {
    const { children, style = {} } = this.props;
    //
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <Text
            style={themeComposer(
              styles.menuItemText,
              { color: "textPrimary" },
              style,
            )}
          >
            {children.toUpperCase()}
          </Text>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export type MenuPropsType = {|
  +name: $PropertyType<MenuType, "name">,
  +open: boolean,
  +collapsedMenuStyle?: StyleType,
  +style?: StyleType,
  +root?: React.Node,
  +children?: React.Node,
  +onShowMenu: (MenuType) => mixed,
  +onCloseMenu: () => mixed,
  +onOpen: () => void,
  +onClose: () => void,
|};

export class Menu extends React.PureComponent<MenuPropsType> {
  static defaultProps = {
    onOpen: () => {},
    onClose: () => {},
  };

  componentDidMount() {
    if (this.props.open) {
      this.props.onOpen();
    }
  }

  componentDidUpdate(prevProps: MenuPropsType) {
    if (this.props.open === true && prevProps.open === false) {
      this.props.onOpen();
    } else if (this.props.open === false && prevProps.open === true) {
      this.props.onClose();
    }
  }

  componentWillUnmount() {
    if (this.props.open) {
      this.props.onCloseMenu();
      this.props.onClose();
    }
  }

  _renderCollapsedMenu = (collapsedChildren: React.Node) => {
    const { collapsedMenuStyle } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              collapsedMenuStyle != null
                ? [styles.collapsedMenu, collapsedMenuStyle]
                : styles.collapsedMenu,
              { backgroundColor: "headerBackground" },
              border(1, "solid", colors.separator, "Bottom"),
              border(1, "solid", colors.separator, "Right"),
              border(1, "solid", colors.separator, "Left"),
            )}
          >
            {React.Children.map(collapsedChildren, (element, idx) => [
              <View key={idx} style={styles.collapsedMenuItem}>
                {element}
              </View>,
              idx === React.Children.count(collapsedChildren) - 1 ? null : (
                <DividerHorizontal key={`${idx}div`} width="75%" />
              ),
            ])}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  };

  render() {
    const {
      name,
      root,
      children,
      style,
      open,
      onShowMenu,
      onCloseMenu,
    } = this.props;
    const childrenArray = React.Children.toArray(children);
    const cleanChildren = childrenArray.filter(
      (child) => child != null && child !== false,
    );
    const rootOnly = root == null && cleanChildren.length <= 1;
    let rootElement = root;
    if (rootElement == null) {
      if (cleanChildren.length < 1) {
        return null;
      } else {
        rootElement = cleanChildren[0];
      }
    }
    const showExpand = root == null && !rootOnly && !open;
    const showCollapse = root == null && !showExpand;

    const collapsedChildren = rootOnly
      ? []
      : cleanChildren.slice(root == null ? 1 : 0);

    return (
      <View style={style != null ? [styles.menu, style] : styles.menu}>
        <Button
          style={styles.menuItem}
          onPress={() => {
            if (!rootOnly && !open) {
              onShowMenu({
                name,
                children: this._renderCollapsedMenu(collapsedChildren),
              });
              return;
            }
            onCloseMenu();
          }}
        >
          {showExpand && <ExpandIcon style={styles.expandIcon} />}
          {showCollapse && <CollapseIcon style={styles.expandIcon} />}
          {rootElement}
        </Button>
      </View>
    );
  }
}
