// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers/index";

import { Header } from "./Header";
import type { DispatchType } from "../../actions/types";

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: DispatchType) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
