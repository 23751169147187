// @flow
import { TransactionList } from "./transactions";
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { validateString } from "./validate";
import { FileMetaList, QuotaList } from "./file";
import { just } from "react-redux-flow-tools";
import { KeyRing } from "./PGP";

const simpleMerger = (key: string) => <T: Model<>>(
  base: T,
  subset: ObjectSubsetType<T>,
): T => {
  if (subset.hasOwnProperty(key)) {
    return base.update({ [key]: subset[key] });
  } else {
    return base;
  }
};

export type DataURIType = string;

export class Account extends Model<Account> {
  static +modelVersion = 2;
  +userName: ?string;
  +userIcon: ?DataURIType;
  +transactions: TransactionList;
  +quotas: QuotaList;
  +files: FileMetaList;
  +keyRing: KeyRing;

  constructor(json: ObjectSubsetType<Account>) {
    super(json);

    if (json.userName != null) {
      this.userName = validateString(json.userName);
    }
    if (json.userIcon != null) {
      this.userIcon = validateString(json.userIcon);
    }
    this.transactions =
      json.transactions == null
        ? TransactionList.empty
        : json.transactions instanceof TransactionList
        ? json.transactions
        : new TransactionList({ transactions: json.transactions });
    this.quotas =
      json.quotas == null
        ? QuotaList.empty
        : json.quotas instanceof QuotaList
        ? json.quotas
        : new QuotaList({ quotas: json.quotas });
    this.files =
      json.files == null
        ? FileMetaList.empty
        : json.files instanceof FileMetaList
        ? json.files
        : new FileMetaList({ files: json.files });
    this.keyRing =
      json.keyRing == null
        ? KeyRing.empty
        : json.keyRing instanceof KeyRing
        ? json.keyRing
        : new KeyRing({ keyRing: json.keyRing });
  }

  static factory(
    userName: $PropertyType<Account, "userName">,
    userIcon: $PropertyType<Account, "userIcon">,
  ) {
    return new Account({ userName, userIcon });
  }

  static +mergers = [
    // quota and files
    (
      account: Account,
      subset: ObjectSubsetType<Account>,
      version: number,
    ): Account => {
      if (version < 2) {
        // $FlowFixMe fix old version
        return account.update({
          quota: undefined,
          files: FileMetaList.empty,
        });
      }
      let merged = account;
      if (subset.quotas != null && subset.quotas.length() > 0) {
        merged = account.update({
          quotas: account.quotas.merge(subset.quotas),
        });
      }
      if (subset.files != null && subset.files.length() > 0) {
        merged = merged.update({
          files: merged.files.merge(just(subset.files)),
        });
      }
      return merged;
    },
    async (
      account: Account,
      subset: ObjectSubsetType<Account>,
    ): Promise<Account> => {
      if (subset.keyRing != null) {
        return account.update({
          keyRing: await account.keyRing.merge(subset.keyRing),
        });
      } else {
        return account;
      }
    },
    (account: Account, subset: ObjectSubsetType<Account>): Account => {
      if (subset.transactions != null) {
        return account.update({
          transactions: account.transactions.merge(subset.transactions),
        });
      } else {
        return account;
      }
    },
    simpleMerger("userName"),
    simpleMerger("userIcon"),
  ];
}
