// @flow
import type { CurrencySymbolType } from "./currencies";
import { Model } from "./models";
import type { ObjectSubsetType } from "./models";
import { validateString } from "./validate";

export type WalletAddressType = string;

export class Wallet extends Model<Wallet> {
  +symbol: CurrencySymbolType;
  +address: WalletAddressType;

  constructor(json: ObjectSubsetType<Wallet>) {
    super(json);
    this.symbol = validateString(json.symbol);
    this.address = validateString(json.address);
  }
}
