// @flow
export class Matrix<T> {
  static emptyMatrix: $ReadOnlyArray<$ReadOnlyArray<T>> = Object.freeze([]);

  +matrix: $ReadOnlyArray<$ReadOnlyArray<T>> = Matrix.emptyMatrix;
  +columns: number = 0;
  +rows: number = 0;

  constructor(data: $ReadOnlyArray<$ReadOnlyArray<T>>) {
    if (data.length) {
      const rows = data.length;
      const columns = data[0].length;
      data.forEach((row, idx) => {
        if (row.length !== columns) {
          throw new Error(
            `ValueError: provided data is not a (${columns}x${rows}) matrix! Row ${idx} has ${
              row.length
            } columns.`,
          );
        }
      });
      this.matrix = data;
      this.columns = columns;
      this.rows = rows;
    }
  }
}

export class SquareMatrix<T> {
  +squareMatrix: $ReadOnlyArray<$ReadOnlyArray<T>>;
  +size: number;

  constructor(matrix: Matrix<T>) {
    if (matrix.columns !== matrix.rows) {
      throw new Error(
        `ValueError: provided matrix is not square ${matrix.columns}x${
          matrix.rows
        }`,
      );
    }
    this.squareMatrix = matrix.matrix;
    this.size = matrix.rows;
  }

  static of<S>(data: $ReadOnlyArray<$ReadOnlyArray<S>>): SquareMatrix<S> {
    return new SquareMatrix(new Matrix(data));
  }
}
