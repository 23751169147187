// @flow
import moize from "moize";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import type {
  LayoutEventType,
  LayoutType,
  MutableLayoutType,
} from "../../models/Layout";
import { flexCenter } from "../theme/common";
import { composeStyles } from "../theme/theme";
import type { StyleType } from "../theme/theme";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
  }),
});

type SquareViewPropsType = {|
  +children: ({| +size: number |}) => React.Node,
  +onLayout?: (LayoutType) => mixed,
  +style?: StyleType,
|};

type SquareViewComponentStateType = {|
  container: MutableLayoutType,
  square: MutableLayoutType,
|};

export class SquareView extends React.PureComponent<
  SquareViewPropsType,
  SquareViewComponentStateType,
> {
  state = {
    container: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
    square: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
  };

  _bounds = () => {
    const { width, height } = this.state.container;
    if (width > height) {
      // noinspection JSSuspiciousNameCombination
      return {
        height,
        width: height,
      };
    } else {
      // noinspection JSSuspiciousNameCombination
      return {
        width,
        height: width,
      };
    }
  };

  _onLayout = (layoutEvent: LayoutEventType) => {
    const layout = layoutEvent.nativeEvent.layout;
    const { width, height } = this.state.container;
    if (layout.width !== width || layout.height !== height) {
      console.debug("Layouting Square");
      this.setState({ container: { ...layout } });
    }
  };

  _onSquareLayout = (layoutEvent: LayoutEventType) => {
    const { onLayout } = this.props;
    if (onLayout != null) {
      const layout = layoutEvent.nativeEvent.layout;
      const { width, height } = this.state.square;
      if (layout.width !== width || layout.height !== height) {
        this.setState({ square: { ...layout } });
        onLayout(layout);
      }
    }
  };

  _renderChild = moize(
    ({ children, size }) => {
      return children({ size });
    },
    {
      maxSize: 1,
      isDeepEqual: true,
    },
  );

  render() {
    const { children, style } = this.props;
    const bounds = this._bounds();
    return (
      <View
        style={
          style != null
            ? StyleSheet.compose(
                styles.container,
                style,
              )
            : styles.container
        }
        onLayout={this._onLayout}
      >
        <View style={bounds} onLayout={this._onSquareLayout}>
          {this._renderChild({ children, size: bounds.width })}
        </View>
      </View>
    );
  }
}
