// @flow
import type { ThreadMessageType, ThreadReferenceType } from "./types";

// eslint-disable-next-line no-restricted-globals
export const agnosticSelf = self;
export const isBrowser = true;

// react-native-workers only supports string messages, browser sends a full event
export const toMessage = (message: ThreadMessageType<mixed>) => message;
export const fromMessage = <T>(maybeMessage: mixed): ThreadMessageType<T> => {
  if (
    maybeMessage == null ||
    typeof maybeMessage !== "object" ||
    typeof maybeMessage.data === "undefined"
  ) {
    throw new Error(
      "ImplementationError: thread message is not a object/message event",
    );
  }
  //$FlowFixMe
  return maybeMessage.data;
};

export const resolve = <T>(reference: ThreadReferenceType, resolve: T) => {
  agnosticSelf.postMessage(toMessage({ reference, data: { resolve } }));
};

export const reject = (reference: ThreadReferenceType, reject: mixed) => {
  agnosticSelf.postMessage(toMessage({ reference, data: { reject } }));
};
