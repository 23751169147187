// @flow
import React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import type { StyleType } from "../theme/theme";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import {
  border,
  flexCenter,
  inputBorderRadius,
  inputStyle,
  inputTheme,
} from "../theme/common";
import { Button } from "./Button";
import { FilterIcon, CloseIcon } from "../img/Icon";
import { iconStyle } from "../header/common";

const styles = StyleSheet.create({
  inputContainer: composeStyles(flexCenter, {
    flexDirection: "row",
    width: "100%",
    borderRadius: inputBorderRadius,
  }),
  input: composeStyles(inputStyle, {
    flex: 1,
  }),
  filterIcon: composeStyles(iconStyle(rem(2)), {
    margin: rem(0.5),
  }),
});

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

type FilterInputPropsType = {|
  +placeholder?: string,
  +onChangeText?: (string) => mixed,
  +containerStyle?: StyleType,
|};

type FilterInputComponentStateType = {|
  input: string,
|};

export class FilterInput extends React.PureComponent<
  FilterInputPropsType,
  FilterInputComponentStateType,
> {
  state = {
    input: "",
  };

  _onChangeText = (input: string) => {
    this.props.onChangeText != null && this.props.onChangeText(input);
    this.setState({ input });
  };
  _clear = () => {
    this.props.onChangeText != null && this.props.onChangeText("");
    this.setState({ input: "" });
  };

  render() {
    const { placeholder = "Filter", containerStyle = {} } = this.props;
    const { input } = this.state;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              styles.inputContainer,
              {
                backgroundColor: "inputBackgroundPrimary",
              },
              border(1, "solid", colors.separator),
              containerStyle,
            )}
          >
            <FilterIcon
              style={styles.filterIcon}
              fill={input === "" ? colors.textSecondary : colors.textPrimary}
            />
            <TextInput
              style={themeComposer(styles.input, inputTheme)}
              placeholder={placeholder}
              placeholderTextColor={colors.textSecondary}
              value={input}
              onChangeText={this._onChangeText}
            />
            {input !== "" && (
              <Button onPress={this._clear}>
                <CloseIcon
                  style={styles.filterIcon}
                  fill={colors.textPrimary}
                />
              </Button>
            )}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
