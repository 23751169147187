// @flow
import Swagger from "swagger-client";

const specUrl = "https://api.juno.rocks/v1/swagger.json";
// const specUrl = "https://api.juno/v1/swagger.json";
const swagger = Swagger(specUrl);

export const getClient = async () => {
  const client = await swagger;
  return client;
};
