// @flow
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { List } from "./list";
import {
  notNull,
  validateDate,
  validateListOf,
  validateNumber,
  validateObject,
  validateString,
} from "./validate";
import { Vault } from "./PGP";

export class EncryptedWALEntry extends Model<EncryptedWALEntry> {
  +fingerprint: string;
  +action: string;
  +timestamp: Date;
  +sequence: number;
  +version: number;

  constructor(json: ObjectSubsetType<EncryptedWALEntry>) {
    super(json);
    this.fingerprint = validateString(json.fingerprint);
    this.action = validateString(json.action);
    this.timestamp = validateDate(json.timestamp);
    this.sequence = validateNumber(json.sequence);
    this.version = json.version == null ? 1 : validateNumber(json.version);
  }
}

export class EncryptedWAL extends List<EncryptedWALEntry, EncryptedWAL> {
  +wal: $ReadOnlyArray<EncryptedWALEntry>;

  constructor(json: ObjectSubsetType<EncryptedWAL>) {
    super("wal");
    this.wal = validateListOf(
      notNull((walEntry) => new EncryptedWALEntry(walEntry)),
      json.wal,
    );
    if (this.length() < 1) {
      throw new Error("WAL was not properly initialized");
    }
  }
}

export class WALEntry<T: Model<>> extends Model<WALEntry<T>> {
  static _parse = (data) => Promise.resolve(JSON.parse(data));

  static configParse(parse: <T>(string) => Promise<T>) {
    WALEntry._parse = parse;
  }

  +action: ObjectSubsetType<T>;
  +timestamp: $PropertyType<EncryptedWALEntry, "timestamp">;
  +sequence: $PropertyType<EncryptedWALEntry, "sequence">;
  +version: $PropertyType<EncryptedWALEntry, "version">;
  +revoked: boolean;

  constructor(json: ObjectSubsetType<WALEntry<T>>) {
    super(json);
    this.action = validateObject(json.action);
    this.timestamp = validateDate(json.timestamp);
    this.sequence = validateNumber(json.sequence);
    this.version = json.version == null ? 1 : validateNumber(json.version);
    this.revoked = json.revoked === true;
  }

  static async fromEncryptedWALEntry(
    encryptedWALEntry: EncryptedWALEntry,
    vault: Vault,
    parse?: (string) => Promise<T> = WALEntry._parse,
  ): Promise<WALEntry<T>> {
    const decryptedAction = await vault.unbox(encryptedWALEntry.action, parse);
    return new WALEntry({
      timestamp: encryptedWALEntry.timestamp,
      sequence: encryptedWALEntry.sequence,
      version: encryptedWALEntry.version,
      action: decryptedAction,
    });
  }

  static revoked(from: EncryptedWALEntry): WALEntry<T> {
    return new WALEntry({
      timestamp: from.timestamp,
      sequence: from.sequence,
      version: from.version,
      action: {},
      revoked: true,
    });
  }
}

export type MergerType<T> = (T, ObjectSubsetType<T>, number) => T | Promise<T>;

export class WAL<T: Model<>> extends List<WALEntry<T>, WAL<T>> {
  +wal: $ReadOnlyArray<WALEntry<T>>;

  constructor(json: ObjectSubsetType<WAL<T>>) {
    super("wal");
    this.wal = validateListOf(
      notNull((walEntry) => new WALEntry(walEntry)),
      json.wal,
    );
    if (this.length() < 1) {
      throw new Error("WAL was not properly initialized");
    }
  }

  last(): ?WALEntry<T> {
    return this.values(true)[this.length(true) - 1];
  }

  length(all: boolean = false): number {
    return this.values(all).length;
  }

  values(all: boolean = false): $ReadOnlyArray<WALEntry<T>> {
    if (all) {
      return super.values();
    } else {
      return this.wal.filter(({ revoked }) => !revoked);
    }
  }

  static async fromEncryptedWAL(
    encryptedWAL: EncryptedWAL,
    vault: Vault,
    parse?: (string) => Promise<T>,
  ): Promise<WAL<T>> {
    const parallelWAL: Array<Promise<WALEntry<T>>> = encryptedWAL
      .values()
      .map(async (encryptedWALEntry) => {
        try {
          return await WALEntry.fromEncryptedWALEntry(
            encryptedWALEntry,
            vault,
            parse,
          );
        } catch (error) {
          if (
            error.message.startsWith(
              "ValueError: cipher is not signed by a key we trust",
            )
          ) {
            const keyid = error.keyid;
            if (await vault.isUntrusted(keyid)) {
              console.debug("ignoring known untrusted key", keyid);
              return WALEntry.revoked(encryptedWALEntry);
            }
          }
          throw error;
        }
      });
    const wal: Array<WALEntry<T>> = await Promise.all(parallelWAL);
    return new WAL({ wal });
  }

  static async mergeEncryptedWAL(
    wal: WAL<T>,
    encryptedWAL: EncryptedWAL,
    vault: Vault,
    parse?: (string) => Promise<T>,
  ): Promise<WAL<T>> {
    const lastEntry = wal.last();
    if (lastEntry == null) {
      return WAL.fromEncryptedWAL(encryptedWAL, vault);
    }
    const currentSequence = lastEntry.sequence;
    // todo could be more efficient
    const encryptedWALSlice = encryptedWAL.filter(
      ({ sequence }) => sequence > currentSequence,
    );
    const parallelWAL: Array<
      Promise<WALEntry<T>>,
    > = encryptedWALSlice.values().map(async (encryptedWALEntry) => {
      try {
        const entry = await WALEntry.fromEncryptedWALEntry(
          encryptedWALEntry,
          vault,
          parse,
        );
        return entry;
      } catch (error) {
        if (
          error.message.startsWith(
            "ValueError: cipher is not signed by a key we trust",
          )
        ) {
          const keyid = error.keyid;
          if (await vault.isUntrusted(keyid)) {
            console.debug("ignoring known untrusted key", keyid);
            return WALEntry.revoked(encryptedWALEntry);
          }
        }
        throw error;
      }
    });
    const maybeWAL: Array<WALEntry<T>> = await Promise.all(parallelWAL);
    return maybeWAL.reduce(
      (acc, entry) => (entry != null ? acc.append(entry) : acc),
      wal,
    );
  }

  original(cls: Class<T>): T {
    return new cls(this.values()[0].action);
  }

  async current(
    cls: Class<T>,
    ...mergers: $ReadOnlyArray<MergerType<T>>
  ): Promise<T> {
    return await this.values()
      .slice(1)
      .reduce(async (acc, entry) => {
        let awaitedAcc = await acc;
        if (mergers.length > 0) {
          return await mergers.reduce(async (previous, merger) => {
            let merged = merger(await previous, entry.action, entry.version);
            if (merged instanceof Promise) {
              merged = await merged;
            }
            return merged;
          }, (awaitedAcc: T));
        } else if (!entry.revoked) {
          return awaitedAcc.update(entry.action);
        } else {
          return awaitedAcc;
        }
      }, this.original(cls));
  }
}
