// @flow
import React from "react";
import { Platform } from "react-native";
import { scaleLinear, scaleTime } from "d3-scale";
import { rgb } from "d3-color";
import {
  area,
  curveCatmullRom,
  line,
  stack,
  stackOffsetSilhouette,
} from "d3-shape";
import type { LayoutType } from "../../../models/Layout";
import { TimeSeries } from "../../../models/TimeSeries";
import { StretchedSVG } from "../../svg/StretchedSVG";
import { Defs, G, Line, Path, Text, Use } from "../../svg/svg";
import type { CurrencySymbolType } from "../../../api/models";
import { hexColor } from "../../svg/d3";
import { rem, ThemeContext } from "../../theme/theme";
import { DeliberateHover } from "../../layout/DeliberateHover";
import { currenciesActions } from "../../../actions/currencies";

type StreamGraphPropsType = {|
  +timeSeries: TimeSeries,
  +textSize: number,
  +labelWidth: number,
  +margin: number,
  +filled: boolean,
  +followMouse: boolean,

  +hoveredCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onHoverCurrency: typeof currenciesActions.currencies.hover,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
|};

type StreamGraphComponentStateType = {|
  width: number,
  height: number,
|};

const strokeDarkness = 0.5;

export class StreamGraph extends React.PureComponent<
  StreamGraphPropsType,
  StreamGraphComponentStateType,
> {
  x: (number) => number;
  y: (number) => number;
  stack: $Call<stack>;
  valueArea: $Call<area>;
  gridLine: $Call<line>;
  deliberateHover = new DeliberateHover();

  static defaultProps = {
    labelWidth: 150,
    textSize: rem(2),
    margin: 0.1,
    filled: true,
  };

  state = {
    width: 0,
    height: 0,
  };

  constructor(props: StreamGraphPropsType) {
    super(props);
    this.x = scaleTime();
    this.y = scaleLinear();
    this.stack = stack()
      // .order(stackOrderInsideOut)
      // .order(stackOrderDescending)
      // .offset(stackOffsetWiggle);
      // .offset(stackOffsetNone);
      .offset(stackOffsetSilhouette);
    // .offset(stackOffsetExpand);
    this.valueArea = area()
      .curve(curveCatmullRom)
      .x((d) => this.x(d.data.timestamp))
      .y0((d) => this.y(d[0]))
      .y1((d) => this.y(d[1]));
    this.gridLine = line()
      .curve(curveCatmullRom)
      .x((d) => this.x(d.data.timestamp))
      .y((d) => this.y(d[1]));
  }

  _onLayout = ({ width, height }: LayoutType) => {
    const { margin } = this.props;
    const finalMargin = margin > 0 && margin < 1 ? margin * height : margin;
    this.x.range([0, width]);
    this.y.range([height - finalMargin, finalMargin]);
    this.setState({ width, height });
  };

  _onMouseEnter = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/streamgraph-.*-/, "");
    if (!this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onEnter(() =>
        this.props.onHoverCurrency({ symbol, flag: true }),
      );
    }
  };
  _onMouseLeave = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/streamgraph-.*-/, "");
    if (this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onLeave(() =>
        this.props.onHoverCurrency({ symbol, flag: false }),
      );
    }
  };
  _onSelect = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/streamgraph-.*-/, "");
    if (this.props.selectedCurrencies.includes(symbol)) {
      this.props.onSelectCurrency({ symbol, flag: false });
    } else {
      this.props.onSelectCurrency({ symbol, flag: true });
    }
  };

  render() {
    const {
      timeSeries,
      filled,
      hoveredCurrencies,
      selectedCurrencies,
      followMouse,
    } = this.props;
    const startByKey = timeSeries.head();
    let data = timeSeries.values().map((row) =>
      timeSeries.keys().reduce(
        (acc, key) => {
          //todo wait wut
          // acc[key] = -1 * (1 - startByKey[key] / row[key]);
          acc[key] = (startByKey[key] - row[key]) / startByKey[key];
          return acc;
        },
        { timestamp: row.timestamp },
      ),
    );
    data = data.map((row) => {
      //$FlowFixMe we need it for the zero line
      row["__zero__"] = 0;
      return row;
    });

    const { width, height } = this.state;
    if (!data.length || !width || !height) {
      return <StretchedSVG onLayout={this._onLayout} />;
    }
    const keys = Object.keys(data[0]).filter((key) => key !== "timestamp");
    this.stack.keys(keys);
    const series = this.stack(data);
    this.x.domain([data[0].timestamp, data[data.length - 1].timestamp]);
    this.y.domain([
      Math.max(
        ...series.map((layer) =>
          Math.max(...layer.map((d) => Math.max(d[0], d[1]))),
        ),
      ),
      Math.min(
        ...series.map((layer) =>
          Math.min(...layer.map((d) => Math.min(d[0], d[1]))),
        ),
      ),
    ]);

    const numDateTicks = Math.floor(width / rem(6));
    const numHorizontalLines = 10;
    const pixelRange = this.y.range()[1] - this.y.range()[0];
    const offsetHeight = pixelRange / numHorizontalLines;
    const twoSided = pixelRange;

    const zero = series[series.length - 1];
    const { zeroTicks } = zero.reduce(
      (acc, element) => {
        const ya = acc.last[0];
        const yb = element[0];
        if (
          (ya <= 0 && yb > ya && yb >= 0) ||
          (ya >= 0 && yb < ya && yb <= 0)
        ) {
          const xa = this.x(acc.last.data.timestamp);
          const xb = this.x(element.data.timestamp);
          const dx = xb - xa;
          const dy = yb - ya;
          // -ya because of d3 inversion
          const x0Linear = xa + (-ya * dx) / dy;
          acc.zeroTicks.push(yb > ya ? x0Linear : -x0Linear);
        }
        acc.last = element;
        return acc;
      },
      { last: zero[0], zeroTicks: [] },
    );

    // @todo moize the value area calls for redraws
    return (
      <ThemeContext.Consumer>
        {({ colors, currencyColors }) => (
          <StretchedSVG onLayout={this._onLayout}>
            <Defs>
              {/*two paths because problem with stroke in use */}
              <Path
                id={"zero"}
                key={"zero"}
                fill={"none"}
                stroke={colors.separator}
                strokeWidth={0.35}
                className="grid-line"
                d={this.gridLine(zero)}
              />
              <Path
                id={"zero-main"}
                key={"zero-main"}
                fill={"none"}
                stroke={colors.textPrimary}
                strokeWidth={1.5}
                className="grid-line"
                d={this.gridLine(zero)}
              />
            </Defs>
            <G className="zero-ticks">
              {zeroTicks.map((tick) => (
                <Line
                  key={tick}
                  x1={Math.abs(tick)}
                  y1={0}
                  x2={Math.abs(tick)}
                  y2={height}
                  stroke={tick > 0 ? colors.buttonDanger : colors.buttonSuccess}
                  strokeWidth={1}
                />
              ))}
              <Line
                key={"zero"}
                x1={0}
                y1={height / 2}
                x2={width}
                y2={height / 2}
                stroke={colors.separator}
                strokeWidth={1}
              />
            </G>
            <G
              className="areas"
              onMouseEnter={this.deliberateHover.onEnableHover}
              onMouseLeave={this.deliberateHover.onDisableHover}
            >
              {series.slice(0, -1).map((layer) => {
                const key = layer.key;
                const fill = rgb(currencyColors(key));
                const fillOpacity =
                  hoveredCurrencies.length + selectedCurrencies.length !== 0 &&
                  !hoveredCurrencies.includes(key) &&
                  !selectedCurrencies.includes(key)
                    ? 0.2
                    : 0.5;
                const stroke = fill.darker(strokeDarkness);
                return (
                  <Path
                    key={key}
                    fill={filled ? hexColor(fill) : "none"}
                    fillOpacity={fillOpacity}
                    stroke={hexColor(stroke)}
                    strokeWidth={fillOpacity === 0.5 ? 2 : 0.2}
                    id={`streamgraph-layer-${key}`}
                    cursor="pointer"
                    onMouseEnter={followMouse ? this._onMouseEnter : null}
                    onMouseLeave={followMouse ? this._onMouseLeave : null}
                    onClick={this._onSelect}
                    className="area"
                    d={this.valueArea(layer)}
                  />
                );
              })}
            </G>
            {// todo Use seems to not be supported yet?
            Platform.OS === "web" && (
              <G className="horizontal-gridlines">
                {new Array(numHorizontalLines * 2).fill(0).map((_, idx) => {
                  const offset = idx * offsetHeight - twoSided;
                  return (
                    <Use
                      key={idx}
                      id={`horizontal-gridline-${idx}`}
                      xlinkHref="#zero"
                      style={{ transform: `translateY(${offset}px)` }}
                    />
                  );
                })}
                {/*<Text*/}
                {/*x={this.x(zero[zero.length / 2].data.timestamp)}*/}
                {/*y={this.y(zero[zero.length / 2][0])}*/}
                {/*textAnchor="middle"*/}
                {/*fill={colors.textPrimary}*/}
                {/*>*/}
                {/*Hello*/}
                {/*</Text>*/}
                <Use xlinkHref="#zero-main" />
              </G>
            )}
            <G className="x-axis">
              <Line
                x1={this.x(timeSeries.head().timestamp)}
                y1={0}
                x2={this.x(timeSeries.current().timestamp)}
                y2={0}
                stroke={colors.textPrimary}
                strokeWidth={1}
              />
              {data.map(
                ({ timestamp }, idx) =>
                  (idx + Math.round((0.5 * data.length) / numDateTicks)) %
                    Math.round(data.length / numDateTicks) ===
                    0 && (
                    <G className="tick" key={`date-tick-${idx}`}>
                      <Line
                        key={timestamp}
                        x1={this.x(timestamp)}
                        y1={0}
                        x2={this.x(timestamp)}
                        y2={8}
                        stroke={colors.textPrimary}
                        strokeWidth={1}
                      />
                      <Text
                        textAnchor="middle"
                        fill={colors.textPrimary}
                        fontSize={10}
                        dy={10}
                        x={this.x(timestamp)}
                        y={10}
                      >
                        {timestamp.toLocaleString("en-US", {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                        })}
                      </Text>
                    </G>
                  ),
              )}
            </G>
          </StretchedSVG>
        )}
      </ThemeContext.Consumer>
    );
  }
}
