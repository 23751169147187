// @flow
import * as React from "react";
import { SectionList, StyleSheet, Text, View } from "react-native";
import moment from "moment";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import { Heading } from "../../layout/Heading";
import { webSectionStyle } from "../../layout";
import { border, flexCenter, flexCenterFlow } from "../../theme/common";
import { RemoveIcon } from "../../img/Icon";
import { iconStyle } from "../../header/common";
import { Button, EditButton } from "../../layout/Button";
import { Transaction, TransactionList, Vault } from "../../../api/models";
import { FilterInput } from "../../layout/FilterInput";
import { escapeRegExp } from "../TokenList";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import { updateWAL } from "../../../actions/account";
import { currencyIcon } from "../../img/currencies";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
    flex: 1,
  }),
  section: composeStyles(webSectionStyle, {
    justifyContent: "flex-start",
    height: "100%",
  }),
  listContainer: {
    flex: 1,
    width: "100%",
    //border
    paddingBottom: 1,
  },
  row: composeStyles(flexCenterFlow("row"), {
    width: "100%",
    // border
    marginBottom: -1,
    justifyContent: "flex-start",
    height: rem(5),
  }),
  titleText: {
    fontSize: rem(1.5),
  },
  detailsText: {
    fontSize: rem(1.5),
  },
  detailsAmountAddressContainer: composeStyles(flexCenter, { flex: 1 }),
  removeButtonLeft: {
    paddingRight: rem(1),
    paddingLeft: rem(1),
  },
  removeButton: {
    height: "100%",
  },
});

type TransactionListViewPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +transactions: TransactionList,
  +vault?: Vault,
  +onUpdate: typeof updateWAL,
|};

type TransactionListViewComponentStateType = {|
  edit: boolean,
  remove: ?$Call<$PropertyType<Class<Transaction>, "hash">>,
  input: string,
|};

export class TransactionListView extends React.PureComponent<
  TransactionListViewPropsType,
  TransactionListViewComponentStateType,
> {
  state = {
    edit: false,
    remove: undefined,
    input: "",
  };

  _onChangeText = (input: string) => this.setState({ input });

  _onRevokeTransaction = (transaction: Transaction) => {
    const { vault, onUpdate, transactions } = this.props;
    if (vault == null) {
      return;
    }
    onUpdate(
      {
        transactions: transactions.revoke(transaction),
      },
      vault,
    );
  };

  _renderSectionHeader = ({
    section: { key },
  }: {
    +section: { +key: string },
  }) => {
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <View
            key={key}
            style={themeComposer(
              styles.row,
              {},
              { justifyContent: "flex-end" },
            )}
          >
            <Text
              style={themeComposer(styles.titleText, {
                color: "textSecondary",
              })}
            >
              {key}
            </Text>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  };

  _renderItem = ({ item }: { +item: Transaction }) => {
    const {
      fromCurrency,
      toCurrency,
      fromAddress,
      toAddress,
      fromAmount,
      toAmount,
    } = item;
    const IconFrom = currencyIcon(fromCurrency);
    const IconTo = currencyIcon(toCurrency);
    const { edit, remove } = this.state;
    const hash = Transaction.hash(item);
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors, currencyColors }) => (
          <View
            key={JSON.stringify(item)}
            style={themeComposer(
              styles.row,
              {},
              border(1, "solid", colors.separator),
            )}
          >
            {edit && (
              <Button
                style={styles.removeButtonLeft}
                onPress={
                  item.revoked === true
                    ? () => {}
                    : () =>
                        this.setState({
                          remove: remove === hash ? undefined : hash,
                        })
                }
              >
                <RemoveIcon
                  style={iconStyle(rem(2.5))}
                  fill={
                    item.revoked === true
                      ? colors.textSecondary
                      : colors.buttonDanger
                  }
                />
              </Button>
            )}
            <View style={styles.detailsAmountAddressContainer}>
              <Text
                style={themeComposer(styles.detailsText, {
                  color: item.revoked === true ? "textDanger" : "textPrimary",
                })}
              >
                {fromAmount} {fromCurrency}
              </Text>
              {fromAddress != null && (
                <Text
                  ellipsizeMode="middle"
                  numberOfLines={1}
                  style={themeComposer(
                    styles.detailsText,
                    {
                      color: "textSecondary",
                    },
                    { width: "100%", textAlign: "center" },
                  )}
                >
                  {fromAddress}
                </Text>
              )}
            </View>
            <View
              style={composeStyles(flexCenter, {
                height: 38,
                width: 38,
                position: "relative",
                marginLeft: rem(0.5),
                marginRight: rem(0.5),
              })}
            >
              <IconFrom
                fill={currencyColors(fromCurrency)}
                style={composeStyles(iconStyle(16), {
                  position: "absolute",
                  top: 38 / 2 - 14,
                  left: 0,
                })}
              />
              <Text style={{ color: colors.textPrimary, fontSize: rem(3) }}>
                /
              </Text>
              <IconTo
                fill={currencyColors(toCurrency)}
                style={composeStyles(iconStyle(16), {
                  position: "absolute",
                  top: 38 / 2,
                  right: 0,
                })}
              />
            </View>
            <View style={styles.detailsAmountAddressContainer}>
              <Text
                style={themeComposer(styles.detailsText, {
                  color: item.revoked === true ? "textDanger" : "textPrimary",
                })}
              >
                {toAmount} {toCurrency}
              </Text>
              {toAddress != null && (
                <Text
                  ellipsizeMode="middle"
                  numberOfLines={1}
                  style={themeComposer(
                    styles.detailsText,
                    {
                      color: "textSecondary",
                    },
                    { width: "100%", textAlign: "center" },
                  )}
                >
                  {toAddress}
                </Text>
              )}
            </View>
            {edit && remove === hash && (
              <Button
                type="danger"
                label="Remove"
                style={styles.removeButton}
                onPress={() => {
                  this.setState({ remove: undefined });
                  this._onRevokeTransaction(item);
                }}
              />
            )}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  };

  _sections = (): $ReadOnlyArray<{
    +key: string,
    +data: $ReadOnlyArray<Transaction>,
  }> => {
    const { transactions, availableCurrencies } = this.props;
    const { input } = this.state;
    const keyedSections = transactions
      .values()
      .slice(0)
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
      .reduce((acc, transaction) => {
        if (input !== "") {
          const escapedValue = escapeRegExp(input);
          if (escapedValue !== "") {
            const regex = new RegExp(
              "^.*" + escapedValue.split(" ").join(".*") + ".*$",
              "i",
            );
            if (
              !(
                transaction.fromAddress != null &&
                regex.test(transaction.fromAddress)
              ) &&
              !regex.test(transaction.fromCurrency) &&
              !(
                transaction.toAddress != null &&
                regex.test(transaction.toAddress)
              ) &&
              !regex.test(transaction.toCurrency) &&
              !(
                availableCurrencies != null &&
                availableCurrencies[transaction.fromCurrency] != null &&
                regex.test(availableCurrencies[transaction.fromCurrency].label)
              ) &&
              !(
                availableCurrencies != null &&
                availableCurrencies[transaction.toCurrency] != null &&
                regex.test(availableCurrencies[transaction.toCurrency].label)
              )
            ) {
              return acc;
            }
          }
        }
        const { timestamp } = transaction;
        const key = moment(timestamp).format("YYYY/MM/DD");
        if (acc[key] == null) {
          acc[key] = [];
        }
        acc[key].push(transaction);
        return acc;
      }, {});
    return Object.keys(keyedSections).map((key) => ({
      key,
      data: keyedSections[key],
    }));
  };

  render() {
    const { edit, remove } = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <Heading
            control={
              <EditButton
                onEdit={() => {
                  this.setState({
                    edit: !edit,
                    remove: !edit === false ? undefined : remove,
                  });
                }}
              />
            }
          >
            Transactions
          </Heading>
          <FilterInput
            onChangeText={this._onChangeText}
            placeholder="Filter Transactions"
          />
          <SectionList
            key={this.state.input}
            style={styles.listContainer}
            keyExtractor={(_, index) => index}
            initialNumToRender={20}
            contentContainerStyle={styles.list}
            sections={this._sections()}
            renderSectionHeader={this._renderSectionHeader}
            renderItem={this._renderItem}
            // onViewableItemsChanged={this._onViewableItemsChanged}
            enableVirtualization={true}
            stickySectionHeadersEnabled={false}
            bounces={true}
            alwaysBounceVertical={true}
            // ItemSeparatorComponent={Separator}
            // SectionSeparatorComponent={Separator}
            ListEmptyComponent={<Text>Loading</Text>}
          />
        </View>
      </View>
    );
  }
}
