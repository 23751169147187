// todo: remove $FlowFixME and replace with React.toChildrenArray
// todo: use [style] style for composition?!
// @flow
import * as React from "react";
import ReactSwipe from "react-swipe";
import { StyleSheet, View } from "react-native";
import { composeStyles, rem } from "../../theme/theme";
import { flexCenter } from "../../theme/common";
import { Dot, SwipeBase } from "./SwipeBase";
import { ThemeContext } from "../../theme/theme";
import type { SwipePropsType } from "./SwipeBase";

const styles = StyleSheet.create({
  main: {
    height: "100%",
    width: "100%",
    flex: 1,
    overflow: "hidden",
  },
  dotContainer: composeStyles(flexCenter, {
    flexDirection: "row",
    width: "100%",
    padding: rem(0.25),
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 7,
  }),
});

export class Swipe extends SwipeBase {
  swiper: ?ReactSwipe;

  componentDidUpdate(prevProps: SwipePropsType) {
    if (
      this.props.name === prevProps.name &&
      this.props.tabIndex !== prevProps.tabIndex
    ) {
      this.swiper != null && this.swiper.slide(this.props.tabIndex, 300);
    }
  }

  render() {
    const {
      name,
      showDots = true,
      children,
      initialTabIndex = 0,
      tabIndex = initialTabIndex,
      style,
      onTabSelect,
    } = this.props;
    const childrenArray = React.Children.toArray(children);
    return (
      <View style={style != null ? [styles.main, style] : styles.main}>
        <ReactSwipe
          ref={(r) => (this.swiper = r)}
          style={{
            container: {
              overflow: "hidden",
              visibility: "hidden",
              position: "relative",
              height: "100%",
            },
            child: {
              float: "left",
              width: "100%",
              position: "relative",
              transitionProperty: "transform",
              height: "100%",
            },
            wrapper: {
              overflow: "hidden",
              position: "relative",
              height: "100%",
            },
          }}
          key={name}
          swipeOptions={{
            continuous: false,
            callback: onTabSelect,
            disableScroll: false,
            startSlide: tabIndex,
            stopPropagation: true,
          }}
        >
          {childrenArray.map((child, idx) => (
            <div key={idx}>{child}</div>
          ))}
        </ReactSwipe>
        {showDots && childrenArray.length > 1 && (
          <ThemeContext.Consumer>
            {({ themeComposer }) => (
              <View
                style={themeComposer(styles.dotContainer, {
                  shadowColor: "background",
                })}
              >
                {childrenArray.map((_, idx) => [
                  <Dot
                    key={idx}
                    active={tabIndex === idx}
                    onPress={() => {
                      this.swiper != null &&
                        tabIndex !== idx &&
                        this.swiper.slide(idx, 300);
                      onTabSelect(idx);
                    }}
                  />,
                  // width spacing idea
                  //// $FlowFixMe is an array now
                  // idx !== childrenArray.length - 1 && (
                  //  <View
                  //    key={-idx}
                  //    // $FlowFixMe is an array now
                  //    style={{ width: `${1 / (childrenArray.length + 1) * 100}%` }}
                  //  />
                ])}
              </View>
            )}
          </ThemeContext.Consumer>
        )}
      </View>
    );
  }
}
