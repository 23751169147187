// @flow
import * as React from "react";
import { LayoutAware } from "../LayoutAware";

type PullToRefreshPropsType = {|
  isRefreshing: boolean,
  onRefresh: () => mixed,
  children: React.Node,
  showRefreshIndicator?: boolean,
|};

export class PullToRefreshBase<ST = {||}> extends LayoutAware<
  PullToRefreshPropsType,
  ST,
> {
  render(): React.Node {
    return null;
  }
}
