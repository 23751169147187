// @flow
import { composeStyles, rem } from "../theme/theme";
import { flexCenter } from "../theme/common";

export const webSectionStyle = composeStyles(flexCenter, {
  width: "40%",
  minWidth: 370,
  overflow: "hidden",
  paddingLeft: rem(0.5),
  paddingRight: rem(0.5),
});
