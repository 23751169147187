// @flow
import { connect } from "react-redux";
import type { StateType } from "../../../../reducers";
import type { DispatchType } from "../../../../actions/types";
import { AccountManagement } from "./AccountManagement";
import {
  accountActions,
  initWAL,
  updateWAL,
} from "../../../../actions/account";

const mapStateToProps = (state: StateType) => ({
  wal: state.account.wal,
  account: state.account.account,
  vault: state.account.vault,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onInitialize: (account, vault) => dispatch(initWAL(account, vault)),
  onUpdate: (action, vault) => dispatch(updateWAL(action, vault)),
  onReceiveVault: (vault) =>
    dispatch(accountActions.account.receiveVault(vault)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountManagement);
