// @flow
import { InteractionManager } from "react-native";
import * as Models from "../models";

type ReviverType = (string, mixed) => mixed;

// date.toJSON will produce iso strings https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toJSON
const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
export const dateReviver = (_: string, value: mixed) =>
  typeof value === "string" && isoDatePattern.test(value)
    ? new Date(value)
    : value;

export const classReviver = (_: string, value: mixed) => {
  if (
    typeof value === "object" &&
    value != null &&
    value.hasOwnProperty("__class__") &&
    typeof value.__class__ === "string"
  ) {
    const cls = Models[value["__class__"]];
    delete value.__class__;
    // todo https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/setPrototypeOf
    // says it's slow
    // alternative:
    // const nobj = Object.create(cls.prototype);
    // // $FlowFixMe
    // Object.keys(value).forEach((key) => (nobj[key] = value[key]));
    // return nobj;
    value = Object.setPrototypeOf(value, cls.prototype);
    if ("__bind" in value) {
      value.__bind();
    }
  }
  return value;
};

export const combineRevivers = (...revivers: ReviverType[]) => (
  key: string,
  value: mixed,
) => revivers.reduce((acc, reviver) => reviver(key, acc), value);

export const classReplacer = (_: string, value: mixed) => {
  if (
    typeof value === "object" &&
    value != null &&
    value.constructor.name in Models
  ) {
    if (process.env.NODE_ENV === "development") {
      // objects are sealed in development mode
      return { ...value, __class__: value.constructor.name };
    } else {
      // directly mutate object in non production for speed
      value.__class__ = value.constructor.name;
    }
  }
  return value;
};

export const defaultReplacers = classReplacer;

export const defaultRevivers = combineRevivers(dateReviver, classReviver);

export const stringify = <T>(data: T): Promise<string> => {
  return new Promise((resolve, reject) => {
    InteractionManager.runAfterInteractions(() => {
      try {
        resolve(JSON.stringify(data, defaultReplacers));
      } catch (err) {
        reject(err);
      }
    });
  });
};
export const parse = <T>(text: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    InteractionManager.runAfterInteractions(() => {
      try {
        resolve(JSON.parse(text, defaultRevivers));
      } catch (err) {
        reject(err);
      }
    });
  });
};
