// @flow
export type ObjectSubsetType<T> = $Shape<{ ...T }>;

export interface ModelInterface<
  // $FlowFixMe top level needs to be an object
  T: Object = Object,
> {
  update(ObjectSubsetType<T>): T;
}

export class Model<
  // $FlowFixMe top level needs to be an object
  T: Object = Object,
> implements ModelInterface<T> {
  // it's not useless, it enforces the "json" constructor for flow
  /*eslint no-useless-constructor: "off"*/
  constructor(_: ObjectSubsetType<T>) {}
  update(changes: ObjectSubsetType<T>): T {
    // elegant version:
    return Object.setPrototypeOf({ ...this, ...changes }, this);

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/setPrototypeOf
    // says it's slow
    // alternative:
    // const nobj = Object.create(this);
    // Object.keys(changes).forEach((key) => (nobj[key] = changes[key]));
    // $FlowFixMe
    // return nobj;
    // however, this version has problems for serialization! todo optimize when it becomes a problem
  }
}
