// @flow
import React from "react";
import { connect } from "react-redux";
import { pure } from "recompose";
import { Image, StyleSheet, View } from "react-native";
import { Button } from "../layout/Button";
import { MenuText } from "./Menu";
import MenuContainer from "./MenuContainer";
import {
  border,
  flexCenter,
  flexCenterDirection,
  flexCenterFlow,
  imageBorder,
  minMenuItemHeight,
} from "../theme/common";
import { iconStyle } from "./common";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { Account as AccountModel } from "../../api/models/index";
import { LayoutAware } from "../layout/LayoutAware";
import { DividerVertical } from "../layout/Dividers";
import { isTouchDevice } from "../layout/Device";
import type { RouterHistory } from "../router";
import { accountTo } from "../router";
import {
  AutoThemeIcon,
  CompactModeIcon,
  CurrentDeviceIcon,
  DarkThemeIcon,
  FaceIcon,
  FilledChartIcon,
  LightThemeIcon,
  MouseIcon,
  UnfilledChartIcon,
  SetupIcon,
} from "../img/Icon";
import type { StateType } from "../../reducers";
import { appActions } from "../../actions/app";
import type { DispatchType } from "../../actions/types";

const styles = StyleSheet.create({
  accountButtonBorder: {
    borderRadius: (32 + 2 * 2) / 2,
  },
  accountButton: composeStyles(flexCenter, iconStyle(32), {
    borderRadius: 32 / 2,
  }),
  collapsedMenu: {
    alignSelf: "flex-start",
  },
  bannerContainer: composeStyles(flexCenterDirection("column"), {
    height: rem(18),
    width: "100%",
  }),
  bannerBorder: {
    borderRadius: (128 + 2 * 4) / 2,
    marginBottom: rem(0.75),
  },
  bannerImage: composeStyles(iconStyle(128), {
    borderRadius: 128 / 2,
  }),
  bannerSetupImage: composeStyles(iconStyle(92), {
    borderRadius: 128 / 2,
    marginTop: 18,
    marginBottom: 18,
    // bit of perceptual balancing
    marginLeft: 17.5,
    marginRight: 19.5,
  }),
  button: composeStyles(flexCenterFlow("row"), {
    flex: 1,
    height: minMenuItemHeight,
  }),
});

const themes = [
  ["light", "Day", LightThemeIcon],
  ["dark", "Night", DarkThemeIcon],
  ["auto", "Auto", AutoThemeIcon],
];

const AccountButton = pure(({ account, navigateTo }) => (
  <ThemeContext.Consumer>
    {({ themeComposer, colors }) => (
      <Button
        style={styles.bannerContainer}
        onPress={() => navigateTo(accountTo())}
      >
        <View
          style={themeComposer(
            styles.bannerBorder,
            {},
            border(2, "solid", colors.juno),
          )}
        >
          {account != null ? (
            account.userIcon != null ? (
              <Image
                style={StyleSheet.compose(
                  styles.bannerImage,
                  imageBorder(4, colors.headerBackground),
                )}
                source={{ uri: account.userIcon }}
                alt="profile image"
                resizeMode="cover"
              />
            ) : (
              <FaceIcon style={styles.bannerImage} />
            )
          ) : (
            <SetupIcon style={styles.bannerSetupImage} />
          )}
        </View>
        <MenuText>
          {account != null
            ? account.userName == null
              ? "Anonymous"
              : account.userName
            : "Setup Device"}
        </MenuText>
      </Button>
    )}
  </ThemeContext.Consumer>
));

const ConnectedDevices = pure(({ navigateTo }) => (
  <Button
    style={styles.button}
    onPress={() => navigateTo(accountTo("devices"))}
  >
    <CurrentDeviceIcon
      style={StyleSheet.compose(
        iconStyle(16),
        {
          marginRight: rem(0.25),
        },
      )}
    />
    <MenuText>Manage Devices</MenuText>
  </Button>
));

const FilledCharts = connect(
  (state: StateType) => ({
    filled: state.app.filled,
  }),
  (dispatch: DispatchType) => ({
    onSetFilled: (filled) => dispatch(appActions.app.receiveFilled(filled)),
  }),
)(
  pure(({ onSetFilled, filled }) => (
    <ThemeContext.Consumer>
      {({ colors }) => (
        <Button style={styles.button} onPress={() => onSetFilled(!filled)}>
          {filled ? (
            <FilledChartIcon
              style={StyleSheet.compose(
                iconStyle(16),
                {
                  marginRight: rem(0.25),
                },
              )}
              fill={colors.juno}
            />
          ) : (
            <UnfilledChartIcon
              style={StyleSheet.compose(
                iconStyle(16),
                {
                  marginRight: rem(0.25),
                },
              )}
              fill={colors.textPrimary}
            />
          )}
          <MenuText>Fill Chart Area</MenuText>
        </Button>
      )}
    </ThemeContext.Consumer>
  )),
);

const FollowMouse = connect(
  (state: StateType) => ({
    followMouse: state.app.followMouse,
  }),
  (dispatch: DispatchType) => ({
    onSetFollowMouse: (followMouse) =>
      dispatch(appActions.app.receiveFollowMouse(followMouse)),
  }),
)(
  pure(({ onSetFollowMouse, followMouse }) => (
    <ThemeContext.Consumer>
      {({ colors }) => (
        <Button
          style={styles.button}
          onPress={() => onSetFollowMouse(!followMouse)}
        >
          <MouseIcon
            style={StyleSheet.compose(
              iconStyle(16),
              {
                marginRight: rem(0.25),
              },
            )}
            fill={followMouse ? colors.juno : colors.textPrimary}
          />
          <MenuText>Follow Mouse</MenuText>
        </Button>
      )}
    </ThemeContext.Consumer>
  )),
);

const CompactMode = connect(
  (state: StateType) => ({
    compact: state.app.compact,
  }),
  (dispatch: DispatchType) => ({
    onSetCompact: (compact) => dispatch(appActions.app.receiveCompact(compact)),
  }),
)(
  pure(({ onSetCompact, compact }) => (
    <ThemeContext.Consumer>
      {({ colors }) => (
        <Button style={styles.button} onPress={() => onSetCompact(!compact)}>
          <CompactModeIcon
            style={StyleSheet.compose(
              iconStyle(16),
              {
                marginRight: rem(0.25),
              },
            )}
            fill={compact ? colors.juno : colors.textPrimary}
          />
          <MenuText>Compact Mode</MenuText>
        </Button>
      )}
    </ThemeContext.Consumer>
  )),
);

const ThemeSelector = connect(
  (state: StateType) => ({
    theme: state.app.theme,
  }),
  (dispatch: DispatchType) => ({
    onSwitchTheme: (theme) => dispatch(appActions.app.receiveTheme(theme)),
  }),
)(
  pure(({ onSwitchTheme, theme }) => (
    <ThemeContext.Consumer>
      {({ themeComposer, colors }) => (
        <View style={styles.button}>
          {themes.map(([name, label, Icon], idx) => [
            <Button
              key={idx}
              onPress={() => onSwitchTheme(name)}
              style={themeComposer(
                styles.button,
                {},
                {
                  maxWidth: rem(8),
                  justifyContent:
                    idx === 0
                      ? "flex-end"
                      : idx === themes.length - 1
                      ? "flex-start"
                      : "center",
                },
              )}
            >
              <Icon
                style={StyleSheet.compose(
                  iconStyle(16),
                  {
                    marginLeft: name === "dark" ? rem(0.6) : rem(0.7),
                    marginRight: name === "dark" ? rem(0.125) : rem(0.25),
                  },
                )}
                fill={theme === name ? colors.juno : colors.textPrimary}
              />
              <MenuText
                style={{
                  marginRight: rem(0.9),
                }}
              >
                {label}
              </MenuText>
            </Button>,
            idx !== themes.length - 1 ? (
              <DividerVertical key={-idx - 1} />
            ) : null,
          ])}
        </View>
      )}
    </ThemeContext.Consumer>
  )),
);

type AccountPropsType = {|
  +account?: AccountModel,
  +history: RouterHistory,
|};

export class Account extends LayoutAware<AccountPropsType> {
  _navigateTo = (route: string) => {
    this.props.history.push(route);
  };

  render() {
    const { account } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <MenuContainer
            name="account"
            style={{ justifyContent: "flex-start" }}
            collapsedMenuStyle={styles.collapsedMenu}
            root={
              account != null && account.userIcon != null ? (
                <View
                  style={themeComposer(
                    styles.accountButtonBorder,
                    {},
                    border(1, "solid", colors.juno),
                  )}
                >
                  <Image
                    style={themeComposer(
                      styles.accountButton,
                      {},
                      imageBorder(2, colors.headerBackground),
                    )}
                    source={{ uri: account.userIcon }}
                    alt={
                      account.userName != null ? account.userName : "Anonymous"
                    }
                    resizeMode="cover"
                  />
                </View>
              ) : (
                <FaceIcon
                  style={themeComposer(
                    styles.accountButton,
                    {},
                    border(2, "solid", colors.headerBackground),
                  )}
                />
              )
            }
          >
            <AccountButton account={account} navigateTo={this._navigateTo} />

            {account != null && (
              <ConnectedDevices navigateTo={this._navigateTo} />
            )}

            {!isTouchDevice() && <FollowMouse />}

            <FilledCharts />

            {this.isPortrait() && this.isMobile() && <CompactMode />}

            <ThemeSelector />
          </MenuContainer>
        )}
      </ThemeContext.Consumer>
    );
  }
}
