// @flow
import {
  notNull,
  validateDate,
  validateListOf,
  validateNumber,
  validateString,
} from "./validate";
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { List } from "./list";

export type FileTypeType =
  | "image/png"
  | "image/jpeg"
  | "image/gif"
  | "application/pdf"
  | "application/octet-stream";
export type UUIDv4Type = string;

export type FileDataType = string;

export class File extends Model<File> {
  +id: UUIDv4Type;
  +data: FileDataType;

  constructor(json: ObjectSubsetType<File>) {
    super(json);
    this.id = validateString(json.id);
    this.data = validateString(json.data);
  }

  size(): number {
    return this.data.length;
  }

  type(): string {
    return File.type(this.data);
  }

  static type(dataB64: string): FileTypeType {
    // naive checks
    if (dataB64.startsWith("iVBORw")) {
      // ?PNG
      return "image/png";
    } else if (dataB64.startsWith("/9j/")) {
      // FF D8
      return "image/jpeg";
    } else if (dataB64.startsWith("R0lG")) {
      // GIF
      return "image/gif";
    } else if (dataB64.startsWith("JVBERi")) {
      // %PDF
      return "application/pdf";
    }
    return File.defaultFileType;
  }

  static +defaultFileType: FileTypeType = "application/octet-stream";
}

export class Quota extends Model<Quota> {
  +id: UUIDv4Type;
  +fingerprint: string;
  +lifetime: number;
  +available: number;
  +total: number;
  +files: $ReadOnlyArray<$PropertyType<File, "id">>;
  +deleted: boolean;

  constructor(json: ObjectSubsetType<Quota>) {
    super(json);
    this.id = validateString(json.id);
    this.fingerprint = validateString(json.fingerprint);
    this.lifetime = validateNumber(json.lifetime);
    this.available = validateNumber(json.available);
    // todo from api
    this.total = 10000000;
    this.files = validateListOf(validateString, json.files);
    this.deleted = json.deleted != null ? json.deleted : false;
  }

  static delete(quota: Quota): Quota {
    return quota.update({ deleted: true, files: [] });
  }

  static deletedFromId(id: $PropertyType<Quota, "id">): Quota {
    return new Quota({
      id,
      lifetime: 0,
      available: 0,
      total: 0,
      files: [],
      deleted: true,
    });
  }
}

export class QuotaList extends List<Quota, QuotaList> {
  +quotas: $ReadOnlyArray<Quota>;

  constructor(json: ObjectSubsetType<QuotaList>) {
    super("quotas");
    this.quotas = validateListOf(
      notNull((quota) => new Quota(quota)),
      json.quotas,
    );
  }

  merge(other: QuotaList): QuotaList {
    const allQuotas = [...this.values(), ...other.values()];
    const quotas = allQuotas.reduce((acc, quota) => {
      acc[quota.id] = quota;
      return acc;
    }, ({}: { [$PropertyType<Quota, "id">]: Quota }));
    return this.update({
      quotas: Object.keys(quotas).map((key) => quotas[key]),
    });
  }

  removeDeleted(): QuotaList {
    return this.filter(({ deleted }) => deleted === false);
  }

  ids(): $ReadOnlyArray<$PropertyType<Quota, "id">> {
    return this.values().map(({ id }) => id);
  }

  filterQuota(
    ...quotaIds: $ReadOnlyArray<$PropertyType<Quota, "id">>
  ): QuotaList {
    return this.filter(({ id }) => quotaIds.includes(id));
  }

  removeQuota(
    ...quotaIds: $ReadOnlyArray<$PropertyType<Quota, "id">>
  ): QuotaList {
    return this.filter(({ id }) => !quotaIds.includes(id));
  }

  static +empty = new QuotaList({ quotas: [] });

  static singleton(quota: Quota): QuotaList {
    return new QuotaList({ quotas: [quota] });
  }
}

export class FileMeta extends Model<FileMeta> {
  +id: $PropertyType<File, "id">;
  +quotaId: $PropertyType<Quota, "id">;
  +name: string;
  +type: string;
  +size: number;
  +created: Date;
  +updated: Date;
  +size: number;
  +deleted: boolean;

  constructor(json: ObjectSubsetType<FileMeta>) {
    super(json);
    this.id = validateString(json.id);
    this.quotaId = validateString(json.quotaId);
    this.name = validateString(json.name);
    this.type = validateString(json.type);
    this.created = validateDate(json.created);
    this.updated = validateDate(json.updated);
    this.size = validateNumber(json.size);
    this.deleted = json.deleted === true;
  }

  static fullId(fileMeta: FileMeta): string {
    return `${fileMeta.id}#${fileMeta.quotaId}`;
  }

  static updateWithFile(
    fileMeta: FileMeta,
    file: File,
    overrideType?: FileTypeType,
  ): FileMeta {
    return fileMeta.update({
      size: file.size(),
      type: overrideType != null ? overrideType : file.type(),
      updated: new Date(),
    });
  }

  static fromFile(
    name: string,
    quotaId: string,
    file: File,
    overrideType?: FileTypeType,
  ): FileMeta {
    const updated = new Date();
    return new FileMeta({
      id: file.id,
      quotaId,
      name,
      created: updated,
      updated,
      type: overrideType != null ? overrideType : file.type(),
      size: file.size(),
    });
  }

  static deletedFromId(
    id: $PropertyType<FileMeta, "id">,
    quotaId: $PropertyType<FileMeta, "quotaId">,
  ) {
    const deleted = new Date();
    return new FileMeta({
      id,
      quotaId,
      name: id,
      created: deleted,
      updated: deleted,
      type: File.defaultFileType,
      size: 0,
      deleted: true,
    });
  }

  static delete(file: FileMeta): FileMeta {
    return file.update({ updated: new Date(), deleted: true });
  }
}

export class FileMetaList extends List<FileMeta, FileMetaList> {
  +files: $ReadOnlyArray<FileMeta>;

  constructor(json: ObjectSubsetType<FileMetaList>) {
    super("files");
    this.files = validateListOf(
      notNull((fileMeta) => new FileMeta(fileMeta)),
      json.files,
    );
  }

  merge(other: FileMetaList): FileMetaList {
    const allFiles: FileMeta[] = [...this.values(), ...other.values()];
    const { files } = allFiles
      .sort((a, b) => b.updated.getTime() - a.updated.getTime())
      .reduce(
        (acc, file) => {
          if (acc.uniqueIds.has(FileMeta.fullId(file))) {
            return acc;
          }
          acc.files.push(file);
          acc.uniqueIds.add(FileMeta.fullId(file));
          return acc;
        },
        { uniqueIds: new Set<string>(), files: [] },
      );
    return new FileMetaList({ files });
  }

  filterQuota(
    ...quotaIds: $ReadOnlyArray<$PropertyType<Quota, "id">>
  ): FileMetaList {
    return this.filter(({ quotaId }) => quotaIds.includes(quotaId));
  }

  removeQuota(
    ...quotaIds: $ReadOnlyArray<$PropertyType<Quota, "id">>
  ): FileMetaList {
    return this.filter(({ quotaId }) => !quotaIds.includes(quotaId));
  }

  filterType(...types: $ReadOnlyArray<FileTypeType>): FileMetaList {
    return this.filter(({ type }) => types.includes(type));
  }

  removeType(...types: $ReadOnlyArray<FileTypeType>): FileMetaList {
    return this.filter(({ type }) => !types.includes(type));
  }

  removeDeleted(): FileMetaList {
    return this.filter(({ deleted }) => !deleted);
  }

  static +empty = new FileMetaList({ files: [] });

  static singleton(fileMeta: FileMeta): FileMetaList {
    return new FileMetaList({ files: [fileMeta] });
  }
}
