// @flow
import React from "react";
import { StyleSheet, View } from "react-native";
import { border } from "../theme/common";
import { rem } from "../theme/theme";
import { ThemeContext } from "../theme/theme";
import type { StyleType } from "../theme/theme";

const styles = StyleSheet.create({
  horizontalDivider: {
    width: rem(0.6),
  },
  verticalDivider: {
    height: rem(1.3125),
    width: rem(0.2),
  },
});

export class DividerHorizontal extends React.PureComponent<{|
  +width?: number | string,
  +style?: StyleType,
  +margin?: number | string,
|}> {
  render() {
    const { width, margin, style = {} } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              styles.horizontalDivider,
              {},
              border(1, "solid", colors.separator, "Bottom"),
              width != null ? { width } : {},
              style,
              margin != null ? { marginTop: margin, marginBottom: margin } : {},
            )}
          />
        )}
      </ThemeContext.Consumer>
    );
  }
}
export class DividerVertical extends React.PureComponent<{|
  +height?: number | string,
  +style?: StyleType,
  +margin?: number | string,
|}> {
  render() {
    const { height, margin, style = {} } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              styles.verticalDivider,
              {},
              border(1, "solid", colors.separator, "Left"),
              height != null ? { height } : {},
              style,
              margin != null ? { marginLeft: margin, marginRight: margin } : {},
            )}
          />
        )}
      </ThemeContext.Consumer>
    );
  }
}
