// @flow
import { Model } from "./models";
import type { ObjectSubsetType } from "./models";
import { validateNumber, validateString } from "./validate";

export class Exchange extends Model<Exchange> {
  +id: number;
  +slug: string;

  constructor(json: ObjectSubsetType<Exchange>) {
    super(json);
    this.id = validateNumber(json.id);
    this.slug = validateString(json.slug);
  }
}

export class Market extends Model<Market> {
  +id: number;
  +exchangeId: $PropertyType<Exchange, "id">;
  +tradePairId: number;

  constructor(json: ObjectSubsetType<Market>) {
    super(json);
    this.id = validateNumber(json.id);
    this.exchangeId = validateNumber(json.exchangeId);
    this.tradePairId = validateNumber(json.tradePairId);
  }
}
