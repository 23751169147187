// @flow
import { handleActions } from "redux-actions";
import { actionHandler } from "react-redux-flow-tools";
import type { CurrencySymbolType } from "../api/models";
import { candlesActions } from "../actions/candles";
import { deepFreeze } from "react-redux-flow-tools";
import { MutableTimeSeries } from "../models/TimeSeries";
import { TimeSeries } from "../models/TimeSeries";
import type { Reducers } from "./types";

export type TimeSeriesStateType = {
  hours24: {
    +[CurrencySymbolType]: TimeSeries,
  },
  +[CurrencySymbolType]: TimeSeries,
};

export const timeSeriesDefaults: TimeSeriesStateType = deepFreeze({
  hours24: {},
});

const hours24 = 1000 * 60 * 60 * 24;
const currentMinus24 = (): Date => new Date(new Date().getTime() - hours24);

const actionHandlers = {
  ...actionHandler(
    candlesActions.candles.receiveCandles,
    (state, action): TimeSeriesStateType => {
      const candleList = action.payload;
      const quote = candleList.quote;
      const timeSeries = new MutableTimeSeries(
        state[quote] || new TimeSeries(),
      );
      timeSeries.addCandles(candleList);
      return { ...state, [quote]: timeSeries };
    },
  ),

  ...actionHandler(
    candlesActions.candles.receiveCandles24,
    (state, action): TimeSeriesStateType => {
      const candleList = action.payload;
      const quote = candleList.quote;
      const timeSeries = new MutableTimeSeries(
        state.hours24[quote] != null ? state.hours24[quote] : new TimeSeries(),
      );
      const timeSeries24 = timeSeries
        .addCandles(candleList)
        .slice(currentMinus24());
      return { ...state, hours24: { ...state.hours24, [quote]: timeSeries24 } };
    },
  ),
};

export const timeSeries: Reducers<TimeSeriesStateType> = handleActions(
  actionHandlers,
  timeSeriesDefaults,
);
