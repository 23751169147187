// @flow
import * as React from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { composeStyles, ThemeContext } from "../theme/theme";
import { flexCenter } from "../theme/common";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    position: "relative",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
  }),
  clickArea: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
  },
});

export class MenuOverlay extends React.PureComponent<{|
  +children: React.Node,
  +onCloseMenu: () => mixed,
|}> {
  render() {
    const { children, onCloseMenu } = this.props;
    if (children == null) {
      return null;
    }
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <View
            style={themeComposer(styles.container, {
              backgroundColor: "transparentBackground",
            })}
          >
            <TouchableWithoutFeedback onPress={onCloseMenu}>
              <View style={styles.clickArea} />
            </TouchableWithoutFeedback>
            {children}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
