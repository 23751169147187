// @flow
import type { CurrencySymbolType } from "../api/models";
import { Candle, CandleList } from "../api/models";
import { just } from "react-redux-flow-tools";

export type TimeSeriesElementType = {
  +timestamp: Date,
  +[CurrencySymbolType]: number,
};

// todo port to List interface
export class TimeSeries {
  _timeSeries: TimeSeriesElementType[] = [];
  _keys: string[] = [];

  constructor(timeSeries?: TimeSeries) {
    if (timeSeries != null) {
      this._timeSeries = timeSeries._timeSeries.slice(0);
      this._keys = timeSeries._keys.slice(0);
    }
  }

  static fromValues(
    elements: $ReadOnlyArray<TimeSeriesElementType>,
  ): TimeSeries {
    if (elements.length === 0) {
      return new TimeSeries();
    } else {
      const timeSeries = new TimeSeries();
      timeSeries._timeSeries = elements.slice(0);
      timeSeries._keys = Object.keys(elements[0]).filter(
        (key) => key !== "timestamp",
      );
      return timeSeries;
    }
  }

  elementFactory(timestamp: Date): TimeSeriesElementType {
    return this._keys
      .map((k) => ({ [k]: 0 }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), { timestamp });
  }

  isEmpty(): boolean {
    return this._timeSeries.length === 0;
  }

  keys(): $ReadOnlyArray<string> {
    return this._keys;
  }

  hasKey(key: ?string): boolean {
    return this._keys.includes(key);
  }

  values(): $ReadOnlyArray<TimeSeriesElementType> {
    return this._timeSeries.slice(0);
  }

  length(): number {
    return this._timeSeries.length;
  }

  head(): TimeSeriesElementType {
    return this._timeSeries.length > 0
      ? this._timeSeries[0]
      : this.elementFactory(new Date());
  }

  tail(): TimeSeries {
    if (this._timeSeries.length === 0) {
      return new TimeSeries();
    } else {
      const timeSeries = new TimeSeries();
      timeSeries._timeSeries = this._timeSeries.slice(1);
      timeSeries._keys = Object.keys(this._timeSeries[0]).filter(
        (key) => key !== "timestamp",
      );
      return timeSeries;
    }
  }

  current(): TimeSeriesElementType {
    return (
      this._timeSeries[this._timeSeries.length - 1] ||
      this.elementFactory(new Date())
    );
  }

  min(key?: string): number {
    if (!this.hasKey(key)) {
      throw new Error(
        `KeyError: key ${JSON.stringify(key)} not found in time series`,
      );
    }
    if (this.isEmpty()) {
      return Number.NEGATIVE_INFINITY;
    }
    if (key == null) {
      const min = Math.min(
        ...this._timeSeries.map((row) =>
          Math.min(
            ...Object.entries(row)
              .filter(([key]) => key !== "timestamp")
              .map(([, value]) => +value),
          ),
        ),
      );
      return min;
    } else {
      const min = Math.min(...this._timeSeries.map((row) => row[just(key)]));
      return min;
    }
  }

  max(key?: string): number {
    if (!this.hasKey(key)) {
      throw new Error(
        `KeyError: key ${JSON.stringify(key)} not found in time series`,
      );
    }
    if (this.isEmpty()) {
      return Number.POSITIVE_INFINITY;
    }
    if (key == null) {
      const max = Math.max(
        ...this._timeSeries.map((row) =>
          Math.max(
            ...Object.entries(row)
              .filter(([key]) => key !== "timestamp")
              .map(([, value]) => +value),
          ),
        ),
      );
      return max;
    } else {
      const max = Math.max(...this._timeSeries.map((row) => row[just(key)]));
      return max;
    }
  }

  minByKey(): { +[string]: number } {
    return this.keys()
      .map((key) => ({ [key]: this.min(key) }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
  }

  maxByKey(): { +[string]: number } {
    return this.keys()
      .map((key) => ({ [key]: this.max(key) }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
  }

  findIndex(test: (TimeSeriesElementType) => number): number {
    // todo binary search!
    return this._timeSeries.findIndex((element) => test(element) >= 0);
  }

  get(idx: number): TimeSeriesElementType {
    return this._timeSeries[idx];
  }

  /** @return index of first position where timestamp >= searchDate or -1 */
  idx(date: Date): number {
    return this.findIndex(
      ({ timestamp }) => timestamp.getTime() - date.getTime(),
    );
  }

  at(date: Date): TimeSeriesElementType {
    const idx = this.idx(date);
    if (idx < 0) {
      return this.elementFactory(date);
    } else {
      return this._timeSeries[idx];
    }
  }

  /** @return a slice of the timeseries from (including) since to (excluding) until */
  slice(since?: Date, until?: Date): TimeSeries {
    if (since == null) {
      return TimeSeries.fromValues(this.values());
    }
    if (until != null && since.getTime() > until.getTime()) {
      throw new Error(
        `ArgumentError: since ${since.toISOString()} cannot be greater than until ${until.toISOString()}`,
      );
    }
    const start = Math.max(0, this.idx(since));
    const untilIdx = until != null ? this.idx(until) : -1;
    const end = untilIdx >= 0 ? untilIdx : this._timeSeries.length;
    return TimeSeries.fromValues(this._timeSeries.slice(start, end));
  }

  // todo performance
  // todo check for equality on keys
  subset(keys: $ReadOnlyArray<string>): TimeSeries {
    return TimeSeries.fromValues(
      this.values().map((element) =>
        keys.reduce(
          (acc, key) => {
            if (key in element) {
              acc[key] = element[key];
            }
            return acc;
          },
          { timestamp: element.timestamp },
        ),
      ),
    );
  }
}

export class MutableTimeSeries extends TimeSeries {
  _clean: boolean = true;

  _cleanUp(): void {
    this.keys().forEach((key) => {
      this._clean = true;
      for (let idx = 0; idx < this.length(); idx++) {
        for (
          let rightIdx = idx, leftIdx = idx;
          this._timeSeries[idx][key] === 0 &&
          (rightIdx < this.length() || leftIdx >= 0);
          rightIdx++, leftIdx--
        ) {
          const leftValue = leftIdx >= 0 ? this._timeSeries[leftIdx][key] : 0;
          if (leftValue > 0) {
            this._timeSeries[idx] = {
              ...this._timeSeries[idx],
              [key]: leftValue,
            };
          } else {
            const rightValue =
              rightIdx < this.length() ? this._timeSeries[rightIdx][key] : 0;
            if (rightValue > 0) {
              this._timeSeries[idx] = {
                ...this._timeSeries[idx],
                [key]: rightValue,
              };
            }
          }
        }
        if (this._timeSeries[idx][key] === 0) {
          this._clean = false;
        }
      }
    });
  }

  add(
    currency: CurrencySymbolType,
    addTimestamp: Date,
    value: number,
    _dirty: boolean = false,
  ): TimeSeries {
    if (!this._keys.includes(currency)) {
      this._timeSeries = this._timeSeries.map((element) => ({
        [currency]: 0,
        ...element,
      }));
      this._keys.push(currency);
    }

    const idx = this.idx(addTimestamp);

    const changeObj = { [currency]: value };
    if (idx < 0) {
      this._timeSeries.push({
        ...this.elementFactory(addTimestamp),
        ...changeObj,
      });
    } else {
      // addTimestamp can only be <= timestamp according to findIndex
      if (
        this._timeSeries[idx].timestamp.toISOString() ===
        addTimestamp.toISOString()
      ) {
        this._timeSeries[idx] = { ...this._timeSeries[idx], ...changeObj };
      } else {
        // addTimestamp has to be smaller now
        this._timeSeries.splice(idx, 0, {
          ...this.elementFactory(addTimestamp),
          ...changeObj,
        });
      }
    }
    _dirty || this._cleanUp();
    return this;
  }

  addCandle(
    currency: CurrencySymbolType,
    candle: Candle,
    _dirty: boolean = false,
  ) {
    this.add(
      currency,
      candle.timestamp,
      (candle.high + candle.low) / 2,
      _dirty,
    );
  }

  addCandles(candleList: CandleList) {
    const adder = (candle) => this.addCandle(candleList.base, candle, true);
    candleList.candles.forEach(adder);
    this._cleanUp();
    return this;
  }
}
