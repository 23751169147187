// @flow
import { Model } from "./models";
import type { CurrencySymbolType } from "./currencies";
import { Exchange } from "./exchanges";
import type { ObjectSubsetType } from "./models";
import {
  notNull,
  validateDate,
  validateListOf,
  validateNumber,
  validateString,
} from "./validate";
import { List } from "./list";

export class Candle extends Model<Candle> {
  +high: number;
  +low: number;
  +open: number;
  +close: number;
  +volume: number;
  +timestamp: Date;

  constructor(json: ObjectSubsetType<Candle>) {
    super(json);
    this.high = validateNumber(json.high);
    this.low = validateNumber(json.low);
    this.open = validateNumber(json.open);
    this.close = validateNumber(json.close);
    this.volume = validateNumber(json.volume);
    this.timestamp = validateDate(json.timestamp);
  }
}

export class CandleList extends List<Candle, CandleList> {
  +candles: $ReadOnlyArray<Candle>;
  +base: CurrencySymbolType;
  +quote: CurrencySymbolType;
  +exchanges: $ReadOnlyArray<$PropertyType<Exchange, "slug">>;

  constructor(json: ObjectSubsetType<CandleList>) {
    super("candles");
    const candles = validateListOf(
      notNull((candle) => new Candle(candle)),
      json.candles,
    );
    const sortedCandles = candles
      .slice(0)
      .sort((a, b) => b.timestamp - a.timestamp);
    this.candles = sortedCandles;
    this.base = validateString(json.base).toUpperCase();
    this.quote = validateString(json.quote).toUpperCase();
    this.exchanges = validateListOf(validateString, json.exchanges);
  }
}
