// @flow
import * as React from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import type { CurrencySymbolType, FileDataType } from "../../../api/models";
import {
  FileMetaList,
  Quota,
  QuotaList,
  TransactionList,
  Vault,
} from "../../../api/models";
import { updateWAL } from "../../../actions/account";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import { accountTo, Redirect } from "../../router";
import { Heading } from "../../layout/Heading";
import { composeStyles, rem } from "../../theme/theme";
import { flexCenter, flexCenterDirection } from "../../theme/common";
import { webSectionStyle } from "../../layout";
import { DocumentsList } from "./DocumentsList";
import { Button, EditButton } from "../../layout/Button";
import {
  AddIcon,
  UploadIcon,
  LockIcon,
  LockClosedIcon,
  DownloadIcon,
  CloseIcon as ErrorIcon,
  ImageIcon,
} from "../../img/Icon";
import { Upload } from "../../layout/upload";
import { iconStyle } from "../../header/common";
import { base64ArrayBuffer } from "./b64";
import {
  createFile,
  createQuota,
  deleteFile,
  deleteQuota,
  downloadFile,
  fetchQuota,
  rekeyQuota,
} from "../../../actions/file";
import type { FilesStateType } from "../../../reducers/files";
import { ThemeContext } from "../../theme/theme";
import { sensibleByte } from "../../layout/SensibleNumber";
import { DividerVertical } from "../../layout/Dividers";
import { isIphoneXorAbove } from "../../layout/Device";
import type { DownloadStateType, UploadStateType } from "../../../actions/file";
import { just } from "react-redux-flow-tools";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
    flex: 1,
  }),
  section: composeStyles(webSectionStyle, {
    justifyContent: "flex-start",
    height: "100%",
  }),
  headingListView: {
    marginBottom: rem(0.5),
  },
  uploadRow: composeStyles(flexCenterDirection("row"), { width: "100%" }),
  uploadContainer: composeStyles({
    flex: 1,
  }),
  uploadButton: composeStyles(flexCenterDirection("row"), {
    marginTop: rem(1.25),
    marginBottom: rem(1.25),
  }),
  uploadButtonText: composeStyles({
    fontSize: rem(1.5),
    marginLeft: rem(0.25),
  }),
  uploadButtonTextBig: composeStyles({
    fontSize: rem(2.5),
    marginLeft: rem(0.5),
  }),
  quotaInfoContainer: composeStyles(flexCenter, {
    width: "100%",
  }),
  quotaInfo: composeStyles(flexCenterDirection("row"), {
    width: "100%",
    paddingTop: rem(0.5),
    paddingBottom: isIphoneXorAbove() ? rem(2) : rem(1),
  }),
  quotaInfoText: {
    flex: 1,
    fontSize: rem(1),
  },
  progressBar: composeStyles({
    borderRadius: rem(0.5),
    height: rem(1),
    width: "100%",
  }),
});

type DocumentsPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +transactions: TransactionList,
  +vault: ?Vault,
  +files: FileMetaList,
  +localQuota: ?Quota,
  +quotas: QuotaList,
  +downloadedFiles: $PropertyType<FilesStateType, "files">,
  +uploadState: UploadStateType,
  +downloadState: DownloadStateType,
  +onUpdate: typeof updateWAL,
  +onCreateQuota: typeof createQuota,
  +onCreateFile: typeof createFile,
  +onRekeyQuota: typeof rekeyQuota,
  +onDeleteFile: typeof deleteFile,
  +onDeleteQuota: typeof deleteQuota,
  +onDownloadFile: typeof downloadFile,
  +onFetchQuota: typeof fetchQuota,
|};

type DocumentsComponentStateType = {|
  edit: boolean,
  uploading: boolean,
|};

export class Documents extends React.PureComponent<
  DocumentsPropsType,
  DocumentsComponentStateType,
> {
  state = {
    edit: false,
    uploading: false,
  };

  _startUploading = () => {
    this.setState({ uploading: true });
  };

  _stopUploading = () => {
    this.setState({ uploading: false });
  };

  async componentDidMount() {
    const { localQuota, quotas, vault } = this.props;
    if (localQuota == null && vault != null) {
      const fingerprint = await vault.keyPair.publicKey.fingerprint();
      const theQuota = quotas.find(
        ({ fingerprint: quotaFingerprint }) => fingerprint === quotaFingerprint,
      );
      if (theQuota != null) {
        this.props.onFetchQuota(theQuota.id, vault);
      }
    }
  }

  render() {
    const {
      files,
      localQuota,
      quotas,
      vault,
      downloadedFiles,
      uploadState,
      downloadState,
      onCreateQuota,
      onRekeyQuota,
      // onDeleteQuota,
      onCreateFile,
      onDownloadFile,
      onDeleteFile,
    } = this.props;
    if (vault == null) {
      return <Redirect to={accountTo("devices")} />;
    }
    const noLocalQuota = localQuota == null || localQuota.deleted === true;
    const cleanQuotas = (noLocalQuota
      ? quotas
      : quotas.removeQuota(just(localQuota).id)
    ).removeDeleted();
    const noRemoteQuota = cleanQuotas.length() === 0;
    if (noLocalQuota && noRemoteQuota) {
      return (
        <View style={styles.container}>
          <View style={styles.section}>
            <Heading>Documents</Heading>
            <Button
              style={styles.uploadButton}
              disabled={!(noLocalQuota && noRemoteQuota)}
              onPress={() => onCreateQuota(vault)}
            >
              <LockIcon style={iconStyle()} />
              <Text style={styles.uploadButtonText}>
                {noLocalQuota && noRemoteQuota
                  ? "Create File Quota"
                  : "Loading..."}
              </Text>
            </Button>
          </View>
        </View>
      );
    }
    const { edit, uploading } = this.state;
    const MyUploadIcon =
      uploadState === "uploading"
        ? UploadIcon
        : uploadState === "encrypting"
        ? LockClosedIcon
        : uploadState === "error"
        ? ErrorIcon
        : downloadState === "downloading"
        ? DownloadIcon
        : downloadState === "decrypting"
        ? LockIcon
        : downloadState === "error"
        ? ErrorIcon
        : uploading
        ? UploadIcon
        : AddIcon;
    const byteStr =
      localQuota != null ? sensibleByte(localQuota.available) : "N/A";
    const deletionDate = new Date();
    if (localQuota != null) {
      deletionDate.setDate(deletionDate.getDate() + localQuota.lifetime);
    }
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.container}>
            <View style={styles.section}>
              <Heading
                control={
                  <EditButton onEdit={() => this.setState({ edit: !edit })} />
                }
                containerStyle={styles.headingListView}
              >
                Documents
              </Heading>
              <DocumentsList
                files={files}
                quotas={quotas}
                downloadedFiles={downloadedFiles}
                edit={edit}
                onDownloadFile={(fileMeta, quota) => {
                  onDownloadFile(fileMeta, quota, vault);
                }}
                onDeleteFile={(fileMeta, quota) => {
                  onDeleteFile(fileMeta, quota, vault);
                }}
              />
              {localQuota != null && (
                <Button
                  style={styles.uploadButton}
                  onPress={() =>
                    onRekeyQuota(
                      localQuota,
                      files.filterQuota(localQuota.id),
                      vault,
                    )
                  }
                >
                  <LockIcon style={iconStyle()} />
                  <Text style={styles.uploadButtonText}>Rekey</Text>
                </Button>
              )}
              {localQuota == null && (
                <Button
                  style={styles.uploadButton}
                  onPress={() => onCreateQuota(vault)}
                >
                  <LockIcon style={iconStyle()} />
                  <Text style={styles.uploadButtonText}>Create File Quota</Text>
                </Button>
              )}
              {localQuota != null && (
                <View style={styles.uploadRow}>
                  <Upload
                    disabled={
                      uploadState !== "ready" || downloadState !== "ready"
                    }
                    onData={(data: string | ArrayBuffer, name: string) => {
                      const fileData: FileDataType =
                        typeof data === "string"
                          ? data
                          : base64ArrayBuffer(data);
                      onCreateFile(fileData, name, localQuota, vault);
                    }}
                    onDragEnter={this._startUploading}
                    onDragLeave={this._stopUploading}
                    style={styles.uploadContainer}
                    type="binary"
                    // accept=".txt"
                  >
                    <View style={styles.uploadButton}>
                      <MyUploadIcon
                        style={uploading ? iconStyle(48) : iconStyle()}
                      />
                      <Text
                        numberOfLines={1}
                        style={themeComposer(
                          uploading
                            ? styles.uploadButtonTextBig
                            : styles.uploadButtonText,
                          { color: "textPrimary" },
                        )}
                      >
                        {uploadState === "uploading"
                          ? "Uploading..."
                          : uploadState === "encrypting"
                          ? "Encrypting..."
                          : uploadState === "error"
                          ? "Error while uploading file!"
                          : downloadState === "downloading"
                          ? "Downloading..."
                          : downloadState === "decrypting"
                          ? "Decrypting..."
                          : downloadState === "error"
                          ? "Error while downloading file!"
                          : "Upload File"}
                      </Text>
                    </View>
                  </Upload>
                  {Platform.OS !== "web" &&
                    (uploadState === "ready" && downloadState === "ready") && (
                      <DividerVertical margin={rem(0.5)} height={0.65 * 48} />
                    )}
                  {Platform.OS !== "web" &&
                    (uploadState === "ready" && downloadState === "ready") && (
                      <Upload
                        onData={(data: string | ArrayBuffer, name: string) => {
                          const fileData: FileDataType =
                            typeof data === "string"
                              ? data
                              : base64ArrayBuffer(data);
                          onCreateFile(fileData, name, localQuota, vault);
                        }}
                        onDragEnter={this._startUploading}
                        onDragLeave={this._stopUploading}
                        style={styles.uploadContainer}
                        type="image"
                        // accept=".txt"
                      >
                        <View style={styles.uploadButton}>
                          <ImageIcon
                            style={uploading ? iconStyle(48) : iconStyle()}
                          />
                          <Text
                            style={themeComposer(
                              uploading
                                ? styles.uploadButtonTextBig
                                : styles.uploadButtonText,
                              { color: "textPrimary" },
                            )}
                            numberOfLines={1}
                          >
                            Upload Image
                          </Text>
                        </View>
                      </Upload>
                    )}
                </View>
              )}
              {localQuota != null && (
                <View style={styles.quotaInfoContainer}>
                  {/*
                only seems to work on web
                <ProgressBar
                  style={styles.progressBar}
                  color={colors.juno}
                  trackColor={colors.transparentJuno}
                  progress={1 - quota.available / quota.total}
                />
                */}
                  <View
                    style={composeStyles(flexCenterDirection("row"), {
                      width: "100%",
                      height: rem(1),
                      borderRadius: rem(0.5),
                      overflow: "hidden",
                    })}
                  >
                    <View
                      style={{
                        flex: 1 - localQuota.available / localQuota.total,
                        backgroundColor: colors.juno,
                        height: "100%",
                      }}
                    />
                    <View
                      style={{
                        flex: localQuota.available / localQuota.total,
                        backgroundColor: colors.transparentJuno,
                        height: "100%",
                      }}
                    />
                  </View>
                  <View style={styles.quotaInfo}>
                    <Text
                      style={themeComposer(
                        styles.quotaInfoText,
                        { color: "textSecondary" },
                        { textAlign: "right" },
                      )}
                    >
                      Available: {byteStr}
                    </Text>
                    <DividerVertical margin={rem(0.5)} />
                    <Text
                      style={themeComposer(styles.quotaInfoText, {
                        color: "textSecondary",
                      })}
                    >
                      Valid until: {deletionDate.toLocaleDateString()}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
