// @flow
import * as React from "react";
import { Dimensions, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Button } from "../layout/Button";
import {
  flexCenter,
  flexCenterDirection,
  flexCenterFlow,
} from "../theme/common";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { isDefined, just } from "react-redux-flow-tools";
import { LayoutAware } from "../layout/LayoutAware";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
  }),
  modal: composeStyles(flexCenterDirection("column"), {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 20,
  }),
  titleContainer: composeStyles(flexCenterFlow("row"), {
    width: "100%",
    paddingTop: rem(2),
    paddingLeft: rem(2),
    paddingRight: rem(2),
  }),
  title: {
    flex: 1,
    fontSize: rem(2),
  },
  closeContainer: composeStyles(flexCenter, {
    width: "100%",
    minHeight: rem(2),
  }),
  button: {
    flex: 1,
  },
  buttonText: {},
  bodyContainer: {
    padding: rem(2),
    width: "100%",
  },
});

type ModalPropsType = {|
  +title?: string,
  +children?: View | Text,
  +closeable?: boolean,
  +onDismiss: () => void,
|};

class JustModal extends LayoutAware<ModalPropsType> {
  render() {
    const { title, children, onDismiss, closeable = true } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(styles.container, {
              backgroundColor: "transparentBackground",
            })}
          >
            <SafeAreaView
              style={themeComposer(
                styles.modal,
                {
                  backgroundColor: "background",
                  shadowColor: "shadow",
                },
                {
                  width: this.isMobile()
                    ? Dimensions.get("window").width
                    : undefined,
                },
              )}
            >
              <View style={styles.titleContainer}>
                {isDefined(title) && (
                  <Text
                    style={themeComposer(styles.title, {
                      color: "textPrimary",
                    })}
                  >
                    {just(title)}
                  </Text>
                )}
              </View>
              <View style={styles.bodyContainer}>{children}</View>
              {closeable && (
                <View style={styles.closeContainer}>
                  <Button style={styles.button} onPress={onDismiss}>
                    <Text
                      style={themeComposer(styles.buttonText, {
                        color: "textSecondary",
                      })}
                    >
                      Close
                    </Text>
                  </Button>
                </View>
              )}
            </SafeAreaView>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export class Modal extends React.PureComponent<ModalPropsType> {
  render() {
    const { children } = this.props;
    if (isDefined(children)) {
      return <JustModal {...this.props} />;
    } else {
      return null;
    }
  }
}
