// @flow
import React from "react";
import moize from "moize";
import { StyleSheet, Text, View } from "react-native";
import {
  composeStyles,
  rem,
  stringToColor,
  ThemeContext,
} from "../../../theme/theme";
import { StretchedSVG } from "../../../svg/StretchedSVG";
import { circlePath, G, Rect, Text as SvgText } from "../../../svg/svg";
import type { LayoutType } from "../../../../models/index";
import { curveBundle, radialLine } from "d3-shape";
import { cluster, hierarchy } from "d3-hierarchy";
import { flexCenter } from "../../../theme/common";
import { SquareView } from "../../../layout/SquareView";
import { FilterInput } from "../../../layout/FilterInput";
import { escapeRegExp } from "../../TokenList";
import { Button } from "../../../layout/Button";
import { defer } from "../../../../threads/defer";
import { Canvas, path2D } from "../../../svg/Canvas";

type NodeId = string;
type EdgeType = {| +id: string, +source: NodeId, +target: NodeId |};
type EdgesType = $ReadOnlyArray<EdgeType>;
type NodeType = $Call<hierarchy>;

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    flexDirection: "column",
    height: "100%",
    width: "100%",
  }),
  networkContainer: composeStyles(flexCenter, {
    flex: 1,
    width: "100%",
  }),
  inputContainer: composeStyles(flexCenter, {
    flexDirection: "column",
    width: "100%",
    padding: rem(1),
    position: "relative",
    zIndex: 1000,
  }),
  input: {
    flex: 1,
  },
});

class Network {
  +scores: {
    [string]: { score: number, node: NodeId, targets: Set<NodeId> },
    max: { score: number, node: NodeId },
  };

  static empty = new Network([]);

  constructor(edges: EdgesType) {
    this.scores = edges.reduce(
      (acc, { source, target }) => {
        if (source in acc) {
          acc[source].score += 1;
        } else {
          acc[source] = { score: 1, node: source, targets: new Set<string>() };
        }
        acc[source].targets.add(target);
        if (acc[source].score > acc.max.score) {
          acc.max = { node: source, score: acc[source].score };
        }

        if (target in acc) {
          acc[target].score += 1;
        } else {
          acc[target] = { score: 1, node: target, targets: new Set<string>() };
        }
        acc[target].targets.add(source);
        if (acc[target].score > acc.max.score) {
          acc.max = { node: target, score: acc[target].score };
        }
        return acc;
      },
      ({
        max: {
          score: Number.NEGATIVE_INFINITY,
          node: "",
        },
      }: $PropertyType<Network, "scores">),
    );
  }

  +getRoot = moize.simple(
    async (
      startName?: NodeId,
      maxLevel?: number = 20,
    ): Promise<$Call<hierarchy>> => {
      if (startName != null && this.scores[startName] == null) {
        alert("no/insufficient channels for " + startName);
      }
      const start =
        startName == null || this.scores[startName] == null
          ? this.scores[this.scores.max.node]
          : this.scores[startName];
      const visited = new Set<string>();
      const todo = [[null, 0, start.node]];
      const root = {
        name: start.node,
        score: start.score,
        children: [],
        parent: null,
      };
      const animated = defer.animated(250);
      while (todo.length > 0) {
        await animated();
        const [parent, level, nextId] = todo.shift();
        if (visited.has(nextId)) {
          continue;
        } else {
          visited.add(nextId);
        }
        const score = this.scores[nextId];
        const node =
          parent == null
            ? root
            : {
                name: nextId,
                score: score.score,
                children: [],
                parent,
              };
        if (parent != null) {
          parent.children.push(node);
        }

        const sortedTargets = [...score.targets].filter(
          (target) => !visited.has(target),
        );
        if (level < maxLevel - 1) {
          const nextLevel = level + 1;
          todo.push(
            ...sortedTargets.map((target) => [node, nextLevel, target]),
          );
        }
      }

      // filter out directs
      root.children = root.children.filter((node) => node.children.length > 0);

      return hierarchy(root).sort(
        (a, b) => a.height - b.height || a.data.name.localeCompare(b.data.name),
      );
    },
  );
}

const degDiff = (a: number, b: number): number => {
  console.assert(
    a >= 0 && a <= 360 && b >= 0 && b <= 360,
    "angles should be between 0 and 360",
    a,
    b,
  );
  // based on https://stackoverflow.com/a/7869457
  // move a b to -180 - 180 range;
  const a180 = a - 180;
  const b180 = b - 180;
  let diff = a180 - b180;
  diff += diff > 180 ? -360 : diff < -180 ? 360 : 0;
  return diff;
};

type NetworkGraphPropsType = {|
  +textSize: number,
  +margin: number,
  +marginTop: number,
  +filled: boolean,
  +followMouse: boolean,
  +dpiScale: number,
|};

type NetworkGraphComponentStateType = {|
  width: number,
  height: number,
  network: Network,
  root: ?NodeType,
  found: ?NodeType,
  nodeInfos: { +[NodeId]: {| +id: NodeId, +alias: string |} },
  candidates: $ReadOnlyArray<string>,
|};

export class NetworkGraph extends React.PureComponent<
  NetworkGraphPropsType,
  NetworkGraphComponentStateType,
> {
  canvas: ?HTMLCanvasElement;
  +cluster: $Call<cluster>;
  +line: $Call<radialLine>;
  debounce: boolean = false;

  static defaultProps = {
    textSize: rem(2),
    margin: 0,
    marginTop: 0,
    filled: true,
    followMouse: true,
    dpiScale: 3,
  };

  state = {
    width: 0,
    height: 0,
    network: Network.empty,
    root: null,
    found: null,
    nodeInfos: {},
    candidates: [],
  };

  constructor(props: NetworkGraphPropsType) {
    super(props);
    this.cluster = cluster().separation((a, b) => {
      if (a.parent !== b.parent) {
        return 5;
      } else {
        return 1;
      }
    });
    this.line = radialLine()
      .curve(curveBundle.beta(0.85))
      .radius((d) => d.y)
      .angle((d) => (d.x / 180) * Math.PI);
  }

  componentDidMount() {
    fetch("/static/experiments/lightning/graph.json").then((resp) =>
      resp.json().then(async (graph) => {
        const { edges, nodes } = graph;
        const network = new Network(
          edges.map((edge) => ({
            id: edge.channel_id,
            source: edge.node1_pub,
            target: edge.node2_pub,
          })),
        );
        const root = await network.getRoot();
        const nodeInfos = nodes.reduce((acc, node) => {
          acc[node.pub_key] = node;
          return acc;
        }, {});

        this.setState({ network, nodeInfos, root }, this._draw);
      }),
    );
    this._draw();
  }

  _draw = () => {
    if (this.canvas == null) {
      return;
    }
    const { width, height, root, found } = this.state;
    if (root == null || width < 10 || height < 10) {
      return;
    }
    const canvas: HTMLCanvasElement = this.canvas;
    canvas.width = width * this.props.dpiScale;
    canvas.height = height * this.props.dpiScale;

    const context = this.canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    context.scale(this.props.dpiScale, this.props.dpiScale);
    context.translate(width / 2, height / 2);
    context.beginPath();

    context.strokeStyle = "#000";
    context.lineWidth = 0.1;
    context.globalAlpha = 0.5;
    root.leaves().forEach((node) => {
      if (node.parent == null) {
        return null;
      }
      const path = node.path(root);
      if (
        found != null &&
        path.some((node) => node.data.name === found.data.name)
      ) {
        context.lineWidth = 1;
      } else {
        context.lineWidth = 0.1;
      }
      const nodeName = path[path.length - 2].data.name;
      // context.strokeStyle = stringToColor(nodeName);
      context.strokeStyle =
        this.state.nodeInfos[nodeName] != null
          ? this.state.nodeInfos[nodeName].color
          : stringToColor(nodeName);
      context.stroke(path2D(canvas, this.line(path)));
    });

    context.rotate(-Math.PI);
    root.descendants().forEach((node) => {
      context.save();
      let walk = node;
      while (walk.parent != null && walk.parent.parent != null) {
        walk = walk.parent;
      }
      context.globalAlpha =
        found != null && found.data.name === node.data.name
          ? 1
          : 1 / Math.max(1, node.depth);
      // context.fillStyle = stringToColor(walk.data.name);
      context.fillStyle =
        this.state.nodeInfos[walk.data.name] != null
          ? this.state.nodeInfos[walk.data.name].color
          : stringToColor(walk.data.name);
      context.rotate((node.x / 180) * Math.PI);
      const radius =
        node.data.score > root.data.score
          ? 8
          : 1 + 8 * Math.sqrt(node.data.score / root.data.score);
      //$FlowFixMe
      const path = path2D(canvas, circlePath(radius, 0, node.y));
      context.fill(path);
      if (found != null && found.data.name === node.data.name) {
        context.strokeStyle = "#000";
        context.lineWidth = 1;
        context.globalAlpha = 1;
        context.stroke(path);
      }
      context.restore();
    });
    context.closePath();
    context.restore();
  };

  _updateRoot = async (node?: NodeId) => {
    const { network } = this.state;
    const root = await network.getRoot(node);
    this.cluster(root);
    this.setState({ root, found: null }, this._draw);
  };

  _onLayout = ({ width, height }: LayoutType) => {
    // const { margin } = this.props;
    // const finalMargin = margin > 0 && margin < 1 ? margin * height : margin;
    this.cluster.size([360, height / 2 - 10]);
    this.setState({ width, height }, () => {
      this.state.root != null && this._updateRoot(this.state.root.data.name);
    });
  };

  _findNode(x: number, y: number): ?NodeType {
    const { root, height } = this.state;
    if (root == null) {
      return null;
    }
    const r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

    const bandWidth = (height / 2 - 10) / root.height;
    const band = Math.round(r / bandWidth);
    const bandR = Math.round(band * bandWidth);
    if (Math.abs(r - bandR) > 8) {
      this.setState({ found: null });
      return null;
    }
    if (r <= bandWidth / 2) {
      return root;
    }

    const atan = Math.atan2(y, x);
    let theta = atan < 0 ? Math.PI / 2 + Math.abs(atan) : Math.PI / 2 - atan;
    theta = theta < 0 ? 2 * Math.PI + theta : theta;
    theta = (theta / Math.PI) * 180;
    let err = Number.POSITIVE_INFINITY;
    let found = null;
    for (let node of root.descendants()) {
      if (Math.abs(node.y - bandR) < 3) {
        const nodeErr = Math.abs(degDiff(theta, node.x));
        // candidate
        if (nodeErr < err) {
          err = nodeErr;
          found = node;
        }
      }
    }
    if (found != null) {
      console.log("found node", found);
    } else if (err > 360) {
      console.log("none found, ", err);
    } else {
      console.log("none found, best candidate", found, "with err deg", err);
      found = null;
    }
    return found;
  }

  lastInput: string = "";
  filterDebounce: boolean = false;

  _onFilterChange = async (text: string): Promise<void> => {
    const baseSet =
      text.length > 1 && text.startsWith(this.lastInput)
        ? this.state.candidates.slice(0)
        : Object.keys(this.state.nodeInfos);
    this.lastInput = text;

    if (this.filterDebounce === true) {
      return Promise.resolve();
    }
    this.filterDebounce = true;
    const escapedValue = escapeRegExp(text);
    if (escapedValue === "") {
      this.setState({ candidates: [] });
      this.lastInput = "";
      this.filterDebounce = false;
      return;
    }
    const regex = new RegExp(
      "^.*" + escapedValue.split(" ").join(".*") + ".*$",
      "i",
    );
    let candidates = [];
    const animated = defer.animated(250, 100);
    while (baseSet.length > 0) {
      const key = baseSet.shift();
      if (regex.test(this.state.nodeInfos[key].alias)) {
        candidates.push(key);
      }
      await animated();
    }
    const sorter = (a, b) => {
      const aAlias = this.state.nodeInfos[a].alias.toLowerCase();
      const bAlias = this.state.nodeInfos[b].alias.toLowerCase();
      if (aAlias < bAlias) {
        return -1;
      }
      if (aAlias > bAlias) {
        return 1;
      }
      return 0;
    };
    candidates = candidates.sort(sorter);
    this.setState({ candidates });
    this.filterDebounce = false;
    if (text !== this.lastInput) {
      return this._onFilterChange(this.lastInput);
    }
  };

  render() {
    const { width, height, root, found, nodeInfos, candidates } = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.inputContainer}>
          <FilterInput onChangeText={this._onFilterChange} />
          <View style={{ width: "100%", height: 0, position: "relative" }}>
            <ThemeContext.Consumer>
              {({ themeComposer, colors }) => (
                <View
                  style={themeComposer(
                    flexCenter,
                    { backgroundColor: "transparentBackground" },
                    {
                      position: "absolute",
                      width: "100%",
                      left: 0,
                      top: rem(0.25),
                    },
                  )}
                >
                  {candidates.slice(0, 100).map((candidate) => (
                    <Button
                      hover
                      fullWidth
                      key={candidate}
                      style={{ zIndex: 1000 }}
                    >
                      <Text
                        style={{
                          fontSize: rem(1.5),
                          color: colors.textPrimary,
                          margin: rem(0.25),
                          width: "100%",
                          textAlign: "center",
                        }}
                        onPress={() => {
                          this.setState({ candidates: [] }, () => {
                            if (this.canvas != null) {
                              this.canvas.style.opacity = "0.5";
                            }
                            setTimeout(async () => {
                              await this._updateRoot(candidate);
                              if (this.canvas != null) {
                                this.canvas.style.opacity = "1";
                              }
                            });
                          });
                        }}
                      >
                        {this.state.nodeInfos[candidate].alias}
                      </Text>
                    </Button>
                  ))}
                </View>
              )}
            </ThemeContext.Consumer>
          </View>
        </View>
        <View style={styles.networkContainer}>
          {root == null ? (
            <Text>Loading Data...</Text>
          ) : (
            <SquareView>
              {({ size }) => (
                <View
                  style={{ position: "relative", width: size, height: size }}
                >
                  <Canvas
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width,
                      height,
                    }}
                    ref={(ref) => (this.canvas = ref)}
                    width={width * this.props.dpiScale}
                    height={height * this.props.dpiScale}
                  />
                  <StretchedSVG onLayout={this._onLayout}>
                    <G transform={`translate(${width / 2}, ${height / 2})`}>
                      {found != null && nodeInfos[found.data.name] != null && (
                        <SvgText
                          y={
                            found.y *
                            Math.cos(((found.x + 180) / 180) * Math.PI)
                          }
                          x={found.y * Math.sin((found.x / 180) * Math.PI)}
                          dy={-15}
                          textAnchor="middle"
                        >
                          {nodeInfos[found.data.name].alias}
                        </SvgText>
                      )}
                    </G>

                    <Rect
                      x={0}
                      y={0}
                      width={width}
                      height={height}
                      fill="white"
                      opacity={0}
                      onMouseMove={(evt) => {
                        const x = -width / 2 + evt.nativeEvent.offsetX;
                        const y = height / 2 - evt.nativeEvent.offsetY;
                        if (this.debounce === false) {
                          this.debounce = true;
                          const found = this._findNode(x, y);
                          this.setState({ found });
                          this._draw();
                          this.debounce = false;
                        }
                      }}
                      onClick={(evt) => {
                        const x = -width / 2 + evt.nativeEvent.offsetX;
                        const y = height / 2 - evt.nativeEvent.offsetY;
                        const node = this._findNode(x, y);
                        if (node != null) {
                          if (this.canvas != null) {
                            this.canvas.style.opacity = "0.5";
                          }
                          setTimeout(async () => {
                            await this._updateRoot(node.data.name);
                            if (this.canvas != null) {
                              this.canvas.style.opacity = "1";
                            }
                          }, 0);
                        }
                      }}
                    />
                  </StretchedSVG>
                </View>
              )}
            </SquareView>
          )}
        </View>
      </View>
    );
  }
}
