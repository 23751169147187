// @flow
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { MenuText } from "./Menu";
import MenuContainer from "./MenuContainer";
import type { RootPathEnum } from "../router";
import { Link, to } from "../router";
import { LayoutAware } from "../layout/LayoutAware";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { DividerVertical } from "../layout/Dividers";
import {
  flexCenter,
  flexCenterFlow,
  menuIconSize,
  menuItemStyle,
  noDecorationStyle,
} from "../theme/common";
import { BackIcon } from "../img/Icon";
import { iconStyle } from "./common";

const navLinkStyle = composeStyles(menuItemStyle, noDecorationStyle, {
  // height: "100%",
});

const styles = StyleSheet.create({
  nav: composeStyles(flexCenterFlow("row"), {
    position: "relative",
    width: "100%",
  }),
  navLinkButton: composeStyles(flexCenter, {
    height: "100%",
  }),
  backIcon: composeStyles(flexCenter, iconStyle(menuIconSize), {}),
});

type NavPropsType = {|
  +backTo?: ?string,
  +currentModule?: RootPathEnum,
|};

export class Nav extends LayoutAware<NavPropsType> {
  _pages: RootPathEnum[] = [
    "overview",
    "documents",
    "transactions",
    "rebalance",
    // "swap_tokens",
    "experiments",
  ];

  renderAgnostic() {
    const { currentModule } = this.props;
    return (
      <View style={styles.nav}>
        {this._pages.map((page, idx) => [
          <Link key={page} to={to(page)} style={navLinkStyle}>
            {/*<Button style={styles.navLinkButton}>*/}
            <ThemeContext.Consumer>
              {({ colors }) => (
                <MenuText
                  style={{
                    color:
                      page === currentModule ? colors.juno : colors.textPrimary,
                  }}
                >
                  {page.replace("_", " ")}
                </MenuText>
              )}
            </ThemeContext.Consumer>
            {/*</Button>*/}
          </Link>,
          idx === this._pages.length - 1 ? null : (
            <DividerVertical key={`${page}div`} height={rem(2)} />
          ),
        ])}
      </View>
    );
  }

  renderMobile() {
    const { backTo, currentModule = "overview" } = this.props;
    const sortedPages = [
      currentModule,
      ...this._pages.filter((page) => page !== currentModule),
    ];
    let backToButton;
    if (backTo != null) {
      const label = backTo
        .split("/")
        .filter((part) => part.length > 0)
        .slice(-1)[0];
      backToButton = (
        <Link to={backTo} style={noDecorationStyle}>
          <View style={flexCenterFlow("row")}>
            <BackIcon style={styles.backIcon} />
            <MenuText>{label}</MenuText>
          </View>
        </Link>
      );
    }
    return (
      <MenuContainer root={backToButton} name="nav">
        {sortedPages.map((page, idx) =>
          idx === 0 ? (
            <MenuText key={page}>{page.replace("_", " ")}</MenuText>
          ) : (
            <Link
              key={page}
              to={to(page)}
              style={composeStyles(flexCenter, noDecorationStyle, {
                width: "100%",
              })}
            >
              <MenuText>{page.replace("_", " ")}</MenuText>
            </Link>
          ),
        )}
      </MenuContainer>
    );
  }
}
