// @flow
import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { border, flexCenter } from "../theme/common";
import { Button } from "../layout/Button";
import { AddIcon, RemoveIcon, WalletIcon } from "../img/Icon";
import { iconStyle } from "../header/common";
import type { CurrencySymbolType } from "../../api/models/index";
import type { CurrenciesStateType } from "../../reducers/currencies";
import { FilterInput } from "../layout/FilterInput";
import { currencyIcon } from "../img/currencies";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    flex: 1,
    overflow: "hidden",
  }),
  listContainer: {
    flex: 1,
    width: "100%",
  },
  row: composeStyles(flexCenter, {
    width: "100%",
    flexDirection: "row",
    height: rem(4),
  }),
  nameContainer: composeStyles(flexCenter, {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-start",
  }),
  name: {
    fontSize: rem(2),
    paddingLeft: rem(0.75),
    paddingRight: rem(0.75),
  },
  walletIcon: iconStyle(rem(2)),
  removeButtonLeft: {
    paddingRight: rem(1.5),
    paddingLeft: rem(1),
  },
});

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

type TokenListPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +usedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onAddCurrency: (CurrencySymbolType) => mixed,
  +onRemoveCurrency: (CurrencySymbolType) => mixed,
|};

type TokenListComponentStateType = {|
  remove?: CurrencySymbolType,
  input: string,
|};

export class TokenList extends React.PureComponent<
  TokenListPropsType,
  TokenListComponentStateType,
> {
  static defaultProps = {
    walletCurrencies: [],
    usedCurrencies: [],
    onAddCurrency: () => {},
    onRemoveCurrency: () => {},
  };

  state = {
    remove: undefined,
    input: "",
  };

  _onChangeText = (input: string) => this.setState({ input });

  render() {
    const {
      availableCurrencies,
      usedCurrencies,
      walletCurrencies,
      onAddCurrency,
      onRemoveCurrency,
    } = this.props;
    const { remove, input } = this.state;
    const unusedCurrencies = Object.keys(availableCurrencies).filter(
      (symbol) => !usedCurrencies.includes(symbol),
    );
    let filteredUsedCurrencies = usedCurrencies;
    let filteredUnusedCurrencies = unusedCurrencies;
    if (input !== "") {
      const escapedValue = escapeRegExp(input);
      if (escapedValue !== "") {
        const regex = new RegExp(
          "^.*" + escapedValue.split(" ").join(".*") + ".*$",
          "i",
        );
        filteredUsedCurrencies = usedCurrencies.filter(
          (symbol) =>
            regex.test(symbol) ||
            (availableCurrencies[symbol] != null &&
              regex.test(availableCurrencies[symbol].label)),
        );
        filteredUnusedCurrencies = unusedCurrencies.filter(
          (symbol) =>
            regex.test(symbol) ||
            (availableCurrencies[symbol] != null &&
              regex.test(availableCurrencies[symbol].label)),
        );
      }
    }
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors, currencyColors }) => (
          <View style={styles.container}>
            <FilterInput
              onChangeText={this._onChangeText}
              placeholder="Filter Tokens"
            />
            <ScrollView style={styles.listContainer}>
              {filteredUsedCurrencies
                .concat(filteredUnusedCurrencies)
                .filter((symbol) => availableCurrencies[symbol] != null)
                .map((symbol, idx) => {
                  const currency = availableCurrencies[symbol];
                  const unused = idx >= filteredUsedCurrencies.length;
                  const AddRemoveIcon = unused ? AddIcon : RemoveIcon;
                  const Icon = currencyIcon(symbol);
                  return (
                    <View
                      key={symbol}
                      style={themeComposer(
                        styles.row,
                        {},
                        border(1, "solid", colors.separator, "Bottom"),
                        idx === usedCurrencies.length
                          ? composeStyles(
                              border(1, "solid", colors.separator, "Top"),
                              { marginTop: 1 },
                            )
                          : {},
                      )}
                    >
                      <Button
                        style={styles.removeButtonLeft}
                        onPress={
                          unused
                            ? () => onAddCurrency(symbol)
                            : () =>
                                this.setState({
                                  remove:
                                    remove === symbol ? undefined : symbol,
                                })
                        }
                      >
                        <AddRemoveIcon
                          style={iconStyle(rem(2.5))}
                          fill={
                            unused ? colors.buttonSuccess : colors.buttonDanger
                          }
                        />
                      </Button>
                      <Icon
                        style={iconStyle(rem(2))}
                        fill={currencyColors(symbol)}
                      />
                      <View style={styles.nameContainer}>
                        <Text
                          style={themeComposer(styles.name, {
                            color: "textPrimary",
                          })}
                        >
                          {currency.label}
                        </Text>
                        {walletCurrencies.includes(currency.symbol) && (
                          <WalletIcon style={styles.walletIcon} />
                        )}
                      </View>
                      {remove === symbol && (
                        <Button
                          type="danger"
                          label="Remove"
                          onPress={() => {
                            this.setState({ remove: undefined });
                            onRemoveCurrency(symbol);
                          }}
                        />
                      )}
                    </View>
                  );
                })}
            </ScrollView>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
