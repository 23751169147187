// @flow
import { createActions } from "redux-actions";
import { ident, just, noop } from "react-redux-flow-tools";
import type {
  ActionCreatorType,
  DispatchType,
  GetStateType,
  PureActionCreatorType,
} from "./types";
import type { DeviceTypeEnum, ObjectSubsetType } from "../api/models";
import { Account, Device, EncryptedWAL, Key, WAL } from "../api/models";
import { DeviceList } from "../api/models/device";
import { KeyPair, Vault } from "../api/models/PGP";
import { getClient } from "../api";
import { currentDeviceType } from "../components/layout/Device";
import { appActions } from "./app";
import { rekeyQuota } from "./file";

type AccountActionTypes = {|
  +account: {|
    +receiveVault: ActionCreatorType<Vault>,
    +clearVault: PureActionCreatorType,
    +receiveAccount: ActionCreatorType<{|
      +account: Account,
      +wal: WAL<Account>,
      +vault: Vault,
    |}>,
    +clearAccount: PureActionCreatorType,
    +receiveDevices: ActionCreatorType<DeviceList>,
    +receiveDevice: ActionCreatorType<Device>,
    +removeDevice: ActionCreatorType<$PropertyType<Device, "name">>,
    +clearDevices: PureActionCreatorType,
    +isSyncing: ActionCreatorType<boolean>,
    +isUploading: ActionCreatorType<boolean>,
    +receiveSeen: ActionCreatorType<number>,
  |},
|};

export const accountActions: AccountActionTypes = createActions({
  account: {
    receiveVault: ident,
    clearVault: noop,
    receiveAccount: ident,
    clearAccount: noop,
    receiveDevices: ident,
    receiveDevice: ident,
    removeDevice: ident,
    clearDevices: noop,
    isSyncing: ident,
    isUploading: ident,
    receiveSeen: ident,
  },
});

const _uploadKey = async (publicKey: Key, type: DeviceTypeEnum) => {
  if (!publicKey.isPublic()) {
    console.error("Can only upload public keys!", publicKey);
    throw new Error("Can only upload public keys!");
  }
  const client = await getClient();
  await client.apis.keys.post_keys_({
    key: {
      key: publicKey.key,
      type,
    },
  });
};
const _fetchKeyById = async (keyid: string): Promise<$ReadOnlyArray<Key>> => {
  const client = await getClient();
  const {
    body: { keys },
  } = await client.apis.keys.get_keys_({
    keyid,
  });
  return keys.map(({ key }) => new Key({ key }));
};

const _fetchKey = async (fingerprint: string): Promise<Key> => {
  const client = await getClient();
  try {
    const {
      body: { key },
    } = await client.apis.keys.get_keys__fingerprint_({
      fingerprint,
    });
    return new Key({ key });
  } catch (error) {
    console.error(error);
    throw new Error("Key with not found for fingerprint: " + fingerprint);
  }
};

const syncer = <T>(
  fun: (DispatchType, getState: GetStateType) => T | Promise<T>,
): ((DispatchType, GetStateType) => Promise<T>) => async (
  dispatch: DispatchType,
  getState: GetStateType,
) => {
  try {
    await dispatch(accountActions.account.isSyncing(true));
    return await fun(dispatch, getState);
  } finally {
    dispatch(accountActions.account.isSyncing(false));
  }
};

const uploader = <T>(
  fun: (DispatchType, getState: GetStateType) => T | Promise<T>,
): ((DispatchType, GetStateType) => Promise<T>) => async (
  dispatch: DispatchType,
  getState: GetStateType,
) => {
  try {
    await dispatch(accountActions.account.isUploading(true));
    return await fun(dispatch, getState);
  } finally {
    dispatch(accountActions.account.isUploading(false));
  }
};

export const fetchDeviceList = (vault: Vault) =>
  syncer(async (dispatch: DispatchType) => {
    const client = await getClient();
    const devices = await Promise.all(
      vault.keyRing.trusted().map(async (vaultKey) => {
        const fingerprint = await vaultKey.fingerprint();
        const {
          body: { key, type },
        } = await client.apis.keys.get_keys__fingerprint_({
          fingerprint,
        });
        if (key == null || type == null) {
          throw new Error("PGPError: upload contains unrecognized keys!");
        }
        const fetchedKey = new Key({ key });
        return new Device({
          name: await fetchedKey.userid(),
          type,
          timestamp: await fetchedKey.timestamp(),
          key: fetchedKey,
        });
      }),
    );
    dispatch(
      accountActions.account.receiveDevices(new DeviceList({ devices })),
    );
  });

export const uploadVault = (vaultText: string) =>
  uploader(async (dispatch: DispatchType) => {
    try {
      const vault = await Vault.deserialize(vaultText);
      await dispatch(fetchDeviceList(vault));
      dispatch(accountActions.account.clearVault());
      dispatch(accountActions.account.receiveVault(vault));
      dispatch(syncWAL(vault));
    } catch (error) {
      console.error(error);
    }
  });

export const createVault = (userid: string) =>
  syncer(async (dispatch: DispatchType) => {
    const keyPair = await KeyPair.generateKeyPair(userid);
    const type = currentDeviceType();
    await _uploadKey(keyPair.publicKey, type);
    const timestamp = await keyPair.publicKey.timestamp();
    const vault = new Vault({ keyPair });
    await dispatch(initWAL(new Account({}), vault));
    dispatch(accountActions.account.receiveVault(vault));
    dispatch(
      accountActions.account.receiveDevice(
        new Device({
          name: userid,
          type,
          timestamp,
          key: keyPair.publicKey,
        }),
      ),
    );
  });

export const clearVault = () => async (dispatch: DispatchType) => {
  dispatch(accountActions.account.clearAccount());
  dispatch(accountActions.account.clearDevices());
  dispatch(accountActions.account.clearVault());
};

export const initWAL = (account: Account, vault: Vault) =>
  uploader(async (dispatch: DispatchType) => {
    const cipher = await vault.box(account);
    const client = await getClient();
    const fingerprint = await vault.keyPair.publicKey.fingerprint();
    await client.apis.wal.put_wal__fingerprint_({
      fingerprint,
      init: { init: cipher, version: Account.modelVersion },
    });
    await dispatch(syncWAL(vault, false, true));
  });

let _debounce = false;
export const syncWAL = (
  vault?: Vault,
  cached?: boolean = true,
  _force: boolean = false,
) => async (dispatch: DispatchType, getState: GetStateType): Promise<void> => {
  if (_debounce && !_force) {
    return;
  }
  _debounce = true;
  if (vault == null) {
    const defaultVault = getState().account.vault;
    if (defaultVault == null) {
      console.warn("no default vault found!");
      return;
    }
    vault = defaultVault;
  }
  const client = await getClient();
  const fingerprint = await vault.keyPair.publicKey.fingerprint();
  let cachedSequence = 0;
  let accountWAL;
  if (cached) {
    accountWAL = getState().account.wal;
    if (accountWAL != null) {
      const last = accountWAL.last();
      if (last != null) {
        cachedSequence = last.sequence;
      }
    }
  }
  let response;
  try {
    response = await client.apis.wal.get_wal__fingerprint_({
      fingerprint,
      sequence: cachedSequence.toString(),
    });
  } catch (error) {
    // todo swagger-js doesn't understand 304. can't fine tune the error, just do nothing
    _debounce = false;
    return;
  }
  await dispatch(
    syncer(async (dispatch) => {
      let encryptedWal;
      try {
        encryptedWal = new EncryptedWAL(response.body);
      } catch (error) {
        console.error(error);
        return;
      }
      try {
        const wal =
          cached && accountWAL != null
            ? WAL.mergeEncryptedWAL(accountWAL, encryptedWal, just(vault))
            : WAL.fromEncryptedWAL(encryptedWal, just(vault));
        const awaitedWal: WAL<Account> = await wal;
        const awaitedAccount: Account = await awaitedWal.current(
          Account,
          ...Account.mergers,
        );
        const newVault = await awaitedAccount.keyRing
          .values()
          .reduce(async (acc, keyTrust) => {
            const intermediateVault = await acc;
            if (keyTrust.trusted === true) {
              return await intermediateVault.trust(keyTrust.key);
            } else {
              return await intermediateVault.distrust(keyTrust.key);
            }
          }, (just(vault): Vault));
        await dispatch(
          accountActions.account.receiveAccount({
            account: awaitedAccount,
            wal: awaitedWal,
            vault: newVault,
          }),
        );
        _debounce = false;
      } catch (error) {
        if (
          !error.message.startsWith(
            "ValueError: cipher is not signed by a key we trust",
          )
        ) {
          console.error(error);
          return;
        }

        console.warn(error.message, error.keyid);
        const keyid = error.keyid;
        if (await just(vault).isUntrusted(keyid)) {
          console.debug("ignoring known untrusted key", keyid);
          return;
        }

        const untrustedKeys = await _fetchKeyById(keyid);
        if (untrustedKeys.length === 0) {
          // unrecognized key
          return dispatch(
            appActions.app.dialog.show({
              title: "Pairing Request",
              message: `An unrecognized key requested access, ignoring. Key ID: ${keyid}.`,
              actions: [
                {
                  label: "Don't Trust Key",
                  primary: true,
                  isClosing: true,
                  onClick: async () => {
                    const newVault = await just(vault).distrust(keyid);
                    await dispatch(
                      accountActions.account.receiveVault(newVault),
                    );
                    await dispatch(syncWAL(newVault, cached, true));
                  },
                },
              ],
            }),
          );
        }
        if (untrustedKeys.length > 1) {
          return dispatch(
            appActions.app.dialog.show({
              title: "Pairing Request",
              message: `Encountered a key collision, please contact support.`,
              actions: [
                {
                  label: "Understood",
                  primary: true,
                  isClosing: true,
                },
              ],
            }),
          );
        }
        const [untrustedKey] = untrustedKeys;
        dispatch(
          appActions.app.dialog.show({
            title: "Pairing Request",
            message: `New key requested access to your Account.
Device Nickname: ${await untrustedKey.userid()}.
Fingerprint: ${await untrustedKey.fingerprint()}.
Created at: ${(await untrustedKey.timestamp()).toISOString()}.`,
            actions: [
              {
                label: "Trust Key",
                primary: true,
                isClosing: true,
                onClick: async () => {
                  const newVault: Vault = await just(vault).trust(untrustedKey);
                  await dispatch(accountActions.account.receiveVault(newVault));
                  const account = getState().account.account;
                  if (account == null) {
                    throw new Error("Account not initialized.");
                  }
                  await dispatch(
                    updateWAL(
                      { keyRing: await account.keyRing.trust(untrustedKey) },
                      newVault,
                    ),
                  );
                  await dispatch(syncWAL(newVault, cached, true));
                  for (let key of newVault.keyRing.trusted()) {
                    if (
                      (await key.fingerprint()) ===
                      (await newVault.keyPair.publicKey.fingerprint())
                    ) {
                      continue;
                    }
                    await dispatch(syncAccountFor(null, key, newVault));
                  }
                },
              },
              {
                label: "Don't Trust Key",
                secondary: true,
                isClosing: true,
                onClick: async () => {
                  const newVault = await just(vault).distrust(keyid);
                  await dispatch(accountActions.account.receiveVault(newVault));
                  await dispatch(syncWAL(newVault, cached, true));
                },
              },
            ],
          }),
        );
      }
    }),
  );
};

export const updateWAL = (action: ObjectSubsetType<Account>, vault: Vault) =>
  uploader(async (dispatch: DispatchType) => {
    const timestamp = new Date().toISOString();
    const cipher = await vault.box(action);
    const client = await getClient();
    const fingerprints = await vault.trustedFingerprints();
    await Promise.all(
      fingerprints.map(async (fingerprint) => {
        console.debug("updating wal:", fingerprint);
        await client.apis.wal.patch_wal__fingerprint_({
          fingerprint,
          action: { action: cipher, timestamp, version: Account.modelVersion },
        });
        console.debug("done updating wal:", fingerprint);
      }),
    );
    dispatch(syncWAL(vault));
  });

export const syncAccountFor = (
  accountWAL: ?WAL<Account>,
  forPublicKey: Key,
  vault: Vault,
) =>
  uploader(async (dispatch: DispatchType, getState: GetStateType) => {
    accountWAL = accountWAL == null ? getState().account.wal : accountWAL;
    if (accountWAL == null) {
      return;
    }
    const fingerprint = await forPublicKey.fingerprint();
    console.debug("does key exist", fingerprint);
    await _fetchKey(fingerprint);

    console.debug("trusting key", fingerprint);
    const newVault = await vault.trust(forPublicKey);
    dispatch(accountActions.account.receiveVault(newVault));
    dispatch(fetchDeviceList(newVault));

    if ((await newVault.isTrusted(forPublicKey)) !== true) {
      throw new Error(
        `PGPError: public key is not trusted! ${forPublicKey.key}`,
      );
    }
    const account = await accountWAL.current(Account, ...Account.mergers);
    await dispatch(
      updateWAL(
        { keyRing: await account.keyRing.trust(forPublicKey) },
        newVault,
      ),
    );
    const client = await getClient();
    console.debug("sync account for", fingerprint, account);
    const cipher = await newVault.box(account);
    await client.apis.wal.patch_wal__fingerprint_({
      fingerprint,
      action: {
        action: cipher,
        timestamp: new Date(),
        version: Account.modelVersion,
      },
    });
    const quota = getState().files.quota;
    console.debug("quota: ", quota);
    if (quota != null) {
      await dispatch(
        appActions.app.dialog.show({
          title: "Action Required",
          message:
            "Do you want to reencrypt the files to give the paired key access to the files?",
          actions: [
            {
              label: "Reencrypt Files",
              primary: true,
              isClosing: true,
              onClick: async () => {
                await dispatch(
                  rekeyQuota(quota, account.files.filterQuota(quota.id), vault),
                );
              },
            },
            {
              label: "Don't Reencrypt",
              secondary: true,
              isClosing: true,
            },
          ],
        }),
      );
    }
    console.debug("done sync account", fingerprint, account);
  });

export const distrustKey = (
  key: Key | KeyPair | Vault | string,
  account: Account,
  vault: Vault,
) => async (dispatch: DispatchType, getState: GetStateType) => {
  const newVault = await vault.distrust(key);
  await dispatch(accountActions.account.receiveVault(newVault));
  await dispatch(
    updateWAL(
      { keyRing: await account.keyRing.distrust(await Vault.toKeyLike(key)) },
      newVault,
    ),
  );
  await dispatch(syncWAL(newVault, false, true));
  await dispatch(fetchDeviceList(newVault));
  const quota = getState().files.quota;
  if (quota != null) {
    await dispatch(
      appActions.app.dialog.show({
        title: "Action Required",
        message:
          "The removed device can still access encrypted files on the server, do you want to reencrypt the files?",
        actions: [
          {
            label: "Reencrypt Files",
            primary: true,
            isClosing: true,
            onClick: async () => {
              await dispatch(
                rekeyQuota(quota, account.files.filterQuota(quota.id), vault),
              );
            },
          },
          {
            label: "I Understand the Risk",
            secondary: true,
            isClosing: true,
          },
        ],
      }),
    );
  }
};
