// @flow
import { createActions } from "redux-actions";
import type { DispatchType, GetStateType } from "./types";
import {
  File,
  FileMeta,
  getClient,
  Vault,
  Quota,
  QuotaList,
  FileMetaList,
} from "../api";
import type { ActionCreatorType, PureActionCreatorType } from "./types";
import { ident } from "react-redux-flow-tools";
import { updateWAL } from "./account";

export type UploadStateType = "ready" | "encrypting" | "uploading" | "error";
export type DownloadStateType =
  | "ready"
  | "downloading"
  | "decrypting"
  | "error";

type FileActionTypes = {|
  +file: {|
    +receiveFile: ActionCreatorType<File>,
    +removeFile: ActionCreatorType<$PropertyType<File, "id">>,
    +receiveQuota: ActionCreatorType<Quota>,
    +removeQuota: PureActionCreatorType,
    +receiveUploadState: ActionCreatorType<UploadStateType>,
    +receiveDownloadState: ActionCreatorType<DownloadStateType>,
  |},
|};

export const fileActions: FileActionTypes = createActions({
  file: {
    receiveFile: ident,
    removeFile: ident,
    receiveQuota: ident,
    receiveUploadState: ident,
    receiveDownloadState: ident,
    removeQuota: ident,
  },
});

export const createQuota = (vault: Vault) => async (dispatch: DispatchType) => {
  const client = await getClient();
  const { body: quotaBody } = await client.apis.files.post_files_({
    fingerprint: { fingerprint: await vault.keyPair.publicKey.fingerprint() },
  });
  const quota = new Quota(quotaBody);
  dispatch(fileActions.file.receiveQuota(quota));
  dispatch(updateWAL({ quotas: QuotaList.singleton(quota) }, vault));
};

export const deleteQuota = (
  quota: Quota,
  files: FileMetaList,
  vault: Vault,
) => async (dispatch: DispatchType) => {
  const client = await getClient();
  const signedQuotaId = await vault.sign(quota.id);
  await client.apis.files.delete_files__quota_id__({
    // eslint-disable-next-line camelcase
    quota_id: quota.id,
    signature: {
      data: signedQuotaId,
    },
  });
  dispatch(fileActions.file.removeQuota());
  dispatch(
    updateWAL(
      {
        quotas: QuotaList.singleton(Quota.delete(quota)),
        files: files.filterQuota(quota.id).map(FileMeta.delete),
      },
      vault,
    ),
  );
};

export const fetchQuota = (
  id: $PropertyType<Quota, "id">,
  vault: Vault,
) => async (dispatch: DispatchType, getState: GetStateType) => {
  const account = getState().account.account;
  const files = account != null ? account.files : FileMetaList.empty;
  const client = await getClient();
  try {
    const { body: quotaBody } = await client.apis.files.get_files__quota_id__({
      // eslint-disable-next-line camelcase
      quota_id: id,
    });
    const quota = new Quota(quotaBody);
    dispatch(fileActions.file.receiveQuota(quota));
    const deletedFiles = files
      .removeDeleted()
      .values()
      .reduce((acc, fileMeta) => {
        const { id, quotaId } = fileMeta;
        if (quotaId === quota.id && !quota.files.includes(id)) {
          acc.push(fileMeta);
        }
        return acc;
      }, ([]: FileMeta[]));
    if (deletedFiles.length > 0) {
      dispatch(
        updateWAL(
          {
            files: new FileMetaList({
              files: deletedFiles.map(FileMeta.delete),
            }),
          },
          vault,
        ),
      );
    }
  } catch (err) {
    if (err.status === 404) {
      dispatch(fileActions.file.removeQuota());
      dispatch(
        updateWAL(
          {
            quotas: QuotaList.singleton(Quota.deletedFromId(id)),
            files: files.filterQuota(id).map(FileMeta.delete),
          },
          vault,
        ),
      );
    } else {
      console.error("Error fetching quota", JSON.stringify(err));
    }
  }
};

export const downloadFile = (
  file: FileMeta | $PropertyType<FileMeta, "id">,
  quota: Quota,
  vault: Vault,
  recoverMeta?: boolean = false,
) => async (dispatch: DispatchType) => {
  const fileId = file instanceof FileMeta ? file.id : file;
  try {
    dispatch(fileActions.file.receiveDownloadState("downloading"));
    const client = await getClient();
    const {
      body: signedFile,
    } = await client.apis.files.get_files__quota_id___file_id_({
      // eslint-disable-next-line camelcase
      file_id: fileId,
      // eslint-disable-next-line camelcase
      quota_id: quota.id,
    });
    if (signedFile.id !== fileId) {
      throw new Error(
        `ValueError: id mismatch for data returned from API ${
          signedFile.id
        } (expected: ${fileId}).`,
      );
    }
    const apiFileId = signedFile.id;
    dispatch(fileActions.file.receiveDownloadState("decrypting"));
    const encryptedData = await vault.verify(signedFile.data);
    const plainData = await vault.unbox(encryptedData);
    const decryptedFile = new File({ id: apiFileId, data: plainData });
    await dispatch(fileActions.file.receiveFile(decryptedFile));
    const fileMeta =
      typeof file === "string"
        ? FileMeta.fromFile(fileId, quota.id, decryptedFile)
        : FileMeta.updateWithFile(file, decryptedFile);
    if (recoverMeta) {
      await dispatch(
        updateWAL({ files: FileMetaList.singleton(fileMeta) }, vault),
      );
    }
    dispatch(fileActions.file.receiveDownloadState("ready"));
    return { file: decryptedFile, fileMeta };
  } catch (err) {
    dispatch(fileActions.file.receiveDownloadState("error"));
    if (err.status === 404) {
      console.warn(
        `Tried to download nonexisitng file ${fileId}, updating WAL to match.`,
      );
      return dispatch(
        updateWAL(
          {
            files: FileMetaList.singleton(
              FileMeta.deletedFromId(fileId, quota.id),
            ),
          },
          vault,
        ),
      );
    }
    console.log(err);
    throw err;
  }
};

export const createFile = (
  data: $PropertyType<File, "data">,
  name: $PropertyType<FileMeta, "name">,
  quota: Quota,
  vault: Vault,
) => async (dispatch: DispatchType) => {
  try {
    dispatch(fileActions.file.receiveUploadState("encrypting"));
    const encryptedData = await vault.box(data);
    const quotaSignedEncryptedData = await vault.sign(encryptedData);
    const client = await getClient();
    dispatch(fileActions.file.receiveUploadState("uploading"));
    const { body: fileBody } = await client.apis.files.post_files__quota_id__({
      // eslint-disable-next-line camelcase
      quota_id: quota.id,
      data: {
        data: quotaSignedEncryptedData,
      },
    });

    const file = new File(fileBody);
    const fileMeta = FileMeta.fromFile(name, quota.id, file, File.type(data));
    dispatch(updateWAL({ files: FileMetaList.singleton(fileMeta) }, vault));

    dispatch(fetchQuota(quota.id, vault));
    dispatch(fileActions.file.receiveUploadState("ready"));
  } catch {
    dispatch(fileActions.file.receiveUploadState("error"));
  }
};

export const deleteFile = (
  file: FileMeta,
  quota: Quota,
  vault: Vault,
) => async (dispatch: DispatchType) => {
  const signature = await vault.sign(file.id);
  const client = await getClient();
  await client.apis.files.delete_files__quota_id___file_id_({
    // eslint-disable-next-line camelcase
    file_id: file.id,
    // eslint-disable-next-line camelcase
    quota_id: quota.id,
    signature: {
      data: signature,
    },
  });
  dispatch(
    updateWAL({ files: FileMetaList.singleton(FileMeta.delete(file)) }, vault),
  );
  dispatch(fetchQuota(quota.id, vault));
};

export const updateFile = (
  fileMeta: FileMeta,
  data: string,
  quota: Quota,
  vault: Vault,
) => async (dispatch: DispatchType) => {
  try {
    dispatch(fileActions.file.receiveUploadState("encrypting"));
    console.log("encrypting file ...", fileMeta);
    const encryptedData = await vault.box(data);
    const quotaSignedEncryptedData = await vault.sign(encryptedData);
    const client = await getClient();
    dispatch(fileActions.file.receiveUploadState("uploading"));
    console.log("uploading file ...", fileMeta);
    const {
      body: fileBody,
    } = await client.apis.files.put_files__quota_id___file_id_({
      // eslint-disable-next-line camelcase
      quota_id: quota.id,
      // eslint-disable-next-line camelcase
      file_id: fileMeta.id,
      data: {
        data: quotaSignedEncryptedData,
      },
    });

    console.log("done uploading file: ", fileMeta);
    const file = new File(fileBody);
    const updatedFileMeta = FileMeta.updateWithFile(
      fileMeta,
      file,
      File.type(data),
    );
    dispatch(
      updateWAL({ files: FileMetaList.singleton(updatedFileMeta) }, vault),
    );

    dispatch(fetchQuota(quota.id, vault));
    dispatch(fileActions.file.receiveUploadState("ready"));
  } catch {
    dispatch(fileActions.file.receiveUploadState("error"));
  }
};

export const rekeyQuota = (
  quota: Quota,
  files: FileMetaList,
  vault: Vault,
) => async (dispatch: DispatchType) => {
  await Promise.all(
    quota.files.map(async (fileId) => {
      const fileMeta = files.find(({ id }) => id === fileId);
      console.log("downloading ...", fileMeta);
      // $FlowFixMe dunno if worky yet
      const downloaded = await dispatch(downloadFile(fileId, quota, vault));
      if (downloaded != null) {
        const { file, fileMeta: recoveredFileMeta } = downloaded;
        console.log("rekeying ...", fileMeta, recoveredFileMeta);
        try {
          return await dispatch(
            updateFile(
              fileMeta == null ? recoveredFileMeta : fileMeta,
              file.data,
              quota,
              vault,
            ),
          );
        } finally {
          console.log("done rekeying: ", fileId);
        }
      } else {
        console.log("donwload failed for: ", fileId);
        return Promise.resolve();
      }
    }),
  );
};
