// @flow
import * as React from "react";
import type { DownloadPropsType } from "./DownloadBase";

export class Download extends React.PureComponent<DownloadPropsType> {
  _onDragStart = (event: SyntheticEvent<HTMLElement>) => {
    // $FlowFixMe dataTransfer should be there
    event.dataTransfer.setData("text", this.props.data);
  };
  render() {
    const { data, mimeType, fileName, style, base64, children } = this.props;
    return (
      <a
        href={`data:${mimeType};charset=utf-8${
          base64 === true ? ";base64" : ""
        },${encodeURIComponent(data)}`}
        download={fileName}
        draggable={true}
        // todo not well supported yet
        // onDragStart={this._onDragStart}
        style={style}
      >
        {children}
      </a>
    );
  }
}
