// @flow
import { handleActions } from "redux-actions";
import { File, Quota } from "../api/models";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import { fileActions } from "../actions/file";
import type { DownloadStateType, UploadStateType } from "../actions/file";
import type { Reducers } from "./types";

export type FilesStateType = {|
  +quota: ?Quota,
  +files: {
    +[$PropertyType<File, "id">]: File,
  },
  +uploadState: UploadStateType,
  +downloadState: DownloadStateType,
|};

export const filesDefaults: FilesStateType = deepFreeze({
  quota: null,
  files: {},
  uploadState: "ready",
  downloadState: "ready",
});

const actionHandlers = {
  ...actionHandler(fileActions.file.receiveFile, (state, action) => ({
    ...state,
    files: {
      ...state.files,
      [action.payload.id]: action.payload,
    },
  })),
  ...actionHandler(fileActions.file.removeFile, (state, action) => {
    const files = { ...state.files };
    delete files[action.payload];
    return {
      ...state,
      files,
    };
  }),
  ...actionHandler(fileActions.file.receiveQuota, (state, action) => ({
    ...state,
    quota: action.payload,
  })),
  ...actionHandler(fileActions.file.receiveUploadState, (state, action) => ({
    ...state,
    uploadState: action.payload,
  })),
  ...actionHandler(fileActions.file.receiveDownloadState, (state, action) => ({
    ...state,
    downloadState: action.payload,
  })),
  ...actionHandler(fileActions.file.removeQuota, (state) => ({
    ...state,
    quota: undefined,
  })),
};

export const files: Reducers<FilesStateType> = handleActions(
  actionHandlers,
  filesDefaults,
);
