// @flow
import React from "react";
import { StyleSheet, View } from "react-native";
import { composeStyles, rem } from "../../../theme/theme";
import { Heading } from "../../../layout/Heading";
import { webSectionStyle } from "../../../layout";
import { flexCenter } from "../../../theme/common";
import { TokenList } from "../../TokenList";
import type { CurrencySymbolType } from "../../../../api/models";
import type { CurrenciesStateType } from "../../../../reducers/currencies";
import { currenciesActions } from "../../../../actions/currencies";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    flex: 1,
  }),
  section: composeStyles(webSectionStyle, {
    position: "relative",
    flex: 1,
    justifyContent: "flex-start",
  }),
  divider: {
    marginTop: rem(1.5),
  },
  listContainer: {
    flex: 1,
    width: "100%",
  },
});

type TokenManagementPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +usedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onAddCurrency: typeof currenciesActions.currencies.addCurrency,
  +onRemoveCurrency: typeof currenciesActions.currencies.removeCurrency,
|};

export class TokenManagement extends React.PureComponent<TokenManagementPropsType> {
  render() {
    const {
      availableCurrencies,
      usedCurrencies,
      walletCurrencies,
      onAddCurrency,
      onRemoveCurrency,
    } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <Heading>Manage Tokens</Heading>
          <View style={styles.listContainer}>
            <TokenList
              onAddCurrency={onAddCurrency}
              onRemoveCurrency={onRemoveCurrency}
              availableCurrencies={availableCurrencies}
              usedCurrencies={usedCurrencies}
              walletCurrencies={walletCurrencies}
            />
          </View>
        </View>
      </View>
    );
  }
}
