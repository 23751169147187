// @flow

// cyclic dep, inject in start up
import { Vault, WALEntry } from "./api/models";
import { parse, stringify } from "./storage/json";

export const inject = () => {
  Vault.configStringify(stringify);
  Vault.configParse(parse);
  WALEntry.configParse(parse);
};
