// @flow
import { createActions } from "redux-actions";
import type { DispatchType } from "./types";
import { getClient } from "../api";
import { Visit } from "../api/models/visit";

type AnalyticsActionTypes = {|
  +analytics: {||},
|};

export const analyticsActions: AnalyticsActionTypes = createActions({
  analytics: {},
});

export const sendAnalytics = (visit: Visit) => async (
  dispatch: DispatchType,
) => {
  const client = await getClient();
  await client.apis.analytics.post_visits_({
    visit: {
      tag: visit.tag,
      type: visit.type,
      hash: visit.hash,
      timestamp: visit.timestamp,
    },
  });
};
