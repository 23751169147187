// @flow
import React from "react";
import { pure } from "recompose";
import { StyleSheet, Text, View } from "react-native";
import { Button } from "../../layout/Button";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import { border, flexCenter, flexCenterFlow } from "../../theme/common";
import { DividerVertical } from "../../layout/Dividers";
import { Sparkline } from "./Sparkline";
import type { CurrencySymbolType } from "../../../api/models";
import { Currency } from "../../../api/models";
import { TimeSeries } from "../../../models/TimeSeries";
import { sensibleNumber } from "../../layout/SensibleNumber";
import type { RouterHistory } from "../../router";
import { accountTo, to } from "../../router";
import { iconStyle } from "../../header/common";
import {
  // currencyIcons,
  HiddenIcon,
  PinpointedIcon,
  VisibleIcon,
  WalletIcon,
} from "../../img/Icon";
import { currenciesActions } from "../../../actions/currencies";
import { LayoutAware } from "../../layout/LayoutAware";
import { hasNotch } from "../../layout/Device";
import { currencyIcon } from "../../img/currencies";

export const walletHeight = rem(8);
const styles = StyleSheet.create({
  wallet: composeStyles(flexCenterFlow("row"), {
    height: walletHeight,
    width: "100%",
  }),
  walletInfo: {
    flex: 1,
    height: "100%",
  },
  currencySymbol: composeStyles(flexCenter, {
    width: walletHeight,
    height: "100%",
    position: "relative",
  }),
  currencySymbolText: composeStyles({
    color: "white",
    fontWeight: "900",
    fontSize: walletHeight / 3,
  }),
  emptyDetailsText: {
    fontWeight: "400",
    fontSize: walletHeight / 3,
    textAlign: "center",
  },
  details: composeStyles(flexCenterFlow("column"), {
    height: "100%",
  }),
  detailsFinancials: composeStyles(flexCenterFlow("row"), {
    flex: 1,
    width: "100%",
    paddingTop: rem(0.5),
    paddingLeft: rem(0.7),
  }),
  detailsFinancialsText: {
    paddingLeft: rem(1),
    paddingRight: rem(1),
    fontSize: rem(1.25),
  },
  detailsSparkline: {
    flex: 2,
    width: "100%",
  },
  visibilityIcon: composeStyles(iconStyle(32), {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: 1,
    paddingRight: 6,
    paddingLeft: 14,
    paddingBottom: 16,
  }),
  pinpointIcon: composeStyles(iconStyle(32), {
    position: "absolute",
    top: 0,
    left: 0,
    paddingTop: 1,
    paddingRight: 14,
    paddingLeft: 6,
    paddingBottom: 16,
  }),
  currencyIcon: composeStyles(iconStyle(64), {
    borderRadius: 32,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 1,
  }),
});

const detailsColors = {
  backgroundColor: "headerBackground",
};

const detailsFinancialsTextColors = {
  color: "textPrimary",
};

const defaultSymbol = "+";

type CurrencySymbolPropsType = {
  +symbol?: CurrencySymbolType,
  +color?: string,
  +hovered: boolean,
  +hidden: boolean,
  +selected?: boolean,
  +wallet?: boolean,
  +onToggleHide: () => mixed,
};

class CurrencySymbol extends LayoutAware<CurrencySymbolPropsType> {
  static defaultProps = {
    hovered: false,
    visible: true,
    pinpoint: false,
    onHideCurrency: () => {},
  };

  render() {
    const {
      symbol,
      color,
      hovered,
      hidden,
      selected,
      onToggleHide,
    } = this.props;
    // const Icon = symbol == null ? null : currencyIcons[symbol];
    const Icon = symbol == null ? null : currencyIcon(symbol);
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              styles.currencySymbol,
              {
                backgroundColor: "backgroundSecondary",
              },
              border(1, "solid", colors.separator, "Right"),
              this.getOrientation() === "LANDSCAPE-LEFT" && hasNotch()
                ? {
                    paddingLeft: rem(3),
                    width: walletHeight + rem(3),
                  }
                : {},
            )}
          >
            {Icon != null && (
              <Icon
                style={themeComposer(styles.currencyIcon, {
                  backgroundColor:
                    symbol === defaultSymbol ? "juno" : "background",
                  shadowColor: "textPrimary",
                })}
                fill={
                  symbol === defaultSymbol
                    ? colors.background
                    : color != null
                    ? color
                    : colors.buttonDefault
                }
              />
            )}
            {Icon != null && symbol !== defaultSymbol && (hovered || hidden) && (
              <Button style={styles.visibilityIcon} onPress={onToggleHide}>
                {!hidden ? (
                  <VisibleIcon fill={colors.juno} />
                ) : (
                  <HiddenIcon fill={colors.juno} />
                )}
              </Button>
            )}
            {selected === true && (
              <View style={styles.pinpointIcon}>
                <PinpointedIcon fill={colors.juno} />
              </View>
            )}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const EmptyDetails = pure(() => (
  <ThemeContext.Consumer>
    {({ themeComposer }) => (
      <View style={themeComposer(styles.details, detailsColors)}>
        <Text
          style={themeComposer(styles.emptyDetailsText, {
            color: "textSecondary",
          })}
        >
          Add Token
        </Text>
      </View>
    )}
  </ThemeContext.Consumer>
));

type JustDetailsPropsType = {|
  +wallet?: boolean,
  +currency: Currency,
  +timeSeries?: TimeSeries,
|};

const emptyTimeSeries = new TimeSeries();

const JustDetails = pure(
  ({
    wallet,
    timeSeries = emptyTimeSeries,
    currency: { symbol, label },
  }: JustDetailsPropsType) => {
    const usdPrice = timeSeries.current()[symbol] || 0;
    const changePercent = usdPrice / timeSeries.head()[symbol];
    const upDown = changePercent > 1 ? "▲" : changePercent === 1 ? "=" : "▼";
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <View style={themeComposer(styles.details, detailsColors)}>
            <View style={styles.detailsFinancials}>
              {wallet === true && (
                <WalletIcon
                  style={composeStyles(iconStyle(rem(1.3)), {
                    marginRight: rem(0.25),
                    marginLeft: -rem(0.3),
                  })}
                />
              )}
              <Text
                style={themeComposer(
                  styles.detailsFinancialsText,
                  detailsFinancialsTextColors,
                  {
                    width: 80 - (wallet === true ? rem(1.3) : 0),
                    paddingLeft: 0,
                  },
                )}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {label}
              </Text>
              <DividerVertical />
              <Text
                style={themeComposer(
                  styles.detailsFinancialsText,
                  detailsFinancialsTextColors,
                  {
                    flex: 1,
                    textAlign: "center",
                  },
                )}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                ${sensibleNumber(usdPrice)}
              </Text>
              <DividerVertical />
              <Text
                style={themeComposer(
                  styles.detailsFinancialsText,
                  detailsFinancialsTextColors,
                  {
                    width: 70,
                    textAlign: "right",
                  },
                )}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {Math.abs((1 - (changePercent || 1)) * 100).toFixed(1)}%{" "}
                {upDown}
              </Text>
            </View>
            <View style={styles.detailsSparkline}>
              {timeSeries !== emptyTimeSeries && (
                <Sparkline symbol={symbol} timeSeries={timeSeries} />
              )}
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  },
);

type WalletInfoPropsType = {
  +timeSeries?: TimeSeries,
  +currency?: Currency,

  +selected?: boolean,
  +wallet?: boolean,
  +hidden: boolean,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
  +onHideCurrency: typeof currenciesActions.currencies.hide,
  +onHideSidebar: () => mixed,
  +history: RouterHistory,
  +color?: string,
  +blank: boolean,
};

type WalletInfoComponentStateType = {|
  hovered: boolean,
|};

export class WalletInfo extends LayoutAware<
  WalletInfoPropsType,
  WalletInfoComponentStateType,
> {
  state = {
    hovered: false,
  };

  static defaultProps = {
    hidden: false,
    blank: false,
  };

  _onMouseEnter = () => this.setState({ hovered: true });
  _onMouseLeave = () => this.setState({ hovered: false });
  _onSelect = (flag?: boolean) => {
    const { currency, selected, onSelectCurrency } = this.props;
    if (currency == null) {
      return;
    }
    onSelectCurrency({
      symbol: currency.symbol,
      flag: flag != null ? flag : selected == null || selected === false,
    });
  };

  _onHide = () => {
    const {
      currency,
      hidden,
      selected,
      onHideCurrency,
      onSelectCurrency,
    } = this.props;
    if (currency == null) {
      return;
    }
    if (selected === true && !hidden) {
      onSelectCurrency({
        symbol: currency.symbol,
        flag: false,
      });
    }
    onHideCurrency({
      symbol: currency.symbol,
      flag: !hidden,
    });
  };

  render() {
    const {
      currency,
      timeSeries,
      color,
      selected,
      hidden,
      wallet,
      // onSelectCurrency,
      onHideSidebar,
      history,
      blank,
    } = this.props;
    const { hovered } = this.state;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <Button
            feedback={false}
            onMouseEnter={this._onMouseEnter}
            onMouseLeave={this._onMouseLeave}
            onLongPress={this._onHide}
            onPress={() => {
              if (currency != null) {
                if (!hidden) {
                  this._onSelect();
                } else {
                  this._onSelect(false);
                }
              } else {
                onHideSidebar();
                history.push(
                  accountTo(
                    "tokens",
                    accountTo("tokens") !== history.location.pathname
                      ? history.location.pathname
                      : to("overview"),
                  ),
                );
              }
            }}
          >
            <View
              style={themeComposer(
                styles.wallet,
                {},
                border(1, "solid", colors.separator, "Bottom"),
                {
                  opacity: !hidden ? 1 : 0.7,
                },
              )}
            >
              <CurrencySymbol
                symbol={
                  currency != null
                    ? currency.symbol
                    : blank
                    ? undefined
                    : defaultSymbol
                }
                color={color}
                hovered={hovered}
                hidden={hidden}
                selected={selected === true}
                onToggleHide={this._onHide}
              />
              <View
                style={themeComposer(
                  styles.walletInfo,
                  {},
                  this.getOrientation() === "LANDSCAPE-RIGHT"
                    ? {
                        marginRight: rem(3),
                        borderRightWidth: 1,
                        borderStyle: "solid",
                        borderColor: colors.separator,
                      }
                    : {},
                )}
              >
                {blank ? null : currency != null ? (
                  <JustDetails
                    wallet={wallet}
                    currency={currency}
                    timeSeries={timeSeries}
                  />
                ) : (
                  <EmptyDetails />
                )}
              </View>
            </View>
          </Button>
        )}
      </ThemeContext.Consumer>
    );
  }
}
