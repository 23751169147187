// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers/index";

import type { DispatchType } from "../../actions/types";
import { WALList } from "./WALList";
import { accountActions } from "../../actions/account";

const mapStateToProps = (state: StateType) => ({
  seen: state.account.seen,
  accountWAL: state.account.wal,
  isSyncing: state.account != null && state.account.syncers > 0,
  isUploading: state.account != null && state.account.uploaders > 0,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onSeen: (seen) => dispatch(accountActions.account.receiveSeen(seen)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WALList);
