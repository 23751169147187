// @flow
import React from "react";
import { ScrollView, StyleSheet, Text, View, Platform } from "react-native";
import { walletHeight, WalletInfo } from "./WalletInfo";
import { TimeSeries } from "../../../models/TimeSeries";
import type { CurrencySymbolType } from "../../../api/models";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import type { RouterHistory } from "../../router";
import { accountTo, to } from "../../router";
import { currenciesActions } from "../../../actions/currencies";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import type { LayoutEventType } from "../../../models";
import { border, flexCenter } from "../../theme/common";
import {
  EditIcon,
  HiddenIcon,
  NotPinpointedIcon,
  PinpointedIcon,
  VisibleIcon,
} from "../../img/Icon";
import { Button } from "../../layout/Button";
import { iconStyle } from "../../header/common";
import { DividerVertical } from "../../layout/Dividers";
import { isIphoneXorAbove } from "../../layout/Device";

const controlsButton = composeStyles(flexCenter, {
  flexDirection: "row",
  flex: 1,
  paddingLeft: rem(1),
  paddingRight: rem(1),
  paddingBottom: isIphoneXorAbove() ? rem(1.25) : 0,
});
const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    flexDirection: "column",
    height: "100%",
    width: "100%",
  }),
  controlsContainer: composeStyles(flexCenter, {
    flexDirection: "row",
    height: isIphoneXorAbove() ? rem(5.5) : rem(4),
    width: "100%",
  }),
  sidebar: {
    // flex: 1,
    // height: "100%",
    width: "100%",
  },
  controlsButton,
  controlsButtonLeft: composeStyles(controlsButton, {
    justifyContent: "flex-end",
  }),
  controlsButtonRight: composeStyles(controlsButton, {
    justifyContent: "flex-start",
  }),
  controlsButtonIcon: composeStyles(iconStyle(rem(1.5)), {
    marginRight: rem(0.5),
  }),
  controlsButtonText: {
    fontSize: rem(1.25),
  },
});

type SidebarPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +currencies: $ReadOnlyArray<CurrencySymbolType>,
  +timeSeries24: TimeSeries,
  +hiddenCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
  +onHideCurrency: typeof currenciesActions.currencies.hide,
  +onHideSidebar: () => mixed,
  +history: RouterHistory,
|};

type SidebarComponentStateType = {|
  height: number,
|};

export class Sidebar extends React.PureComponent<
  SidebarPropsType,
  SidebarComponentStateType,
> {
  state = {
    height: 0,
  };

  _onLayout = (layoutEvent: LayoutEventType) =>
    this.setState({ height: layoutEvent.nativeEvent.layout.height });

  _onManage = () => {
    const { history, onHideSidebar } = this.props;
    onHideSidebar();
    history.push(
      accountTo(
        "tokens",
        accountTo("tokens") !== history.location.pathname
          ? history.location.pathname
          : to("overview"),
      ),
    );
  };

  // todo stupid performance
  _filteredCurrencies = (): $ReadOnlyArray<CurrencySymbolType> => {
    return this.props.currencies.filter(
      (symbol) => this.props.availableCurrencies[symbol] != null,
    );
  };

  _selectAll = () =>
    this.props.selectedCurrencies.length <
    this._filteredCurrencies().length >> 1;

  _hideAll = () =>
    this.props.hiddenCurrencies.length < this._filteredCurrencies().length >> 1;

  _onHide = () => {
    const { onHideCurrency } = this.props;
    if (this._hideAll()) {
      this._filteredCurrencies().forEach((symbol) =>
        onHideCurrency({ symbol, flag: true }),
      );
    } else {
      this._filteredCurrencies().forEach((symbol) =>
        onHideCurrency({ symbol, flag: false }),
      );
    }
  };

  _onSelect = () => {
    const { onSelectCurrency } = this.props;
    if (this._selectAll()) {
      this._filteredCurrencies().forEach((symbol) =>
        onSelectCurrency({ symbol, flag: true }),
      );
    } else {
      this._filteredCurrencies().forEach((symbol) =>
        onSelectCurrency({ symbol, flag: false }),
      );
    }
  };

  render() {
    const {
      currencies,
      availableCurrencies,
      walletCurrencies,
      timeSeries24,
      selectedCurrencies,
      hiddenCurrencies,
      onSelectCurrency,
      onHideCurrency,
      onHideSidebar,
      history,
    } = this.props;
    const { height } = this.state;
    const filteredCurrencies = this._filteredCurrencies();
    const walletsHeight = height - filteredCurrencies.length * walletHeight;
    const nothingToAdd =
      currencies.length >= Object.keys(availableCurrencies).length;
    const numFillerAdds = Math.max(1, Math.ceil(walletsHeight / walletHeight));
    const SelectControlIcon = this._selectAll()
      ? NotPinpointedIcon
      : PinpointedIcon;
    const HideControlIcon = this._hideAll() ? VisibleIcon : HiddenIcon;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors, currencyColors }) => (
          <View style={styles.container}>
            <ScrollView
              onLayout={this._onLayout}
              style={themeComposer(
                styles.sidebar,
                {},
                Platform.OS === "web"
                  ? { overflowY: numFillerAdds > 1 ? "hidden" : "auto" }
                  : {},
              )}
              contentContainerStyle={styles.sidebar}
              scrollEnabled={
                Platform.OS !== "web" ? numFillerAdds <= 1 : undefined
              }
              horizontal={false}
            >
              {filteredCurrencies.map((symbol, idx) => (
                <WalletInfo
                  currency={availableCurrencies[symbol]}
                  history={history}
                  key={idx}
                  color={currencyColors(symbol)}
                  timeSeries={timeSeries24}
                  hidden={hiddenCurrencies.includes(symbol)}
                  wallet={walletCurrencies.includes(symbol)}
                  selected={
                    selectedCurrencies.length > 0
                      ? selectedCurrencies.includes(symbol)
                      : undefined
                  }
                  onSelectCurrency={onSelectCurrency}
                  onHideCurrency={onHideCurrency}
                  onHideSidebar={onHideSidebar}
                />
              ))}
              {new Array(numFillerAdds).fill(0).map((_, idx) => (
                <WalletInfo
                  history={history}
                  key={`add${idx}`}
                  color={colors.juno}
                  onSelectCurrency={onSelectCurrency}
                  onHideCurrency={onHideCurrency}
                  onHideSidebar={onHideSidebar}
                  blank={nothingToAdd || (numFillerAdds > 1 && idx !== 0)}
                />
              ))}
            </ScrollView>
            <View
              style={themeComposer(
                styles.controlsContainer,
                {
                  backgroundColor: "headerBackground",
                },
                border(1, "solid", colors.separator, "Top"),
              )}
            >
              <Button onPress={this._onSelect} style={styles.controlsButton}>
                <SelectControlIcon style={styles.controlsButtonIcon} />
                <Text
                  style={themeComposer(styles.controlsButtonText, {
                    color: "textPrimary",
                  })}
                >
                  {this._selectAll() ? "Select " : "Deselect "}
                  All
                </Text>
              </Button>
              <DividerVertical height="100%" />
              <Button onPress={this._onHide} style={styles.controlsButton}>
                <HideControlIcon style={styles.controlsButtonIcon} />
                <Text
                  style={themeComposer(styles.controlsButtonText, {
                    color: "textPrimary",
                  })}
                >
                  {this._hideAll() ? "Hide " : "Show "}
                  All
                </Text>
              </Button>
              <DividerVertical height="100%" />
              <Button style={styles.controlsButton} onPress={this._onManage}>
                <EditIcon style={styles.controlsButtonIcon} />
                <Text
                  style={themeComposer(styles.controlsButtonText, {
                    color: "textPrimary",
                  })}
                >
                  Manage
                </Text>
              </Button>
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
