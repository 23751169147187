//@flow
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import type { WalletAddressType } from "./wallets";
import {
  notNull,
  validateDate,
  validateListOf,
  validateNumber,
  validateString,
} from "./validate";
import { List } from "./list";
import type { CurrencySymbolType } from "./currencies";
import { validateCurrencySymbol } from "./currencies";

export class Transaction extends Model<Transaction> {
  +fromCurrency: CurrencySymbolType;
  +fromAddress: ?WalletAddressType;
  +fromAmount: number;
  +toCurrency: CurrencySymbolType;
  +toAddress: ?WalletAddressType;
  +toAmount: number;
  +timestamp: Date;
  +modifiedTimestamp: ?Date;
  +revoked: boolean;

  constructor(json: ObjectSubsetType<Transaction>) {
    super(json);
    this.fromCurrency = validateCurrencySymbol(json.fromCurrency).toUpperCase();
    if (json.fromAddress != null) {
      this.fromAddress = validateString(json.fromAddress);
    }
    this.fromAmount = validateNumber(json.fromAmount);
    this.toCurrency = validateCurrencySymbol(json.toCurrency).toUpperCase();
    if (json.toAddress != null) {
      this.toAddress = validateString(json.toAddress);
    }
    this.toAmount = validateNumber(json.toAmount);
    this.timestamp = validateDate(json.timestamp);
    if (json.modifiedTimestamp != null) {
      this.modifiedTimestamp = validateDate(json.modifiedTimestamp);
    }
    this.revoked = json.revoked === true;
  }

  static +hash = (transaction: Transaction): string => {
    return JSON.stringify([
      transaction.fromCurrency,
      transaction.fromAddress,
      transaction.fromAmount,
      transaction.toCurrency,
      transaction.toAddress,
      transaction.toAmount,
      transaction.timestamp.getTime(),
    ]);
  };
}

export class TransactionList extends List<Transaction, TransactionList> {
  +transactions: $ReadOnlyArray<Transaction>;

  constructor(json: ObjectSubsetType<TransactionList>) {
    super("transactions");
    this.transactions = validateListOf(
      notNull((transaction) => new Transaction(transaction)),
      json.transactions,
    );
  }

  static +empty = new TransactionList({ transactions: [] });

  merge(other: TransactionList): TransactionList {
    const transactions = this.values()
      .concat(other.values())
      .sort((a, b) => {
        if (a.timestamp.getTime() === b.timestamp.getTime()) {
          const modifiedB =
            b.modifiedTimestamp != null ? b.modifiedTimestamp.getTime() : 0;
          const modifiedA =
            a.modifiedTimestamp != null ? a.modifiedTimestamp.getTime() : 0;
          return modifiedB - modifiedA;
        } else {
          return b.timestamp.getTime() - a.timestamp.getTime();
        }
      })
      .reduce((acc, transaction) => {
        if (acc.length === 0) {
          acc.push(transaction);
        } else {
          const last = acc[acc.length - 1];
          if (Transaction.hash(last) !== Transaction.hash(transaction)) {
            acc.push(transaction);
          }
        }
        return acc;
      }, []);
    return new TransactionList({ transactions });
  }

  revoke(revoke: Transaction): TransactionList {
    const revokeHash = Transaction.hash(revoke);
    return this.map((transaction) => {
      if (Transaction.hash(transaction) === revokeHash) {
        return transaction.update({
          revoked: true,
          modifiedTimestamp: new Date(),
        });
      } else {
        return transaction;
      }
    });
  }
}
