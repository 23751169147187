// @flow
import React from "react";
import qr from "qr-image";
import { Image, StyleSheet } from "react-native";
import type { StyleType } from "../theme/theme";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { StretchedSVG } from "../svg/StretchedSVG";
import { Path } from "../svg/svg";
import { imageBorder } from "../theme/common";
import type { LayoutEventType } from "../../models";

const empty = Object.freeze({});

const styles = StyleSheet.create({
  png: composeStyles(imageBorder(3, "transparent"), {
    width: "100%",
    height: "100%",
    borderRadius: rem(1),
    padding: 2,
  }),
});

type QRPropsType = {|
  +data: string,
  +ecLevel: "L" | "M" | "Q" | "H" | "M",
  +type: "png" | "svg",
  +style?: StyleType,
|};

type QRComponentStateType = {|
  fix: number,
|};

export class QR extends React.PureComponent<QRPropsType, QRComponentStateType> {
  static defaultProps = {
    ecLevel: "L",
    type: "svg",
  };
  state = {
    fix: 0,
  };
  // react native caches images that are rendered while the parent view still has no width/height leading to blurry images
  _cacheFix = (layout: LayoutEventType) =>
    this.setState({
      fix: layout.nativeEvent.layout.width + layout.nativeEvent.layout.height,
    });
  render() {
    const { data, ecLevel, type, style = empty } = this.props;

    if (type === "svg") {
      const { size, path } = qr.svgObject(data, {
        type,
        // eslint-disable-next-line camelcase
        ec_level: ecLevel,
      });
      // todo border on svgs
      return (
        <StretchedSVG style={style} viewBox={`0 0 ${size} ${size}`}>
          <Path d={path} />
        </StretchedSVG>
      );
    } else {
      const buffer = qr.imageSync(data, {
        type,
        // eslint-disable-next-line camelcase
        ec_level: ecLevel,
      });
      return (
        <ThemeContext.Consumer>
          {({ themeComposer }) => (
            <Image
              key={this.state.fix}
              onLayout={this._cacheFix}
              style={themeComposer(styles.png, { borderColor: "juno" }, style)}
              source={{
                uri: "data:image/png;base64," + buffer.toString("base64"),
              }}
              alt="public key"
              resizeMode="cover"
            />
          )}
        </ThemeContext.Consumer>
      );
    }
  }
}
