// @flow
import { connect } from "react-redux";
import type { StateType } from "../../../../reducers";
import { DeviceManagementWeb } from "./DeviceManagement";
import type { DispatchType } from "../../../../actions/types";
import {
  clearVault,
  createVault,
  distrustKey,
  uploadVault,
} from "../../../../actions/account";

const mapStateToProps = (state: StateType) => ({
  account: state.account.account,
  devices: state.account.devices,
  vault: state.account.vault,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onCreateVault: (userid) => dispatch(createVault(userid)),
  onClearVault: () => dispatch(clearVault()),
  onUploadVault: (vaultText) => dispatch(uploadVault(vaultText)),
  onDistrustKey: (fingerprint, account, vault) =>
    dispatch(distrustKey(fingerprint, account, vault)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeviceManagementWeb);
