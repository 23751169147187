// @flow
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import type { StyleType } from "../theme/theme";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { border, flexCenterDirection } from "../theme/common";

const styles = StyleSheet.create({
  heading: composeStyles(
    flexCenterDirection("row"),
    border(2, "solid", "transparent", "Bottom"),
    {
      alignItems: "flex-end",
      width: "100%",
      paddingBottom: rem(0.5),
      marginTop: rem(2),
      marginBottom: rem(2),
    },
  ),
  headingText: composeStyles({
    fontSize: rem(2),
    flex: 1,
  }),
});

export class Heading extends React.PureComponent<{|
  +control?: React.Node,
  +children: React.Node,
  +style?: StyleType,
  +containerStyle?: StyleType,
|}> {
  render() {
    const { control, children, style = {}, containerStyle = {} } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <View
            style={themeComposer(
              styles.heading,
              {
                borderBottomColor: "separator",
              },
              containerStyle,
            )}
          >
            <Text
              style={themeComposer(
                styles.headingText,
                { color: "textPrimary" },
                style,
              )}
            >
              {children}
            </Text>
            {control != null && control}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
