// @flow
import type { ModelInterface } from "./models";
import { Model } from "./models";
import { assert } from "react-redux-flow-tools";

export class List<E: ModelInterface<>, T: ModelInterface<>> extends Model<T> {
  +_key: $Keys<T>;

  constructor(key: $Keys<T>) {
    super(key);
    this._key = key;
  }

  values(): $ReadOnlyArray<E> {
    // $FlowFixMe this is indexable... whatever flow says
    const values = this[this._key];
    assert(
      this instanceof List && values instanceof Array,
      `ImplementationError: ${this._key} should point to an array!`,
    );
    return values;
  }

  length(): number {
    return this.values().length;
  }

  head(): ?E {
    return this.values()[0];
  }

  last(): ?E {
    return this.values()[this.length() - 1];
  }

  slice(idx: number): T {
    return this.update({ [this._key]: this.values().slice(idx) });
  }

  map(mapper: (E, number, $ReadOnlyArray<E>) => E): T {
    return this.update({ [this._key]: this.values().map(mapper) });
  }

  forEach(action: (E, number, $ReadOnlyArray<E>) => void): void {
    this.values().forEach(action);
  }

  every(
    action: (E, number, $ReadOnlyArray<E>) => boolean | Promise<boolean>,
  ): boolean | Promise<boolean> {
    return this.values().every(action);
  }

  some(action: (E, number, $ReadOnlyArray<E>) => boolean): boolean {
    return this.values().some(action);
  }

  filter(filter: (E, number, $ReadOnlyArray<E>) => boolean): T {
    return this.update({ [this._key]: this.values().filter(filter) });
  }

  append(element: E): T {
    return this.update({ [this._key]: [...this.values(), element] });
  }

  unshift(element: E): T {
    return this.update({ [this._key]: [element, ...this.values()] });
  }

  concat(other: List<E, T>): T {
    return this.update({ [this._key]: [...this.values(), ...other.values()] });
  }

  includes(element: E): boolean {
    return this.values().includes(element);
  }

  find(finder: (E) => boolean): ?E {
    return this.values().find(finder);
  }
}
