// @flow
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { DeviceListView } from "./DeviceListView";
import { DeviceList } from "../../../../api/models/device";
import { composeStyles } from "../../../theme/theme";
import { flexCenter } from "../../../theme/common";
import { DeviceKey } from "./DeviceKey";
import { Vault } from "../../../../api/models/PGP";
import {
  clearVault,
  createVault,
  distrustKey,
  uploadVault,
} from "../../../../actions/account";
import { webSectionStyle } from "../../../layout";
import { Account } from "../../../../api/models";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
  }),
  keySection: composeStyles(webSectionStyle, {
    flex: 0.4,
    minHeight: 400,
  }),
  listSection: composeStyles(webSectionStyle, {
    flex: 0.6,
  }),
});

type DevicesPropsType = {|
  +devices: DeviceList,
  +vault?: Vault,
  +account?: Account,
  +onClearVault: typeof clearVault,
  +onCreateVault: typeof createVault,
  +onUploadVault: typeof uploadVault,
  +onDistrustKey: typeof distrustKey,
|};

export class DeviceManagementWeb extends React.PureComponent<DevicesPropsType> {
  render() {
    const {
      devices,
      vault,
      account,
      onClearVault,
      onCreateVault,
      onUploadVault,
      onDistrustKey,
    } = this.props;
    return (
      <View style={styles.container}>
        <DeviceKey
          vault={vault}
          onCreateVault={onCreateVault}
          onClearVault={onClearVault}
          onUploadVault={onUploadVault}
          style={styles.keySection}
        />
        {vault != null && account != null && (
          <DeviceListView
            devices={devices}
            style={styles.listSection}
            account={account}
            onDistrustKey={onDistrustKey}
            vault={vault}
          />
        )}
      </View>
    );
  }
}
