// @flow
export const transform = (transformOperations: { +[string]: string }) =>
  Object.keys(transformOperations)
    .map((key) => [key, transformOperations[key]]) //flow
    .map(([op, val]) => `${op}(${val.toString()})`)
    .join(", ");

export const Circle = "circle";
export const ClipPath = "clipPath";
export const Defs = "defs";
export const Ellipse = "ellipse";
export const G = "g";
export const Image = "image";
export const Line = "line";
export const LinearGradient = "linearGradient";
export const Path = "path";
export const Pattern = "pattern";
export const Polygon = "polygon";
export const Polyline = "polyline";
export const RadialGradient = "radialGradient";
export const Rect = "rect";
export const Shape = "shape";
export const Stop = "stop";
export const Svg = "svg";
export const Symbol = "symbol";
export const Text = "text";
export const TextPath = "textPath";
export const TSpan = "tSpan";
export const Use = "use";
export default Svg;

export const circlePath = (
  r: number,
  cx?: number = 0,
  cy?: number = 0,
): string => `
    M ${cx} ${cy}
    m -${r}, 0
    a ${r},${r} 0 1,1 ${r * 2},0
    a ${r},${r} 0 1,1 -${r * 2},0
`;
