// @flow
import { deepFreeze, ident } from "react-redux-flow-tools";

const deepFreezePerformance = (): boolean => {
  if (process.env.NODE_ENV === "development" && deepFreeze === ident) {
    throw new Error(
      "EnvironmentError: development mode enforces object immutability",
    );
  }

  if (process.env.NODE_ENV !== "development" && deepFreeze !== ident) {
    throw new Error(
      "EnvironmentError: a non development mode should not enforce object immutability for speed reasons",
    );
  }
  return true;
};

const functionsHaveNames = (): boolean => {
  const featureSupported = functionsHaveNames.name === "functionsHaveNames";
  if (!featureSupported) {
    throw new Error(
      "EnvironmentError: We need function.names for introspection!",
    );
  }
  return featureSupported;
};

export const testEnvironment = () =>
  deepFreezePerformance() && functionsHaveNames();
