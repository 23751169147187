// @flow
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { validateDate, validateEnum, validateString } from "./validate";

export type VisitTypeType = "visit" | "signup" | "app" | "slack" | "testflight";
//todo better type
const visitTypes: $ReadOnlyArray<VisitTypeType> = [
  "visit",
  "signup",
  "app",
  "slack",
  "testflight",
];

export class Visit extends Model<Visit> {
  +tag: string;
  +hash: string;
  +timestamp: ?Date;
  +type: VisitTypeType;

  constructor(json: ObjectSubsetType<Visit>) {
    super(json);
    this.tag = validateString(json.tag);
    this.hash = validateString(json.hash);
    this.type = validateEnum(visitTypes, json.type);
    if (json.timestamp != null) {
      this.timestamp = validateDate(json.timestamp);
    }
  }

  static visit(
    tag: $PropertyType<Visit, "tag">,
    hash: $PropertyType<Visit, "hash">,
    timestamp?: Date,
  ): Visit {
    return new Visit({ tag, hash, type: "visit", timestamp });
  }
  static signup(
    tag: $PropertyType<Visit, "tag">,
    hash: $PropertyType<Visit, "hash">,
    timestamp?: Date,
  ): Visit {
    return new Visit({ tag, hash, type: "signup", timestamp });
  }
  static app(
    tag: $PropertyType<Visit, "tag">,
    hash: $PropertyType<Visit, "hash">,
    timestamp?: Date,
  ): Visit {
    return new Visit({ tag, hash, type: "app", timestamp });
  }
  static slack(
    tag: $PropertyType<Visit, "tag">,
    hash: $PropertyType<Visit, "hash">,
    timestamp?: Date,
  ): Visit {
    return new Visit({ tag, hash, type: "slack", timestamp });
  }
  static testflight(
    tag: $PropertyType<Visit, "tag">,
    hash: $PropertyType<Visit, "hash">,
    timestamp?: Date,
  ): Visit {
    return new Visit({ tag, hash, type: "testflight", timestamp });
  }
}
