// @flow
import { handleActions } from "redux-actions";
import type { WalletAddressType } from "../api/models";
import { Wallet } from "../api/models";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import { accountActions } from "../actions/account";
import type { Reducers } from "./types";

export type WalletsStateType = {
  +[WalletAddressType]: Wallet,
};

export const walletsDefaults: WalletsStateType = deepFreeze({});

const actionHandlers = {
  ...actionHandler(accountActions.account.receiveAccount, (state, action) => {
    const transactions = action.payload.account.transactions;
    return transactions.values().reduce((acc, transaction) => {
      if (
        transaction.fromAddress != null &&
        !acc.hasOwnProperty(transaction.fromAddress)
      ) {
        acc[transaction.fromAddress] = new Wallet({
          symbol: transaction.fromCurrency,
          address: transaction.fromAddress,
        });
      }
      if (
        transaction.toAddress != null &&
        !acc.hasOwnProperty(transaction.toAddress)
      ) {
        acc[transaction.toAddress] = new Wallet({
          symbol: transaction.toCurrency,
          address: transaction.toAddress,
        });
      }
      return acc;
    }, {});
  }),
};

export const wallets: Reducers<WalletsStateType> = handleActions(
  actionHandlers,
  walletsDefaults,
);
