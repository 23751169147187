// @flow
import React from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import ReactPullToRefresh from "react-pull-to-refresh";
import { composeStyles, rem } from "../../theme/theme";
import { flexCenter } from "../../theme/common";
import { PullToRefreshBase } from "./PullToRefreshBase";
import { iconStyle } from "../../header/common";
import { ThemeContext } from "../../theme/theme";
import "./PullToRefresh.web.less";
import { RefreshIcon } from "../../img/Icon";
import { isTouchDevice } from "../Device";

const containerStyle = composeStyles({
  height: "100%",
  width: "100%",
  position: "relative",
  // border: "1px solid transparent", // I... don't know. smh forces browser to actually go 100% height instead of ~90%
});

const hackTop = 2000;

const refreshIconContainerStyle = composeStyles(flexCenter, {
  marginTop: -24,
  width: "100%",
  position: "absolute",
  alignItems: "flex-end",
  paddingBottom: 6,
  marginBottom: -2,
  height: hackTop,
  bottom: 0,
});

const refreshIconStyle = iconStyle(24);

const activityIndicatorStyle = composeStyles({
  margin: rem(0.75),
  paddingBottom: 10,
  marginBottom: -10,
});

const pullDistance = 50;

type PullToRefreshComponentStateType = {|
  active: boolean,
  percent: number,
|};

export class PullToRefresh extends PullToRefreshBase<PullToRefreshComponentStateType> {
  icon: ?HTMLDivElement;
  checking: boolean = false;
  lastTop: number = 0;

  state = {
    active: false,
    percent: 0,
  };

  _onRefresh = async (resolve: () => void, reject: () => void) => {
    const { onRefresh } = this.props;
    try {
      const result = onRefresh();
      if (result instanceof Promise) {
        await result;
      }
      resolve();
    } catch (err) {
      console.error("error during refresh");
      reject();
    }
  };

  _onNoop = (resolve: () => void) => {
    resolve();
  };

  _monitorReloadIcon = () => {
    if (this.icon != null && !this.checking) {
      this.checking = true;
      window.requestAnimationFrame(() => {
        if (this.icon != null) {
          const { top } = this.icon.getBoundingClientRect();
          if (top !== this.lastTop) {
            this.lastTop = top;
            const { active } = this.state;
            // activates at pull distance + some insurance
            const insurance = 5;
            const percent = (top + hackTop) / (pullDistance + insurance);
            this.setState({ percent });
            if (percent >= 1) {
              !active && this.setState({ active: true });
            } else {
              active && this.setState({ active: false });
            }
          }
        }
        this.checking = false;
      });
    }
  };

  componentDidMount() {
    if (isTouchDevice()) {
      window.addEventListener("touchmove", this._monitorReloadIcon);
    } else {
      //todo performance?
      window.addEventListener("mousemove", this._monitorReloadIcon);
    }
  }

  componentWillUnmount() {
    if (isTouchDevice()) {
      window.removeEventListener("touchmove", this._monitorReloadIcon);
    } else {
      window.removeEventListener("mousemove", this._monitorReloadIcon);
    }
  }

  render() {
    const { children, isRefreshing, showRefreshIndicator = true } = this.props;
    const { active, percent } = this.state;
    return (
      <ThemeContext.Consumer>
        {({ colors }) => (
          <ReactPullToRefresh
            onRefresh={isRefreshing ? this._onNoop : this._onRefresh}
            icon={
              <div
                ref={(r) => (this.icon = r)}
                style={composeStyles(refreshIconContainerStyle, {
                  backgroundColor: colors.headerBackground,
                })}
              >
                {!isRefreshing && (
                  <RefreshIcon
                    style={StyleSheet.compose(
                      refreshIconStyle,
                      {
                        transform: [{ rotate: `${-45 + 90 * percent}deg` }],
                      },
                    )}
                    fill={active ? colors.juno : colors.textPrimary}
                  />
                )}
              </div>
            }
            resistance={4 /*default: 2.5*/}
            distanceToRefresh={pullDistance /*default: 70*/}
            loading={
              showRefreshIndicator && isRefreshing ? (
                <div style={activityIndicatorStyle}>
                  <ActivityIndicator
                    animating={true}
                    size={32}
                    color={colors.juno}
                  />
                </div>
              ) : null
            }
            style={containerStyle}
          >
            {children}
          </ReactPullToRefresh>
        )}
      </ThemeContext.Consumer>
    );
  }
}
