// @flow
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import { root } from "../reducers/index";
import { loadStorage, subscribeObservers } from "./storage";
import type { StoreType } from "./types";
import { refreshCandles } from "../actions/candles";
import { fetchDeviceList, syncWAL } from "../actions/account";
import { fetchCurrencies } from "../actions/currencies";

const composeEnhancers =
  (window != null && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const newStore = async () => {
  const initialState = await loadStorage();
  console.debug("initial state", initialState);
  const store = createStore(
    root,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  );
  return store;
};

let _store: ?Promise<StoreType> = null;
export const getStore = async (): Promise<StoreType> => {
  if (_store == null) {
    console.debug("constructing new store singleton");
    _store = newStore();
  }
  return await _store;
};

subscribeObservers(getStore());

const pollWAL = (store) => {
  setTimeout(() => {
    store.dispatch(syncWAL(undefined, true)).finally(() => pollWAL(store));
  }, 2000);
};

// init data
(async () => {
  const store = await getStore();
  await store.dispatch(fetchCurrencies());
  store.dispatch(refreshCandles());
  const vault = store.getState().account.vault;
  if (vault != null) {
    store.dispatch(syncWAL(vault, false)).finally(() => pollWAL(store));
    store.dispatch(fetchDeviceList(vault));
  }
})();
