// @flow
import { combineReducers } from "redux";
import type { CombinedReducer } from "redux";
import type { AppStateType } from "./app";
import { app, appDefaults } from "./app";
import type { CandlesStateType } from "./candles";
import { candles, candlesDefaults } from "./candles";
import type { TimeSeriesStateType } from "./timeSeries";
import { timeSeries, timeSeriesDefaults } from "./timeSeries";
import type { WalletsStateType } from "./wallets";
import { wallets, walletsDefaults } from "./wallets";
import { account, accountDefaults } from "./account";
import type { AccountStateType } from "./account";
import { currencies, currenciesDefaults } from "./currencies";
import type { CurrenciesStateType } from "./currencies";
import type { TransactionsStateType } from "./transactions";
import { transactions, transactionsDefaults } from "./transactions";
import type { MenuStateType } from "./menu";
import { menu, menuDefaults } from "./menu";
import type { FilesStateType } from "./files";
import { files, filesDefaults } from "./files";
import type { ActionType } from "../actions/types";

type _StateType = {|
  app: AppStateType,
  candles: CandlesStateType,
  timeSeries: TimeSeriesStateType,
  wallets: WalletsStateType,
  account: AccountStateType,
  currencies: CurrenciesStateType,
  transactions: TransactionsStateType,
  menu: MenuStateType,
  files: FilesStateType,
|};

export type StateShapeType = $Shape<_StateType>;
export type StateType = $ReadOnly<_StateType>;

export const root: CombinedReducer<
  StateType,
  ActionType<mixed>,
> = combineReducers({
  app,
  candles,
  timeSeries,
  wallets,
  account,
  currencies,
  transactions,
  menu,
  files,
});

// i can't believe we can't just pass a partial state in...
export const defaults: StateType = {
  app: appDefaults,
  candles: candlesDefaults,
  timeSeries: timeSeriesDefaults,
  wallets: walletsDefaults,
  account: accountDefaults,
  currencies: currenciesDefaults,
  transactions: transactionsDefaults,
  menu: menuDefaults,
  files: filesDefaults,
};
export default root;
