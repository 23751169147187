// @flow
import React from "react";
import { ScrollView, StyleSheet, Text, View, Platform } from "react-native";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import { border, flexCenter, flexCenterDirection } from "../../theme/common";
import { Button } from "../../layout/Button";
import {
  RemoveIcon,
  FileIcon,
  NotDownloadedIcon,
  DownloadedIcon,
  PdfIcon,
  ImageIcon,
} from "../../img/Icon";
import { iconStyle } from "../../header/common";
import { FileMeta, FileMetaList, Quota, QuotaList } from "../../../api/models";
import type { FilesStateType } from "../../../reducers/files";
import { Download } from "../../layout/download";
import { sensibleByte } from "../../layout/SensibleNumber";
import { DividerVertical } from "../../layout/Dividers";

// todo
const nameContainerStyle = composeStyles(flexCenter, {
  flexDirection: "row",
  flex: 1,
  justifyContent: "flex-start",
  overflow: Platform.OS === "web" ? "hidden" : undefined,
});
const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    flex: 1,
    overflow: "hidden",
  }),
  listContainer: {
    flex: 1,
    width: "100%",
  },
  row: composeStyles(flexCenter, {
    width: "100%",
    flexDirection: "row",
    height: rem(4),
  }),
  nameContainer: nameContainerStyle,
  name: {
    fontSize: rem(2),
    paddingLeft: rem(0.75),
    paddingRight: rem(0.75),
  },
  downloadIcon: composeStyles(iconStyle(rem(1.25)), {
    marginRight: rem(0.5),
  }),
  removeButtonLeft: {
    paddingRight: rem(1.5),
    paddingLeft: rem(1),
  },
  removeButtonRight: {
    marginLeft: rem(0.5),
  },
});

type DocumentsListPropsType = {|
  +edit?: boolean,
  +files: FileMetaList,
  +quotas: QuotaList,
  +downloadedFiles: $PropertyType<FilesStateType, "files">,
  +onDownloadFile: (FileMeta, Quota) => void,
  +onDeleteFile: (FileMeta, Quota) => void,
|};

type DocumentsListComponentStateType = {|
  remove?: string,
|};

export class DocumentsList extends React.PureComponent<
  DocumentsListPropsType,
  DocumentsListComponentStateType,
> {
  static defaultProps = {
    edit: false,
  };

  state = {
    remove: undefined,
  };

  render() {
    const {
      edit,
      files,
      quotas,
      downloadedFiles,
      onDownloadFile,
      onDeleteFile,
    } = this.props;
    const { remove } = this.state;

    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.container}>
            {files.length() > 0 ? (
              <ScrollView style={styles.listContainer}>
                {files
                  .values()
                  .filter(({ deleted }) => !deleted)
                  .map((file, idx) => {
                    const fileType =
                      file.id in downloadedFiles
                        ? downloadedFiles[file.id].type()
                        : file.type;
                    const MyFileIcon =
                      fileType === "application/pdf"
                        ? PdfIcon
                        : fileType.startsWith("image")
                        ? ImageIcon
                        : FileIcon;
                    const DownloadStateIcon =
                      file.id in downloadedFiles
                        ? DownloadedIcon
                        : NotDownloadedIcon;
                    return (
                      <View
                        key={file.id}
                        style={themeComposer(
                          styles.row,
                          {},
                          files.length() > 1 && idx !== files.length() - 1
                            ? border(1, "solid", colors.separator, "Bottom")
                            : {},
                        )}
                      >
                        {edit === true && (
                          <Button
                            style={styles.removeButtonLeft}
                            onPress={() =>
                              this.setState({
                                remove:
                                  remove === file.id ? undefined : file.id,
                              })
                            }
                          >
                            <RemoveIcon
                              style={iconStyle(rem(2.5))}
                              fill={colors.buttonDanger}
                            />
                          </Button>
                        )}
                        <MyFileIcon style={iconStyle(rem(2))} />
                        {file.id in downloadedFiles ? (
                          <Download
                            data={downloadedFiles[file.id].data}
                            // mimeType={file.type}
                            mimeType={"image/png"}
                            fileName={file.name}
                            base64
                            style={nameContainerStyle}
                          >
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="middle"
                              style={themeComposer(styles.name, {
                                color: "textPrimary",
                              })}
                            >
                              {file.name}
                            </Text>
                            <DownloadStateIcon style={styles.downloadIcon} />
                          </Download>
                        ) : (
                          <Button
                            style={styles.nameContainer}
                            onPress={() => {
                              const quota = quotas.find(
                                ({ id }) => id === file.quotaId,
                              );
                              if (quota != null) {
                                onDownloadFile(file, quota);
                              } else {
                                //todo show dialog
                                console.error(
                                  `Quota for file ${file.name} (${
                                    file.id
                                  }) not found`,
                                );
                              }
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="middle"
                              style={themeComposer(styles.name, {
                                color: "textPrimary",
                              })}
                            >
                              {file.name}
                            </Text>
                            <DownloadStateIcon style={styles.downloadIcon} />
                          </Button>
                        )}
                        {remove !== file && (
                          <View style={flexCenterDirection("row")}>
                            <Text
                              style={{
                                color: colors.textSecondary,
                                fontSize: rem(1),
                              }}
                            >
                              {sensibleByte(file.size)}
                            </Text>
                            <DividerVertical margin={rem(0.5)} />
                            <Text
                              style={{
                                color: colors.textSecondary,
                                fontSize: rem(1),
                              }}
                            >
                              {file.updated.toDateString()}
                            </Text>
                          </View>
                        )}
                        {remove === file.id && (
                          <Button
                            type="danger"
                            label="Remove"
                            style={styles.removeButtonRight}
                            onPress={() => {
                              this.setState({ remove: undefined });
                              const quota = quotas.find(
                                ({ id }) => id === file.quotaId,
                              );
                              if (quota != null) {
                                onDeleteFile(file, quota);
                              } else {
                                //todo show dialog
                                console.error(
                                  `Quota for file ${file.name} (${
                                    file.id
                                  }) not found`,
                                );
                              }
                            }}
                          />
                        )}
                      </View>
                    );
                  })}
              </ScrollView>
            ) : (
              <Text
                style={themeComposer(styles.name, {
                  color: "textSecondary",
                })}
              >
                It's quiet... too... quiet...
              </Text>
            )}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
