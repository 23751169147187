// @flow
import { handleActions } from "redux-actions";
import { TransactionList } from "../api/models";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import { accountActions } from "../actions/account";
import type { Reducers } from "./types";

export type TransactionsStateType = {|
  +transactions: TransactionList,
|};

export const transactionsDefaults: TransactionsStateType = deepFreeze({
  transactions: TransactionList.empty,
});

const actionHandlers = {
  ...actionHandler(accountActions.account.receiveAccount, (state, action) => ({
    transactions: action.payload.account.transactions,
  })),
};

export const transactions: Reducers<TransactionsStateType> = handleActions(
  actionHandlers,
  transactionsDefaults,
);
