// @flow
import React from "react";
import { scaleLinear, scaleTime } from "d3-scale";
// import { scalePow } from "d3-scale";
// import {scaleLog} from 'd3-scale';
import { rgb } from "d3-color";
import { area, curveCatmullRom, line, stack } from "d3-shape";
import type { LayoutType } from "../../../models/Layout";
import { TimeSeries } from "../../../models/TimeSeries";
import { StretchedSVG } from "../../svg/StretchedSVG";
import { rem, ThemeContext } from "../../theme/theme";
import { G, Line, Path, Text } from "../../svg/svg";
import type { CurrencySymbolType } from "../../../api/models";
import { hexColor } from "../../svg/d3";
import { DeliberateHover } from "../../layout/DeliberateHover";
import { sensibleNumber } from "../../layout/SensibleNumber";
import { currenciesActions } from "../../../actions/currencies";

type ValueChangeGraphPropsType = {|
  +timeSeries: TimeSeries,
  +textSize: number,
  +margin: number,
  +marginTop: number,
  +filled: boolean,
  +followMouse: boolean,

  +hoveredCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onHoverCurrency: typeof currenciesActions.currencies.hover,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
|};

type ValueChangeGraphStateType = {|
  width: number,
  height: number,
|};

const strokeDarkness = 0.5;

export class ValueChangeGraph extends React.PureComponent<
  ValueChangeGraphPropsType,
  ValueChangeGraphStateType,
> {
  x: (number) => number;
  y: (number) => number;
  stack: $Call<stack>;
  valueArea: $Call<area>;
  valueLine: $Call<line>;
  deliberateHover = new DeliberateHover();

  static defaultProps = {
    textSize: rem(2),
    margin: 0,
    marginTop: 0,
    filled: true,
    followMouse: true,
  };

  state = {
    width: 0,
    height: 0,
  };

  constructor(props: ValueChangeGraphPropsType) {
    super(props);
    this.x = scaleTime();
    this.y = scaleLinear();
    // this.y = scaleLog();
    // this.y = scalePow().exponent(-2);
    this.valueArea = area()
      .curve(curveCatmullRom)
      .x((d) => this.x(d.timestamp));
    this.valueLine = area()
      .curve(curveCatmullRom)
      .x((d) => this.x(d.timestamp));
  }

  _onLayout = ({ width, height }: LayoutType) => {
    const { margin, marginTop } = this.props;
    const finalMargin = margin > 0 && margin < 1 ? margin * height : margin;
    const finalMarginTop =
      marginTop > 0 && marginTop < 1 ? marginTop * height : marginTop;
    this.x.range([0, width]);
    this.y.range([height - finalMargin, finalMargin + finalMarginTop]);
    this.setState({ width, height });
  };

  _onMouseEnter = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/valuechangegraph-.*-/, "");
    if (!this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onEnter(() =>
        this.props.onHoverCurrency({ symbol, flag: true }),
      );
    }
  };
  _onMouseLeave = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/valuechangegraph-.*-/, "");
    if (this.props.hoveredCurrencies.includes(symbol)) {
      this.deliberateHover.onLeave(() =>
        this.props.onHoverCurrency({ symbol, flag: false }),
      );
    }
  };
  _onSelect = (evt: SyntheticEvent<HTMLElement>) => {
    const symbol = evt.currentTarget.id.replace(/valuechangegraph-.*-/, "");
    if (this.props.selectedCurrencies.includes(symbol)) {
      this.props.onSelectCurrency({ symbol, flag: false });
    } else {
      this.props.onSelectCurrency({ symbol, flag: true });
    }
  };

  render() {
    const {
      timeSeries,
      filled,
      followMouse,
      hoveredCurrencies,
      selectedCurrencies,
    } = this.props;
    const { width, height } = this.state;
    if (timeSeries.isEmpty() || !width || !height) {
      return <StretchedSVG onLayout={this._onLayout} />;
    }

    const startByKey = timeSeries.head();
    // const endByKey = timeSeries.current();
    let min = 0,
      max = 0;
    const data = timeSeries.values().map((row) =>
      timeSeries.keys().reduce(
        (acc, key) => {
          acc[key] = -(startByKey[key] - row[key]) / startByKey[key];
          min = Math.min(acc[key], min);
          max = Math.max(acc[key], max);
          return acc;
        },
        { timestamp: row.timestamp },
      ),
    );

    const unsortedKeys = timeSeries.keys();
    const averages = data
      .reduce((acc, row) => {
        unsortedKeys.forEach((key, idx) => {
          acc[idx] += Math.abs(row[key]);
        });
        return acc;
      }, new Array(unsortedKeys.length).fill(0))
      .map((sum, idx) => [unsortedKeys[idx], sum / unsortedKeys.length])
      .sort((a, b) => b[1] - a[1]);
    let keys = averages.map(([key]) => key);
    if (
      selectedCurrencies.length !== 0 &&
      selectedCurrencies.length !== keys.length
    ) {
      keys = [
        ...keys.filter((key) => !selectedCurrencies.includes(key)),
        ...keys.filter((key) => selectedCurrencies.includes(key)),
      ];
    }
    if (hoveredCurrencies != null) {
      keys = [
        ...keys.filter((key) => !hoveredCurrencies.includes(key)),
        ...keys.filter((key) => hoveredCurrencies.includes(key)),
      ];
    }

    this.x.domain([
      timeSeries.head().timestamp,
      timeSeries.current().timestamp,
    ]);
    this.y.domain([min, max]);
    const range = max - min;
    const zero = min > 0 ? min : max < 0 ? max : 0;
    const zeroLine = this.y(zero);
    const zeroLineFlip = height - 30;
    const zeroLineMax = height - 4;
    const zeroLineMin = 4;
    this.valueArea.y0(() => zeroLine);

    const numDateTicks = Math.floor(width / rem(6));
    const numValueLines = 5;
    const numValueTicks = 3;
    const numSubValueLines = 3;

    const showCurrencyArr =
      hoveredCurrencies.length === 1
        ? hoveredCurrencies
        : selectedCurrencies.length === 1
        ? selectedCurrencies
        : [];
    const showCurrency = showCurrencyArr[0];

    // @todo moize the value area calls for redraws
    return (
      <ThemeContext.Consumer>
        {({ colors, currencyColors }) => (
          <StretchedSVG onLayout={this._onLayout}>
            <G className="grid-lines-horizontal">
              {new Array(numValueLines).fill(0).map((_, idx) => (
                <G
                  className="grid-line-with-tick"
                  key={`value-sub-container-${idx}`}
                >
                  {new Array(numSubValueLines).fill(0).map((_, subIdx) => (
                    <Line
                      key={`value-sub-${subIdx}`}
                      x1={this.x(timeSeries.head().timestamp)}
                      y1={this.y(
                        this.y.domain()[0] +
                          ((idx + subIdx / numSubValueLines) *
                            (this.y.domain()[1] - this.y.domain()[0])) /
                            numValueLines,
                      )}
                      x2={this.x(timeSeries.current().timestamp)}
                      y2={this.y(
                        this.y.domain()[0] +
                          ((idx + subIdx / numSubValueLines) *
                            (this.y.domain()[1] - this.y.domain()[0])) /
                            numValueLines,
                      )}
                      stroke={colors.separator}
                      strokeWidth={0.25}
                    />
                  ))}
                </G>
              ))}
            </G>
            <G
              className="areas"
              onMouseEnter={this.deliberateHover.onEnableHover}
              onMouseLeave={this.deliberateHover.onDisableHover}
            >
              {keys.map((key) => {
                this.valueArea.y1((d) => this.y(d[key]));
                this.valueLine.y((d) => this.y(d[key]));

                const fill = rgb(currencyColors(key));
                const fillOpacity =
                  hoveredCurrencies.length + selectedCurrencies.length !== 0 &&
                  !hoveredCurrencies.includes(key) &&
                  !selectedCurrencies.includes(key)
                    ? 0.2
                    : 0.5;
                const stroke = fill.darker(strokeDarkness);
                return (
                  <G key={key}>
                    {filled && (
                      <Path
                        fill={hexColor(fill)}
                        fillOpacity={fillOpacity}
                        stroke="none"
                        id={`valuechangegraph-layer-${key}`}
                        cursor="pointer"
                        onMouseEnter={followMouse ? this._onMouseEnter : null}
                        onMouseLeave={followMouse ? this._onMouseLeave : null}
                        onClick={this._onSelect}
                        className="area"
                        d={this.valueArea(data)}
                      />
                    )}
                    <Path
                      fill="none"
                      stroke={hexColor(stroke)}
                      strokeWidth={fillOpacity === 0.5 ? 2 : 0.2}
                      id={`valuechangegraph-layerline-${key}`}
                      cursor="pointer"
                      onMouseEnter={followMouse ? this._onMouseEnter : null}
                      onMouseLeave={followMouse ? this._onMouseLeave : null}
                      onClick={this._onSelect}
                      className="line"
                      d={this.valueLine(data)}
                    />
                  </G>
                );
              })}
            </G>

            <G className="grid-lines-horizontal">
              {new Array(numValueLines + 1).fill(0).map((_, idx) => (
                <Line
                  key={`value-line-${idx}`}
                  x1={this.x(timeSeries.head().timestamp)}
                  y1={this.y(min + (idx * range) / numValueLines)}
                  x2={this.x(timeSeries.current().timestamp)}
                  y2={this.y(min + (idx * range) / numValueLines)}
                  stroke={colors.textSecondary}
                  strokeWidth={0.5}
                />
              ))}

              {new Array(numValueTicks).fill(0).map((_, idx) => [
                ...new Array(numValueLines + 1).fill(0).map((_, subIdx) => {
                  const factor = min + subIdx * (range / numValueLines);
                  let tick;
                  if (showCurrency == null) {
                    tick = `${sensibleNumber(factor * 100)}%`;
                  } else {
                    tick = `$${sensibleNumber(
                      // relative change
                      // startByKey[showCurrency] * factor,
                      // absolute value
                      startByKey[showCurrency] +
                        startByKey[showCurrency] * factor,
                    )}`;
                  }
                  return (
                    <Text
                      key={`value-text-${idx}-${subIdx}`}
                      textAnchor="middle"
                      fill={colors.textSecondary}
                      fontSize={10}
                      dy={subIdx === numValueLines ? 11 : -3}
                      x={
                        ((this.x.range()[1] - this.x.range()[0]) /
                          numValueTicks) *
                        (idx + 0.5)
                      }
                      y={this.y(factor)}
                    >
                      {tick}
                    </Text>
                  );
                }),
              ])}
            </G>

            <G className="x-axis">
              <Line
                x1={this.x(timeSeries.head().timestamp)}
                y1={zeroLine}
                x2={this.x(timeSeries.current().timestamp)}
                y2={zeroLine}
                stroke={colors.textPrimary}
                strokeWidth={zeroLine === height ? 2 : 1}
              />
              {data.map(
                ({ timestamp }, idx) =>
                  (idx + Math.round((0.5 * data.length) / numDateTicks)) %
                    Math.round(data.length / numDateTicks) ===
                    0 && (
                    <G className="tick" key={`date-tick-${idx}`}>
                      <Line
                        key={timestamp}
                        x1={this.x(timestamp)}
                        y1={
                          zeroLine > zeroLineMax
                            ? zeroLine - 8
                            : zeroLine < zeroLineMin
                            ? zeroLine
                            : zeroLine - 4
                        }
                        x2={this.x(timestamp)}
                        y2={
                          zeroLine > zeroLineMax
                            ? zeroLine
                            : zeroLine < zeroLineMin
                            ? zeroLine + 8
                            : zeroLine + 4
                        }
                        stroke={colors.textPrimary}
                        strokeWidth={1}
                      />
                      <Text
                        textAnchor="middle"
                        fill={colors.textPrimary}
                        fontSize={10}
                        dy={10}
                        x={this.x(timestamp)}
                        y={
                          zeroLine > zeroLineMax
                            ? zeroLine - 22
                            : zeroLine > zeroLineFlip
                            ? zeroLine - 18
                            : zeroLine < zeroLineMin
                            ? zeroLine + 10
                            : zeroLine + 6
                        }
                      >
                        {timestamp.toLocaleString("en-US", {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                        })}
                      </Text>
                    </G>
                  ),
              )}
            </G>
            {/*{showCurrency != null && (*/}
            {/*<G className="y-labels">*/}
            {/*<Text*/}
            {/*transform={transform({ rotate: "90" })}*/}
            {/*textAnchor="start"*/}
            {/*fill={colors.textSecondary}*/}
            {/*fontSize={17}*/}
            {/*dy={-1}*/}
            {/*x={-this.y(0) + 10}*/}
            {/*y={0}*/}
            {/*>*/}
            {/*${sensibleNumber(startByKey[showCurrency])}*/}
            {/*</Text>*/}
            {/*<Text*/}
            {/*transform={transform({ rotate: "-90" })}*/}
            {/*textAnchor="end"*/}
            {/*fill={colors.textSecondary}*/}
            {/*fontSize={17}*/}
            {/*dy={-1}*/}
            {/*x={*/}
            {/*-this.y(*/}
            {/*-(startByKey[showCurrency] - endByKey[showCurrency]) /*/}
            {/*startByKey[showCurrency],*/}
            {/*) + 1*/}
            {/*}*/}
            {/*y={width}*/}
            {/*>*/}
            {/*${sensibleNumber(endByKey[showCurrency])}*/}
            {/*</Text>*/}
            {/*</G>*/}
            {/*)}*/}
          </StretchedSVG>
        )}
      </ThemeContext.Consumer>
    );
  }
}
