// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers";
import type { DispatchType } from "../../actions/types";
import { MenuOverlay } from "./MenuOverlay";
import { appActions } from "../../actions/app";

const mapStateToProps = (state: StateType) => ({
  children: state.menu.menu != null ? state.menu.menu.children : null,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onCloseMenu: () => dispatch(appActions.app.menu.closeMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuOverlay);
