// @flow
import React from "react";
import moize from "moize";
import { hsl } from "d3-color";
import { Platform, StyleSheet } from "react-native";
import { deepFreeze } from "react-redux-flow-tools";
import { isIphoneXorAbove } from "../layout/Device";
import type { CurrencySymbolType } from "../../api/models";
import { hexColor } from "../svg/d3";

export type StyleType = {
  [string]: string | number | $ReadOnlyArray<{ +[string]: string }> | StyleType,
};
export const composeStyles = moize.deep(
  (...styles: $ReadOnlyArray<$ReadOnly<StyleType>>): $ReadOnly<StyleType> =>
    styles.reduce((acc, style) => ({ ...acc, ...style }), {}),
);

export const rem = (multiplier?: number = 1) => multiplier * 10;

export type ColorsType = {
  +textPrimary: string,
  +textSecondary: string,
  +textTertiary: string,
  +textDanger: string,

  +inputBackgroundPrimary: string,
  +inputBackgroundSecondary: string,
  +inputBackgroundTertiary: string,

  +background: string,
  +backgroundSecondary: string,
  +transparentBackground: string,
  +headerBackground: string,
  +hoverBackground: string,

  +separator: string,

  +buttonDefault: string,
  +buttonPrimary: string,
  +buttonSecondary: string,
  +buttonSuccess: string,
  +buttonDanger: string,

  +shadow: string,

  +juno: string,
  +transparentJuno: string,

  +[CurrencySymbolType]: string,
};

const coinColors: {
  +[CurrencySymbolType]: string,
} = {
  XRP: "#5ea9ff",
  ETH: "#aa9cff",
  BTC: "#FFC873",
  LTC: "#8bccf3",
  BCH: "#ed8d2d",
  BCHABC: "#ed8d2d",
  DASH: "#1f5eff",
  EOS: "#505050",
  USDT: "#2ea07c",
  XLM: "#bde8c3",
  XMR: "#fc6621",
  ADA: "#071f2e",
  BNB: "#eab33d",
  BSV: "#ebbf2e",
  DOGE: "#b29c3b",
  ETC: "#1ec056",
  NEO: "#9cca2e",
  TRX: "#a7f4ec",
  USD: "#e4e8d8",
  ZEC: "#cb8833",
  PAY: "#3b3b3b",
  XEM: "#2cb4ab",
};

const colorDanger = "#9f2700";
const colors: ColorsType = deepFreeze({
  textPrimary: "#3e3e3e",
  textSecondary: "#7e7e7e",
  textTertiary: "#ffffff",
  textDanger: colorDanger,

  inputBackgroundPrimary: "#eeeeee",
  inputBackgroundSecondary: "#888888",
  inputBackgroundTertiary: "#555555",

  // yellow
  // background: "#fdfcf0",
  // transparentBackground: "#fdfcf099",
  background: "#ffffff",
  backgroundSecondary: "#fafafa",
  transparentBackground: "#ffffffaa",
  headerBackground: "#ffffff",
  hoverBackground: "#f9f9f9",

  separator: "#c6d6da",

  buttonDefault: "#eeeeee",
  buttonPrimary: "#606b8e",
  buttonSecondary: "#cf9416",
  buttonSuccess: "#009f41",
  buttonDanger: colorDanger,

  shadow: "#c6d6da",

  juno: "#568c97",
  transparentJuno: "#568c9755",

  ...coinColors,
});

const darkColors: ColorsType = deepFreeze({
  textPrimary: "#ffffff",
  textSecondary: "#cccccc",
  textTertiary: "#7a7a7a",
  textDanger: colorDanger,

  inputBackgroundPrimary: "#888888",
  inputBackgroundSecondary: "#666666",
  inputBackgroundTertiary: "#444444",

  background: "#2b2b2b",
  backgroundSecondary: "#282828",
  transparentBackground: "#2b2b2bdd",
  headerBackground: "#3c3f41",
  hoverBackground: "#4a4a4a",

  separator: "#555555",

  buttonDefault: "#bbbbbb",
  buttonPrimary: "#606b8e",
  buttonSecondary: "#cf9416",
  buttonSuccess: "#009f41",
  buttonDanger: colorDanger,

  shadow: "#000",

  juno: "#76bbc8",
  transparentJuno: "#568c9755",

  ...coinColors,

  ADA: "#eeeeee",
  BSV: "#ffff68",
  PAY: "#f7f7f7",
});

export const headerHeight = (portrait: boolean) =>
  Platform.OS === "ios"
    ? portrait
      ? isIphoneXorAbove()
        ? rem(8)
        : rem(6)
      : Platform.isPad
      ? rem(6)
      : rem(5)
    : rem(5);

export type AvailableThemesType = "dark" | "light";

export type ThemeArgsType = {
  [$Keys<StyleType>]: $Keys<ColorsType>,
};

const themer = moize.simple(
  (colors: ColorsType) => (args: ThemeArgsType): $ReadOnly<StyleType> =>
    composeStyles(
      ...Object.keys(args).map((styleKey) => ({
        [styleKey]: colors[args[styleKey]],
      })),
    ),
);

const themeComposer = moize.simple(
  (themer: (ThemeArgsType) => $ReadOnly<StyleType>) =>
    moize.deep(
      (
        base: $ReadOnly<StyleType>,
        args: ThemeArgsType,
        ...styles: $ReadOnlyArray<$ReadOnly<StyleType>>
      ) =>
        styles.reduce(
          (acc, style) =>
            StyleSheet.compose(
              acc,
              style,
            ),
          StyleSheet.compose(
            base,
            themer(args),
          ),
        ),
    ),
);

export const stringToColor = (str: string): string => {
  // thx to https://stackoverflow.com/a/52171480
  let p1 = 2654435761,
    p2 = 1597334677,
    h1 = 0xdeadbeef | 0,
    h2 = 0x41c6ce57 | 0;
  let ch;
  for (let i = 0; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 + ch, p1);
    h2 = Math.imul(h2 + ch, p2);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), p2);
  h2 = Math.imul(h2 ^ (h2 >>> 15), p1);
  const hash = (h2 & 2097151) * 4294967296 + h1;
  const hue = hash % 360;
  const saturation = (hash % 37) / 100 + 0.5;
  const lightness = (hash % 41) / 100 + 0.4;
  return hexColor(hsl(hue, saturation, lightness).rgb());
};

const currencyColors = moize.simple((colors: ColorsType) =>
  moize(
    (symbol: CurrencySymbolType): string => {
      if (symbol in colors) {
        return colors[symbol];
      } else {
        return stringToColor(symbol);
      }
    },
  ),
);

const themeContextValue = moize.simple(
  (theme: AvailableThemesType, colors: ColorsType) => ({
    themer: themer(colors),
    themeComposer: themeComposer(themer(colors)),
    colors,
    theme,
    currencyColors: currencyColors(colors),
  }),
);

const themes: {
  +[AvailableThemesType]: $Call<
    typeof themeContextValue,
    "light",
    typeof colors,
  >,
} = {
  dark: themeContextValue("dark", darkColors),
  light: themeContextValue("light", colors),
};

export const loadTheme = (theme: AvailableThemesType | "auto") => {
  if (theme === "auto") {
    const time = new Date();
    const autoTheme =
      time.getHours() >= 21 || time.getHours() < 8 ? "dark" : "light";
    return themes[autoTheme];
  } else {
    return themes[theme];
  }
};

export const ThemeContext: React$Context<
  $Call<typeof themeContextValue, "light", typeof colors>,
> = React.createContext(themes["light"]);
