// @flow
export * from "./Router";

export type RootPathEnum =
  | "account"
  | "documents"
  | "transactions"
  | "overview"
  | "rebalance"
  | "swap_tokens"
  | "experiments";
export const routes: $ReadOnlyArray<RootPathEnum> = [
  "account",
  "documents",
  "transactions",
  "overview",
  "rebalance",
  "swap_tokens",
  "experiments",
];

export const checkRootPath = (
  path: ?string,
  def: RootPathEnum,
): RootPathEnum => {
  if (path != null) {
    const idx = routes.indexOf(path);
    if (idx >= 0) {
      return routes[idx];
    }
  }
  return def;
};

export const to = (path?: RootPathEnum | "" = "", backTo?: string): string =>
  `/app/${path}${backTo != null ? "?redirect=" + backTo : ""}`;

export type AccountPathEnum = "profile" | "devices" | "tokens";
export const accountTo = (
  path?: AccountPathEnum | "" = "",
  backTo?: string,
): string =>
  `${to("account")}/${path}${backTo != null ? "?redirect=" + backTo : ""}`;
