// @flow
import moize from "moize";
import { withDefault } from "react-redux-flow-tools";
import type { CurrencySymbolType } from "../api/models";
import { TimeSeries } from "./TimeSeries";
import { SquareMatrix } from "./Matrix";

export class Movements {
  +_squareMatrix: number[][];
  +_currencies: $ReadOnlyArray<CurrencySymbolType>;
  +_currenciesHalf: $ReadOnlyArray<CurrencySymbolType>;

  constructor(origins: { +[CurrencySymbolType]: number }) {
    this._currenciesHalf = Object.keys(origins);
    this._currencies = this._currenciesHalf.concat(
      this._currenciesHalf.slice(0).reverse(),
    );
    this._squareMatrix = this._currencies.map(() =>
      new Array(this._currencies.length).fill(0),
    );
    for (let xy = 0; xy < this._squareMatrix.length; xy++) {
      this._squareMatrix[xy][xy] =
        origins[this._currencies[xy % this._squareMatrix.length]];
    }
  }

  origin(currency: CurrencySymbolType): number {
    const index = this._currencies.lastIndexOf(currency);
    if (index < 0) {
      throw new Error(
        `KeyError: currency ${JSON.stringify(currency)} not found`,
      );
    }
    return this._squareMatrix[index].reduce((acc, val) => acc + val, 0);
  }

  current(currency: CurrencySymbolType): number {
    const index = this._currencies.indexOf(currency);
    if (index < 0) {
      throw new Error(
        `KeyError: currency ${JSON.stringify(currency)} not found`,
      );
    }
    return this._squareMatrix[index].reduce((acc, val) => acc + val, 0);
  }

  currencies(): $ReadOnlyArray<CurrencySymbolType> {
    return this._currenciesHalf;
  }

  matrix(): SquareMatrix<number> {
    return SquareMatrix.of(this._squareMatrix);
  }
  isEmpty(): boolean {
    return this._squareMatrix.length === 0;
  }
}

export class MutableMovements extends Movements {
  add(
    from: CurrencySymbolType,
    fromValue: number,
    to: CurrencySymbolType,
    toValue: number,
  ): MutableMovements {
    if (fromValue < 0 || toValue < 0) {
      throw new Error("ValueError: fromValue and toValue can't be negative!");
    }
    const indexFrom = this._currencies.lastIndexOf(from);
    const indexFromToday = this._currencies.indexOf(from);
    const indexTo = this._currencies.indexOf(to);

    this._squareMatrix[indexFrom][indexFrom] -= fromValue;
    this._squareMatrix[indexFrom][indexTo] += fromValue;

    this._squareMatrix[indexFromToday][indexFromToday] -= fromValue;
    this._squareMatrix[indexTo][indexFrom] += toValue;

    return this;
  }
}

export const absoluteMovementsFromTimeseries = moize.simple(
  (timeSeries: TimeSeries): Movements => {
    const head = timeSeries.head();
    const origins = timeSeries
      .keys()
      .map((key) => ({ [key]: head[key] }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
    const movements = new MutableMovements(origins);

    const current = timeSeries.current();
    timeSeries.keys().forEach((key) => {
      const priceDelta = origins[key] - current[key];
      if (priceDelta > 0) {
        movements.add(key, 0, key, priceDelta);
      } else {
        movements.add(key, Math.abs(priceDelta), key, 0);
      }
    });
    return movements;
  },
);

export const scaledMovementsFromTimeseries = moize.simple(
  (timeSeries: TimeSeries, scalingFactor?: number): Movements => {
    const current = timeSeries.current();
    const head = timeSeries.head();
    const changesByKeys = timeSeries
      .keys()
      .map((key) => ({ [key]: (current[key] - head[key]) / head[key] }))
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
    const totalGrowth = Object.keys(changesByKeys)
      .map((key) => changesByKeys[key]) //flow
      .reduce((acc, change: number) => (change > 0 ? acc + change : acc), 0);
    const totalShrink = Object.keys(changesByKeys)
      .map((key) => changesByKeys[key]) //flow
      .reduce(
        (acc, change: number) => (change < 0 ? acc + Math.abs(change) : acc),
        0,
      );
    const factor = withDefault(
      scalingFactor,
      Math.max(totalGrowth, totalShrink),
    );

    // left side basically
    const origins = timeSeries
      .keys()
      .map((key) => {
        if (changesByKeys[key] > 0) {
          // it rose, so start from "100%"
          return { [key]: 1 };
        } else {
          // it fell so start from "100%" + change/factor
          return { [key]: 1 + Math.abs(changesByKeys[key]) / factor };
        }
      })
      .reduce((acc, obs) => ({ ...acc, ...obs }), {});
    const movements = new MutableMovements(origins);
    timeSeries.keys().forEach((key) => {
      const change = changesByKeys[key];
      if (change > 0) {
        // it rose so it has to rise from 0 to change/factor
        movements.add(key, 0, key, change / factor);
      } else {
        // it fell, so it has to fall from change/factor to 0
        movements.add(key, Math.abs(change) / factor, key, 0);
      }
    });
    return movements;
  },
);

export const impactScaledMovementsFromTimeseries = (
  timeSeries: TimeSeries,
): Movements => scaledMovementsFromTimeseries(timeSeries);
export const percentageScaledMovementsFromTimeseries = (
  timeSeries: TimeSeries,
): Movements => scaledMovementsFromTimeseries(timeSeries, 1);
