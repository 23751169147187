// @flow
import { deepFreeze } from "react-redux-flow-tools";
import moize from "moize";
import { rgb } from "d3-color";
import type { ColorsType } from "./theme";
import { composeStyles, rem } from "./theme";
import { Platform } from "react-native";

export const flexCenter = composeStyles({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const flexCenterDirection = moize((flexDirection: "row" | "column") =>
  composeStyles(flexCenter, {
    flexDirection,
  }),
);

export const flexCenterFlow = moize(
  (flexDirection: "row" | "column", wrap?: boolean = false) =>
    composeStyles(flexCenterDirection(flexDirection), {
      flexWrap: wrap ? "wrap" : "nowrap",
    }),
);

// react native doesn't allow "borderStyle" on image styles
export const imageBorder = moize(
  (
    width: number,
    color: string,
    side?: "" | "Top" | "Left" | "Right" | "Bottom" = "",
  ) => ({
    [`border${side}Width`]: width,
    [`border${side}Color`]: color,
  }),
);

export const border = moize(
  (
    width: number,
    style: "solid" | "dotted" | "dashed",
    color: string,
    side?: "" | "Top" | "Left" | "Right" | "Bottom" = "",
  ) => ({
    ...imageBorder(width, color, side),
    borderStyle: style,
  }),
);

const _noOffset = deepFreeze({ width: 0, height: 0 });

type textShadowParamType = {|
  +offset?: {| +width: number, +height: number |},
  +color: string,
  +radius?: number,
|};

export const textShadow = moize(
  ({ offset = _noOffset, color, radius = 1 }: textShadowParamType = {}) => ({
    // textShaowOffset: offset, // todo
    textShadowColor: color,
    textShadowRadius: radius,
  }),
);

export const inputBorderRadius = rem(0.25);

export const inputStyle = composeStyles({
  fontSize: rem(2),
  padding: rem(0.5),
  borderRadius: inputBorderRadius,
});

export const inputTheme = {
  color: "textPrimary",
  backgroundColor: "inputBackgroundPrimary",
};

export const inputBorder = moize((colors: ColorsType) =>
  border(1, "solid", colors.separator),
);

export const noDecorationStyle = composeStyles(
  Platform.OS === "web"
    ? {
        textDecoration: "none",
      }
    : {},
);

export const menuItemStyle = composeStyles(flexCenterFlow("row"), {
  paddingLeft: rem(1),
  paddingRight: rem(1),
});

export const menuIconSize = 24;
export const minMenuItemHeight = rem(4);

export const sidebarWidth = 325;

// courtesy https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const lightnessTextColor = (
  fillOrStr: { +r: number, +g: number, +b: number } | string,
) => {
  const fill = typeof fillOrStr === "string" ? rgb(fillOrStr) : fillOrStr;
  return fill.r * 0.299 + fill.g * 0.587 + fill.b * 0.114 > 186
    ? "black"
    : "white";
};
