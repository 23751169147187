// @flow
import * as React from "react";
import { StyleSheet, View } from "react-native";
import type { LayoutEventType } from "../../models/Layout";
import { withDefault } from "react-redux-flow-tools";
import { Svg } from "./svg";
import type { LayoutType } from "../../models/Layout";
import type { StyleType } from "../theme/theme";
import { Button } from "../layout/Button";
import { ShareIcon } from "../img/Icon";
import { iconStyle } from "../header/common";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  shareContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: 6,
  },
  shareIcon: iconStyle(16),
});

type StretchedSVGPropsType = {|
  +children?: React.Node,
  +onLayout?: (LayoutType) => mixed,
  +viewBox?: string,
  +style?: StyleType,
  +shareButton?: boolean,
|};

type StretchedSVGComponentStateType = {|
  width: number,
  height: number,
|};

export class StretchedSVG extends React.PureComponent<
  StretchedSVGPropsType,
  StretchedSVGComponentStateType,
> {
  state = {
    width: 0,
    height: 0,
  };

  _svgRef: ?Element;

  _onLayout = (layoutEvent: LayoutEventType) => {
    console.debug("Layouting SVG", layoutEvent.nativeEvent.layout);
    const layout = layoutEvent.nativeEvent.layout;
    const { width, height } = layout;
    const { onLayout } = this.props;
    this.setState({ width, height }, () => {
      if (onLayout != null) {
        onLayout(layout);
      }
    });
  };

  _setSvgRef = (ref: ?Element) => {
    this._svgRef = ref;
  };

  render() {
    const { width, height } = this.state;
    if (!width || !height) {
      return <View style={styles.container} onLayout={this._onLayout} />;
    }

    const { shareButton = false, style, children, viewBox } = this.props;
    return (
      <View style={styles.container} onLayout={this._onLayout}>
        <Svg
          ref={this._setSvgRef}
          style={style}
          width={width}
          height={height}
          viewBox={withDefault(viewBox, `0 0 ${width} ${height}`)}
        >
          {children}
        </Svg>
        {shareButton === true && (
          <View style={styles.shareContainer}>
            <Button
              onPress={() => {
                if (this._svgRef == null) {
                  return;
                }
                const serializer = new XMLSerializer();
                let source = serializer.serializeToString(this._svgRef);
                console.log(source.length, source);
                source = source.replace(/width="\d*"/, "");
                source = source.replace(/height="\d*"/, "");
                source = source.replace(/(\d\.\d)\d\d*/g, "$1");
                console.log(source.length, source);
                //add name spaces.
                if (
                  !source.match(
                    /^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/,
                  )
                ) {
                  source = source.replace(
                    /^<svg/,
                    '<svg xmlns="http://www.w3.org/2000/svg"',
                  );
                }
                if (
                  !source.match(
                    /^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/,
                  )
                ) {
                  source = source.replace(
                    /^<svg/,
                    '<svg xmlns:xlink="http://www.w3.org/1999/xlink"',
                  );
                }

                //add xml declaration
                source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

                //convert svg source to URI data scheme.
                const url =
                  "data:image/svg+xml;charset=utf-8," +
                  encodeURIComponent(source);
                const a = document.createElement("a");
                a.href = url;
                a.download = "graph";
                a.click();
              }}
            >
              <ShareIcon style={styles.shareIcon} />
            </Button>
          </View>
        )}
      </View>
    );
  }
}
