// @flow
import type { Node } from "react";
import { Platform, Text, View } from "react-native";
import { createActions } from "redux-actions";
import { ident, noop } from "react-redux-flow-tools";
import type {
  ActionCreatorType,
  DispatchType,
  GetStateType,
  PureActionCreatorType,
} from "./types";
import type { AvailableThemesType } from "../components/theme/theme";

export type ChartType = "valuechange" | "layered" | "stream";
export const chartTypes: $ReadOnlyArray<ChartType> = [
  "valuechange",
  "layered",
  "stream",
];

export type DialogActionType = {|
  +primary?: boolean,
  +secondary?: boolean,
  +label: string,
  +isClosing?: boolean,
  +onClick?: () => mixed,
|};

export type DialogType = {|
  +actions: $ReadOnlyArray<DialogActionType>,
  +message: string,
  +title: string,
|};

export type ModalType = {|
  +title: string,
  +children: View | Text,
  +closeable?: boolean,
  +fitContent?: boolean,
|};

export type MenuType = {|
  +name: string,
  +children: Node,
|};

type AppActionTypes = {|
  +app: {|
    +receiveFirstTime: PureActionCreatorType,
    +receiveTabIndex: ActionCreatorType<{|
      +name: string,
      +tabIndex: number,
    |}>,
    +modal: {|
      +show: ActionCreatorType<ModalType>,
      +dismissFirst: PureActionCreatorType,
    |},
    +dialog: {|
      +show: ActionCreatorType<DialogType>,
      +dismissFirst: PureActionCreatorType,
    |},
    +menu: {|
      +showMenu: ActionCreatorType<MenuType>,
      +closeMenu: PureActionCreatorType,
    |},
    +receiveFollowMouse: ActionCreatorType<boolean>,
    +receiveFilled: ActionCreatorType<boolean>,
    +receiveTheme: ActionCreatorType<AvailableThemesType | "auto">,
    +receiveCompact: ActionCreatorType<boolean>,
    +showBurger: ActionCreatorType<boolean>,
    +receiveChartType: ActionCreatorType<ChartType>,
  |},
|};

export const appActions: AppActionTypes = createActions({
  app: {
    receiveFirstTime: noop,
    receiveTabIndex: ident,
    modal: {
      show: ident,
      dismissFirst: noop,
    },
    dialog: {
      show: ident,
      dismissFirst: noop,
    },
    menu: {
      showMenu: ident,
      closeMenu: noop,
    },
    receiveFollowMouse: ident,
    receiveFilled: ident,
    receiveTheme: ident,
    receiveCompact: ident,
    showBurger: ident,
    hoverCurrency: ident,
    selectCurrency: ident,
    newCoinForm: {
      changeCoin: ident,
      changeCoinDate: ident,
      changeCoinDateFocus: ident,
    },
    receiveChartType: ident,
  },
});

export const toggleBurger = () => (
  dispatch: DispatchType,
  getState: GetStateType,
) => {
  dispatch(appActions.app.showBurger(!getState().app.showBurger));
  if (Platform.OS === "web") {
    window.dispatchEvent(new Event("resize"));
  }
};
