// @flow
import { handleActions } from "redux-actions";
import type { CurrencySymbolType } from "../api/models";
import { Currency } from "../api/models";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import type { AddCurrencyType } from "../actions/currencies";
import { currenciesActions } from "../actions/currencies";
import type { Reducers } from "./types";

export type CurrenciesStateType = {|
  +available: {
    +[CurrencySymbolType]: Currency,
  },
  +used: {
    +[CurrencySymbolType]: {|
      +hovered: boolean,
      +selected: boolean,
      +hidden: boolean,
    |},
  },
  +add?: ?AddCurrencyType,
|};

export const currenciesDefaults: CurrenciesStateType = deepFreeze({
  available: {},
  used: {},
});

const actionHandlers = {
  ...actionHandler(
    currenciesActions.currencies.hover,
    (state, { payload: { symbol, flag } }) =>
      state.used[symbol] == null
        ? state
        : {
            ...state,
            used: {
              ...state.used,
              [symbol]: {
                ...state.used[symbol],
                hovered: flag,
              },
            },
          },
  ),

  ...actionHandler(
    currenciesActions.currencies.select,
    (state, { payload: { symbol, flag } }) =>
      state.used[symbol] == null
        ? state
        : {
            ...state,
            used: {
              ...state.used,
              [symbol]: {
                ...state.used[symbol],
                selected: flag,
              },
            },
          },
  ),

  ...actionHandler(
    currenciesActions.currencies.hide,
    (state, { payload: { symbol, flag } }) =>
      state.used[symbol] == null
        ? state
        : {
            ...state,
            used: {
              ...state.used,
              [symbol]: {
                ...state.used[symbol],
                selected: flag === true ? false : state.used[symbol].selected,
                hidden: flag,
              },
            },
          },
  ),

  ...actionHandler(currenciesActions.currencies.add, (state, action) => ({
    ...state,
    add: action.payload,
  })),

  ...actionHandler(
    currenciesActions.currencies.receiveCurrencies,
    (state, action) => ({
      ...state,
      // did currencies become unavailable?
      used: Object.keys(state.used).reduce((acc, usedCurrency) => {
        if (
          action.payload.find((currency) => currency.symbol === usedCurrency) !=
          null
        ) {
          acc[usedCurrency] = state.used[usedCurrency];
        }
        return acc;
      }, {}),
      available: action.payload
        .values()
        .filter((currency) => !currency.symbol.startsWith("USD"))
        .reduce(
          (acc, currency) => ({
            ...acc,
            [currency.symbol]: currency,
          }),
          {},
        ),
    }),
  ),

  ...actionHandler(
    currenciesActions.currencies.addCurrency,
    (state, action) => ({
      ...state,
      used: {
        ...state.used,
        [action.payload]: { hidden: false, selected: false, hovered: false },
      },
    }),
  ),

  ...actionHandler(
    currenciesActions.currencies.removeCurrency,
    (state, action) => {
      if (action.payload in state.used) {
        const without = { ...state.used };
        delete without[action.payload];
        return { ...state, used: without };
      }
      return state;
    },
  ),
};

export const currencies: Reducers<CurrenciesStateType> = handleActions(
  actionHandlers,
  currenciesDefaults,
);
