// @flow
import { composeStyles } from "../theme/theme";

export const iconStyle = (iconSize?: number = 28) =>
  composeStyles({
    height: iconSize,
    minHeight: iconSize,
    maxHeight: iconSize,
    width: iconSize,
    minWidth: iconSize,
    maxWidth: iconSize,
  });
