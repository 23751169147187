// @flow
import React from "react";
import JunoIMG from "./logo_path.png";
import "./Landing.scss";

export class Privacy extends React.PureComponent<{||}> {
  render() {
    return (
      <main id="app">
        <section id="head">
          <img
            src="/static/img/security.svg"
            alt="Your Privacy is Paramount!"
            style={{ width: "8rem", height: "auto" }}
          />
          <img src={JunoIMG} alt="JUNO" />
          <h1>Your Privacy is Paramount!</h1>
          <p>Full transparency and plain english without legalese.</p>
        </section>
        <section id="encryption" className="box">
          <h1>User Data and Encryption</h1>
          <p>
            Juno is a completely end-to-end encrypted service! We have no way of
            knowing who you are, what you store in the platform, or what third
            party apps you integrate with.
          </p>
          <h3>How does this work?</h3>
          <p>
            Each device (your phone, your browser, etc.) creates its own
            encryption key and needs to be added to your local keyring before it
            can access the data. If you add new information to your account it
            is encrypted right there on your device and only the devices in your
            keyring will be able to read it. This gives us no way of
            intercepting your information and puts you in complete control.
          </p>
        </section>
        <section id="analytics" className="box">
          <h1>Analytics</h1>
          <p>
            We are a startup, so we need to figure out what our users like about
            our product. However, your privacy is our main concern! That's why
            we refuse to use services like Google Analytics who store your data
            and profit off of it.
          </p>
          <h3>How do we do it?</h3>
          <p>
            We create a completely random and anonymous unique number that is
            stored in your browser's "session storage", meaning it will only
            stay around while you're active on the site. This number cannot be
            used to identify you, it just tells us that events are coming from
            the same session.
          </p>
          <p>
            Our metrics are very simple: Did someone visit the page? Did someone
            add their email? Did someone click the Beta link? etc.
          </p>
          <p>
            We don't log IP addresses, user agents, or any other identifiable
            information.
          </p>
        </section>
        <section id="analytics" className="box">
          <h1>Cookies</h1>
          <p>
            We don't use "cookies" at all. However we store some things in your
            browser's "local storage" and "session storage". These technologies
            allow the website to store some data in your browser and are
            necessary for JUNO to work.
          </p>
          <p>
            We do, however, use the service <a href="CloudFlare">CloudFlare</a>{" "}
            to secure our site and protect ourselves from attacks. CloudFlare
            sets a completely anonymous cookie called "cfduid" that is necessary
            for the service to function. This can't be used to track you. You
            can read more&nbsp;
            <a href="https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-">
              here
            </a>
            , or in their&nbsp;
            <a href="https://www.cloudflare.com/privacypolicy/">
              Privacy Policy
            </a>
            .
          </p>
          <h3>Local Storage</h3>
          <p>
            The local storage is a long term storage that is used to store data
            for repeated use. Here we store your encryption key as well as use
            it as a cache for data loaded from JUNO such as (encrypted) Account
            info, candle data, etc.
          </p>
          <h3>Session Storage</h3>
          <p>
            The session storage is an ephemeral storage that only lives as long
            as your active on the page. As described above we use this to store
            a random anonymous number that helps us to discern analytic events.
          </p>
        </section>
      </main>
    );
  }
}
