// @flow
import { connect } from "react-redux";
import type { StateType } from "../../../../reducers";
import type { DispatchType } from "../../../../actions/types";
import { TokenManagement } from "./TokenManagement";
import { currenciesActions } from "../../../../actions/currencies";
import { refreshCandles } from "../../../../actions/candles";

const mapStateToProps = (state: StateType) => ({
  availableCurrencies:
    state.currencies.available != null ? state.currencies.available : {},
  usedCurrencies: Object.freeze(Object.keys(state.currencies.used).sort()),
  walletCurrencies: [
    ...new Set(
      Object.keys(state.wallets).map(
        (address) => state.wallets[address].symbol,
      ),
    ),
  ],
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onAddCurrency: (currency) => {
    dispatch(currenciesActions.currencies.addCurrency(currency));
    dispatch(refreshCandles());
  },
  onRemoveCurrency: (currency) =>
    dispatch(currenciesActions.currencies.removeCurrency(currency)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TokenManagement);
