// @flow
import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import queryString from "query-string";
import {
  border,
  flexCenter,
  flexCenterDirection,
  flexCenterFlow,
} from "../theme/common";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { Nav } from "./Nav";
import AccountContainer from "./AccountContainer";
import { iconStyle } from "./common";
import { LayoutAware } from "../layout/LayoutAware";
import type { Location, Match, RootPathEnum } from "../router";
import { checkRootPath, Redirect, Route, to } from "../router";
import WALListContainer from "./WALListContainer";
import { hasNotch } from "../layout/Device";

// fix transparent space in pull contexts on native
const hackTop = 2000;
const styles = StyleSheet.create({
  header: composeStyles(flexCenterFlow("row"), {
    justifyContent: "flex-start",
    flex: 1,
    width: "100%",
    marginTop: Platform.OS !== "web" ? -hackTop : 0,
    paddingTop: Platform.OS !== "web" ? hackTop : 0,
    position: "relative",
    alignItems: Platform.OS === "web" ? "center" : "flex-end",
    paddingBottom: Platform.OS === "web" ? 0 : rem(0.5),
  }),
  headerNav: composeStyles(flexCenterFlow("row"), {
    flex: 1,
    overflow: "visible",
  }),
  headerControl: composeStyles(flexCenterFlow("row"), {
    width: 80,
  }),
  walIcon: iconStyle(),
  walButton: composeStyles(flexCenter, {
    marginRight: rem(1),
    marginLeft: rem(1),
  }),
});

type HeaderPropsType = {|
  +match: Match,
  +location: Location,
|};

export class Header extends LayoutAware<HeaderPropsType> {
  render() {
    const { match, location } = this.props;
    const currentModule: RootPathEnum = checkRootPath(
      match.params.module,
      "overview",
    );
    if (currentModule !== match.params.module) {
      return <Redirect to={to(currentModule)} />;
    }
    const queryParams = queryString.parse(location.search);
    const backTo =
      queryParams.redirect instanceof Array
        ? queryParams.redirect[0]
        : queryParams.redirect;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              styles.header,
              { backgroundColor: "headerBackground" },
              border(1, "solid", colors.separator, "Bottom"),
            )}
          >
            <View
              style={composeStyles(flexCenterDirection("row"), {
                width: "100%",
              })}
            >
              <View
                style={
                  this.isLandscape() && hasNotch()
                    ? [styles.headerControl, { paddingLeft: rem(1.5) }]
                    : styles.headerControl
                }
              >
                <Route component={AccountContainer} />
              </View>
              <View style={styles.headerNav}>
                <Nav backTo={backTo} currentModule={currentModule} />
              </View>
              <View
                style={
                  this.isLandscape() && hasNotch()
                    ? [styles.headerControl, { paddingRight: rem(1.5) }]
                    : styles.headerControl
                }
              >
                <WALListContainer />
              </View>
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
