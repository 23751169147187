// @flow
import { connect } from "react-redux";
import type { StateType } from "../../../reducers";

import { Sidebar } from "./Sidebar";
import type { DispatchType } from "../../../actions/types";
import { currenciesActions } from "../../../actions/currencies";
import { appActions } from "../../../actions/app";

const mapStateToProps = (state: StateType) => ({
  availableCurrencies: state.currencies.available,
  currencies: Object.freeze(
    Object.keys(state.currencies.used).sort((a, b) => (a < b ? -1 : 1)),
  ),
  selectedCurrencies: Object.keys(state.currencies.used).filter(
    (symbol) => state.currencies.used[symbol].selected,
  ),
  hiddenCurrencies: Object.keys(state.currencies.used).filter(
    (symbol) => state.currencies.used[symbol].hidden,
  ),
  timeSeries24: state.timeSeries.hours24.USD,
  walletCurrencies: [
    ...new Set(
      Object.keys(state.wallets).map(
        (address) => state.wallets[address].symbol,
      ),
    ),
  ],
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onSelectCurrency: (flag) =>
    dispatch(currenciesActions.currencies.select(flag)),
  onHideCurrency: (flag) => dispatch(currenciesActions.currencies.hide(flag)),
  onHideSidebar: () => {
    dispatch(
      appActions.app.receiveTabIndex({ name: "frame-mobile", tabIndex: 0 }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
