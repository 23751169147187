// @flow
import React from "react";
import { StyleSheet } from "react-native";
import { Button } from "../layout/Button";
import { MenuText } from "./Menu";
import MenuContainer from "./MenuContainer";
import { flexCenterFlow, minMenuItemHeight } from "../theme/common";
import { iconStyle } from "./common";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { Account, WAL } from "../../api/models/index";
import { LayoutAware } from "../layout/LayoutAware";
import {
  Icon,
  FilledDotIcon,
  NotificationActiveIcon,
  NotificationIcon,
  NotificationEmptyIcon,
  UnfilledDotIcon,
  UploadIcon,
  DownloadIcon,
  UndoIcon,
} from "../img/Icon";

const styles = StyleSheet.create({
  collapsedMenu: {
    alignSelf: "flex-end",
  },
  button: composeStyles(flexCenterFlow("row"), {
    flex: 1,
    justifyContent: "flex-start",
    paddingLeft: rem(1),
    paddingRight: rem(1),
    height: minMenuItemHeight,
  }),
  iconLeft: composeStyles(iconStyle(16), {
    marginRight: rem(0.25),
  }),
  iconRight: composeStyles(iconStyle(16), {
    marginLeft: rem(0.25),
  }),
  rootButton: iconStyle(32),
  itemTextStyle: composeStyles(flexCenterFlow("row"), {
    flex: 1,
    textAlign: "center",
  }),
});

type AccountPropsType = {|
  +seen: number,
  +accountWAL?: WAL<Account>,
  +isSyncing: boolean,
  +isUploading: boolean,
  +onSeen: (number) => mixed,
|};

export class WALList extends LayoutAware<AccountPropsType> {
  static defaultProps = {
    seen: -1,
    isSyncing: false,
    isUploading: false,
  };
  _rootIcon = (): Class<Icon> => {
    const { accountWAL, isSyncing, isUploading, seen } = this.props;
    if (isSyncing) {
      return DownloadIcon;
    } else if (isUploading) {
      return UploadIcon;
    } else if (accountWAL == null) {
      return NotificationEmptyIcon;
    } else {
      const last = accountWAL.last();
      if (last != null && last.sequence > seen) {
        return NotificationActiveIcon;
      } else {
        return NotificationIcon;
      }
    }
  };

  _onSeen = () => {
    const { accountWAL, onSeen } = this.props;
    if (accountWAL != null) {
      const last = accountWAL.last();
      if (last != null) {
        onSeen(last.sequence);
      }
    }
  };

  render() {
    const { seen, accountWAL } = this.props;
    const RootIcon = this._rootIcon();
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <MenuContainer
            name="wal"
            onClose={this._onSeen}
            style={{ justifyContent: "flex-end" }}
            collapsedMenuStyle={styles.collapsedMenu}
            root={<RootIcon style={styles.rootButton} />}
          >
            {accountWAL != null &&
              [...accountWAL.values(false)].reverse().map((walEntry, idx) => {
                const Icon =
                  walEntry.sequence > seen ? FilledDotIcon : UnfilledDotIcon;
                return (
                  <Button style={styles.button} key={idx} onPress={() => {}}>
                    <Icon style={styles.iconLeft} fill={colors.textSecondary} />
                    <MenuText style={styles.itemTextStyle}>
                      {Object.keys(walEntry.action).join("; ")
                      // +" " +
                      // walEntry.timestamp.toISOString()}
                      }
                    </MenuText>
                    <UndoIcon style={styles.iconRight} />
                  </Button>
                );
              })}
          </MenuContainer>
        )}
      </ThemeContext.Consumer>
    );
  }
}
