// @flow
import moize from "moize";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { DividerVertical } from "../../layout/Dividers";
import { border, flexCenter, flexCenterFlow } from "../../theme/common";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import { SquareView } from "../../layout/SquareView";
import { ChordDiagram } from "./ChordDiagram";
import { LayeredGraph } from "./LayeredGraph";
import { ValueChangeGraph } from "./ValueChangeGraph";
import { RadialDiagram } from "./RadialDiagram";
import { impactScaledMovementsFromTimeseries } from "../../../models/Movements";
import type { CurrencySymbolType } from "../../../api/models";
import { LayoutAware } from "../../layout/LayoutAware";
import { TimeSeries } from "../../../models/TimeSeries";
import SwipeContainer from "../../layout/swipe";
import {
  ValueChangeGraphIcon,
  StreamGraphIcon,
  LayeredGraphIcon,
} from "../../img/Icon";
import { Button } from "../../layout/Button";
import { iconStyle } from "../../header/common";
import type { ChartType } from "../../../actions/app";
import { chartTypes } from "../../../actions/app";
import { StreamGraph } from "./StreamGraph";
import { currenciesActions } from "../../../actions/currencies";
import { hasNotch } from "../../layout/Device";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import type { SpecificOrientationType } from "../../layout/LayoutAwareBase";

const chartComponents = {
  valuechange: ValueChangeGraph,
  layered: LayeredGraph,
  stream: StreamGraph,
};

const chartIcons = {
  valuechange: ValueChangeGraphIcon,
  layered: LayeredGraphIcon,
  stream: StreamGraphIcon,
};

const mobileLandscapeNotchStyles = moize.simple(
  (hasNotch: boolean, orientation: SpecificOrientationType) => {
    return {
      container: {
        paddingLeft: hasNotch && orientation === "LANDSCAPE-LEFT" ? 30 : 0,
        paddingRight: hasNotch && orientation === "LANDSCAPE-RIGHT" ? 30 : 0,
        position: "relative",
        width: "100%",
        height: "100%",
      },
      child: {
        width: "100%",
        height: "100%",
        position: "relative",
        borderStyle: "solid",
        borderLeftWidth: hasNotch && orientation === "LANDSCAPE-LEFT" ? 1 : 0,
        borderRightWidth: hasNotch && orientation === "LANDSCAPE-RIGHT" ? 1 : 0,
      },
    };
  },
);
const styles = StyleSheet.create({
  mainDesktop: composeStyles(flexCenterFlow("column"), { flex: 1 }),
  horizontalRow: flexCenterFlow("row"),

  verticalRow: flexCenterFlow("column"),
  columnLeft: {
    flex: 1,
    height: "100%",
    paddingLeft: "2%",
  },
  columnRight: {
    flex: 1,
    height: "100%",
    paddingRight: "2%",
  },
  controlsContainer: composeStyles(flexCenter, {
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    left: 0,
    marginLeft: rem(2),
    marginBottom: rem(2),
  }),
  controlsButton: composeStyles(iconStyle(48), {
    borderRadius: 48 / 2,
    marginRight: rem(1),
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 1,
    padding: rem(0.5),
  }),
});

class Container extends React.PureComponent<{|
  +separator?: boolean,
  +vertical?: boolean,
  +children?: React.Node,
  +percent?: number,
|}> {
  render() {
    const {
      percent = 1,
      children,
      vertical = false,
      separator = false,
    } = this.props;
    const $percent = `${percent * 100}%`;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View
            style={themeComposer(
              vertical === true ? styles.verticalRow : styles.horizontalRow,
              {},
              separator ? border(1, "solid", colors.separator, "Bottom") : {},
              {
                width: vertical === true ? $percent : "100%",
                height: vertical === true ? "100%" : $percent,
                position: "relative",
              },
            )}
          >
            {children}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

class ChartControls extends React.PureComponent<{|
  +chartType: ChartType,
  +onSelectChartType: (ChartType) => mixed,
|}> {
  render() {
    const { chartType, onSelectChartType } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.controlsContainer}>
            {chartTypes.map((type) => {
              const Icon = chartIcons[type];
              return (
                <Button
                  key={type}
                  onPress={() => onSelectChartType(type)}
                  style={themeComposer(
                    styles.controlsButton,
                    {
                      backgroundColor:
                        type === chartType ? "juno" : "background",
                    },
                    border(3, "solid", colors.juno),
                  )}
                >
                  <Icon
                    fill={type === chartType ? colors.background : colors.juno}
                  />
                </Button>
              );
            })}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

type OverviewPropsType = {|
  +timeSeries: TimeSeries,
  +timeSeries24: TimeSeries,
  +hoveredCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +selectedCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +compact: boolean,
  +filled: boolean,
  +followMouse: boolean,
  +chartType: ChartType,
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +onHoverCurrency: typeof currenciesActions.currencies.hover,
  +onSelectCurrency: typeof currenciesActions.currencies.select,
  +onSelectChartType: (ChartType) => mixed,
  +onManualTabSelect: (name: string, tabIndex: number) => mixed,
|};

const topRowPercent = 0.55;

export class Overview extends LayoutAware<OverviewPropsType> {
  static defaultProps = {
    compact: true,
    filled: false,
    followMouse: true,
  };

  renderAgnostic() {
    const {
      timeSeries,
      timeSeries24,
      hoveredCurrencies,
      selectedCurrencies,
      filled,
      followMouse,
      availableCurrencies,
      onHoverCurrency,
      onSelectCurrency,
      chartType,
      onSelectChartType,
    } = this.props;
    const ChartComponent = chartComponents[chartType];

    return (
      <View style={styles.mainDesktop}>
        <Container percent={topRowPercent} separator={true}>
          <View style={styles.columnLeft}>
            <SquareView>
              {({ size }) => (
                <ChordDiagram
                  key={size}
                  filled={filled}
                  followMouse={followMouse}
                  hoveredCurrencies={hoveredCurrencies}
                  selectedCurrencies={selectedCurrencies}
                  onHoverCurrency={onHoverCurrency}
                  onSelectCurrency={onSelectCurrency}
                  movements={impactScaledMovementsFromTimeseries(timeSeries24)}
                  originValues={timeSeries24.head()}
                  currentValues={timeSeries24.current()}
                  margin={15}
                  availableCurrencies={availableCurrencies}
                />
              )}
            </SquareView>
          </View>
          <DividerVertical height="75%" />
          <View style={styles.columnRight}>
            <SquareView>
              {({ size }) => (
                <RadialDiagram
                  key={size}
                  filled={filled}
                  followMouse={followMouse}
                  hoveredCurrencies={hoveredCurrencies}
                  selectedCurrencies={selectedCurrencies}
                  onHoverCurrency={onHoverCurrency}
                  onSelectCurrency={onSelectCurrency}
                  timeSeries={timeSeries24}
                />
              )}
            </SquareView>
          </View>
        </Container>
        <Container percent={1 - topRowPercent}>
          <ChartComponent
            filled={filled}
            followMouse={followMouse}
            hoveredCurrencies={hoveredCurrencies}
            selectedCurrencies={selectedCurrencies}
            onHoverCurrency={onHoverCurrency}
            onSelectCurrency={onSelectCurrency}
            timeSeries={timeSeries}
          />
          <ChartControls
            chartType={chartType}
            onSelectChartType={onSelectChartType}
          />
        </Container>
      </View>
    );
  }

  // []     ([])
  // -- >[] ([])
  // []     ([])
  renderTabletPortrait() {
    const {
      timeSeries,
      timeSeries24,
      hoveredCurrencies,
      selectedCurrencies,
      filled,
      followMouse,
      onHoverCurrency,
      onSelectCurrency,
      chartType,
      onSelectChartType,
      onManualTabSelect,
      availableCurrencies,
    } = this.props;
    const ChartComponent = chartComponents[chartType];
    return (
      <SwipeContainer
        name="main-portrait"
        onTabSelect={(tabIndex) => {
          onManualTabSelect("main-portrait", tabIndex);
          onManualTabSelect("main-mobileLandscape", tabIndex);
          onManualTabSelect("main-mobilePortrait", tabIndex + 1); // account for extra view
        }}
      >
        <Container vertical={true}>
          <Container percent={0.5} separator={true}>
            <SquareView>
              {({ size }) => (
                <RadialDiagram
                  key={size} // this is to trigger redrawing on change (safari)
                  filled={filled}
                  followMouse={followMouse}
                  hoveredCurrencies={hoveredCurrencies}
                  selectedCurrencies={selectedCurrencies}
                  onHoverCurrency={onHoverCurrency}
                  onSelectCurrency={onSelectCurrency}
                  timeSeries={timeSeries24}
                />
              )}
            </SquareView>
          </Container>
          <Container percent={0.5}>
            <SquareView>
              {({ size }) => (
                <ChordDiagram
                  key={size} // this is to trigger redrawing on change (safari)
                  filled={filled}
                  followMouse={followMouse}
                  hoveredCurrencies={hoveredCurrencies}
                  selectedCurrencies={selectedCurrencies}
                  onHoverCurrency={onHoverCurrency}
                  onSelectCurrency={onSelectCurrency}
                  movements={impactScaledMovementsFromTimeseries(timeSeries24)}
                  originValues={timeSeries24.head()}
                  currentValues={timeSeries24.current()}
                  margin={15}
                  availableCurrencies={availableCurrencies}
                />
              )}
            </SquareView>
          </Container>
        </Container>
        <Container vertical={true}>
          <ChartComponent
            filled={filled}
            followMouse={followMouse}
            hoveredCurrencies={hoveredCurrencies}
            selectedCurrencies={selectedCurrencies}
            onHoverCurrency={onHoverCurrency}
            onSelectCurrency={onSelectCurrency}
            timeSeries={timeSeries}
          />
          <ChartControls
            chartType={chartType}
            onSelectChartType={onSelectChartType}
          />
        </Container>
      </SwipeContainer>
    );
  }

  renderMobilePortrait() {
    const {
      timeSeries,
      timeSeries24,
      hoveredCurrencies,
      selectedCurrencies,
      compact,
      filled,
      followMouse,
      onHoverCurrency,
      onSelectCurrency,
      chartType,
      onSelectChartType,
      onManualTabSelect,
      availableCurrencies,
    } = this.props;
    if (compact) {
      return this.renderTabletPortrait();
    }
    const padding = { paddingBottom: "20%" };
    const ChartComponent = chartComponents[chartType];
    return (
      <SwipeContainer
        name="main-mobilePortrait"
        onTabSelect={(tabIndex) => {
          onManualTabSelect("main-portrait", Math.max(tabIndex - 1, 0)); // account for extra view
          onManualTabSelect("main-mobileLandscape", Math.max(tabIndex - 1, 0)); // account for extra view
          onManualTabSelect("main-mobilePortrait", tabIndex);
        }}
      >
        <Container vertical={true}>
          <SquareView style={padding}>
            {({ size }) => (
              <RadialDiagram
                key={size}
                filled={filled}
                followMouse={followMouse}
                hoveredCurrencies={hoveredCurrencies}
                selectedCurrencies={selectedCurrencies}
                onHoverCurrency={onHoverCurrency}
                onSelectCurrency={onSelectCurrency}
                timeSeries={timeSeries24}
              />
            )}
          </SquareView>
        </Container>
        <Container vertical={true}>
          <SquareView style={padding}>
            {({ size }) => (
              <ChordDiagram
                key={size}
                filled={filled}
                followMouse={followMouse}
                hoveredCurrencies={hoveredCurrencies}
                selectedCurrencies={selectedCurrencies}
                onHoverCurrency={onHoverCurrency}
                onSelectCurrency={onSelectCurrency}
                movements={impactScaledMovementsFromTimeseries(timeSeries24)}
                originValues={timeSeries24.head()}
                currentValues={timeSeries24.current()}
                margin={15}
                availableCurrencies={availableCurrencies}
              />
            )}
          </SquareView>
        </Container>
        <Container vertical={true}>
          <ChartComponent
            filled={filled}
            followMouse={followMouse}
            hoveredCurrencies={hoveredCurrencies}
            selectedCurrencies={selectedCurrencies}
            onHoverCurrency={onHoverCurrency}
            onSelectCurrency={onSelectCurrency}
            timeSeries={timeSeries}
          />
          <ChartControls
            chartType={chartType}
            onSelectChartType={onSelectChartType}
          />
        </Container>
      </SwipeContainer>
    );
  }

  // []|[] > [   ] ([])
  renderMobileLandscape() {
    const {
      timeSeries,
      timeSeries24,
      hoveredCurrencies,
      selectedCurrencies,
      filled,
      followMouse,
      availableCurrencies,
      onHoverCurrency,
      onSelectCurrency,
      chartType,
      onSelectChartType,
      onManualTabSelect,
    } = this.props;
    const ChartComponent = chartComponents[chartType];
    const { container, child } = mobileLandscapeNotchStyles(
      hasNotch(),
      this.getOrientation(),
    );
    return (
      <SwipeContainer
        name="main-mobileLandscape"
        onTabSelect={(tabIndex) => {
          onManualTabSelect("main-portrait", tabIndex);
          onManualTabSelect("main-mobileLandscape", tabIndex);
          onManualTabSelect("main-mobilePortrait", tabIndex + 1); // account for extra view
        }}
      >
        <Container vertical={true}>
          <Container>
            <View style={styles.columnLeft}>
              <SquareView>
                {({ size }) => (
                  <ChordDiagram
                    key={size}
                    filled={filled}
                    followMouse={followMouse}
                    availableCurrencies={availableCurrencies}
                    hoveredCurrencies={hoveredCurrencies}
                    selectedCurrencies={selectedCurrencies}
                    onHoverCurrency={onHoverCurrency}
                    onSelectCurrency={onSelectCurrency}
                    movements={impactScaledMovementsFromTimeseries(
                      timeSeries24,
                    )}
                    originValues={timeSeries24.head()}
                    currentValues={timeSeries24.current()}
                    margin={15}
                  />
                )}
              </SquareView>
            </View>
            <DividerVertical height="75%" />
            <View style={styles.columnRight}>
              <SquareView>
                {({ size }) => (
                  <RadialDiagram
                    key={size}
                    filled={filled}
                    followMouse={followMouse}
                    hoveredCurrencies={hoveredCurrencies}
                    selectedCurrencies={selectedCurrencies}
                    onHoverCurrency={onHoverCurrency}
                    onSelectCurrency={onSelectCurrency}
                    timeSeries={timeSeries24}
                  />
                )}
              </SquareView>
            </View>
          </Container>
        </Container>
        <Container vertical={true}>
          <View style={container}>
            <ThemeContext.Consumer>
              {({ themeComposer }) => (
                <View
                  style={themeComposer(child, { borderColor: "separator" })}
                >
                  <ChartComponent
                    filled={filled}
                    followMouse={followMouse}
                    hoveredCurrencies={hoveredCurrencies}
                    selectedCurrencies={selectedCurrencies}
                    onHoverCurrency={onHoverCurrency}
                    onSelectCurrency={onSelectCurrency}
                    timeSeries={timeSeries}
                  />
                  <ChartControls
                    chartType={chartType}
                    onSelectChartType={onSelectChartType}
                  />
                </View>
              )}
            </ThemeContext.Consumer>
          </View>
        </Container>
      </SwipeContainer>
    );
  }
}
