// @flow
import uuidv1 from "uuid/v1";
// $FlowFixMe  loaded by worker-loader
import { fromMessage, toMessage } from "./self";
import type {
  ThreadMessageType,
  ThreadReferenceType,
  ThreadResultType,
} from "./types";
import { pgpThread } from "./worker";

export type ThreadType = {|
  // with lower case
  onmessage: (mixed) => void,
  +postMessage: (mixed) => void,
|};

/** react-native-threads doesn't support addEventListener/removeEventListener */
export class ThreadManager<T, R> {
  +_listeners: {
    [ThreadReferenceType]: (ThreadMessageType<ThreadResultType<R>>) => void,
  };
  +_thread: ThreadType;

  constructor(thread: ThreadType) {
    this._listeners = {};
    this._thread = thread;
    this._thread.onmessage = (response) => {
      const message = fromMessage(response);
      const listener = this._listeners[message.reference];
      if (listener == null) {
        console.warn("IndexError: no listener for", message.reference);
      } else {
        if (message.data.resolve == null && message.data.reject == null) {
          delete this._listeners[message.reference];
          throw new Error(
            "ImplementationError: thread result has neither 'resolve' nor 'reject' field",
          );
        }
        listener(message);
      }
    };
  }

  newReference(): ThreadReferenceType {
    return uuidv1();
  }

  addEventListener(
    handler: (ThreadMessageType<ThreadResultType<R>>) => void,
  ): ThreadReferenceType {
    const reference = this.newReference();
    this._listeners[reference] = handler;
    return reference;
  }

  removeEventListener(reference: ThreadReferenceType): void {
    if (reference in this._listeners) {
      delete this._listeners[reference];
    } else {
      console.warn(`Can't remove listener ${reference}. Does not exist.`);
    }
  }

  post(data: T): Promise<R> {
    const promise = new Promise((resolve, reject) => {
      const reference = this.addEventListener((message) => {
        if (message.reference !== reference) {
          reject(
            new Error(
              `ImplementationError: thread references got mixed up ${
                message.reference
              } != ${reference}`,
            ),
          );
        }
        this.removeEventListener(reference);
        const response = message.data;
        if (response.resolve != null) {
          // $FlowFixMe do some defensive type checking?
          resolve(response.resolve);
        } else if (response.reject != null) {
          reject(response.reject);
        }
      });
      this._thread.postMessage(
        toMessage({
          reference,
          data,
        }),
      );
    });
    return promise;
  }
}

export type PGPSignCommandType = {|
  +plain: string,
  +signWith: string,
|};
export type PGPSignResultType = {|
  +cipher: string,
|};

export type PGPEncryptCommandType = {|
  +plain: string,
  +encryptFor: $ReadOnlyArray<string>,
  +signWith: string,
|};
export type PGPEncryptResultType = {|
  +cipher: string,
|};

export type PGPVerifyCommandType = {|
  +cipher: string,
  +verifyWith: $ReadOnlyArray<string>,
|};
export type PGPVerifyResultType = {|
  +plain: string,
|};

export type PGPDecryptCommandType = {|
  +cipher: string,
  +decryptWith: string,
  +verifyWith: $ReadOnlyArray<string>,
|};
export type PGPDecryptResultType = {|
  +plain: string,
|};

export type PGPCommandType =
  | PGPSignCommandType
  | PGPVerifyCommandType
  | PGPEncryptCommandType
  | PGPDecryptCommandType;
export type PGPResultType =
  | PGPSignResultType
  | PGPVerifyResultType
  | PGPEncryptResultType
  | PGPDecryptResultType;

// $FlowFixMe  loaded by worker-loader
export const pgpThreadManager: ThreadManager<
  PGPCommandType,
  PGPResultType,
> = new ThreadManager(pgpThread);
