// @flow
import React from "react";
import { connect } from "react-redux";
import { Dimensions, StatusBar, StyleSheet, View } from "react-native";
import deepEqual from "fast-deep-equal";
import AppFrameContainer from "./appFrame";
import HeaderContainer from "./header";
import MenuOverlayContainer from "./header/MenuOverlayContainer";
import ModalContainer from "./modal";
import DialogContainer from "./dialog";
import type { AvailableThemesType } from "./theme/theme";
import {
  composeStyles,
  headerHeight,
  loadTheme,
  ThemeContext,
} from "./theme/theme";
import { flexCenter } from "./theme/common";
import type { StateType } from "../reducers";
import { LayoutAware } from "./layout/LayoutAware";
import type { Match } from "./router";
import { Route } from "./router";
import { PullToRefresh } from "./layout/pullToRefresh";
import { refreshCandles } from "../actions/candles";
import type { DispatchType } from "../actions/types";
import { appActions } from "../actions/app";

const styles = StyleSheet.create({
  app: composeStyles(flexCenter, {
    flexDirection: "column",
    width: "100%",
    flex: 1,
    overflow: "hidden",
    position: "relative",
  }),
  header: composeStyles(flexCenter, {
    width: "100%",
    zIndex: 99,
  }),
  main: composeStyles(flexCenter, {
    flex: 1,
    width: "100%",
  }),
  menuOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

type ThemedAppPropsType = {|
  +theme: AvailableThemesType | "auto",
  +loading: boolean,
  +match: Match,
  +shouldHideSidebar: boolean,
  +onHideSidebar: () => mixed,
  +onCloseMenu: () => mixed,
  +onRefresh: () => mixed,
|};
class ThemedApp extends LayoutAware<ThemedAppPropsType> {
  componentDidUpdate(prevProps: ThemedAppPropsType) {
    if (!deepEqual(this.props.match, prevProps.match)) {
      this.props.onHideSidebar();
      this.props.onCloseMenu();
    }
  }

  render() {
    const { theme, loading, onRefresh } = this.props;
    return (
      <ThemeContext.Provider value={loadTheme(theme)}>
        <ThemeContext.Consumer>
          {({ themeComposer, theme }) => (
            <View
              style={themeComposer(
                styles.app,
                { backgroundColor: "background" },
                {
                  height: Dimensions.get("window").height,
                  maxHeight: Dimensions.get("window").height,
                },
              )}
            >
              <StatusBar
                barStyle={theme === "light" ? "dark-content" : "light-content"}
                animated
              />
              <View
                style={[
                  styles.header,
                  { height: headerHeight(this.isPortrait()) },
                ]}
              >
                <PullToRefresh
                  showRefreshIndicator={true}
                  isRefreshing={loading}
                  onRefresh={onRefresh}
                >
                  <View
                    style={[
                      styles.header,
                      { height: headerHeight(this.isPortrait()) },
                    ]}
                  >
                    <Route component={HeaderContainer} />
                  </View>
                </PullToRefresh>
              </View>
              <View style={styles.main}>
                <Route component={AppFrameContainer} />
              </View>
              <ModalContainer />
              <DialogContainer />
              <View
                style={[
                  styles.menuOverlay,
                  { top: headerHeight(this.isPortrait()) },
                ]}
                pointerEvents="box-none"
              >
                <MenuOverlayContainer />
              </View>
            </View>
          )}
        </ThemeContext.Consumer>
      </ThemeContext.Provider>
    );
  }
}
const mapStateToProps = (state: StateType) => ({
  theme: state.app.theme,
  loading: state.candles.loading,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onCloseMenu: () => dispatch(appActions.app.menu.closeMenu()),
  onHideSidebar: () =>
    dispatch(
      appActions.app.receiveTabIndex({ name: "frame-mobile", tabIndex: 0 }),
    ),
  onRefresh: () => {
    dispatch(refreshCandles());
  },
});

export const ThemedAppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemedApp);
