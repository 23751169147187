// @flow
import React from "react";
import { Dimensions } from "react-native";
import { mobileSize, tabletSize } from "./Device";

export type SpecificOrientationType =
  | "LANDSCAPE"
  | "LANDSCAPE-LEFT"
  | "LANDSCAPE-RIGHT"
  | "PORTRAIT"
  | "PORTRAITUPSIDEDOWN"
  | "UNKNOWN";

export class LayoutAwareBase<PT = {||}, ST = {||}> extends React.PureComponent<
  PT,
  ST,
> {
  updating: boolean = false;
  mounted: boolean = false;

  _forceUpdate = () => {
    if (!this.updating && typeof window !== "undefined") {
      this.updating = true;
      window.requestAnimationFrame(() => {
        this.mounted && this.forceUpdate();
        this.updating = false;
      });
    }
  };

  componentDidMount() {
    this.mounted = true;
    Dimensions.addEventListener("change", this._forceUpdate);
  }

  componentWillUnmount() {
    this.mounted = false;
    Dimensions.removeEventListener("change", this._forceUpdate);
  }

  isLandscape(): boolean {
    const { height, width } = Dimensions.get("window");
    return width > height;
  }

  isPortrait(): boolean {
    return !this.isLandscape();
  }

  getOrientation(): SpecificOrientationType {
    if (this.isLandscape()) {
      return "LANDSCAPE";
    } else {
      return "PORTRAIT";
    }
  }

  isMobile(): boolean {
    const { height, width } = Dimensions.get("window");
    return (
      (this.isLandscape() &&
        (width <= mobileSize.major || height <= mobileSize.minor)) ||
      (this.isPortrait() &&
        (height <= mobileSize.major || width <= mobileSize.minor))
    );
  }

  isTablet(): boolean {
    const { height, width } = Dimensions.get("window");
    return (
      (this.isLandscape() &&
        (width <= tabletSize.major || height <= tabletSize.minor)) ||
      (this.isPortrait() &&
        (height <= tabletSize.major || width <= tabletSize.minor))
    );
  }

  render() {
    if (this.isMobile()) {
      return this.renderMobile();
    } else if (this.isTablet()) {
      return this.renderTablet();
    } else {
      if (this.isLandscape()) {
        return this.renderLandscape();
      } else {
        return this.renderPortrait();
      }
    }
  }

  renderAgnostic() {
    throw new Error(
      "NotImplementedError: override at least one layouting render method",
    );
  }

  renderMobile() {
    if (this.isLandscape()) {
      return this.renderMobileLandscape();
    } else {
      return this.renderMobilePortrait();
    }
  }

  renderTablet() {
    if (this.isLandscape()) {
      return this.renderTabletLandscape();
    } else {
      return this.renderTabletPortrait();
    }
  }

  renderLandscape() {
    return this.renderAgnostic();
  }

  renderTabletLandscape() {
    return this.renderLandscape();
  }

  renderMobileLandscape() {
    return this.renderTabletLandscape();
  }

  renderPortrait() {
    return this.renderAgnostic();
  }

  renderTabletPortrait() {
    return this.renderPortrait();
  }

  renderMobilePortrait() {
    return this.renderTabletPortrait();
  }
}
