// @flow
import openpgp, { key } from "../../threads/openpgp";
import moize from "moize";
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { List } from "./list";
import {
  notNull,
  validateBoolean,
  validateDate,
  validateListOf,
  ValidationError,
  valueToString,
} from "./validate";
import { pgpThreadManager } from "../../threads/threads";

export type KeyType = "public" | "private";

export type KeyIdType = string;
export type KeyFingerprintType = string;
export type KeyUserIdType = string;

const uint8ToHex = (uint8: Uint8Array) => {
  return Array.from(uint8)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

const findIndex = async <T>(
  predicate: (T) => Promise<boolean> | boolean,
  arr: $ReadOnlyArray<T>,
): Promise<number> => {
  for (let idx = 0; idx < arr.length; idx++) {
    if ((await predicate(arr[idx])) === true) {
      return idx;
    }
  }
  return -1;
};

export class Key extends Model<Key> {
  +key: string;

  static emptyPrivate = new Key({
    key: `-----BEGIN PGP PRIVATE KEY BLOCK-----
    
-----END PGP PRIVATE KEY BLOCK-----`,
  });

  static emptyPublic = new Key({
    key: `-----BEGIN PGP PUBLIC KEY BLOCK-----
    
-----END PGP PUBLIC KEY BLOCK-----`,
  });

  constructor(json: ObjectSubsetType<Key>) {
    super(json);
    if (json.key == null) {
      throw new Error(`ValidationError: no key provided`);
    }
    const key = json.key;
    this.key =
      key.startsWith("-----BEGIN") && key.endsWith("BLOCK-----")
        ? key
        : Key.cleanupKey(key).key;
  }

  type(): KeyType {
    return Key.keyType(this.key);
  }

  isPublic(): boolean {
    return this.type() === "public";
  }

  isPrivate(): boolean {
    return this.type() === "private";
  }

  async fingerprint(): Promise<KeyFingerprintType> {
    const parsed = await pgpParseOne(this);
    return uint8ToHex(parsed.primaryKey.fingerprint);
  }

  async keyid(): Promise<KeyIdType> {
    const parsed = await pgpParseOne(this);
    return parsed.primaryKey.keyid.toHex();
  }

  async userid(): Promise<KeyUserIdType> {
    const parsed = await pgpParseOne(this);
    const userId = parsed.users[0].userId;
    return `${userId.userid}${userId.email !== "" ? ` <${userId.email}>` : ""}`;
  }

  async timestamp(): Promise<Date> {
    const parsed = await pgpParseOne(this);
    return parsed.primaryKey.created;
  }

  /**
   * @return compress version of key with comments stripped
   */
  compress(): string {
    return Key.compressKey(this.key);
  }

  static keyType(key: string): KeyType {
    const lines = key
      .trim()
      .split("\n")
      .map((line) => line.trim());
    for (let line of lines) {
      if (line === "") {
        continue;
      }
      if (line.toUpperCase() === "-----BEGIN PGP PUBLIC KEY BLOCK-----") {
        return "public";
      } else if (
        line.toUpperCase() === "-----BEGIN PGP PRIVATE KEY BLOCK-----"
      ) {
        return "private";
      } else {
        break;
      }
    }
    throw new Error(
      `ValueError: provided string has no key block. ${valueToString(key)}`,
    );
  }

  static compressKey(key: string): string {
    const lines = key
      .trim()
      .split("\n")
      .map((line) => line.trim());

    let start = -1,
      stop = -1;
    for (let idx = 0; idx < lines.length; idx++) {
      if (lines[idx] === "") {
        start = idx + 1;
      } else if (
        lines[idx] ===
        `-----END PGP ${Key.keyType(key).toUpperCase()} KEY BLOCK-----`
      ) {
        stop = idx;
        break;
      }
    }
    if (start < 0 || stop < 0 || start >= stop) {
      throw new Error("Parsing error could not read key string " + key);
    }
    return lines.slice(start, stop).join("\n");
  }

  static uncompressKey(compressed: string, type: KeyType): Key {
    const typeStr = type.toUpperCase();
    return new Key({
      key: `-----BEGIN PGP ${typeStr} KEY BLOCK-----

${compressed}
-----END PGP ${typeStr} KEY BLOCK-----`,
    });
  }

  static cleanupKey(key: string): Key {
    return Key.uncompressKey(Key.compressKey(key), Key.keyType(key));
  }
}

const pgpParse = moize.promise(
  async (theKey: Key) => (await key.readArmored(theKey.key)).keys,
);

const pgpParseOne = async (theKey: Key) => (await pgpParse(theKey))[0];

export class KeyPair extends Model<KeyPair> {
  +publicKey: Key;
  +privateKey: Key;

  static empty = new KeyPair({
    publicKey: Key.emptyPublic,
    privateKey: Key.emptyPrivate,
  });

  constructor(keyPair: ObjectSubsetType<KeyPair>) {
    const { publicKey, privateKey } = keyPair;
    super(keyPair);
    if (publicKey == null || !publicKey.isPublic()) {
      throw new ValidationError(publicKey, "public key");
    }
    if (privateKey == null || !privateKey.isPrivate()) {
      throw new ValidationError(privateKey, "private key");
    }
    this.publicKey = publicKey;
    this.privateKey = privateKey;
  }

  static from(publicKey: string, privateKey: string) {
    return new KeyPair({
      publicKey: new Key({ key: publicKey }),
      privateKey: new Key({ key: privateKey }),
    });
  }

  static async generateKeyPair(userid: string): Promise<KeyPair> {
    const options = {
      userIds: [{ name: userid }],
      curve: "p256",
    };
    const key = await openpgp.generateKey(options);
    const publicKey = new Key({ key: key.publicKeyArmored });
    const privateKey = new Key({ key: key.privateKeyArmored });
    return new KeyPair({ publicKey, privateKey });
  }
}

export class KeyTrust extends Model<KeyTrust> {
  +key: Key | KeyIdType | KeyFingerprintType;
  +trusted: boolean;
  +timestamp: Date;

  constructor(json: ObjectSubsetType<KeyTrust>) {
    super(json);
    if (json.key == null) {
      throw new ValidationError(json.key, "key cannot be null");
    }
    const key = json.key;
    if (
      typeof key === "string" &&
      (key.length < 16 ||
        (key.trim().startsWith("-----BEGIN") &&
          !key.trim().startsWith("-----BEGIN PGP PUBLIC KEY BLOCK-----")))
    ) {
      throw new ValidationError(key, "keyid, fingerprint or public key");
    } else if (
      key instanceof Key &&
      (!key.isPublic() || json.trusted !== true)
    ) {
      if (!key.isPublic()) {
        throw new ValidationError(key, "only public keys in trusted keys");
      }
      if (json.trusted !== true) {
        throw new ValidationError(
          key,
          "only fingerprint or id for untrusted keys",
        );
      }
    }
    this.key =
      key instanceof Key
        ? key
        : key.startsWith("-----BEGIN")
        ? new Key({ key })
        : key;
    this.trusted = validateBoolean(json.trusted);
    this.timestamp = validateDate(json.timestamp);
  }

  static from(
    key: $PropertyType<KeyTrust, "key"> | string,
    trusted: boolean,
  ): KeyTrust {
    return new KeyTrust({
      key,
      trusted,
      timestamp: new Date(),
    });
  }
}

export type KeyIdentifierType =
  | Key
  | KeyPair
  | KeyIdType
  | KeyFingerprintType
  | KeyUserIdType;

export class KeyRing extends List<KeyTrust, KeyRing> {
  +keyRing: $ReadOnlyArray<KeyTrust>;

  constructor(json: ObjectSubsetType<KeyRing>) {
    super("keyRing");
    this.keyRing = validateListOf(
      notNull((keyTrust) => new KeyTrust(keyTrust)),
      json.keyRing,
    )
      .slice(0)
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  }

  async trust(key: Key): Promise<KeyRing> {
    return this.merge(KeyRing.from(KeyTrust.from(key, true)));
  }

  async distrust(keyLike: $PropertyType<KeyTrust, "key">): Promise<KeyRing> {
    let keyIdentifier = keyLike;
    if (keyLike instanceof Key) {
      keyIdentifier = await keyLike.fingerprint();
    }
    return this.merge(KeyRing.from(KeyTrust.from(keyIdentifier, false)));
  }

  async merge(other: KeyRing): Promise<KeyRing> {
    let newKeyRing = this.keyRing.slice(0);
    for (let otherKeyTrust of other.keyRing) {
      const found = await this.get(otherKeyTrust.key);
      if (found == null) {
        newKeyRing.push(otherKeyTrust);
        continue;
      }
      const [thisKeyTrust, idx] = found;
      if (
        otherKeyTrust.timestamp.getTime() > thisKeyTrust.timestamp.getTime() ||
        (otherKeyTrust.timestamp.getTime() ===
          thisKeyTrust.timestamp.getTime() &&
          otherKeyTrust.trusted === false &&
          thisKeyTrust.trusted === true)
      ) {
        newKeyRing.splice(idx, 1, otherKeyTrust);
      }
    }
    newKeyRing = newKeyRing.sort(
      (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
    );
    return this.update({ keyRing: newKeyRing });
  }

  async get(
    keyIdentifier: KeyIdentifierType,
    includeUntrusted: boolean = true,
  ): Promise<?[KeyTrust, number]> {
    let found: number = -1;
    if (keyIdentifier instanceof Key) {
      const key: Key = keyIdentifier;
      // todo secure enough?
      found = await findIndex(
        async ({ key: _key }) =>
          typeof _key === "string"
            ? (await key.fingerprint()).endsWith(_key)
            : (await _key.fingerprint()) === (await key.fingerprint()),
        this.keyRing,
      );
    } else if (keyIdentifier instanceof KeyPair) {
      const key = keyIdentifier.publicKey;
      // todo secure enough?
      found = await findIndex(
        async ({ key: _key }) =>
          typeof _key === "string"
            ? (await key.fingerprint()).endsWith(_key)
            : (await _key.fingerprint()) === (await key.fingerprint()),
        this.keyRing,
      );
    } else {
      console.assert(typeof keyIdentifier === "string");
      if (keyIdentifier.includes("<")) {
        const userId: string = keyIdentifier;
        found = await findIndex(
          async ({ key: _key }) =>
            typeof _key !== "string" &&
            (await _key.userid()).trim() === userId.trim(),
          this.keyRing,
        );
      } else if (keyIdentifier.length === 16) {
        const keyId: string = keyIdentifier;
        found = await findIndex(
          async ({ key: _key }) =>
            typeof _key === "string"
              ? _key.endsWith(keyId)
              : (await _key.keyid()) === keyId,
          this.keyRing,
        );
      } else {
        const fingerprint: string = keyIdentifier;
        found = await findIndex(
          async ({ key: _key }) =>
            typeof _key === "string"
              ? fingerprint.endsWith(_key)
              : (await _key.fingerprint()) === fingerprint,
          this.keyRing,
        );
      }
    }
    if (found >= 0) {
      const keyTrust = this.keyRing[found];
      if (includeUntrusted || keyTrust.trusted) {
        return [keyTrust, found];
      }
    }
    return null;
  }

  async isTrusted(keyIdentifier: KeyIdentifierType): Promise<boolean> {
    return (await this.get(keyIdentifier, false)) != null;
  }

  trusted(): $ReadOnlyArray<Key> {
    return this.filter(({ trusted }) => trusted === true)
      .values()
      .map(({ key }) => {
        if (key instanceof Key) {
          return key;
        } else {
          throw new Error(`AssertionError: ${key} is not a Key instance`);
        }
      });
  }

  untrusted(): $ReadOnlyArray<KeyIdentifierType> {
    return this.filter(({ trusted }) => trusted !== true)
      .values()
      .map((keyTrust) => keyTrust.key);
  }

  static from(...keyTrusts: $ReadOnlyArray<KeyTrust>): KeyRing {
    return new KeyRing({
      keyRing: keyTrusts,
    });
  }

  static empty = KeyRing.from();
}

export class Vault extends Model<Vault> {
  static _stringify = (data) => Promise.resolve(JSON.stringify(data));
  static _parse = (data) => Promise.resolve(JSON.parse(data));

  +keyPair: KeyPair;
  +keyRing: KeyRing;
  // +trusted: $ReadOnlyArray<Key>;
  // +untrusted: $ReadOnlyArray<string>;

  constructor(json: ObjectSubsetType<Vault>) {
    super(json);
    this.keyPair =
      json.keyPair instanceof KeyPair
        ? json.keyPair
        : new KeyPair(json.keyPair);
    if (json.keyRing == null) {
      this.keyRing = KeyRing.from(KeyTrust.from(this.keyPair.publicKey, true));
    } else {
      this.keyRing =
        json.keyRing instanceof KeyRing
          ? json.keyRing
          : new KeyRing(json.keyRing);
      this.keyRing.isTrusted(this.keyPair.publicKey).then((trusts) => {
        trusts !== true &&
          console.error(this, "Provided keyring doesn't trust own vault key!");
      });
    }
  }

  static empty = new Vault({ keyPair: KeyPair.empty });

  static configStringify(stringify: (mixed) => Promise<string>) {
    Vault._stringify = stringify;
  }

  static configParse(parse: <T>(string) => Promise<T>) {
    Vault._parse = parse;
  }

  static async toKeyLike(
    keyIdentifier: KeyPair | Vault | KeyIdentifierType,
  ): Promise<$PropertyType<KeyTrust, "key">> {
    if (keyIdentifier instanceof Vault) {
      keyIdentifier = await keyIdentifier.keyPair.publicKey.fingerprint();
    } else if (keyIdentifier instanceof KeyPair) {
      keyIdentifier = await keyIdentifier.publicKey.fingerprint();
    } else if (keyIdentifier instanceof Key) {
      keyIdentifier = await keyIdentifier.fingerprint();
    } else if (typeof keyIdentifier !== "string") {
      throw new Error("ValueError: keyIdentifier not a keyLike");
    }
    return keyIdentifier;
  }

  async distrust(
    keyIdentifier: KeyPair | Vault | KeyIdentifierType,
  ): Promise<Vault> {
    if (await this.isUntrusted(keyIdentifier)) {
      return this;
    }
    return this.update({
      keyRing: await this.keyRing.distrust(
        await Vault.toKeyLike(keyIdentifier),
      ),
    });
  }

  static async toKey(
    keyIdentifier: KeyPair | Vault | Key | string,
  ): Promise<Key> {
    if (keyIdentifier instanceof Vault) {
      keyIdentifier = await keyIdentifier.keyPair.publicKey;
    } else if (keyIdentifier instanceof KeyPair) {
      keyIdentifier = await keyIdentifier.publicKey;
    } else if (typeof keyIdentifier === "string") {
      keyIdentifier = new Key({ key: keyIdentifier });
    } else if (!(keyIdentifier instanceof Key)) {
      throw new Error(
        `ValueError: keyIdentifier ${JSON.stringify(
          keyIdentifier,
        )} not parseable`,
      );
    }
    return keyIdentifier;
  }

  async trust(keyIdentifier: KeyPair | Vault | Key | string): Promise<Vault> {
    if (await this.isTrusted(keyIdentifier)) {
      return this;
    }
    return this.update({
      keyRing: await this.keyRing.trust(await Vault.toKey(keyIdentifier)),
    });
  }

  async isTrusted(
    keyIdentifier: KeyPair | Vault | KeyIdentifierType,
  ): Promise<boolean> {
    if (keyIdentifier instanceof Vault) {
      keyIdentifier = await keyIdentifier.keyPair.publicKey;
    } else if (keyIdentifier instanceof KeyPair) {
      keyIdentifier = await keyIdentifier.publicKey;
    }
    return await this.keyRing.isTrusted(keyIdentifier);
  }

  async isUntrusted(
    keyIdentifier: KeyPair | Vault | KeyIdentifierType,
    missingIsUntrusted: boolean = false,
  ): Promise<boolean> {
    if (keyIdentifier instanceof Vault) {
      keyIdentifier = await keyIdentifier.keyPair.publicKey;
    } else if (keyIdentifier instanceof KeyPair) {
      keyIdentifier = await keyIdentifier.publicKey;
    }
    const found = await this.keyRing.get(keyIdentifier);
    if (missingIsUntrusted && found == null) {
      return true;
    } else if (found != null && found[0].trusted !== true) {
      return true;
    }
    return false;
  }

  async trustedFingerprints() {
    return Promise.all(
      this.keyRing.trusted().map(async (key) => await key.fingerprint()),
    );
  }

  static async signWith<T: {} | string>(
    data: T,
    withKey: KeyPair | Key,
    stringify?: (mixed) => Promise<string> = Vault._stringify,
  ) {
    if (withKey instanceof Key && withKey.isPublic()) {
      throw new Error("ValueError: Cannot sign with a public key");
    }
    const signWith =
      withKey instanceof KeyPair ? withKey.privateKey.key : withKey.key;
    const pgpResult = await pgpThreadManager.post({
      plain: await stringify(data),
      signWith,
    });
    if (pgpResult.cipher == null) {
      throw new Error(`AssertionError: no cipher in result`);
    }
    return pgpResult.cipher;
  }

  /** only sign some data and pack it as a cipher message*/
  async sign<T: {} | string>(
    data: T,
    stringify?: (mixed) => Promise<string> = Vault._stringify,
  ): Promise<string> {
    return await Vault.signWith(data, this.keyPair, stringify);
  }

  /** encrypt and sign data and pack it as a cipher message*/
  async box<T: {} | string>(
    data: T,
    stringify?: (mixed) => Promise<string> = Vault._stringify,
  ): Promise<string> {
    const signWith = this.keyPair.privateKey.key;
    const encryptFor = this.keyRing.trusted().map((key) => key.key);
    const pgpResult = await pgpThreadManager.post({
      plain: await stringify(data),
      signWith,
      encryptFor,
    });
    if (pgpResult.cipher == null) {
      throw new Error(`AssertionError: no cipher in result`);
    }
    return pgpResult.cipher;
  }

  static async verifyWith<T: {}>(
    cipher: string,
    withKey: KeyPair | Key | $ReadOnlyArray<Key>,
    parse?: (string) => Promise<T> = Vault._parse,
  ): Promise<T> {
    if (withKey instanceof Key && !withKey.isPublic()) {
      throw new Error("ValueError: Cannot verify with a private key");
    }
    if (
      !(withKey instanceof KeyPair) && //flow
      !(withKey instanceof Key) && //flow
      withKey instanceof Array &&
      withKey.some((key) => !key.isPublic())
    ) {
      throw new Error("ValueError: Cannot verify with a private key");
    }
    const verifyWith =
      withKey instanceof KeyPair
        ? [withKey.publicKey.key]
        : withKey instanceof Key
        ? [withKey.key]
        : withKey.map((key) => key.key);
    const pgpResult = await pgpThreadManager.post({
      cipher,
      verifyWith,
    });
    if (pgpResult.plain == null) {
      throw new Error(`AssertionError: no plaintext in result`);
    }
    return await parse(pgpResult.plain);
  }

  async verify<T: {}>(
    cipher: string,
    parse?: (string) => Promise<T> = Vault._parse,
  ): Promise<T> {
    return await Vault.verifyWith(cipher, this.keyRing.trusted(), parse);
  }

  /** verify and decrypt cipher and construct original object */
  async unbox<T: {}>(
    cipher: string,
    parse?: (string) => Promise<T> = Vault._parse,
  ): Promise<T> {
    const decryptWith = this.keyPair.privateKey.key;
    const verifyWith = this.keyRing.trusted().map((key) => key.key);
    const pgpResult = await pgpThreadManager.post({
      cipher,
      decryptWith,
      verifyWith,
    });
    if (pgpResult.plain == null) {
      throw new Error(`AssertionError: no plaintext in result`);
    }
    return await parse(pgpResult.plain);
  }

  serialize(): string {
    return [
      this.keyPair.publicKey,
      this.keyPair.privateKey,
      ...this.keyRing.trusted(),
    ]
      .map(({ key }) => key)
      .concat(
        this.keyRing.untrusted().map((untrusted) => {
          if (typeof untrusted !== "string") {
            throw new Error(
              "ImplementationError: untrusted needs to be string, fingerprint, keyid",
            );
          }
          return "!" + untrusted;
        }),
      )
      .join("\n");
  }

  static async deserialize(vaultText: string): Promise<Vault> {
    const { publicKey, privateKey, trusted, untrusted } = vaultText
      .split("\n")
      .map((line) => line.trim())
      .reduce(
        (acc, line) => {
          if (acc.mode === "searchPublic") {
            if (line === "") {
              return acc;
            } else if (line === "-----BEGIN PGP PUBLIC KEY BLOCK-----") {
              acc.mode = "public";
              acc.publicKey = line + "\n";
              return acc;
            }
          } else if (acc.mode === "public") {
            if (line === "-----END PGP PUBLIC KEY BLOCK-----") {
              acc.mode = "searchPrivate";
            }

            acc.publicKey += line + "\n";
            return acc;
          } else if (acc.mode === "searchPrivate") {
            if (line === "") {
              return acc;
            } else if (line === "-----BEGIN PGP PRIVATE KEY BLOCK-----") {
              acc.mode = "private";
              acc.privateKey = line + "\n";
              return acc;
            }
          } else if (acc.mode === "private") {
            if (line === "-----END PGP PRIVATE KEY BLOCK-----") {
              acc.mode = "searchTrust";
            }

            acc.privateKey += line + "\n";
            return acc;
          } else if (acc.mode === "searchTrust") {
            if (line === "") {
              return acc;
            } else if (line.startsWith("!")) {
              acc.untrusted.push(line.substr(1));
              return acc;
            } else if (line === "-----BEGIN PGP PUBLIC KEY BLOCK-----") {
              acc.mode = "trust";
              acc.trusted.push(line + "\n");
              return acc;
            }
          } else if (acc.mode === "trust") {
            if (line === "-----END PGP PUBLIC KEY BLOCK-----") {
              acc.mode = "searchTrust";
            }

            acc.trusted[acc.trusted.length - 1] += line + "\n";
            return acc;
          }
          throw new Error("FormatError: can't read key file.");
        },
        ({
          mode: "searchPublic",
          publicKey: "",
          privateKey: "",
          trusted: [],
          untrusted: [],
        }: {|
          mode: string,
          publicKey: string,
          privateKey: string,
          trusted: string[],
          untrusted: string[],
        |}),
      );
    let keyRing = KeyRing.empty;
    for (let trustedKey of trusted) {
      keyRing = await keyRing.trust(trustedKey);
    }
    for (let untrustedKey of untrusted) {
      keyRing = await keyRing.distrust(untrustedKey);
    }
    const vault = new Vault({
      keyPair: KeyPair.from(publicKey, privateKey),
      keyRing,
    });
    // check if everything worked
    await vault.keyPair.privateKey.fingerprint();
    await vault.trustedFingerprints();
    if (!(await vault.isTrusted(vault.keyPair.publicKey))) {
      throw new ValidationError(vault, "vault does not trust itself!");
    }
    return vault;
  }
}
