// @flow
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import {
  notNull,
  validateListOf,
  validateString,
  ValidationError,
} from "./validate";
import { List } from "./list";

export type CurrencySymbolType = string;
export type CurrencyTypeType = string;

export const validateCurrencySymbol = (value: ?mixed): CurrencySymbolType => {
  const maybeSymbol: string = validateString(value);
  if (maybeSymbol.length === 0) {
    throw ValidationError(value, "currency");
  } else {
    return maybeSymbol.toUpperCase();
  }
};

export class Currency extends Model<Currency> {
  +symbol: CurrencySymbolType;
  +label: string;
  +type: string;

  constructor(json: ObjectSubsetType<Currency>) {
    super(json);
    this.symbol = validateCurrencySymbol(json.symbol).toUpperCase();
    this.label = validateString(json.label);
    this.type = validateString(json.type);
  }
}

export class CurrencyList extends List<Currency, CurrencyList> {
  +currencies: $ReadOnlyArray<Currency>;

  constructor(json: ObjectSubsetType<CurrencyList>) {
    super("currencies");
    this.currencies = validateListOf(
      notNull((currency) => new Currency(currency)),
      json.currencies,
    );
  }
}
