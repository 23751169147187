// @flow
import { handleActions } from "redux-actions";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import type { MenuType } from "../actions/app";
import { appActions } from "../actions/app";
import type { Reducers } from "./types";

export type MenuStateType = {|
  +menu?: MenuType,
|};

export const menuDefaults: MenuStateType = deepFreeze({ menu: undefined });

const actionHandlers = {
  ...actionHandler(appActions.app.menu.showMenu, (state, action) => ({
    menu: action.payload,
  })),

  ...actionHandler(appActions.app.menu.closeMenu, (state, action) => ({
    menu: undefined,
  })),
};

export const menu: Reducers<MenuStateType> = handleActions(
  actionHandlers,
  menuDefaults,
);
