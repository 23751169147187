// @flow
import { createActions } from "redux-actions";
import { ident } from "react-redux-flow-tools";
import type { ActionCreatorType, DispatchType } from "./types";
import type { CurrencySymbolType } from "../api/models";
import { CurrencyList } from "../api/models";
import { getClient } from "../api";

type CurrencyFlag = {|
  symbol: CurrencySymbolType,
  flag: boolean,
|};

export type AddCurrencyType = {|
  +coinInput: string,
  +acquiredDate: Date,
  +dateFocused: boolean,
|};

type CurrenciesActionTypes = {|
  +currencies: {|
    +receiveCurrencies: ActionCreatorType<CurrencyList>,
    +addCurrency: ActionCreatorType<CurrencySymbolType>,
    +removeCurrency: ActionCreatorType<CurrencySymbolType>,
    +hover: ActionCreatorType<CurrencyFlag>,
    +select: ActionCreatorType<CurrencyFlag>,
    +hide: ActionCreatorType<CurrencyFlag>,
    +add: ActionCreatorType<?AddCurrencyType>,
  |},
|};

export const currenciesActions: CurrenciesActionTypes = createActions({
  currencies: {
    receiveCurrencies: ident,
    addCurrency: ident,
    removeCurrency: ident,
    hover: ident,
    select: ident,
    hide: ident,
    add: ident,
  },
});

export const fetchCurrencies = () => async (dispatch: DispatchType) => {
  const client = await getClient();
  const response = await client.apis.currencies.get_currencies_({
    // eslint-disable-next-line camelcase
    exclude_empty: true,
  });
  const currencyList = new CurrencyList(response.body);
  await dispatch(currenciesActions.currencies.receiveCurrencies(currencyList));
};
