// @flow
import { connect } from "react-redux";
import { appActions } from "../../actions/app";

import { Modal } from "./Modal";
import type { StateType } from "../../reducers";
import { deepFreeze, withDefault } from "react-redux-flow-tools";
import type { DispatchType } from "../../actions/types";

const empty = deepFreeze({});

const mapStateToProps = (state: StateType) => {
  const modal = withDefault(state.app.modals[0], empty);
  return {
    title: modal.title,
    children: modal.children,
    closeable: modal.closeable,
    fitContent: modal.fitContent,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onDismiss: () => dispatch(appActions.app.modal.dismissFirst()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
