// @flow
import React from "react";
import { area, curveMonotoneX, line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import type { LayoutType } from "../../../models/Layout";
import { StretchedSVG } from "../../svg/StretchedSVG";
import { Defs, LinearGradient, Path, Stop, transform } from "../../svg/svg";
import { TimeSeries } from "../../../models/TimeSeries";
import type { CurrencySymbolType } from "../../../api/models";
import type { TimeSeriesElementType } from "../../../models/TimeSeries";
import { ThemeContext } from "../../theme/theme";

type SparklinePropsType = {|
  +timeSeries: TimeSeries,
  +symbol: CurrencySymbolType,
|};

export class Sparkline extends React.PureComponent<SparklinePropsType> {
  x: (number) => number;
  y: (number) => number;
  valueLine: ($ReadOnlyArray<TimeSeriesElementType>) => string;
  valueArea: ($ReadOnlyArray<TimeSeriesElementType>) => string;

  constructor(props: SparklinePropsType) {
    super(props);

    this.x = scaleTime();
    this.x.domain([0, 0]).range([0, 0]);
    this.y = scaleLinear();
    this.y.domain([0, 0]).range([0, 0]);
    const curve = curveMonotoneX;
    this.valueLine = line()
      .curve(curve)
      .x((d) => this.x(d.timestamp));
    this.valueArea = area()
      .curve(curve)
      .x((d) => this.x(d.timestamp));
  }

  _onLayout = ({ width, height }: LayoutType) => {
    this.x.range([0, width]);
    this.y.range([0, height]);
    this.forceUpdate();
  };

  render() {
    const { timeSeries, symbol } = this.props;

    if (timeSeries.isEmpty() || !timeSeries.hasKey(symbol)) {
      return <StretchedSVG shareButton={false} onLayout={this._onLayout} />;
    }
    const data = timeSeries.values();
    this.x.domain([
      timeSeries.head().timestamp,
      timeSeries.current().timestamp,
    ]);
    // todo * 1000 is a dirty hack to prevent inverted sparkline
    const hack = 100000;
    this.y.domain([
      timeSeries.max(symbol) * hack,
      timeSeries.min(symbol) * hack,
    ]);
    this.valueLine.y((d) => this.y(d[symbol] * hack));
    this.valueArea.y0(() => this.y.domain()[1]);
    this.valueArea.y1((d) => this.y(d[symbol] * hack));

    return (
      <ThemeContext.Consumer>
        {({ colors }) => (
          <StretchedSVG shareButton={false} onLayout={this._onLayout}>
            <Defs>
              {/* not really supported in react-native-svg yet, gradientTranform missing */}
              <LinearGradient
                id="AreaGradient"
                gradientTransform={transform({ rotate: "90" })}
              >
                <Stop
                  offset="0"
                  stopColor={colors.textSecondary}
                  stopOpacity={0.8}
                />
                <Stop
                  offset="100%"
                  stopColor={colors.textSecondary}
                  stopOpacity={0.0}
                />
              </LinearGradient>
            </Defs>

            {/*<Path fill="url(#AreaGradient)"*/}
            <Path
              fill={colors.textSecondary}
              fillOpacity={0.5}
              strokeWidth={1.0}
              d={this.valueArea(data)}
            />
            <Path
              stroke={colors.textSecondary}
              strokeWidth={1}
              fill="none"
              d={this.valueLine(data)}
            />
          </StretchedSVG>
        )}
      </ThemeContext.Consumer>
    );
  }
}
