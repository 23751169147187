// @flow

import type { DeviceTypeEnum } from "../../api/models";
import { Platform, Dimensions } from "react-native";

export const mobileSize = Object.freeze({
  // iphone xs max
  minor: 414,
  major: 896,
});

export const tabletSize = Object.freeze({
  // ipad pro
  minor: 1024,
  major: 1366,
});

export const isTouchDevice = (): boolean =>
  Boolean(
    typeof document === "undefined" ||
      // $FlowFixMe
      "ontouchstart" in document.documentElement ||
      (typeof navigator !== "undefined" && navigator.maxTouchPoints),
  );

export const currentDeviceType = (): DeviceTypeEnum =>
  Platform.OS === "web" ? "desktop" : Platform.isPad ? "tablet" : "mobile";

// courtesy https://stackoverflow.com/questions/52519478/react-native-how-to-check-for-iphone-xs-max
export const isIphoneXorAbove = (): boolean => {
  const dimen = Dimensions.get("window");
  return (
    Platform.OS === "ios" &&
    !Platform.isPad &&
    !Platform.isTVOS &&
    (dimen.height === 812 ||
      dimen.width === 812 ||
      (dimen.height === 896 || dimen.width === 896))
  );
};

// todo also account for android notches
export const hasNotch = isIphoneXorAbove;
