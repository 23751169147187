// @flow
import { connect } from "react-redux";
import { Documents } from "./Documents";
import type { StateType } from "../../../reducers";
import type { DispatchType } from "../../../actions/types";
import {
  createFile,
  createQuota,
  deleteFile,
  deleteQuota,
  downloadFile,
  fetchQuota,
  rekeyQuota,
} from "../../../actions/file";
import { FileMetaList, QuotaList } from "../../../api/models";

const mapStateToProps = (state: StateType) => {
  const account = state.account.account;
  return {
    downloadedFiles: state.files.files,
    localQuota: state.files.quota,
    files: account != null ? account.files : FileMetaList.empty,
    quotas: account != null ? account.quotas : QuotaList.empty,
    uploadState: state.files.uploadState,
    downloadState: state.files.uploadState,
    vault: state.account.vault,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onCreateQuota: (vault) => dispatch(createQuota(vault)),
  onCreateFile: (data, name, quota, vault) =>
    dispatch(createFile(data, name, quota, vault)),
  onRekeyQuota: (quota, files, vault) =>
    dispatch(rekeyQuota(quota, files, vault)),
  onDeleteFile: (fileMeta, quota, vault) =>
    dispatch(deleteFile(fileMeta, quota, vault)),
  onDownloadFile: (fileId, quota, vault) =>
    dispatch(downloadFile(fileId, quota, vault)),
  onFetchQuota: (quotaId, vault) => dispatch(fetchQuota(quotaId, vault)),
  onDeleteQuota: (quota, files, vault) =>
    dispatch(deleteQuota(quota, files, vault)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);
