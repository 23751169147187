// @flow
import React from "react";
import { Provider } from "react-redux";
import { Image, View } from "react-native";
import { getStore } from "../storage";
import type { StoreType } from "../storage/types";
import { ThemedAppContainer } from "./ThemedAppContainer";
import { AgnosticRouter, Route, Switch } from "./router";
import { Landing } from "./landing";
import Splash from "./img/logo-512.png";
import { composeStyles } from "./theme/theme";
import { flexCenter } from "./theme/common";
import { Privacy } from "./landing/Privacy";

type AppComponentStateType = {|
  resolvedStore: ?StoreType,
|};

export class App extends React.PureComponent<{||}, AppComponentStateType> {
  state = {
    resolvedStore: null,
  };

  async componentDidMount() {
    const resolvedStore = await getStore();
    this.setState({ resolvedStore });
  }

  render() {
    const { resolvedStore } = this.state;
    if (resolvedStore == null) {
      return (
        <View
          style={composeStyles(flexCenter, {
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          })}
        >
          <Image
            style={{ width: 256 }}
            source={Splash}
            alt="JUNO"
            resizeMode="contain"
          />
        </View>
      );
    } else {
      return (
        <Provider store={resolvedStore}>
          <AgnosticRouter>
            <Switch>
              <Route
                path="/app/:module?/:submodule?"
                component={ThemedAppContainer}
              />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/" component={Landing} />
            </Switch>
          </AgnosticRouter>
        </Provider>
      );
    }
  }
}
