// @flow
import * as React from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import type { ColorsType, StyleType, ThemeArgsType } from "../theme/theme";
import { composeStyles, rem, ThemeContext } from "../theme/theme";
import { deepFreeze } from "react-redux-flow-tools";
import { flexCenterDirection } from "../theme/common";
import { EditIcon, Icon } from "../img/Icon";
import { iconStyle } from "../header/common";

export type ButtonTypeType =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "danger";

const styles = StyleSheet.create({
  button: composeStyles(flexCenterDirection("row"), {
    padding: rem(0.5),
    borderRadius: rem(0.25),
    height: rem(4),
  }),
  plainButton: flexCenterDirection("row"),
  label: composeStyles({
    fontSize: rem(1.5),
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  }),
});

const createButtonColor = (background: $Keys<ColorsType>): ThemeArgsType =>
  deepFreeze({
    backgroundColor: background,
  });

const buttonColors: { [ButtonTypeType]: ThemeArgsType } = {
  default: createButtonColor("buttonDefault"),
  primary: createButtonColor("buttonPrimary"),
  secondary: createButtonColor("buttonSecondary"),
  success: createButtonColor("buttonSuccess"),
  danger: createButtonColor("buttonDanger"),
};

export class Button extends React.PureComponent<
  {|
    +children?: React.Node,
    +label?: string,
    +type?: ButtonTypeType,
    +style?: $ReadOnly<StyleType>,
    +hover: boolean,
    +feedback: boolean,
    +disabled: boolean,
    +fullWidth: boolean,
    +onPress?: () => mixed,
    +onLongPress?: () => mixed,
    +onMouseEnter?: (evt: SyntheticEvent<HTMLElement>) => mixed,
    +onMouseLeave?: (evt: SyntheticEvent<HTMLElement>) => mixed,
  |},
  {|
    hovered: boolean,
  |},
> {
  static defaultProps = {
    feedback: true,
    hover: false,
    disabled: false,
    fullWidth: false,
  };

  state = {
    hovered: false,
  };

  _onMouseEnter = (evt: SyntheticEvent<HTMLElement>) => {
    const { hover, onMouseEnter } = this.props;
    if (onMouseEnter) {
      evt.nativeEvent.preventDefault();
      evt.nativeEvent.stopPropagation();
      onMouseEnter(evt);
    }
    if (hover) {
      this.setState({ hovered: true });
    }
  };
  _onMouseLeave = (evt: SyntheticEvent<HTMLElement>) => {
    const { hover, onMouseLeave } = this.props;
    if (onMouseLeave) {
      evt.nativeEvent.preventDefault();
      evt.nativeEvent.stopPropagation();
      onMouseLeave(evt);
    }
    if (hover) {
      this.setState({ hovered: false });
    }
  };

  render() {
    const {
      disabled,
      fullWidth,
      type,
      children,
      label,
      style = {},
      feedback,
      onPress,
      onLongPress,
    } = this.props;
    const { hovered } = this.state;
    const Component = feedback ? TouchableOpacity : TouchableWithoutFeedback;
    Component.propTypes = {};
    const isWeb = Platform.OS === "web";
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <Component
            style={themeComposer(
              type == null ? styles.plainButton : styles.button,
              type == null ? {} : buttonColors[type],
              style,
              hovered ? { backgroundColor: colors.hoverBackground } : {},
              disabled
                ? {
                    opacity: 0.5,
                    ...(Platform.OS === "web" ? { cursor: "not-allowed" } : {}),
                  }
                : {},
              fullWidth ? { width: "100%" } : {},
            )}
            disabled={disabled}
            onPress={onPress}
            onLongPress={onLongPress}
            onMouseEnter={isWeb ? this._onMouseEnter : undefined}
            onMouseLeave={isWeb ? this._onMouseLeave : undefined}
          >
            {children != null ? (
              children
            ) : label != null ? (
              <Text style={styles.label}>{label.toUpperCase()}</Text>
            ) : null}
          </Component>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const controlsButtonStyles = StyleSheet.create({
  icon: iconStyle(rem(1.5)),
  text: {
    fontSize: rem(1.5),
    marginLeft: rem(0.25),
  },
});

export class ControlsButton extends React.PureComponent<{|
  +onPress: () => mixed,
  +icon: Class<Icon>,
  +label: string,
|}> {
  render() {
    const { onPress, icon, label } = this.props;
    const Icon = icon;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <Button
            onPress={onPress}
            style={{
              paddingTop: rem(0.4),
              paddingBottom: rem(0.5),
              paddingLeft: rem(1),
              paddingRight: rem(1.25),
              backgroundColor: colors.buttonPrimary,
              borderRadius: rem(1.5),
            }}
          >
            <Icon
              style={controlsButtonStyles.icon}
              fill={colors.textTertiary}
            />
            <Text
              style={themeComposer(controlsButtonStyles.text, {
                color: "textTertiary",
              })}
            >
              {label}
            </Text>
          </Button>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export class EditButton extends React.PureComponent<{|
  +onEdit: () => mixed,
|}> {
  render() {
    const { onEdit } = this.props;
    return <ControlsButton icon={EditIcon} label={"Edit"} onPress={onEdit} />;
  }
}
