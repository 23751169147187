// @flow

export const sensibleNumber = (value: number): string => {
  const abs = Math.abs(value);
  if (abs === 0) {
    return "0";
  }
  return value
    .toFixed(abs < 1 ? 5 : abs < 10 ? 3 : abs < 50 ? 1 : 0)
    .replace(/(\d+\.\d*[1-9])0*$/, "$1");
};

export const sensibleByte = (byte: number): string => {
  byte = Math.round(byte);
  const kiloByte = byte / 1000;
  const megaByte = kiloByte / 1000;
  return megaByte < 1
    ? kiloByte < 1
      ? `${byte} Byte`
      : `${sensibleNumber(kiloByte)} KB`
    : `${sensibleNumber(megaByte)} MB`;
};
