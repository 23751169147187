// @flow
import { testEnvironment } from "./env";
import { App } from "./components/App";
import { AppRegistry } from "react-native";

import { inject } from "./inject";

inject();

testEnvironment();
AppRegistry.registerComponent("App", () => App);
AppRegistry.runApplication("App", {
  rootTag: document.getElementById("react-root"),
});
