// @flow
import * as React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import type { DeviceTypeEnum } from "../../../../api/models/device";
import { DeviceList } from "../../../../api/models/device";
import type { StyleType } from "../../../theme/theme";
import { composeStyles, rem, ThemeContext } from "../../../theme/theme";
import { border, flexCenter, flexCenterFlow } from "../../../theme/common";
import { iconStyle } from "../../../header/common";
import { Button, EditButton } from "../../../layout/Button";
import { Heading } from "../../../layout/Heading";
import {
  Icon,
  LaptopIcon,
  PhoneIcon,
  RemoveIcon,
  TabletIcon,
  UnfilledDotIcon,
} from "../../../img/Icon";
import { distrustKey } from "../../../../actions/account";
import { Key, Vault } from "../../../../api/models";
import { Account } from "../../../../api/models";

const icons: { +[DeviceTypeEnum]: Class<Icon> } = {
  mobile: PhoneIcon,
  tablet: TabletIcon,
  desktop: LaptopIcon,
};

const rowBorder = (color: string) =>
  composeStyles(
    border(1, "solid", color, "Top"),
    border(1, "solid", color, "Left"),
    border(1, "solid", color, "Right"),
  );
const lastRowBorder = (color: string) =>
  composeStyles(rowBorder(color), border(1, "solid", color, "Bottom"));

const styles = StyleSheet.create({
  section: composeStyles(flexCenter, {
    width: "100%",
    justifyContent: "flex-start",
  }),
  container: composeStyles({
    width: "100%",
    flex: 1,
  }),
  containerSelf: composeStyles({ width: "100%" }),
  row: composeStyles(flexCenterFlow("row"), {
    width: "100%",
    height: rem(4),
  }),
  iconColumn: composeStyles(iconStyle(rem(2.5)), {
    marginLeft: rem(1),
    marginRight: rem(1),
  }),
  nameColumn: {
    flex: 1,
    textAlign: "center",
  },
  dateColumn: {
    width: rem(19),
    textAlign: "right",
    marginLeft: rem(1),
    marginRight: rem(1),
  },
  removeButton: {
    paddingLeft: rem(1),
  },
});

type DeviceListViewPropsType = {|
  +vault: Vault,
  +account: Account,
  +devices: DeviceList,
  +style?: StyleType,
  +onDistrustKey: typeof distrustKey,
|};

type DeviceListViewComponentStateType = {|
  remove?: Key,
  edit: boolean,
|};

export class DeviceListView extends React.PureComponent<
  DeviceListViewPropsType,
  DeviceListViewComponentStateType,
> {
  state = {
    remove: undefined,
    edit: false,
  };

  render() {
    const { account, devices, onDistrustKey, vault, style = {} } = this.props;
    const { remove, edit } = this.state;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={themeComposer(styles.section, {}, style)}>
            <Heading
              control={
                <EditButton
                  onEdit={() =>
                    this.setState({
                      edit: !edit,
                      remove: !edit === false ? undefined : remove,
                    })
                  }
                />
              }
            >
              Device List
            </Heading>
            <ScrollView
              contentContainerStyle={styles.container}
              style={styles.containerSelf}
              horizontal={false}
            >
              {devices.length() === 0 && (
                <Text style={styles.row}>No Paired Devices</Text>
              )}
              {devices
                .values()
                .slice(0)
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
                .map((device, idx) => {
                  const Icon = icons[device.type];
                  const fontWeight =
                    vault != null &&
                    device.key.key === vault.keyPair.publicKey.key
                      ? "bold"
                      : "normal";
                  return (
                    <Button
                      key={idx}
                      hover={true}
                      style={themeComposer(
                        styles.row,
                        {},
                        idx === devices.length() - 1
                          ? lastRowBorder(colors.separator)
                          : rowBorder(colors.separator),
                      )}
                      fullWidth={true}
                    >
                      {edit &&
                        vault != null &&
                        device.key.key === vault.keyPair.publicKey.key && (
                          <Button style={styles.removeButton}>
                            <UnfilledDotIcon style={iconStyle(rem(2.5))} />
                          </Button>
                        )}
                      {edit &&
                        vault != null &&
                        device.key.key !== vault.keyPair.publicKey.key && (
                          <Button
                            style={styles.removeButton}
                            onPress={() =>
                              this.setState({
                                remove:
                                  remove === device.key
                                    ? undefined
                                    : device.key,
                              })
                            }
                          >
                            <RemoveIcon
                              style={iconStyle(rem(2.5))}
                              fill={colors.buttonDanger}
                            />
                          </Button>
                        )}
                      <Icon style={styles.iconColumn} />
                      <Text
                        style={themeComposer(
                          styles.nameColumn,
                          {
                            color: "textPrimary",
                          },
                          {
                            fontWeight,
                          },
                        )}
                      >
                        {device.name}
                      </Text>
                      <Text
                        style={themeComposer(
                          styles.dateColumn,
                          {
                            color: "textPrimary",
                          },
                          {
                            fontWeight,
                          },
                        )}
                      >
                        {device.timestamp.toISOString()}
                      </Text>
                      {edit &&
                        vault != null &&
                        device.key.key !== vault.keyPair.publicKey.key &&
                        remove === device.key && (
                          <Button
                            type="danger"
                            label="Remove"
                            onPress={() => {
                              this.setState({ remove: undefined });
                              onDistrustKey(device.key, account, vault);
                            }}
                          />
                        )}
                    </Button>
                  );
                })}
            </ScrollView>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
