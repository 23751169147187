// @flow
import React from "react";
import { just, noop } from "react-redux-flow-tools";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { accountTo, Redirect } from "../../../router";
import { Button } from "../../../layout/Button";
import { composeStyles, rem, ThemeContext } from "../../../theme/theme";
import {
  accountActions,
  initWAL,
  syncAccountFor,
  updateWAL,
} from "../../../../actions/account";
import { Account, Vault, WAL } from "../../../../api/models";
import { Heading } from "../../../layout/Heading";
import { webSectionStyle } from "../../../layout";
import {
  border,
  flexCenter,
  imageBorder,
  inputBorder,
  inputStyle,
  inputTheme,
} from "../../../theme/common";
import { FaceIcon, UploadIcon } from "../../../img/Icon";
import { iconStyle } from "../../../header/common";
import { Upload } from "../../../layout/upload";

const iconSize = 256;
const imageBorderSize = 4;
const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  }),
  userIconBorder: composeStyles(
    flexCenter,
    iconStyle(iconSize + 2 * imageBorderSize),
    {
      borderRadius: (iconSize + 2 * imageBorderSize) / 2,
      marginBottom: rem(1.5),
    },
  ),
  userIcon: composeStyles(flexCenter, iconStyle(iconSize), {
    borderRadius: iconSize / 2,
  }),
  userIconSmaller: composeStyles(flexCenter, iconStyle(iconSize * 0.8), {
    borderRadius: (iconSize * 0.8) / 2,
  }),
  section: webSectionStyle,
  input: composeStyles(inputStyle, {
    width: "100%",
    marginBottom: rem(0.5),
    marginTop: rem(2),
  }),
});

type AccountManagementPropsType = {|
  +wal?: WAL<Account>,
  +account?: Account,
  +vault?: Vault,
  +onInitialize: typeof initWAL,
  +onUpdate: typeof updateWAL,
  +onSyncKey: typeof syncAccountFor,
  +onReceiveVault: typeof accountActions.account.receiveVault,
|};

type AccountManagementComponentStateType = {|
  userName: string,
  userIcon: string,
  uploadingUserIcon: boolean,
  warning: string,
|};

export class AccountManagement extends React.PureComponent<
  AccountManagementPropsType,
  AccountManagementComponentStateType,
> {
  constructor(props: AccountManagementPropsType) {
    super(props);
    const { account } = props;
    if (account != null) {
      this.state = {
        userName: account.userName != null ? account.userName : "",
        userIcon: account.userIcon != null ? account.userIcon : "",
        uploadingUserIcon: false,
        warning: "",
      };
    } else {
      this.state = {
        userName: "",
        userIcon: "",
        uploadingUserIcon: false,
        warning: "",
      };
    }
  }

  _onUploadUserIcon = (data: string | ArrayBuffer) => {
    if (data instanceof ArrayBuffer) {
      const fileTypeBytes = new Uint8Array(data.slice(0, 2));
      // https://stackoverflow.com/questions/4550296/how-to-identify-contents-of-a-byte-is-a-jpeg
      if (fileTypeBytes[0] === 255 && fileTypeBytes[1] === 216) {
        const bytes = new Uint8Array(data);
        if (bytes.length > 262144) {
          this.setState({ warning: "File size shouldn't exceed 256kb!" });
          return;
        }
        this.setState({
          userIcon:
            "data:image/jpeg;base64," + btoa(String.fromCharCode(...bytes)),
        });
      } else {
        this.setState({ warning: "Image has to be JPEG!" });
      }
    } else {
      // todo is this a valid check? base64 is longer
      if (data.length > 262144 * 2) {
        this.setState({ warning: "File size shouldn't exceed 256kb!" });
        return;
      }
      //data is a string
      if (data.startsWith("/9j/")) {
        // /9j/ === jpeg
        this.setState({
          userIcon: "data:image/jpeg;base64," + data,
        });
      } else if (data.startsWith("data:image/jpeg;base64,")) {
        this.setState({
          userIcon: data,
        });
      }
    }
  };

  _onStartUploadingUserIcon = () => this.setState({ uploadingUserIcon: true });
  _onStopUploadingUserIcon = () => this.setState({ uploadingUserIcon: false });

  _onChangeUserName = (userName: string) => this.setState({ userName });

  _onUpdateUserName = () => {
    const { vault, onUpdate } = this.props;
    const { userName } = this.state;
    onUpdate({ userName }, just(vault));
  };

  _onUpdateUserIcon = () => {
    const { vault, onUpdate } = this.props;
    const { userIcon } = this.state;
    if (userIcon.startsWith("data:image/")) {
      onUpdate({ userIcon }, just(vault));
    }
  };

  _onInitialize = () => {
    const { vault, onInitialize } = this.props;
    const { userName } = this.state;
    onInitialize(Account.factory(userName), just(vault));
  };

  render() {
    const { account, vault } = this.props;
    const { warning } = this.state;
    if (vault == null) {
      return <Redirect to={accountTo("devices")} />;
    }
    const { userName, userIcon, uploadingUserIcon } = this.state;
    const Icon = uploadingUserIcon ? UploadIcon : FaceIcon;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.section}>
              <Heading>Account Details</Heading>
              <Upload
                style={themeComposer(
                  styles.userIconBorder,
                  { backgroundColor: colors.headerBackground },
                  border(3, "solid", colors.juno),
                )}
                onDragEnter={this._onStartUploadingUserIcon}
                onDragLeave={this._onStopUploadingUserIcon}
                onData={this._onUploadUserIcon}
                type="image"
                accept="image/jpeg"
              >
                {userIcon.startsWith("data") ? (
                  <Image
                    style={StyleSheet.compose(
                      styles.userIcon,
                      imageBorder(imageBorderSize * 2, colors.headerBackground),
                    )}
                    source={{ uri: userIcon }}
                    alt="profile image"
                    resizeMode="cover"
                  />
                ) : (
                  <Icon
                    style={
                      uploadingUserIcon
                        ? styles.userIconSmaller
                        : styles.userIcon
                    }
                  />
                )}
              </Upload>
              {warning !== "" ? (
                <Text
                  style={{
                    color: colors.textDanger,
                    marginBottom: rem(1),
                    fontSize: rem(2),
                  }}
                >
                  {warning}
                </Text>
              ) : (
                <Text
                  style={{
                    color: colors.textSecondary,
                    marginBottom: rem(1),
                    fontSize: rem(1.5),
                  }}
                >
                  Supported Format: JPEG, max 256KB
                </Text>
              )}
              <Button
                fullWidth
                disabled={
                  vault == null ||
                  account == null ||
                  userIcon === "" ||
                  userIcon === account.userIcon
                }
                type="primary"
                label={"Change Icon"}
                onPress={
                  vault != null
                    ? account != null
                      ? this._onUpdateUserIcon
                      : noop
                    : noop
                }
              />
              <TextInput
                autoFocus={true}
                style={themeComposer(
                  styles.input,
                  inputTheme,
                  inputBorder(colors),
                )}
                value={userName}
                placeholder="Enter User Name..."
                placeholderTextColor={colors.textSecondary}
                onChangeText={this._onChangeUserName}
              />
              <Button
                fullWidth
                disabled={
                  vault == null ||
                  account == null ||
                  userName === "" ||
                  userName === account.userName
                }
                type="primary"
                label={account == null ? "Create Account" : "Change User Name"}
                onPress={
                  vault != null
                    ? account != null
                      ? this._onUpdateUserName
                      : this._onInitialize
                    : noop
                }
              />
            </View>
          </ScrollView>
        )}
      </ThemeContext.Consumer>
    );
  }
}
