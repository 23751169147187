// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers";
import type { DispatchType } from "../../actions/types";
import { appActions } from "../../actions/app";
import { Menu } from "./Menu";
import type { MenuPropsType } from "./Menu";

const mapStateToProps = (state: StateType, { name }: MenuPropsType) => {
  return {
    open: state.menu.menu != null && state.menu.menu.name === name,
  };
};

const mapDispatchToProps = (dispatch: DispatchType, _: MenuPropsType) => ({
  onShowMenu: (menu) => dispatch(appActions.app.menu.showMenu(menu)),
  onCloseMenu: () => dispatch(appActions.app.menu.closeMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
