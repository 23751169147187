// @flow
import React from "react";
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";
import type { Match } from "../router";
import { Route, Switch, to } from "../router";
import SidebarContainer from "./sidebar";
import MainContainer from "./overview";
import { border, flexCenter, sidebarWidth } from "../theme/common";
import { composeStyles, ThemeContext } from "../theme/theme";
import { LayoutAware } from "../layout/LayoutAware";
import { Account } from "./account/Account";
import SwipeContainer from "../layout/swipe";
import TransactionsContainer from "./transactions";
import DocumentsContainer from "./documents";
import { NetworkGraph } from "./overview/network";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    flexDirection: "row",
    height: "100%",
    width: "100%",
  }),
  content: {
    flex: 1,
    height: "100%",
  },
  sidebar: composeStyles({
    height: "100%",
    width: sidebarWidth,
  }),
  dragIn: composeStyles({
    backgroundColor: "transparent",
    height: "100%",
    width: 7,
    position: "absolute",
    top: 0,
    right: 0,
  }),
});

const ComingSoon = () => <Text>Coming Soon...</Text>;

type OverviewPropsType = {|
  +showBurger: boolean,
  +firstTime: boolean,
  +match: Match,
  +onFirstTime: () => mixed,
|};

export class AppFrame extends LayoutAware<OverviewPropsType> {
  componentDidMount() {
    this.props.onFirstTime();
  }

  renderAgnostic() {
    const { showBurger } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.content}>
          <Switch>
            <Route path={to("account")} component={Account} />
            <Route path={to("documents")} component={DocumentsContainer} />
            <Route
              path={to("transactions")}
              component={TransactionsContainer}
            />
            <Route path={to("rebalance")} component={ComingSoon} />
            <Route path={to("swap_tokens")} component={ComingSoon} />
            <Route path={to("experiments")} component={NetworkGraph} />
            <Route path={to()} component={MainContainer} />
          </Switch>
        </View>
        {showBurger && (
          <ThemeContext.Consumer>
            {({ themeComposer, colors }) => (
              <View
                style={themeComposer(
                  styles.sidebar,
                  { backgroundColor: "background" },
                  border(1, "solid", colors.separator, "Left"),
                )}
              >
                <Route component={SidebarContainer} />
              </View>
            )}
          </ThemeContext.Consumer>
        )}
      </View>
    );
  }
  renderMobile() {
    const { Root, props } =
      // todo glitchy in react native web
      Platform.OS !== "web"
        ? {
            Root: KeyboardAvoidingView,
            props: {
              behavior: "position",
              contentContainerStyle: styles.container,
            },
          }
        : {
            Root: View,
            props: { style: styles.container },
          };
    const { firstTime } = this.props;
    return (
      <Root {...props}>
        <SwipeContainer
          name="frame-mobile"
          tabIndex={firstTime === true ? 1 : undefined}
          showDots={false}
        >
          <View style={styles.content}>
            <Switch>
              <Route path={to("account")} component={Account} />
              <Route path={to("documents")} component={DocumentsContainer} />
              <Route
                path={to("transactions")}
                component={TransactionsContainer}
              />
              <Route path={to("experiments")} component={NetworkGraph} />
              <Route path={to("rebalance")} component={ComingSoon} />
              <Route path={to("swap_tokens")} component={ComingSoon} />
              <Route path={to()} component={MainContainer} />
            </Switch>
            {/*drag in space on right side for wallet */}
            <View style={styles.dragIn} />
          </View>
          <Route component={SidebarContainer} />
        </SwipeContainer>
      </Root>
    );
  }
}
