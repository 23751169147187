// @flow
import * as React from "react";
import { StyleSheet } from "react-native";
import type { CurrencySymbolType } from "../../../api/models";
import { TransactionList, Vault } from "../../../api/models";
import { updateWAL } from "../../../actions/account";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import SwipeContainer from "../../layout/swipe";
import { TransactionListView } from "./TransactionList";
import { AddTransaction } from "./AddTransaction";
import { accountTo, Redirect } from "../../router";

const styles = StyleSheet.create({
  container: { height: "100%", flex: 1 },
});

type TransactionsPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +transactions: TransactionList,
  +vault?: Vault,
  +onUpdate: typeof updateWAL,
|};

export class Transactions extends React.PureComponent<TransactionsPropsType> {
  render() {
    const {
      vault,
      walletCurrencies,
      availableCurrencies,
      transactions,
      onUpdate,
    } = this.props;
    if (vault == null) {
      return <Redirect to={accountTo("devices")} />;
    }
    return (
      <SwipeContainer name={"transactions"} style={styles.container}>
        <AddTransaction
          availableCurrencies={availableCurrencies}
          walletCurrencies={walletCurrencies}
          transactions={transactions}
          vault={vault}
          onUpdate={onUpdate}
        />
        {transactions.length() > 0 && (
          <TransactionListView
            availableCurrencies={availableCurrencies}
            transactions={transactions}
            onUpdate={onUpdate}
            vault={vault}
          />
        )}
      </SwipeContainer>
    );
  }
}
