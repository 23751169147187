// @flow
import moize from "moize";
import { connect } from "react-redux";

import type { StateType } from "../../../reducers";
import { Overview } from "./Overview";
import { TimeSeries } from "../../../models/TimeSeries";
import { appActions } from "../../../actions/app";
import { withDefault } from "react-redux-flow-tools";
import type { DispatchType } from "../../../actions/types";
import { currenciesActions } from "../../../actions/currencies";

const emptyTimeSeries = new TimeSeries();

const _cachedList = () =>
  moize(
    <T>(lst: $ReadOnlyArray<T>): $ReadOnlyArray<T> => {
      return lst;
    },
    {
      maxSize: 1,
      isDeepEqual: true,
      transformArgs: (args) => args[0].slice(0).sort(),
    },
  );

const hoveredCache = _cachedList();
const selectedCache = _cachedList();
const visibleCache = _cachedList();

const _cachedSubset = () =>
  moize(
    (
      subsetKeys: $ReadOnlyArray<string>,
      timeSeries: TimeSeries,
    ): TimeSeries => {
      return withDefault(timeSeries, emptyTimeSeries).subset(subsetKeys);
    },
    {
      maxSize: 1,
    },
  );

const timeSeriesSubsetCache = _cachedSubset();
const timeSeries24SubsetCache = _cachedSubset();

const mapStateToProps = (state: StateType) => {
  const visibleCurrencies = visibleCache(
    Object.keys(state.currencies.used).filter(
      (symbol) => !state.currencies.used[symbol].hidden,
    ),
  );
  return {
    loading: state.candles.loading,

    timeSeries: timeSeriesSubsetCache(visibleCurrencies, state.timeSeries.USD),
    timeSeries24: timeSeries24SubsetCache(
      visibleCurrencies,
      state.timeSeries.hours24.USD,
    ),

    hoveredCurrencies: hoveredCache(
      Object.keys(state.currencies.used).filter(
        (symbol) => state.currencies.used[symbol].hovered,
      ),
    ),
    selectedCurrencies: selectedCache(
      Object.keys(state.currencies.used).filter(
        (symbol) => state.currencies.used[symbol].selected,
      ),
    ),

    compact: state.app.compact,
    filled: state.app.filled,
    followMouse: state.app.followMouse,

    chartType: state.app.chartType,

    availableCurrencies: state.currencies.available,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onHoverCurrency: (flag) => {
    dispatch(currenciesActions.currencies.hover(flag));
  },
  onSelectCurrency: (flag) => {
    dispatch(currenciesActions.currencies.select(flag));
  },

  onSelectChartType: (chartType) =>
    dispatch(appActions.app.receiveChartType(chartType)),

  onManualTabSelect: (name, tabIndex) =>
    dispatch(
      appActions.app.receiveTabIndex({ name, tabIndex: Math.max(tabIndex, 0) }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overview);
