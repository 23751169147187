// @flow
import { connect } from "react-redux";
import type { StateType } from "../../../reducers/index";

import { Swipe } from "./Swipe";
import type { DispatchType } from "../../../actions/types";
import { appActions } from "../../../actions/app";

const mapStateToProps = (state: StateType, { name, tabIndex }) => {
  const swipe = state.app.swipe[name];
  if (swipe == null) {
    return { tabIndex };
  }
  return {
    tabIndex: tabIndex != null ? tabIndex : swipe.tabIndex,
  };
};

const mapDispatchToProps = (dispatch: DispatchType, { name, onTabSelect }) => ({
  onTabSelect:
    onTabSelect != null
      ? onTabSelect
      : (tabIndex: number) =>
          dispatch(
            appActions.app.receiveTabIndex({
              name,
              tabIndex: Math.max(tabIndex, 0),
            }),
          ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Swipe);
