// @flow
import { handleActions } from "redux-actions";
import { deepFreeze, actionHandler } from "react-redux-flow-tools";
import type { ChartType, DialogType, ModalType } from "../actions/app";
import { appActions } from "../actions/app";
import type { CurrencySymbolType } from "../api/models";
import type { AvailableThemesType } from "../components/theme/theme";
import type { Reducers } from "./types";

export type AppStateType = {|
  +firstTime: boolean,
  +followMouse: boolean,
  +filled: boolean,
  +theme: AvailableThemesType | "auto",
  +modals: $ReadOnlyArray<ModalType>,
  +dialogs: $ReadOnlyArray<DialogType>,
  +showBurger: boolean,
  +swipe: {
    +[string]: {|
      +tabIndex: number,
    |},
  },
  +compact: boolean,
  +hoveredCurrency: ?CurrencySymbolType,
  +selectedCurrency: ?CurrencySymbolType,
  +chartType: ChartType,
|};

export const appDefaults: AppStateType = deepFreeze({
  firstTime: true,
  followMouse: false,
  filled: true,
  theme: "auto",
  swipe: {},
  compact: false,
  modals: [],
  dialogs: [],
  showBurger: true,
  hoveredCurrency: undefined,
  selectedCurrency: undefined,
  chartType: "valuechange",
});

const actionHandlers = {
  ...actionHandler(appActions.app.receiveFirstTime, (state) => ({
    ...state,
    firstTime: false,
  })),

  ...actionHandler(appActions.app.receiveTabIndex, (state, action) => {
    return {
      ...state,
      swipe: {
        ...state.swipe,
        [action.payload.name]: {
          ...state.swipe[action.payload.name],
          tabIndex: action.payload.tabIndex,
        },
      },
    };
  }),

  ...actionHandler(appActions.app.receiveChartType, (state, action) => ({
    ...state,
    chartType: action.payload,
  })),

  ...actionHandler(appActions.app.receiveFollowMouse, (state, action) => ({
    ...state,
    followMouse: action.payload,
  })),

  ...actionHandler(appActions.app.receiveFilled, (state, action) => ({
    ...state,
    filled: action.payload,
  })),

  ...actionHandler(appActions.app.receiveTheme, (state, action) => ({
    ...state,
    theme: action.payload,
  })),

  ...actionHandler(appActions.app.receiveCompact, (state, action) => ({
    ...state,
    compact: action.payload,
  })),

  ...actionHandler(appActions.app.modal.show, (state, action) => ({
    ...state,
    modals: [action.payload, ...state.modals],
  })),

  ...actionHandler(appActions.app.modal.dismissFirst, (state) => ({
    ...state,
    modals: state.modals.slice(1),
  })),

  ...actionHandler(appActions.app.dialog.show, (state, action) => ({
    ...state,
    dialogs: [action.payload, ...state.dialogs],
  })),

  ...actionHandler(appActions.app.dialog.dismissFirst, (state) => ({
    ...state,
    dialogs: state.dialogs.slice(1),
  })),

  ...actionHandler(appActions.app.showBurger, (state, action) => ({
    ...state,
    showBurger: action.payload,
  })),
};

export const app: Reducers<AppStateType> = handleActions(
  actionHandlers,
  appDefaults,
);
