// @flow

import deepEqual from "fast-deep-equal";
import { AsyncStorage } from "react-native";
import type { StateShapeType, StateType } from "../reducers";
import { defaults } from "../reducers";
import pick from "lodash/fp/pick";
import omit from "lodash/fp/omit";
import type { StoreType } from "./types";
import { stringify, parse } from "./json";
import { filesDefaults } from "../reducers/files";
import { Account, KeyRing, Vault } from "../api/models";

// version before migration mechanism
// const STORAGE_VERSION = 36;

const storageKey = (key: $Keys<StateType>): string => `_juno_${key}`;

type StoredItemType = {|
  +version: number,
  +data: StateShapeType,
  +ts: Date,
  +expires?: number,
|};

const bumpVersion = (version: number, item: StoredItemType) => ({
  ...item,
  version,
});

type MigrationType = (StoredItemType) => Promise<StoredItemType>;

const createMigration = (
  version: number,
  migration: MigrationType,
): { +[number]: MigrationType } => ({
  [version]: async (item) => bumpVersion(version, await migration(item)),
});

const migrations: { +[number]: (StoredItemType) => StoredItemType } = {
  ...createMigration(36, () => {
    throw new Error(
      `UnsupportedVersionError: Your storage version is not supported anymore. Please re-setup the device.`,
    );
  }),
  ...createMigration(37, async (stored) => {
    if (stored.data.currencies != null) {
      const usedCurrencies =
        stored.data.currencies.used == null ? {} : stored.data.currencies.used;
      return {
        ...stored,
        data: {
          ...stored.data,
          currencies: {
            ...stored.data.currencies,
            // filter out USD from used currencies
            used: Object.keys(usedCurrencies)
              .filter((currency) => !currency.startsWith("USD"))
              .reduce(
                (acc, currency) => ({
                  ...acc,
                  [currency]: usedCurrencies[currency],
                }),
                {},
              ),
          },
        },
      };
    } else {
      return stored;
    }
  }),
  ...createMigration(38, async (stored) => {
    if (stored.data.files != null && stored.data.files.quota != null) {
      return {
        ...stored,
        data: {
          ...stored.data,
          files: filesDefaults,
        },
      };
    } else {
      return stored;
    }
  }),
  ...createMigration(39, async (stored) => {
    if (stored.data.account != null) {
      let vault = stored.data.account.vault;
      if (vault != null) {
        let keyRing = KeyRing.empty;
        //$FlowFixMe changed to a KeyRing
        for (let trustedKey of vault.trusted) {
          keyRing = await keyRing.trust(trustedKey);
        }
        //$FlowFixMe changed to a KeyRing
        for (let untrustedKey of vault.untrusted) {
          keyRing = await keyRing.distrust(untrustedKey);
        }
        vault = new Vault({
          keyPair: vault.keyPair,
          keyRing,
        });
      }
      return {
        ...stored,
        data: {
          ...stored.data,
          account: {
            ...stored.data.account,
            // $FlowFixMe type changed: account -> wal
            wal: stored.data.account.account,
            account:
              stored.data.account.account != null
                ? // $FlowFixMe type changed: account -> wal
                  await stored.data.account.account.current(
                    Account,
                    ...Account.mergers,
                  )
                : undefined,
            vault,
          },
        },
      };
    } else {
      return stored;
    }
  }),
};

const storageVersions = Object.keys(migrations)
  .map((version) => +version)
  .sort();
const storageVersion = storageVersions[storageVersions.length - 1];

type UnsubscribeFunctionType = () => void;
type StoreObserverType = (
  Promise<StoreType>,
) => Promise<UnsubscribeFunctionType>;

//https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
const observeStore = (
  select: (StateType) => $Shape<StateType>,
  onChange: (StateType) => Promise<mixed>,
): StoreObserverType => async (store) => {
  let currentState;
  const _store = await store;

  const handleChange = (): void => {
    let nextState = select(_store.getState());
    if (!deepEqual(nextState, currentState)) {
      currentState = nextState;
      onChange(currentState)
        .then(() => console.debug("updated state", currentState))
        .catch((error) =>
          console.error("couldn't update state", currentState, error),
        );
    }
  };

  const unsubscribe = _store.subscribe(handleChange);
  await handleChange();
  return unsubscribe;
};

const storeItem = (
  key: $Keys<StateType>,
  expiresMilliseconds?: number,
) => async (data: {}) => {
  const item: StoredItemType = {
    version: storageVersion,
    data,
    ts: new Date(),
    expires: expiresMilliseconds,
  };
  return await AsyncStorage.setItem(storageKey(key), await stringify(item));
};

const observer = (expires?: number) => (
  key: $Keys<StateType>,
  ...excludePaths: string[]
) =>
  observeStore(
    (state: StateType) => omit(excludePaths, pick([key], state)),
    storeItem(key, expires),
  );

const permanentObserver = observer();

const mergeObservers = (
  ...observers: $ReadOnlyArray<StoreObserverType>
) => async (
  store: Promise<StoreType>,
): Promise<$ReadOnlyArray<Promise<UnsubscribeFunctionType>>> =>
  await observers.map(async (observer) => await observer(store));

export const subscribeObservers = mergeObservers(
  permanentObserver("app"),
  permanentObserver("account", "account.syncers", "account.uploaders"),
  permanentObserver("currencies"),
  permanentObserver("transactions"),
  permanentObserver("wallets"),
  observer(1000 * 60 * 60 * 1)("timeSeries"), //1hours
  observer(1000 * 60 * 60 * 1)("candles"), //1hours
);

const loadItem = async (key: $Keys<StateType>): Promise<StoredItemType> => {
  const json = await AsyncStorage.getItem(storageKey(key));
  const parsed: StoredItemType = await parse(json);
  if (parsed == null) {
    throw new Error(`KeyNotFoundError: ${key} not present in storage`);
  }
  if (
    parsed.expires != null &&
    parsed.ts.getTime() + parsed.expires < new Date().getTime()
  ) {
    await AsyncStorage.removeItem(key);
    throw new Error(`KeyExpiredError: ${key} has is expired, removed.`);
  }
  if (parsed.version !== storageVersion) {
    let versionIdx = storageVersions.findIndex(
      (version) => version > parsed.version,
    );
    if (versionIdx === -1) {
      await AsyncStorage.removeItem(key);
      throw new Error(
        `VersionMismatchError: ${key} has an unrecognized version ${
          parsed.version
        }, removed.`,
      );
    }
    let migrated = parsed;
    for (; versionIdx < storageVersions.length; versionIdx++) {
      const version = storageVersions[versionIdx];
      const migration = migrations[version];
      console.log(
        `Migrating stored item ${key} from version: ${
          migrated.version
        } to ${version}`,
      );
      migrated = await migration(parsed);
    }
    return migrated;
  } else {
    return parsed;
  }
};

const itemLoader = (
  key: $Keys<StateType>,
) => async (): Promise<StateShapeType> => {
  try {
    const {
      data: { [key]: keyState },
    } = await loadItem(key);
    // $FlowFixMe loaded from a mixed
    return { [key]: { ...defaults[key], ...keyState } };
  } catch (error) {
    console.warn(error);
    return { [key]: (defaults[key]: $ElementType<StateShapeType, string>) };
  }
};

const mergeItemLoaders = (...loaders) => async (): Promise<StateType> => {
  return await loaders.reduce(async (asyncAcc, loader) => {
    const acc = await asyncAcc;
    const partialState = await loader();
    return { ...acc, ...partialState };
  }, Promise.resolve((defaults: StateType)));
};

export const loadStorage = mergeItemLoaders(
  itemLoader("app"),
  itemLoader("currencies"),
  itemLoader("account"),
  itemLoader("timeSeries"),
  itemLoader("transactions"),
  itemLoader("wallets"),
);
