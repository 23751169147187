// @flow

import { List } from "./list";
import {
  notNull,
  validateDate,
  validateEnum,
  validateListOf,
  validateString,
  ValidationError,
} from "./validate";
import type { ObjectSubsetType } from "./models";
import { Model } from "./models";
import { Key } from "./PGP";

export type DeviceTypeEnum = "mobile" | "tablet" | "desktop";
export const deviceTypes: $ReadOnlyArray<DeviceTypeEnum> = Object.freeze([
  "mobile",
  "tablet",
  "desktop",
]);

export class Device extends Model<Device> {
  +type: DeviceTypeEnum;
  +name: string;
  +timestamp: Date;
  +key: Key;

  constructor(json: ObjectSubsetType<Device>) {
    super(json);
    this.type = validateEnum(deviceTypes, json.type);
    this.name = validateString(json.name);
    this.timestamp = validateDate(json.timestamp);
    const key = json.key;
    if (key == null) {
      throw new ValidationError(key, Key.constructor.name);
    }
    this.key = key instanceof Key ? key : new Key({ key });
  }
}

export class DeviceList extends List<Device, DeviceList> {
  +devices: $ReadOnlyArray<Device>;

  constructor(json: ObjectSubsetType<DeviceList>) {
    super("devices");
    const devices = validateListOf(
      notNull((device) =>
        device instanceof Device ? device : new Device(device),
      ),
      json.devices,
    );
    this.devices = devices.slice(0).sort((a, b) => b.timestamp - a.timestamp);
  }

  static +empty = new DeviceList({ devices: [] });
}
