// @flow
import * as React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { composeStyles, rem, ThemeContext } from "../../theme/theme";
import { Heading } from "../../layout/Heading";
import { webSectionStyle } from "../../layout";
import {
  flexCenter,
  flexCenterDirection,
  inputBorder,
  inputBorderRadius,
  inputStyle,
  inputTheme,
} from "../../theme/common";
import { CameraIcon } from "../../img/Icon";
import { iconStyle } from "../../header/common";
import { Button } from "../../layout/Button";
import type {
  CurrencySymbolType,
  WalletAddressType,
} from "../../../api/models";
import { Transaction, TransactionList, Vault } from "../../../api/models";
import { updateWAL } from "../../../actions/account";
import { TokenList } from "../TokenList";
import type { CurrenciesStateType } from "../../../reducers/currencies";
import { currencyIcon } from "../../img/currencies";

const styles = StyleSheet.create({
  container: composeStyles(flexCenter, {
    width: "100%",
    height: "100%",
    flex: 1,
  }),
  section: composeStyles(webSectionStyle, {
    justifyContent: "flex-start",
    height: "100%",
  }),
  fromToContainer: composeStyles(flexCenter, {
    width: "100%",
    alignContent: "flex-start",
    marginBottom: rem(2),
  }),
  fromToLabel: {
    width: "100%",
    fontSize: rem(2),
    paddingBottom: rem(0.5),
  },
  fromToContent: composeStyles(flexCenter, {
    flex: 1,
    width: "100%",
  }),
  fromToContentFlex: { width: "100%", flex: 1 },
  fromToContentInput: composeStyles(flexCenter, {
    flexDirection: "row",
    width: "100%",
    height: 40,
    borderRadius: inputBorderRadius,
    paddingLeft: rem(0.5),
    marginBottom: rem(0.5),
  }),

  input: composeStyles(inputStyle, {
    flex: 1,
  }),
  cameraIcon: composeStyles(iconStyle(rem(2)), { marginLeft: rem(0) }),
});

type FromToPropsType = {|
  +label: string,
  +selected?: ?CurrencySymbolType,
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +onSelect: (CurrencySymbolType) => void,
  +onRemove: (CurrencySymbolType) => void,
  +onAddressChange: (string) => void,
  +address?: string,
|};

class FromTo extends React.PureComponent<FromToPropsType> {
  render() {
    const {
      address,
      label,
      selected,
      availableCurrencies,
      walletCurrencies,
      onSelect,
      onRemove,
      onAddressChange,
    } = this.props;
    const Icon = selected != null ? currencyIcon(selected) : undefined;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors, currencyColors }) => (
          <View
            style={
              selected != null
                ? [styles.fromToContainer, { height: 100 }]
                : [styles.fromToContainer, { flex: 1 }]
            }
          >
            <Text
              style={themeComposer(styles.fromToLabel, {
                color: "textPrimary",
              })}
            >
              {label}:
            </Text>
            <View style={styles.fromToContent}>
              <View
                style={themeComposer(
                  styles.fromToContentInput,
                  {
                    backgroundColor: "inputBackgroundPrimary",
                  },
                  inputBorder(colors),
                )}
              >
                <Button>
                  <CameraIcon
                    style={styles.cameraIcon}
                    fill={colors.textSecondary}
                  />
                </Button>
                <TextInput
                  style={themeComposer(styles.input, inputTheme)}
                  placeholder="Address (optional)"
                  placeholderTextColor={colors.textSecondary}
                  onChangeText={onAddressChange}
                  value={address}
                />
              </View>
              <View style={styles.fromToContentFlex}>
                {selected != null ? (
                  <Button
                    onPress={() => onRemove(selected)}
                    style={composeStyles(
                      flexCenterDirection("row"),
                      {
                        borderRadius: inputBorderRadius,
                        backgroundColor: colors.inputBackgroundPrimary,
                        marginBottom: rem(1),
                        padding: rem(0.25),
                        paddingLeft: rem(0.75),
                        paddingRight: rem(1),
                      },
                      inputBorder(colors),
                    )}
                  >
                    {Icon != null && (
                      <Icon
                        style={composeStyles(iconStyle(32), {
                          backgroundColor: "white",
                          borderRadius: 16,
                        })}
                        fill={currencyColors(selected)}
                      />
                    )}
                    <Text
                      style={themeComposer(styles.input, {
                        color: "textPrimary",
                      })}
                    >
                      {selected}
                    </Text>
                    <Text
                      style={{
                        fontSize: rem(1.2),
                        color: colors.textSecondary,
                      }}
                    >
                      CHANGE
                    </Text>
                  </Button>
                ) : (
                  <TokenList
                    availableCurrencies={availableCurrencies}
                    walletCurrencies={walletCurrencies}
                    onAddCurrency={onSelect}
                    onRemoveCurrency={onRemove}
                  />
                )}
              </View>
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

type AddTransactionPropsType = {|
  +availableCurrencies: $PropertyType<CurrenciesStateType, "available">,
  +walletCurrencies: $ReadOnlyArray<CurrencySymbolType>,
  +transactions: TransactionList,
  +vault?: Vault,
  +onUpdate: typeof updateWAL,
|};

type AddTransactionComponentStateType = {|
  fromCurrency?: CurrencySymbolType,
  toCurrency?: CurrencySymbolType,
  fromAddress?: WalletAddressType,
  toAddress?: WalletAddressType,
  fromAmount?: string,
  toAmount?: string,
|};

const defaultState = {
  fromCurrency: undefined,
  toCurrency: undefined,
  fromAmount: undefined,
  toAmount: undefined,
  fromAddress: undefined,
  toAddress: undefined,
};

export class AddTransaction extends React.PureComponent<
  AddTransactionPropsType,
  AddTransactionComponentStateType,
> {
  state = defaultState;

  _onSelectFromCurrency = (currency: CurrencySymbolType) => {
    if (this.state.fromCurrency === currency) {
      this.setState({ fromCurrency: undefined });
    } else {
      this.setState({ fromCurrency: currency });
    }
  };
  _onRemoveFromCurrency = () => {
    this.setState({ fromCurrency: undefined });
  };

  _onSelectToCurrency = (currency: CurrencySymbolType) => {
    if (this.state.toCurrency === currency) {
      this.setState({ toCurrency: undefined });
    } else {
      this.setState({ toCurrency: currency });
    }
  };

  _onRemoveToCurrency = () => {
    this.setState({ toCurrency: undefined });
  };

  _onFromAmountChange = (fromAmount: string) => {
    this.setState({
      fromAmount: fromAmount === "" ? undefined : fromAmount,
    });
  };

  _onToAmountChange = (toAmount: string) => {
    this.setState({ toAmount: toAmount === "" ? undefined : toAmount });
  };

  _onFromAddressChange = (fromAddress: string) => {
    this.setState({
      fromAddress: fromAddress === "" ? undefined : fromAddress,
    });
  };

  _onToAddressChange = (toAddress: string) => {
    this.setState({ toAddress: toAddress === "" ? undefined : toAddress });
  };

  _onAddTransaction = () => {
    const { onUpdate, transactions, vault } = this.props;
    const {
      fromAddress,
      toAddress,
      fromAmount,
      toAmount,
      fromCurrency,
      toCurrency,
    } = this.state;
    const timestamp = new Date();
    if (
      vault != null &&
      fromCurrency != null &&
      toCurrency != null &&
      fromAmount != null &&
      toAmount != null
    ) {
      onUpdate(
        {
          transactions: transactions.append(
            new Transaction({
              fromAmount: Number(fromAmount),
              toAmount: Number(toAmount),
              fromCurrency,
              toCurrency,
              fromAddress,
              toAddress,
              timestamp,
            }),
          ),
        },
        vault,
      );
      this.setState(defaultState);
    }
  };

  render() {
    const {
      fromCurrency,
      toCurrency,
      fromAmount,
      toAmount,
      fromAddress,
      toAddress,
    } = this.state;
    const { vault, availableCurrencies, walletCurrencies } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.container}>
            <View style={styles.section}>
              <Heading>Add Transaction</Heading>
              <FromTo
                label="From"
                availableCurrencies={availableCurrencies}
                walletCurrencies={walletCurrencies}
                selected={fromCurrency}
                onSelect={this._onSelectFromCurrency}
                onRemove={this._onRemoveFromCurrency}
                onAddressChange={this._onFromAddressChange}
                address={fromAddress}
              />
              {fromCurrency != null && (
                <FromTo
                  label="To"
                  availableCurrencies={availableCurrencies}
                  walletCurrencies={walletCurrencies}
                  selected={toCurrency}
                  onSelect={this._onSelectToCurrency}
                  onRemove={this._onRemoveToCurrency}
                  onAddressChange={this._onToAddressChange}
                  address={toAddress}
                />
              )}
              {fromCurrency != null && toCurrency != null && (
                <View style={styles.fromToContainer}>
                  <Text
                    style={themeComposer(styles.fromToLabel, {
                      color: "textPrimary",
                    })}
                  >
                    Amount:
                  </Text>
                  <View
                    style={[
                      styles.fromToContent,
                      {
                        flexDirection: "row",
                        flex: undefined,
                        width: "100%",
                      },
                    ]}
                  >
                    <TextInput
                      style={themeComposer(
                        styles.input,
                        inputTheme,
                        inputBorder(colors),
                        { marginRight: rem(0.5) },
                      )}
                      keyboardType="number-pad"
                      placeholder={`From ${
                        fromCurrency != null ? fromCurrency : ""
                      }`}
                      placeholderTextColor={colors.textSecondary}
                      onChangeText={this._onFromAmountChange}
                      value={fromAmount != null ? fromAmount.toString() : ""}
                      // key necessary to trigger autofocus
                      key={
                        JSON.stringify(fromCurrency) +
                        JSON.stringify(toCurrency)
                      }
                      autoFocus={fromCurrency != null && toCurrency != null}
                    />
                    <TextInput
                      style={themeComposer(
                        styles.input,
                        inputTheme,
                        inputBorder(colors),
                        { marginLeft: rem(0.5) },
                      )}
                      keyboardType="number-pad"
                      placeholder={`To ${toCurrency != null ? toCurrency : ""}`}
                      placeholderTextColor={colors.textSecondary}
                      onChangeText={this._onToAmountChange}
                      value={toAmount != null ? toAmount.toString() : ""}
                    />
                  </View>
                </View>
              )}
              {fromCurrency != null && toCurrency != null && (
                <Button
                  fullWidth
                  type="primary"
                  label="Add Transaction"
                  onPress={this._onAddTransaction}
                  disabled={
                    vault == null ||
                    fromAmount == null ||
                    Number.isNaN(Number(fromAmount)) ||
                    Number(fromAmount) <= 0 ||
                    toAmount == null ||
                    Number.isNaN(Number(toAmount)) ||
                    Number(toAmount) <= 0 ||
                    fromCurrency == null ||
                    toCurrency == null
                  }
                />
              )}
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
