// @flow
import React from "react";
import { noop } from "react-redux-flow-tools";
import { Platform, StyleSheet, Text, TextInput, View } from "react-native";
import type { StyleType } from "../../../theme/theme";
import { composeStyles, rem, ThemeContext } from "../../../theme/theme";
import { QR } from "../../../img/QR";
import {
  flexCenter,
  flexCenterFlow,
  inputBorder,
  inputStyle,
  inputTheme,
} from "../../../theme/common";
import { SquareView } from "../../../layout/SquareView";
import { Vault } from "../../../../api/models/PGP";
import { Button } from "../../../layout/Button";
import {
  clearVault,
  createVault,
  uploadVault,
} from "../../../../actions/account";
import { Heading } from "../../../layout/Heading";
import { LockClosedIcon, LockIcon } from "../../../img/Icon";
import { Download } from "../../../layout/download";
import { Upload } from "../../../layout/upload";

const styles = StyleSheet.create({
  section: composeStyles(flexCenter, {
    width: "100%",
  }),
  container: composeStyles(flexCenter, {
    width: "100%",
    flex: 1,
  }),
  qr: composeStyles({
    flex: 1,
    width: "100%",
    marginBottom: rem(1),
  }),
  lockSvgButton: composeStyles(flexCenter, {
    flex: 1,
    width: "100%",
  }),
  lockSvgContainer: composeStyles(flexCenter, {
    flex: 1,
    width: "100%",
    height: "100%",
  }),
  lockSvgTitle: composeStyles({
    fontSize: rem(1.75),
    fontWeight: "900",
    marginTop: -rem(1.25),
  }),
  nickname: composeStyles({
    fontWeight: "900",
  }),
  useridInput: composeStyles(
    inputStyle,
    {
      width: "100%",
      textAlign: "center",
    },
    Platform.OS === "web"
      ? {
          outline: "none",
        }
      : {},
  ),
  keyControl: composeStyles(flexCenterFlow("row"), {
    marginTop: rem(1),
    width: "100%",
  }),
  keyControlButton: composeStyles(flexCenter, {
    flex: 1,
    marginLeft: rem(0.25),
    marginRight: rem(0.25),
  }),
});

type QrDisplayPropsType = {|
  +vault: Vault,
  +onClearVault: typeof clearVault,
  +onCreateVault: typeof createVault,
|};

type QrDisplayComponentStatetype = {|
  userid: ?string,
  fingerprint: ?string,
|};

class QrDisplay extends React.PureComponent<
  QrDisplayPropsType,
  QrDisplayComponentStatetype,
> {
  state = {
    userid: undefined,
    fingerprint: undefined,
  };

  async componentDidMount() {
    const { vault } = this.props;
    const userid = await vault.keyPair.publicKey.userid();
    const fingerprint = await vault.keyPair.publicKey.fingerprint();
    this.setState({ userid, fingerprint });
  }

  _recreateKey = () => {
    const { userid } = this.state;
    if (userid != null) {
      this.props.onClearVault();
      this.props.onCreateVault(userid);
    }
  };

  render() {
    const { vault, onClearVault } = this.props;
    const keyData = vault.keyPair.publicKey.compress();
    const { userid, fingerprint } = this.state;
    const buttonDisabled = userid == null;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.container}>
            <SquareView style={styles.qr}>
              {() => (
                <Download
                  data={vault.serialize()}
                  mimeType="text/plain"
                  fileName={`${userid != null ? userid + "." : ""}keypair.txt`}
                  style={{ width: "100%", height: "100%" }}
                >
                  <QR type="png" data={keyData} />
                </Download>
              )}
            </SquareView>
            {userid != null ? (
              <View style={flexCenterFlow("row")}>
                <Text style={{ color: colors.textSecondary }}>
                  Device Nickname:&nbsp;
                </Text>
                <Text style={themeComposer(styles.nickname, { color: "juno" })}>
                  {userid}
                </Text>
              </View>
            ) : (
              <Text>Loading...</Text>
            )}
            {fingerprint != null ? (
              <Text style={{ color: colors.textSecondary }}>{fingerprint}</Text>
            ) : (
              <Text>Loading...</Text>
            )}
            <View style={styles.keyControl}>
              <Button
                onPress={this._recreateKey}
                disabled={buttonDisabled}
                style={styles.keyControlButton}
                type="default"
              >
                <Text style={{ color: colors.buttonSuccess }}>
                  Recreate Key
                </Text>
              </Button>
              <Button
                onPress={onClearVault}
                disabled={buttonDisabled}
                style={styles.keyControlButton}
                type="default"
              >
                <Text style={{ color: colors.buttonDanger }}>Delete Key</Text>
              </Button>
            </View>
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

type GenerateVaultPropsType = {|
  +onCreateVault: typeof createVault,
  +onUploadVault: typeof uploadVault,
|};

type GenerateVaultComponentStateType = {|
  userid: string,
  generating: boolean,
  uploading: boolean,
|};

class GenerateVault extends React.PureComponent<
  GenerateVaultPropsType,
  GenerateVaultComponentStateType,
> {
  state = {
    userid: "",
    generating: false,
    uploading: false,
  };

  _useridValid = (): boolean => this.state.userid.length >= 3;
  _onUseridChange = (userid: string) => this.setState({ userid });
  _onCreateVault = () => {
    this.setState({ generating: true });
    this.props.onCreateVault(this.state.userid);
  };

  _startUploading = () => this.setState({ uploading: true });
  _stopUploading = () => this.setState({ uploading: false });

  render() {
    const { userid, generating, uploading } = this.state;
    const { onUploadVault } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer, colors }) => (
          <View style={styles.container}>
            <Button
              feedback={!generating}
              onPress={generating ? noop : this._onCreateVault}
              disabled={!this._useridValid() || generating || uploading}
              type="default"
              style={composeStyles(flexCenter, {
                flex: 1,
                width: "100%",
              })}
            >
              <Upload
                disabled={this._useridValid() && !generating && !uploading}
                // disabled={generating}
                onData={(text: string | ArrayBuffer) =>
                  typeof text === "string" && onUploadVault(text)
                }
                onDragEnter={this._startUploading}
                onDragLeave={this._stopUploading}
                style={styles.lockSvgContainer}
                accept=".txt"
              >
                <View style={styles.lockSvgButton}>
                  {generating ? (
                    <LockClosedIcon style={styles.lockSvgContainer} />
                  ) : (
                    <LockIcon style={styles.lockSvgContainer} />
                  )}
                  <Text
                    style={themeComposer(styles.lockSvgTitle, {
                      color: "juno",
                    })}
                  >
                    {generating ? "GENERATING..." : "GENERATE VAULT"}
                  </Text>
                </View>
              </Upload>
            </Button>
            <TextInput
              autoFocus={true}
              style={themeComposer(
                styles.useridInput,
                inputTheme,
                inputBorder(colors),
              )}
              value={userid}
              placeholder="Device Nickname..."
              placeholderTextColor={colors.textSecondary}
              onChangeText={this._onUseridChange}
            />
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

type DeviceKeyPropsType = {|
  +vault: ?Vault,
  +style: StyleType,
  +onClearVault: typeof clearVault,
  +onCreateVault: typeof createVault,
  +onUploadVault: typeof uploadVault,
|};

export class DeviceKey extends React.PureComponent<DeviceKeyPropsType> {
  static defaultProps = {
    style: {},
  };
  render() {
    const {
      vault,
      style,
      onCreateVault,
      onClearVault,
      onUploadVault,
    } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <View style={themeComposer(styles.section, {}, style)}>
            <Heading>Device Key</Heading>
            {vault == null ? (
              <GenerateVault
                onUploadVault={onUploadVault}
                onCreateVault={onCreateVault}
              />
            ) : (
              <QrDisplay
                onCreateVault={onCreateVault}
                onClearVault={onClearVault}
                vault={vault}
              />
            )}
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}
