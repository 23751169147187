// @flow
import { rgb } from "d3-color";

const numToHex = (num: number): string =>
  ("0" + Math.round(num).toString(16)).slice(-2);
export const hexColor = (color: rgb, opacity: boolean = false): string =>
  `#${numToHex(color.r)}${numToHex(color.g)}${numToHex(color.b)}${
    opacity
      ? Math.round(255 * Math.max(0, Math.min(1.0, color.opacity))).toString(16)
      : ""
  }`;

export type DomainType = [number, number];
export type RangeType = [number, number];
