// @flow
import * as React from "react";
import { View } from "react-native";
import type { UploadPropsType } from "./UploadBase";
import { defaultProps } from "./UploadBase";
import { composeStyles } from "../../theme/theme";

const innerStyle = composeStyles({
  width: "100%",
  height: "100%",
  position: "absolute",
  cursor: "pointer",
  top: 0,
  left: 0,
  zIndex: 1000,
});

const inputStyle = { display: "none" };

export class Upload extends React.PureComponent<UploadPropsType> {
  static defaultProps = defaultProps;

  inside: boolean = false;
  input: ?HTMLInputElement;

  _onDragEnter = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!this.inside) {
      console.log(event, this.inside);
      this.inside = true;
      this.props.onDragEnter(event);
    }
  };
  _onDragLeave = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    console.log(event, this.inside);
    this.inside = false;
    this.props.onDragLeave(event);
  };
  _onDrop = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.inside = false;
    this.props.onDragLeave(event);
    // $FlowFixMe dataTransfer should be there
    const transferItem = event.dataTransfer.items[0];
    if (transferItem != null && transferItem.kind === "file") {
      const file = transferItem.getAsFile();
      const reader = new FileReader();
      reader.onload = (event) =>
        this.props.onData(event.target.result, file.name);
      if (this.props.type === "text") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  _onClick = (event: SyntheticEvent<HTMLDivElement>) => {
    if (this.input != null) {
      this.input.click();
    }
  };

  _onFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    if (this.input == null) {
      return;
    }
    // const file = event.nativeEvent.target.files[0];
    const file = this.input.files[0];
    if (file != null) {
      const reader = new FileReader();
      reader.onload = (event) =>
        this.props.onData(event.target.result, file.name);
      if (this.props.type === "text") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  render() {
    const { style, children, accept, disabled } = this.props;
    return (
      <View style={style}>
        <div
          onClick={!disabled ? this._onClick : null}
          onDragOver={!disabled ? this._onDragEnter : null}
          onDragLeave={!disabled ? this._onDragLeave : null}
          onDrop={!disabled ? this._onDrop : null}
          style={innerStyle}
        >
          <input
            ref={(r) => (this.input = r)}
            type="file"
            id="input"
            accept={accept}
            onChange={this._onFileChange}
            style={inputStyle}
          />
        </div>
        {children}
      </View>
    );
  }
}
