// @flow
import { connect } from "react-redux";
import type { StateType } from "../../reducers";

import { AppFrame } from "./AppFrame";
import { appActions } from "../../actions/app";
import type { DispatchType } from "../../actions/types";

const mapStateToProps = (state: StateType) => ({
  firstTime: state.app.firstTime,
  showBurger: state.app.showBurger,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onFirstTime: () => dispatch(appActions.app.receiveFirstTime()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppFrame);
