// @flow
import { connect } from "react-redux";
import { appActions } from "../../actions/app";

import { Dialog } from "./Dialog";
import type { StateType } from "../../reducers";
import { deepFreeze, withDefault } from "react-redux-flow-tools";
import type { DispatchType } from "../../actions/types";

const empty = deepFreeze({});

const mapStateToProps = (state: StateType) => {
  const dialog = withDefault(state.app.dialogs[0], empty);
  return {
    title: dialog.title,
    message: dialog.message,
    actions: dialog.actions,
  };
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onDismiss: () => dispatch(appActions.app.dialog.dismissFirst()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dialog);
