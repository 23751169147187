// @flow
import { handleActions } from "redux-actions";
import { actionHandler, deepFreeze } from "react-redux-flow-tools";
import type { CurrencySymbolType } from "../api/models";
import { CandleList } from "../api/models";
import { candlesActions } from "../actions/candles";
import type { Reducers } from "./types";

export type CandlesStateType = {
  +loading: boolean,
  +[CurrencySymbolType]: {
    +[CurrencySymbolType]: CandleList,
  },
};

export const candlesDefaults: CandlesStateType = deepFreeze({
  loading: false,
  BTC: {
    USD: new CandleList({
      candles: Object.freeze([]),
      base: "BTC",
      quote: "USD",
      exchanges: Object.freeze([]),
    }),
  },
});

const actionHandlers = {
  ...actionHandler(candlesActions.candles.receiveCandles, (state, action) => ({
    ...state,
    [action.payload.base]: {
      [action.payload.quote]: action.payload,
    },
  })),

  ...actionHandler(candlesActions.candles.receiveLoading, (state, action) => ({
    ...state,
    loading: action.payload,
  })),
};

export const candles: Reducers<CandlesStateType> = handleActions(
  actionHandlers,
  candlesDefaults,
);
