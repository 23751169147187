// @flow
const _defer = (contextSwitch) => <R>(
  fun: () => R | Promise<R>,
): Promise<R> => {
  return new Promise((resolve, reject) => {
    contextSwitch(async () => {
      try {
        resolve(await fun());
      } catch (err) {
        reject(err);
      }
    });
  });
};

export const defer = _defer(setTimeout);
defer.atAnimation = _defer(window.requestAnimationFrame);

const _noop = () => {};
const _release = (contextSwitch) => (probabilistic?: number) => {
  if (
    probabilistic == null ||
    probabilistic <= 0 ||
    probabilistic >= 1 ||
    Math.random() > probabilistic
  ) {
    return _defer(contextSwitch)(_noop);
  }
};
defer.release = _release(setTimeout);
defer.animation = _release(window.requestAnimationFrame);

defer.animated = (ms: number, chunked?: number) => {
  let lastDraw = Date.now();
  let processed = 0;
  return async (): Promise<void> => {
    if (
      Date.now() > lastDraw + ms &&
      (chunked == null || (chunked != null && processed > chunked))
    ) {
      await defer.animation();
      lastDraw = Date.now();
      processed = 0;
    } else {
      processed += 1;
    }
  };
};

/*defer.sort = async <T>(
  unsorted: $ReadOnlyArray<T>,
  comparator: (T, T) => number,
  interrupt?:
    | typeof defer.release
    | typeof defer.animation
    | $Call<$PropertyType<typeof defer, "animated">, number> = defer.release,
): Promise<T[]> => {
  const numParts = 4;
  if (unsorted.length <= Math.max(numParts, 100)) {
    await interrupt();
    return unsorted.slice(0).sort((a, b) => {
      return comparator(a, b);
    });
  }
  const elementsPerPart = Math.ceil(unsorted.length / numParts);
  const partPromises = [];
  for (let partIdx = 0; partIdx < numParts; partIdx++) {
    const start = partIdx * elementsPerPart;
    const stop = start + elementsPerPart;
    const sortedPartPromise: Promise<T[]> = defer.sort(
      unsorted.slice(start, stop),
      comparator,
      interrupt,
    );
    partPromises.push(sortedPartPromise);
  }
  const parts = await Promise.all(partPromises);
  const sorted: T[] = [];
  for (let elementIdx = 0; elementIdx < elementsPerPart; elementIdx++) {
    await interrupt();
    let element = parts[0][0];
    let partIdx = 0;
    let currentPartIdx = 0;
    for (let part of parts.slice(1)) {
      currentPartIdx += 1;
      const other = part[0];
      if (element == null) {
        element = other;
        continue;
      }
      if (other == null) {
        continue;
      }
      if (comparator(element, other) < 0) {
        element = other;
        partIdx = currentPartIdx;
      }
    }
    parts[partIdx].shift();
    sorted.push(element);
  }
  return sorted;
};*/
