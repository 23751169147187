// @flow
import * as React from "react";
import { View, StyleSheet } from "react-native";
import type { StyleType } from "../../theme/theme";
import { rem, ThemeContext } from "../../theme/theme";
import { Button } from "../Button";
import { LayoutAware } from "../LayoutAware";

export type SwipePropsType = {|
  +name: string,
  +showDots?: boolean,
  +tabIndex?: number,
  +initialTabIndex?: number,
  +onTabSelect: (number) => mixed,
  +children: React.Node,
  +style?: StyleType,
  +loadMinimal?: boolean,
  +loadMinimalSize?: number,
|};

export class SwipeBase<ST = {||}> extends LayoutAware<SwipePropsType, ST> {}
const dotSizeMargin = rem(0.5);
const dotSizeBase = rem(1);
export const dotSize = rem(1) + 2 * dotSizeMargin;

const dotStyles = StyleSheet.create({
  dot: {
    borderRadius: rem(0.5),
    width: dotSizeBase,
    height: dotSizeBase,
    margin: dotSizeMargin,
  },
});
type DotPropsType = {|
  +active?: boolean,
  +onPress?: () => mixed,
|};

export class Dot extends React.PureComponent<DotPropsType> {
  render() {
    const { active = false, onPress } = this.props;
    return (
      <ThemeContext.Consumer>
        {({ themeComposer }) => (
          <Button onPress={onPress}>
            <View
              style={themeComposer(dotStyles.dot, {
                backgroundColor: active ? "juno" : "transparentJuno",
              })}
            />
          </Button>
        )}
      </ThemeContext.Consumer>
    );
  }
}
