// @flow
import { handleActions } from "redux-actions";
import { actionHandler } from "react-redux-flow-tools";
import { Account, WAL } from "../api/models";
import { Vault } from "../api/models/PGP";
import { DeviceList } from "../api/models/device";
import type { Reducers } from "./types";
import { accountActions } from "../actions/account";

export type AccountStateType = {|
  +account: ?Account,
  +wal: ?WAL<Account>,
  +vault: ?Vault,
  +devices: DeviceList,
  +syncers: number,
  +uploaders: number,
  +seen: number,
|};

export const accountDefaults: AccountStateType = {
  account: undefined,
  wal: undefined,
  vault: undefined,
  devices: DeviceList.empty,
  syncers: 0,
  uploaders: 0,
  seen: -1,
};

const actionHandlers = {
  ...actionHandler(accountActions.account.clearAccount, (state) => ({
    ...state,
    account: undefined,
    wal: undefined,
  })),

  ...actionHandler(accountActions.account.receiveAccount, (state, action) => ({
    ...state,
    account: action.payload.account,
    wal: action.payload.wal,
    vault: action.payload.vault,
  })),

  ...actionHandler(accountActions.account.receiveVault, (state, action) => {
    return { ...state, vault: action.payload };
  }),

  ...actionHandler(accountActions.account.clearVault, (state) => {
    return { ...state, vault: undefined, seen: -1 };
  }),

  ...actionHandler(accountActions.account.receiveDevices, (state, action) => {
    return { ...state, devices: action.payload };
  }),

  ...actionHandler(accountActions.account.receiveDevice, (state, action) => {
    return { ...state, devices: state.devices.append(action.payload) };
  }),

  ...actionHandler(accountActions.account.removeDevice, (state, action) => {
    return {
      ...state,
      devices: state.devices.filter(({ name }) => name !== action.payload),
    };
  }),

  ...actionHandler(accountActions.account.clearDevices, (state) => {
    return {
      ...state,
      devices: DeviceList.empty,
    };
  }),

  ...actionHandler(accountActions.account.isSyncing, (state, action) => {
    return {
      ...state,
      syncers: state.syncers + (action.payload ? 1 : -1),
    };
  }),

  ...actionHandler(accountActions.account.isUploading, (state, action) => {
    return {
      ...state,
      uploaders: state.uploaders + (action.payload ? 1 : -1),
    };
  }),

  ...actionHandler(accountActions.account.receiveSeen, (state, action) => {
    return {
      ...state,
      seen: Math.max(action.payload, state.seen),
    };
  }),
};

export const account: Reducers<AccountStateType> = handleActions(
  actionHandlers,
  accountDefaults,
);
