// @flow
import { connect } from "react-redux";
import { Transactions } from "./Transactions";
import type { StateType } from "../../../reducers";
import { updateWAL } from "../../../actions/account";
import type { DispatchType } from "../../../actions/types";

const mapStateToProps = (state: StateType) => ({
  availableCurrencies:
    state.currencies.available != null ? state.currencies.available : {},
  walletCurrencies: [
    ...new Set(
      Object.keys(state.wallets).map(
        (address) => state.wallets[address].symbol,
      ),
    ),
  ],
  transactions: state.transactions.transactions,
  vault: state.account.vault,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onUpdate: (action, vault) => dispatch(updateWAL(action, vault)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transactions);
