// @flow
import * as React from "react";
import type { StyleType } from "../../theme/theme";
import { noop } from "react-redux-flow-tools";

export type UploadPropsType = {|
  +disabled: boolean,
  +children: React.Node,
  +style: StyleType,
  +type: "text" | "binary" | "image",
  +accept?: string,
  +onDragEnter: (event: SyntheticEvent<HTMLDivElement>) => mixed,
  +onDragLeave: (event: SyntheticEvent<HTMLDivElement>) => mixed,
  +onData: (data: string | ArrayBuffer, name: string) => mixed,
|};

export const defaultProps = {
  disabled: false,
  onDragEnter: noop,
  onDragLeave: noop,
  onData: noop,
  style: {},
  type: "text",
};
