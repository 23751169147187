// @flow
import React from "react";
import { accountTo, Route, Switch } from "../../router";
import DeviceManagementContainer from "./deviceManagement";
import AccountManagementContainer from "./accountManagement";
import TokenManagementContainer from "./tokenManagement";

export class Account extends React.PureComponent<{||}> {
  render() {
    return (
      <Switch>
        <Route
          path={accountTo("devices")}
          component={DeviceManagementContainer}
        />
        <Route
          path={accountTo("tokens")}
          component={TokenManagementContainer}
        />
        <Route path={accountTo()} component={AccountManagementContainer} />
      </Switch>
    );
  }
}
