// @flow
import uuid from "uuid/v1";
import React from "react";
import { connect } from "react-redux";
import type { StateType } from "../../reducers/index";
import JunoIMG from "./logo_path.png";
import "./Landing.scss";
import { sendAnalytics } from "../../actions/analytics";
import { Visit } from "../../api/models/visit";
import type { DispatchType } from "../../actions/types";

// eslint-disable-next-line
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValid = (email: string): boolean => emailRegEx.test(email);

const abTests: { +[string]: {| +tagline: string, +valueprop: string |} } = {
  initial: {
    tagline: "... why settle for the moon?",
    valueprop: "Monitor your entire crypto-token portfolio with JUNO",
  },
};
const sessionStorageKey = "__juno_anonymous_analytics";
const cachedSessionHash = sessionStorage.getItem(sessionStorageKey);
const sessionHash = cachedSessionHash == null ? uuid() : cachedSessionHash;
sessionStorage.setItem("__juno_anonymous_analytics", uuid());

const abTestTags = Object.keys(abTests);
const tag = abTestTags[Math.floor(Math.random() * abTestTags.length)];
const abTest = abTests[tag];

class _Landing extends React.PureComponent<
  {| +onSendAnalytics: typeof sendAnalytics |},
  {| email: string |},
> {
  form: ?HTMLFormElement;
  state = {
    email: "",
  };

  _onChange = (evt?: SyntheticEvent<HTMLInputElement>) => {
    if (evt != null) {
      this.setState({ email: evt.currentTarget.value });
    }
  };

  componentDidMount() {
    const visit = Visit.visit(tag, sessionHash);
    this.props.onSendAnalytics(visit);
  }

  render() {
    const { onSendAnalytics } = this.props;
    const { email } = this.state;
    return (
      <main id="app">
        <section id="head">
          <img src={JunoIMG} alt="JUNO" />
          <h1>{abTest.tagline}</h1>
        </section>
        <section id="hero">
          <img
            src="/static/img/phone_template_sidebar.png"
            alt="portfolio manager"
          />
          <img
            src="/static/img/phone_template_radial_unfilled.png"
            alt="portfolio manager"
          />
          <img
            src="/static/img/phone_template_longchart_empty.png"
            alt="portfolio manager"
          />
        </section>
        <section id="signup" className="box">
          <h1>{abTest.valueprop}</h1>
          <h2>Enter your email to get invited!</h2>
          <form
            id="subscribe-form"
            action="/subscribe"
            method="POST"
            ref={(r) => (this.form = r)}
          >
            <input
              name="address"
              placeholder="jane.doe@example.com"
              type="email"
              onChange={this._onChange}
              value={email}
            />
            <button
              onClick={() => {
                onSendAnalytics(Visit.signup(tag, sessionHash));
                if (this.form != null) {
                  this.form.submit();
                }
              }}
              className={`g-recaptcha ${!emailValid(email) ? "disabled" : ""}`}
              data-sitekey="6Lcv7kIUAAAAAEgdk6Lfz1uetsRHJQD6rlO2Pqyg"
              data-callback="recaptchaSubmit"
            >
              Submit
            </button>
          </form>
          <a
            className="slack-link"
            onClick={() => onSendAnalytics(Visit.testflight(tag, sessionHash))}
            href="https://testflight.apple.com/join/Tiz07eKj"
          >
            <span>Download the Beta Release:</span>
            <img
              src="/static/img/Download_on_the_App_Store_Badge.svg"
              alt="App Store Logo"
              style={{ marginLeft: "0.5rem" }}
            />
          </a>
          <a
            className="slack-link"
            onClick={() => onSendAnalytics(Visit.slack(tag, sessionHash))}
            href="https://join.slack.com/t/junorocks/shared_invite/enQtMzY4MDY4NTM4ODAwLTFmNjIxODUzMTg1ZDVmZmRhYTY5NDcyZjk2N2E4ODFkZDAxOWJiNmY2YzVkY2RmMjVlMDU4ZGU5MzBmNGNlMGY"
          >
            <span>Join the Conversation on:</span>
            <img src="/static/img/Slack_RGB.svg" alt="slack logo" />
          </a>
        </section>
        <p style={{ textAlign: "center" }}>
          By using JUNO you agree to our&nbsp;
          <a href="/privacy">privacy statement</a>.<br />
          TL;DR: We don't use cookies and have no way of knowing who you are.
        </p>
      </main>
    );
  }
}

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  onSendAnalytics: (visit) =>
    process.env.NODE_ENV === "production" && dispatch(sendAnalytics(visit)),
});

export const Landing = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_Landing);
